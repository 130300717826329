import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import omit from 'lodash/omit';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { SingleDatePicker } from 'react-dates';
import { SingleDatePickerPhrases } from 'react-dates/esm/defaultPhrases';
import { HORIZONTAL_ORIENTATION, ANCHOR_LEFT } from 'react-dates/constants';
import isInclusivelyAfterDay from 'react-dates/esm/utils/isInclusivelyAfterDay';



const defaultProps = {
  autoFocus: false,
  initialDate: null,
  id: 'single_date',
  placeholder: "Stat Date",
  disabled: false,
  required: true,
  screenReaderInputMessage: '',
  showClearDate: true,
  showDefaultInputIcon: false,
  customInputIcon: null,
  block: false,
  small: false,
  regular: false,
  verticalSpacing: undefined,
  keepFocusOnInput: false,
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDate: false,
  isRTL: false,
  hideKeyboardShortcutsPanel:true,
  firstDayOfWeek:1,
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},
  renderCalendarDay: undefined,
  renderDayContents: null,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => {},
  displayFormat: () => moment.localeData().longDateFormat('L'),
  monthFormat: 'DD MMMM YYYY',
  phrases: SingleDatePickerPhrases,
};

class SingleDatePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    const separator =
      this.props.item && this.props.item.separator
        ? this.props.item.separator
        : '/';

    const dateFormat =
      this.props.item && this.props.item.format
        ? this.props.item.format
        : 'L';

    this.state = {
      focused: props.autoFocus,
      date: props.initialDate,
      dateFormat,
      separator
    };


    this.onDateChange = this.onDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDateChange(date) {
    const { dateFormat } = this.state;

    this.setState({ date });

    if (date !== null) {
      let dateValue = moment(date.format(dateFormat)).format("DD-MM-YYYY")
      this.props.onDateChange({
        setDate: dateValue
      });

    }

  }

  onFocusChange({ focused }) {
    this.setState({ focused });
  }





  render() {
    const { focused, date } = this.state;

    // autoFocus and initialDate are helper props for the example wrapper but are not
    // props on the SingleDatePicker itself and thus, have to be omitted.
    const props = omit(this.props, ['autoFocus', 'initialDate']);



    return (
              <SingleDatePicker
                {...props}
                date={date}
                focused={focused}
                onDateChange={this.onDateChange}
                onFocusChange={this.onFocusChange}
                id="single_picker"
              />
    );
  }
}

SingleDatePickerWrapper.propTypes={
/** updateSearchData of the date-picker field to send component data to parent component */
  onDateChange: PropTypes.func,

};
SingleDatePickerWrapper.defaultProps = defaultProps;

export default SingleDatePickerWrapper;
