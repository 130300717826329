import { FC } from "react";
import { PostDataType } from "data/types";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero from "components/SectionHero/SectionHero";
import sustainIMG from "images/sustain1.png";
import SectionStatistic from "./SectionStatistic";




export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  className: "";
}

const PageSustainability: FC<React.PropsWithChildren<React.PropsWithChildren<PageSingleProps>>> = ({ className = "" }) => {


  const { t } = useTranslation();
  return (
    <div
      className={`nc-PageSingle pt-8 lg:pt-16 container ${className}`}
      data-nc-id="PageSingle"
    >
    <Helmet>
        <title>Sustainability || Gonnaway</title>
      </Helmet>
            <BgGlassmorphism />
        <div className="container py-8 lg:py-12 space-y-12 lg:space-y-16">
        <SectionHero
          rightImg={sustainIMG}
          heading="🏕 Sustainability"
          btnText=""
          subHeading={t('sustain.header')}
        />
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark word-break text-neutral-6000 dark:text-neutral-400">
            <Heading desc={t('sustain.respect')} className="w-full">
                🛶 {t('sustain.First')}
          </Heading>
          <div className="mx-auto ">
                <p style={{textAlign:"justify"}}>{t('sustain.desc')}</p>
                <p style={{textAlign:"justify"}}>{t('sustain.descCont')}</p>
          </div>
        </div>

        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark word-break text-neutral-6000 dark:text-neutral-400">
            <Heading desc="" className="w-full">
                📜 {t('sustain.Second1')}
          </Heading>
          <div className="mx-auto ">
                <p style={{textAlign:"justify"}}>{t('sustain.Second2')}</p>
                <ul style={{textAlign:"justify"}}>
                    <li>{t('sustain.Second3')}</li>
                    <li>{t('sustain.Second4')}</li>
                    <li>{t('sustain.Second5')}</li>
                    <li>{t('sustain.Second6')}</li>
                </ul>
          </div>
        </div>

        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        <div className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark word-break text-neutral-6000 dark:text-neutral-400">
            <Heading desc="" className="w-full">
                📝 {t('sustain.Third1')}
          </Heading>
          <div className="mx-auto ">
                <ul style={{textAlign:"justify"}}>
                    <li>{t('sustain.Third2')}</li>
                    <li>{t('sustain.Third3')}</li>
                    <li>{t('sustain.Third4')}</li>
                    <li>{t('sustain.Third5')}</li>
                    <li>{t('sustain.Third6')}</li>
                    <li>{t('sustain.Third7')}</li>
                </ul>
          </div>
        </div>



        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>

        <SectionStatistic />



      </div>
    </div>
  );
};



export default PageSustainability;
