/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-loop-func */
import React from "react";
import { useNavigate } from "react-router";
import "./HereMap.css";
import { useAppSelector } from "app/hooks";
import { selectCountries } from "app/content/content";
import { useTranslation } from 'react-i18next';
import HereMap from 'components/HereMapComponent/HEREMap';
import MarkerMap from 'components/HereMapComponent/Marker';
import ncNanoId from "utils/ncNanoId";

export interface PostCardLikeActionProps {
  countries: [];
}

const HereMapHome: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const countries: any = useAppSelector(selectCountries);
  const history = useNavigate();
  const mapRef = React.useRef(null);
  const {t} = useTranslation();

  const mapId="mapHome"+ncNanoId();
  return (
   <div
      className="relative"
      ref={mapRef}
      style={{ width: "100%", height: "70vh", minHeight: "400px" }}
    >
        <HereMap
          mapContainerId={mapId}
          center={{ lat: 52.5321472, lng: 13.3935785 }}
          zoom={3}
          animateZoom={true}
          interactive={true}
          secure={true}
          homeMap={true}
          countries={countries}
        />
    </div>


  );
};

export default HereMapHome;
