import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {clearUpTokens} from "../../TokenAuth";

interface UserState {
  isLoggedIn: boolean;
  user: {};
}

const initialState: UserState = {
  isLoggedIn: false ,
  user: {},
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{}>) => {
      state = {
        isLoggedIn: true,
        user: action.payload,
      };
      return state;
    },
    removeUser: (state) => {
      clearUpTokens();
      state = {
       user: {},
       isLoggedIn: false
      };
      return state;
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export const selectUser = (state: RootState) => state.user


export default userSlice.reducer;
