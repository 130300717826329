import { FC } from "react";

export interface PageCategoryProps {
  className?: string;
}

const PageCategory: FC<React.PropsWithChildren<React.PropsWithChildren<PageCategoryProps>>> = ({ className = "" }) => {

  return (

    <div className={`nc-PageCategory ${className}`} data-nc-id="PageCategory">

    </div>
  );
};

export default PageCategory;
