import React, { FC, useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MdAlarmOn,MdFlightTakeoff,MdModeOfTravel } from "react-icons/md";
import { FaCoins} from "react-icons/fa";


export interface Suggestion {
  id: string;
  heading: string;
  subHeading: string;
  icon:any;
}



export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<React.PropsWithChildren<React.PropsWithChildren<SectionStatisticProps>>> = ({ className = "" }) => {
const { t } = useTranslation();
const SUGGESTION_DATA: Suggestion[] = [
  {
    id: "1",
    heading: "Itinerary",
    subHeading: t("nomad.nomad.description"),
    icon: <MdModeOfTravel style={{height:"60px",width:"60px",color:"#008487"}}/>
  },
  {
    id: "2",
    heading: "Everywhere",
    subHeading: t('nomad.everywhere'),
    icon: <MdFlightTakeoff style={{height:"60px",width:"60px",color:"#008487"}}/>
  },
  {
    id: "3",
    heading: "Readiness",
    subHeading: t('nomad.readiness'),
    icon: <MdAlarmOn style={{height:"60px",width:"60px",color:"#008487"}}/>
  },
  {
    id: "4",
    heading: "Save Money",
    subHeading: t('nomad.savemoney'),
    icon: <FaCoins style={{height:"60px",width:"60px",color:"#008487"}}/>
  },
];

///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);


  return (
    <div className={`nc-SectionStatistic relative ${className}`}>

      <div className="grid md:grid-cols-3 gap-5 lg:grid-cols-4 xl:gap-8">

        {SUGGESTION_DATA.map((item) => (
          <div
            key={item.id}
            className="p-6 rounded-2xl dark:border-neutral-800"
          >
            <span className="flex justify-center items-center">{item.icon}</span>
            <h3 className="flex mt-3 items-center justify-center text-xl font-semibold leading-none text-neutral-800 dark:text-neutral-100 md:text-4xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span style={{textAlign:"justify"}} className="flex items-center justify-center block text-base text-neutral-800 dark:text-neutral-100 mt-3 sm:text-base ">
              {item.subHeading}
            </span>

          </div>

        ))}




      </div>



    </div>
  );
};

export default SectionStatistic;
