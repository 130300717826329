import React, { FC } from "react";
import { TaxonomyType } from "data/types";
import CardCategory1 from "components/CardCategory1/CardCategory1";
import NcModal from "components/NcModal/NcModal";
import { useTranslation } from 'react-i18next';


export interface ModalCategoriesProps {
  categories: TaxonomyType[];
}

const ModalCategories: FC<React.PropsWithChildren<React.PropsWithChildren<ModalCategoriesProps>>> = ({ categories }) => {
  const renderModalContent = () => {
    return (
      <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
        {categories.map((cat) => (
          <CardCategory1 key={cat.id} taxonomy={cat} size="normal" />
        ))}
      </div>
    );
  };
  const {t} = useTranslation();


  return (
    <div className="nc-ModalCategories">
      <NcModal
        triggerText={
          <span>
            <span className="sm:inline">{t('category.other')}</span>
          </span>
        }
        modalTitle={t('category.other')}
        renderContent={renderModalContent}
      />
    </div>
  );
};

export default ModalCategories;
