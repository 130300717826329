import FetchData from "FetchData";
import MyRouter from "routers/index";
import React from "react";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
// @ts-ignore
import { CookieBanner } from '@palmabit/react-cookie-law';
import { useTranslation } from 'react-i18next';


function App() {
  const {t} = useTranslation();

  const lrocket=process.env["REACT_APP_LOGROCKET"] as string;

  return (

    <div className="bg-[#fff] text-base dark:bg-neutral-900/95 text-neutral-900 dark:text-neutral-200 font-body">
      <FetchData />
      <MyRouter />
      <CookieBanner
          message={t('cookie')}
          wholeDomain={true}
          policyLink="https://www.gonnaway.com/privacy"
          privacyPolicyLinkText="Privacy Policy"
          acceptButtonText={t('cookieAccept')}
          declineButtonText="Decline"
          setDeclineCookie={true}
          dismissOnScroll= {false}
          showDeclineButton={true}
          showPreferencesOption={true}
          showStatisticsOption={true}
          showMarketingOption={true}
          preferencesOptionText={t('cookiePref')}
          statisticsOptionText={t('cookieStatistic')}
          marketingOptionText={t('cookieMarketing')}
          onAccept={()=>{
             LogRocket.init(lrocket);
             setupLogRocketReact(LogRocket);

          }}
          onDecline={() => {LogRocket.init("");}}
          onAcceptPreferences={() => {LogRocket.init(lrocket);setupLogRocketReact(LogRocket);}}
          onAcceptStatistics={() => {LogRocket.init(lrocket);setupLogRocketReact(LogRocket);}}
          onAcceptMarketing={() => {LogRocket.init(lrocket);setupLogRocketReact(LogRocket);}}
          onDeclinePreferences={() => {LogRocket.init("");}}
          onDeclineStatistics={() => {LogRocket.init("");}}
          onDeclineMarketing={() => {LogRocket.init("");}}
          managePreferencesButtonText={t('cookieManage')}
          styles={{
              dialog: {
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    zIndex: '100000',
                    backgroundColor: '#f8f7f7',
                    padding: '10px',
              }
          }}
        />
    </div>

  );
}

export default App;
