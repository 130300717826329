import React, {FC, useState } from 'react';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import DateRangePickerBox from 'components/DatePicker/ReactDates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import {FaRegCalendar, FaUserFriends,FaMoneyBillWave,FaStar,FaHotel,FaHeart} from 'react-icons/fa';
import { setStateToUrl } from 'helpers/url_handler';
import Slider from '@mui/material/Slider';
import { HOTELS_DETAILS_PAGE } from 'data/constant';
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import { Fragment } from "react";
import { IoIosArrowDown } from 'react-icons/io';
import { HotelSearch} from "data/types";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  FilterArea,
  FilterElementsWrapper,
  RoomGuestWrapper,
  ItemWrapper,
} from './CategorySearchDark.style';



export interface ModalCategoriesProps {
  dataSearch: HotelSearch;
  budgetData:number;
  changeSelectedFilter?:()=>void;
  filterData?: () =>void;
}

const CategorySearch: FC<React.PropsWithChildren<React.PropsWithChildren<ModalCategoriesProps>>> = ({
    dataSearch,
    budgetData,
    changeSelectedFilter=(select:any)=>{},
    filterData = (budget:number, stars:number,rating:any) => {},

}) => {
 const {t} = useTranslation();

 const [classStar1,setClassStart1]= useState ("text-neutral-500");
  const [classStar2,setClassStart2]= useState ("text-neutral-500");
  const [classStar3,setClassStart3]= useState ("text-neutral-500");
  const [classStar4,setClassStart4]= useState ("text-neutral-500");
  const [classStar5,setClassStart5]= useState ("text-neutral-500");
  const [selectedStars, setSelectedStars] = useState(1);

  const [searchDate, setSearchDate] = useState({
        setStartDate: dataSearch.checkin_date,
        setEndDate: dataSearch.checkout_date,
  });


  // Flight guest state
  const [hotelAdult,setHotelAdult] = useState (dataSearch.adults_count);
  const [hotelChild,setHotelChild] = useState (dataSearch.childs_count);
  const [hotelInfant,setHotelInfant] = useState (dataSearch.infants_count);
  const [hotelRoom,setHotelRoom] = useState (dataSearch.rooms_count);
  const [hotelBudget,setHotelBudget] = useState (budgetData);
  const [changeDate, setChangeDate] = useState(false);
  const [changeRoom, setChangeRoom] = useState(false);
  const [changeBudget, setChangeBudget] = useState(false);
  const [changeStar, setChangeStar] = useState(false);

    //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };

   const FILTERS : ListBoxItemType[]= [
        {id: 0, name: t("filterFH") },
        {id: 1, name: t('filter.best') },
        {id: 2, name: t('filter.cheapest') },
        {id: 3, name: t('filter.close') },
    ];

  const [selected, setSelected] = useState(FILTERS[0]);

  const changeSelectedStarFilter = (value:number) =>
 {
    switch (value)
    {
       default: setClassStart1("text-neutral-500");setClassStart2("text-neutral-500");setClassStart3("text-neutral-500");setClassStart4("text-neutral-500");setClassStart5("text-neutral-500");setSelectedStars(0);break;
       case 1: setChangeStar(true);setClassStart1("text-yellow-600");setClassStart2("text-neutral-500");setClassStart3("text-neutral-500");setClassStart4("text-neutral-500");setClassStart5("text-neutral-500"); setSelectedStars(1);
                break;
       case 2: setChangeStar(true);setClassStart1("text-neutral-500");setClassStart2("text-yellow-600");setClassStart3("text-neutral-500");setClassStart4("text-neutral-500");setClassStart5("text-neutral-500");setSelectedStars(2);
                break;
       case 3: setChangeStar(true);setClassStart1("text-neutral-500");setClassStart2("text-neutral-500");setClassStart3("text-yellow-600");setClassStart4("text-neutral-500");setClassStart5("text-neutral-500");setSelectedStars(3);
                break;
       case 4: setChangeStar(true);setClassStart1("text-neutral-500");setClassStart2("text-neutral-500");setClassStart3("text-neutral-500");setClassStart4("text-yellow-600");setClassStart5("text-neutral-500");setSelectedStars(4);
                break;
       case 5: setChangeStar(true);setClassStart1("text-neutral-500");setClassStart2("text-neutral-500");setClassStart3("text-neutral-500");setClassStart4("text-neutral-500");setClassStart5("text-yellow-600");setSelectedStars(5);
               break;
    }
 }



  const handleIncrementAdult = () => {
        setHotelAdult(hotelAdult+1);
        setChangeRoom(true);
  };


  const handleIncrementChild = () => {
    setHotelChild(hotelChild+1);
    setChangeRoom(true);
  };
  const handleIncrementInfant = () => {
    setHotelInfant(hotelInfant+1);
    setChangeRoom(true);
  };

   const handleIncrementBudget = () => {
    setHotelBudget(hotelBudget+100);
    setChangeBudget(true);
  };

  const handleIncrementRoom = () => {
    setHotelRoom(hotelRoom+1);
    setChangeRoom(true);
  };


  //set guests number
  const handleDecrementAdult = () => {
    if(hotelAdult<=1)
    {
        setHotelAdult(1);
    } else{
      setHotelAdult(hotelAdult-1);
    }
    setChangeRoom(true);
  };

  //set guests number
  const handleDecrementChild = () => {
    if(hotelChild<=0)
    {
        setHotelChild(0);
    } else {
      setHotelChild(hotelChild-1);
    }
    setChangeRoom(true);
  };

  //set guests number
  const handleDecrementInfant = () => {
    if(hotelInfant<=0)
    {
        setHotelInfant(0);
    } else {
      setHotelInfant(hotelInfant-1);
    }
    setChangeRoom(true);
  };

  //set guests number
  const handleDecrementRoom = () => {
    if(hotelRoom<=1)
    {
        setHotelRoom(1);
    } else {
      setHotelRoom(hotelRoom-1);
    }
    setChangeRoom(true);
  };



  //set guests number
  const handleDecrementBudget = () => {
    if(hotelBudget<=0)
    {
        setHotelBudget(0);
    } else {
      setHotelBudget(hotelBudget-100);
    }
    setChangeBudget(true);
  };


  //DATE
  let oneOrReturn= (
                        <DateRangePickerBox
                           id="date_picker"
                           item={calendarItem}
                           startDateId="startDateId-id-home"
                           endDateId="endDateId-id-home"
                           updateSearchData={(setDateValue:any) => {setSearchDate(setDateValue);setChangeDate(true);}}
                           showClearDates={true}
                           small={true}
                           numberOfMonths={1}
                           required={true}
                           startDatePlaceholderText={dataSearch.checkin_date}
                           endDatePlaceholderText={dataSearch.checkout_date}
                           onFocusChange={(e:any) => e.target.blur()}
                         />);


      const history = useNavigate();

      const goToSearchPage = () => {
       const query = {
          checkin_date: searchDate.setStartDate,
          checkout_date: searchDate.setEndDate,
          rooms_count: hotelRoom===0 ? 1 : hotelRoom,
          adults_count: hotelAdult === 0 ? 1 : hotelAdult,
          childs_count: hotelChild,
          infants_count: hotelInfant,
          city_code: dataSearch.city_code,
          accomodation_only: true,
          stars:selectedStars
        };

    const search = setStateToUrl(query);
    history({
      pathname: HOTELS_DETAILS_PAGE,
      search: search,
    });
 };

 /*CALLBACKS*/
    const filterOrderByCallback = (e:any) => {
      changeSelectedFilter(e);
  };

  const filterCallback = () => {
        filterData(hotelBudget,selectedStars,valueRating);
  };
 /*end*/

 /*management of Accordion expansion*/
  const [exDate,setExDate]=useState(true);
  const [exGuest,setExGuest]=useState(true);
  const [exBudget,setExBudget]=useState(true);
  const [exStars,setExStars]=useState(true);
  const [exRating,setExRating]=useState(true);

  const handleSetExDate=(e:any)=>{
    e.preventDefault();
    setExDate(!exDate);
  }

  const handleSetExGuest=(e:any)=>{
    e.preventDefault();
    setExGuest(!exGuest);
  }

  const handleSetExBudget=(e:any)=>{
    e.preventDefault();
    setExBudget(!exBudget);
  }

  const handleSetExStars=(e:any)=>{
    e.preventDefault();
    setExStars(!exStars);
  }

  const handleSetExRating=(e:any)=>{
    e.preventDefault();
    setExRating(!exRating);
  }

  const [valueRating, setValueRating] = React.useState<number[]>([0, 100]);
  const handleChangeRating = (event: Event, newValue: number | number[]) => {
    setValueRating(newValue as number[]);
  };

  function valuetext(value: number) {
    return `${value}`;
  }


  /*end management of expanded Accordion*/



  return (
    <FilterArea>
        <div className="p-6 mt-10">

            <div
                      className={`nc-ArchiveFilterListBox md:-mr-1`}
                      data-nc-id="ArchiveFilterListBox"
            >

                    <Listbox value={selected} onChange={(e:any)=>filterOrderByCallback(e)}>
                        <div className="relative md:min-w-[200px]">
                          <Listbox.Button as={"div"}>
                            <ButtonDropdown>{selected.name}</ButtonDropdown>
                          </Listbox.Button>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900 dark:text-neutral-200 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700">
                              {FILTERS.map((item: ListBoxItemType, index: number) => (
                                <Listbox.Option
                                  key={index}
                                  className={({ active }) =>
                                    `${
                                      active
                                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                        : ""
                                    } cursor-default select-none relative py-2 pl-10 pr-4`
                                  }
                                  value={item}
                                >
                                  {({ selected }) => (
                                    <>
                                      <span
                                        className={`${
                                          selected ? "font-medium" : "font-normal"
                                        } block truncate`}
                                      >
                                        {item.name}
                                      </span>
                                      {selected ? (
                                        <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                    </Listbox>
            </div>


        <FilterElementsWrapper>
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-10"></div>
          <div className="w-full">
            <b><h2 className="text-neutral-900 dark:text-neutral-200 mt-5 text-center ">{t("filter.search")}</h2></b>
          </div>
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-5"></div>
          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {/* Start date filter element */}
            <AccordionItem dangerouslySetExpanded={exDate}>
              <AccordionItemHeading onClick={(e)=>handleSetExDate(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-100 ml-1"><FaRegCalendar className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-100">Date</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {oneOrReturn}
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of date filter element */}

            {/* Room & Guest type filter element */}
            <AccordionItem dangerouslySetExpanded={exGuest}>
              <AccordionItemHeading onClick={(e)=>handleSetExGuest(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaUserFriends className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-100">{t('filter.guest')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>

                        <ItemWrapper>
                            <strong ><span className="text-neutral-100">{t('room')}</span></strong>
                            <InputIncDec
                              id="room"
                              className="text-neutral-100"
                              increment={() => handleIncrementRoom()}
                              decrement={() => handleDecrementRoom()}
                              value={hotelRoom}
                            />
                          </ItemWrapper>
                        <ItemWrapper>
                            <strong><span className="text-neutral-100">{t('adult')}</span></strong>
                            <InputIncDec
                              id="adult"
                              className="text-neutral-100"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={hotelAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong><span className="text-neutral-100">{t('child')}</span></strong>
                            <InputIncDec
                              id="child"
                              className="text-neutral-100"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={hotelChild}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong><span className="text-neutral-100">{t('setInfant')}</span></strong>
                            <InputIncDec
                              id="infant"
                              className="text-neutral-100"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={hotelInfant}
                            />
                          </ItemWrapper>

                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of Room & Guest type filter element */}
            <ButtonPrimary className="mt-10 mb-5 w-full" onClick={goToSearchPage}>
                {t('filter.search')}
            </ButtonPrimary>

            </Accordion>
          </FilterElementsWrapper>
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-10"></div>
          <div className="w-full ">
          <b><h2 className="text-neutral-900 dark:text-neutral-200 mt-5 text-center">{t("filter.title")}</h2></b>
          </div>
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-5"></div>
          <FilterElementsWrapper>

            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
            {/* Start stars filter element */}
            <AccordionItem dangerouslySetExpanded={exStars}>
              <AccordionItemHeading onClick={(e)=>handleSetExStars(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaHotel className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-100">{t('filter.star')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                                <p className={`flex flex-wrap ${classStar1}`} onClick={() => changeSelectedStarFilter(1)}><FaStar /></p>
                             </ItemWrapper>
                             <ItemWrapper>
                                <span className={`flex flex-wrap ${classStar2}`} onClick={() => changeSelectedStarFilter(2)}><FaStar /><FaStar /></span>
                             </ItemWrapper>
                             <ItemWrapper>
                                <span className={`flex flex-wrap ${classStar3}`} onClick={() => changeSelectedStarFilter(3)}><FaStar /><FaStar /><FaStar /></span>
                             </ItemWrapper>
                             <ItemWrapper>
                                <span className={`flex flex-wrap ${classStar4}`} onClick={() => changeSelectedStarFilter(4)}><FaStar /><FaStar /><FaStar /><FaStar /></span>
                             </ItemWrapper>
                             <ItemWrapper>
                                <span className={`flex flex-wrap ${classStar5}`} onClick={() => changeSelectedStarFilter(5)}><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></span>
                             </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of price range filter element */}




            {/* Start price range filter element */}
            <AccordionItem dangerouslySetExpanded={exBudget}>
              <AccordionItemHeading onClick={(e)=>handleSetExBudget(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-100 ml-1"><FaMoneyBillWave className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-100">Budget</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong><span className="text-neutral-100">Budget</span></strong>
                                <InputIncDec
                                  id="budget"
                                  className="text-neutral-100"
                                  increment={() => handleIncrementBudget()}
                                  decrement={() => handleDecrementBudget()}
                                  value={hotelBudget}
                                />
                            </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of price range filter element */}

             {/* Start customer rating range filter element */}
            <AccordionItem dangerouslySetExpanded={exRating}>
              <AccordionItemHeading onClick={(e)=>handleSetExRating(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-100 ml-1"><FaHeart className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">Rating</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong><span className="text-neutral-100">Customer Rating</span></strong>
                                 <Slider
                                    getAriaLabel={() => 'Customer Rating'}
                                    value={valueRating}
                                    onChange={handleChangeRating}
                                    getAriaValueText={valuetext}
                                    min={0}
                                    max={100}
                                    valueLabelDisplay="on"

                                />
                            </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of customer rating filter element */}





          </Accordion>
          <ButtonPrimary className="mb-10 w-full" onClick={filterCallback}>
            {t('filter.apply')}
          </ButtonPrimary>



        </FilterElementsWrapper>
        </div>
    </FilterArea>
  );
};

export default CategorySearch;
