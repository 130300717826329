import React, { FC } from "react";
import SectionSustainability from 'components/SectionSustainability/SectionSustainability';
import { useAppSelector } from "app/hooks";
import SectionSearchFlight from 'components/SectionSearchFlight/SectionSearchFlight';
import SectionSearchFlightDark from 'components/SectionSearchFlight/SectionSearchFlightDark';
import useScriptAds from "hooks/useScriptAds";
import { useThemeMode } from "hooks/useThemeMode";



export interface SidebarProps {
  className?: string;
}

/*const widgetPosts: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 2 && i < 7);
const tags = DEMO_TAGS.filter((_, i) => i > 5);
const categories = DEMO_CATEGORIES.filter((_, i) => i > 7 && i < 13);
const authors = DEMO_AUTHORS.filter((_, i) => i < 5);*/

 const Sidebar: FC<React.PropsWithChildren<React.PropsWithChildren<SidebarProps>>> = ({ className = "space-y-16 " }) => {
   const {isDarkMode}=useThemeMode();
   //let scriptTemp=useScriptAds("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4781953001044216");

  return (
    <div className={`nc-SingleSidebar ${className} `}>
     {/* <WidgetTags tags={tags} />
      <WidgetCategories categories={categories} />
      <WidgetAuthors authors={authors} />
      <WidgetPosts posts={widgetPosts} />*/}
      <div className="mt-6">
          {isDarkMode ? (
                <SectionSearchFlightDark />
          ):(
                <SectionSearchFlight />
          )}
      </div>
      <div className="max-w-screen-md mt-6 mx-auto border-b border-t border-neutral-200 dark:border-neutral-700"></div>
      <SectionSustainability className="py-16 lg:py-28" />

     {/*} <div id="gonnaway_ads" className="relative mt-6" >
            {scriptTemp}

      </div>*/}
    </div>
  );
};

export default Sidebar;
