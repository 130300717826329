import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import { useTranslation } from 'react-i18next';
import SectionSliderNewSuggestedPlace from "components/SectionSliderNewSuggestedPlace/SectionSliderNewSuggestedPlaces";
import Spain from "images/slide/Minorca.jpg";
import itineraries from "data/jsons/__suggestedPlaces.json";


export interface SectionSuggestions {
  className?: string;
  heading?:string;
}

const SectionSuggestedPlaces: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSuggestions>>> = ({
  heading = "",
  className = "",
}) => {
  const {t} = useTranslation();

  return (
    <div className={`nc-SectionTrending mx-14 relative ${className}`}>
      {!!heading && <Heading>{heading}</Heading>}
      <div className="">
        <SectionSliderNewSuggestedPlace
              className="pb-8 lg:pb-8"
              heading="🛫 Gonnaway Suggestions"
              categoryCardType="card5"
              itemPerRow={4}
              subHeading={t('nomad.suggestions')}
              itineraries={itineraries}
            />

      </div>
    </div>
  );
};

export default SectionSuggestedPlaces;

