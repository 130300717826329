import React, { useState,useEffect } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import useFetch from "hooks/useFetchAuthn";
import Select from "components/Select/Select";
import Textarea from "components/Textarea/Textarea";
import Countries from "./countries";
import Cities from "./cities";
import ncNanoId from "utils/ncNanoId";
import Categories from "./categories";
import Label from "components/Label/Label";
import axios from "../../axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {removeUser, selectUser} from "app/user/user";
import "./Tags.css";
import "react-quill/dist/quill.snow.css";

/*import toolbars and Quills, different for the IDs*/
import ReactQuill from 'react-quill';
import ReactQuillMeat from 'react-quill';
import ReactQuillVisit from 'react-quill';
import ReactQuillOspitality from 'react-quill';
import ReactQuillMoveOn from 'react-quill';
import Toolbar, {formats, modules} from "./Toolbar";
import ToolbarMeat, {formatsMeat, modulesMeat} from "./Toolbars/ToolbarMeat";
import ToolbarMoveOn, {formatsMoveOn, modulesMoveOn} from "./Toolbars/ToolbarMoveOn";
import ToolbarOspitality, {formatsOspitality, modulesOspitality} from "./Toolbars/ToolbarOspitality";
import ToolbarVisit, {formatsVisit, modulesVisit} from "./Toolbars/ToolbarVisit";

import "./TextEditor.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import ModalFillAllFields from 'components/CommentCard/ModalFillAllFields';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import Layout from "./layout";
import { useThemeMode } from "hooks/useThemeMode";

const DashboardSubmitPost = () => {
  const history = useNavigate();
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {isDarkMode}=useThemeMode();
  const [submitting, setSubmitting] = useState(false);
  const [submittingDraft, setSubmittingDraft] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [labelText, setLabelText] = useState("");
  const [userInput, setUserInput] = useState<any>("Select or Add Tag");
  const tagArray = [userInput];
  Categories.map((category) => tagArray.push(category.name));
  const user: any = useAppSelector(selectUser);
  const [image, setImage] = useState<any>(null);


  /*text edit consts*/
  const [content, setContent] = useState<any>(null);
  const [contentMeat, setContentMeat] = useState<any>(null);
  const [contentMeatLength, setContentMeatLength] = useState(0);
  const [contentVisit, setContentVisit] = useState<any>(null);
  const [contentVisitLength, setContentVisitLength] = useState(0);
  const [contentMoveOn, setContentMoveOn] = useState<any>(null);
  const [contentMoveOnLength, setContentMoveOnLength] = useState(0);
  const [contentOspitality, setContentOspitality] = useState<any>(null);
  const [contentOspitalityLength, setContentOspitalityLength] = useState(0);
  var reactQuillRefMeat:any = null;
  var reactQuillRefOspitality:any = null;
  var reactQuillRefMoveOn:any = null;
  var reactQuillRefVisit:any = null;
  const [changedVisit, setChangedVisit]=useState(false);
  const [changedOspitality, setChangedOspitality]=useState(false);
  const [changedMoveOn, setChangedMoveOn]=useState(false);
  const [changedMeat, setChangedMeat]=useState(false);
  /*end*/

  const [isMessage, setIsMessage] = useState(false);
  const openModalMessage = () => setIsMessage(true);
  const closeModalMessage = () => setIsMessage(false);
  const [cityArray,setCityArray] = useState<any>([]);
  const [isDraft, setIsDraft] = useState(false);
  const [categoryList, setCategoryList]=useState<any>(Categories);


  const [article, setArticle] = useState<any>({
    title: "",
    author_id: user.user.id,
    excerpt: "",
    category: "",
    country: "",
    tags: "",
    city: "",
   // is_draft: false

  });

  let timeLastMove=new Date().getTime();


  const handleClickDropDown = () => {
    openModalMessage();
  };


  const handleInput = (e: any) => {
    setUserInput(e.target.value);
  };

  const handleContentChange = (value: any) => {

   setContent(value);
  };

  /*management of changes for quill edit*/
  const handleContentVisitChange = (value: any) => {
   var limit=800;
   let quill=reactQuillRefVisit.getEditor();
   quill.on('text-change', function (delta:any, old:any, source:any) {
      if (quill.getLength() > limit) {
       quill.deleteText(limit, quill.getLength());
      }
    });
    setContentVisit(value);
    setContentVisitLength(quill.getLength()-1);
    (contentVisit!=="" && contentVisit!=="<p>null</p>") ? setChangedVisit(true) : setChangedVisit(false);

  };

  const handleContentMoveOnChange = (value: any) => {
   var limit=800;
   let quill=reactQuillRefMoveOn.getEditor();
   quill.on('text-change', function (delta:any, old:any, source:any) {
      if (quill.getLength() > limit) {
       quill.deleteText(limit, quill.getLength());
      }
    });
    setContentMoveOn(value);
    setContentMoveOnLength(quill.getLength()-1);
    (contentMoveOn!=="" && contentMoveOn!=="<p>null</p>") ? setChangedMoveOn(true) : setChangedMoveOn(false);

  };

  const handleContentOspitalityChange = (value: any) => {
   var limit=500;
   let quill=reactQuillRefOspitality.getEditor();
   quill.on('text-change', function (delta:any, old:any, source:any) {
      if (quill.getLength() > limit) {
       quill.deleteText(limit, quill.getLength());
      }
    });
    setContentOspitality(value);
    setContentOspitalityLength(quill.getLength()-1);
    (contentOspitality!=="" && contentOspitality!=="<p>null</p>") ? setChangedOspitality(true) : setChangedOspitality(false);

  };

  const handleContentMeatChange = (value: any) => {
   var limit=800;
   let quill=reactQuillRefMeat.getEditor();
   quill.on('text-change', function (delta:any, old:any, source:any) {
      if (quill.getLength() > limit) {
       quill.deleteText(limit, quill.getLength());
      }
    });
    setContentMeat(value);
    setContentMeatLength(quill.getLength()-1);

    (contentMeat!=="" && contentMeat!=="<p>null</p>") ? setChangedMeat(true) : setChangedMeat(false);
  };

  /*end management*/



  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if(name==="country")
    {
        // @ts-nocheck
        let country = Countries.find(obj => obj.country_id == value)?.name;
        for (let key in Cities)
        {
         if (Cities[key].country===country)
         {
             let temp:any=[];
             temp=Cities[key].cities
             let otherTemp:any=[];
             temp.map((city:any)=>{
             let findItem = otherTemp.find((x:any) => x===city);
             if(!findItem){
                otherTemp.push(city);
             }
             });
             setCityArray(otherTemp);
             break;
         }
        }
    }



    setArticle((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleTagChange = (value: any) => {

    setArticle((prevState: any) => ({
      ...prevState,
      tags: value,
    }));
  };

  const handleIdChange = () => {
    return ncNanoId();
  };

  const handleCityChange = (value: any) => {
    setArticle((prevState: any) => ({
      ...prevState,
      city: value,
    }));
  };


  const handleImageChange = (e: any) => {
    setImage(e.target.files[0]);
  };

function handleLogout (){
    dispatch(removeUser());
  };

//category management
 const { loading, error, data } = useFetch(`/categories`);



useEffect(() => {
    if(data){
        setCategoryList([]);
        let tempCatArray: any = [];
        for (let key in data){
            tempCatArray.push({
                 category_id: data[key].category_id,
                 name: data[key].name
            });
        }
        setCategoryList(tempCatArray);
    }
  }, [data]);



useEffect(() => {

    //session management
    getAccessToken().then((access_token: any) => {

    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                handleLogout();
                history("/login");
        }
    });

    //// idle management
   let timeCurrentMove=new Date().getTime();
   if((timeCurrentMove-timeLastMove)>240000)
   {
          handleSubmitDraft();
          timeLastMove=new Date().getTime();

   }

    const setActivityTime = (e:any) => {
      timeLastMove=new Date().getTime();
    }
    document.body.addEventListener("mousedown", setActivityTime);
    document.body.addEventListener("touchstart", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);


  }, [article,image, content,data]);

  const handleSubmit = (e:any) => {
    e.preventDefault();
    var addUpdate:string;
    if(!isDraft){addUpdate="/articles/add";}else{addUpdate="/articles/update";}

    if (article.category==="" || article.tags==="" || article.country==="" || !changedMeat || !changedMoveOn || !changedOspitality || !changedVisit)
    {
     handleClickDropDown();
     return;
    }

    if (!submitting) {
      setSubmitting(true);

      let articleBk=article;
      articleBk["is_draft"]=false;
      const data = new FormData();
      data.append("file", image);
      data.append("article", JSON.stringify(articleBk));
      data.append("contentVisit", contentVisit);
      data.append("contentMoveOn", contentMoveOn);
      data.append("contentMeat", contentMeat);
      data.append("contentOspitality", contentOspitality);
      data.append("content", content);

      getAccessToken().then((access_token: any) => {
        axios
          .post(addUpdate, data,{headers: {'Authorization': `Bearer ${access_token}`}})
          .then((res: any) => {
            setSubmitting(false);
            if (res.data.article_id) {history(`/blog/article/${res.data.article_id}#fetchData`);}
          }).catch((err) => {
            setSubmitting(false);
          });
      }).catch((err) => {
            setTimeout(() => {
                  setActiveLabel(false);
            }, 5000);
            let textL=t('404.loginissue');
            setLabelText(textL);
            setActiveLabel(true);
            setSubmitting(false);
            if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
            {
                handleLogout();
                history("/login");
            }
        });
    }
  };

  ///SUBMIT DRAFT

  const handleSubmitDraft = () => {
    var addUpdate:string;
    if(!isDraft){addUpdate="/articles/add";} else {addUpdate="/articles/update";}
        if (article.category==="" || article.tags==="" || article.country==="")
        {
         handleClickDropDown();
         return;
        }

        if (!submittingDraft) {
          setSubmittingDraft(true);
          let articleBk=article;
          articleBk["is_draft"]=true;
          const data = new FormData();
          data.append("file", image);
          data.append("article", JSON.stringify(articleBk));
          data.append("contentVisit", contentVisit);
          data.append("contentMoveOn", contentMoveOn);
          data.append("contentMeat", contentMeat);
          data.append("contentOspitality", contentOspitality);
          data.append("content", content);
          getAccessToken().then((access_token: any) => {
            axios
              .post(addUpdate, data,{headers: {'Authorization': `Bearer ${access_token}`}})
              .then((res: any) => {
                setSubmittingDraft(false);
                setIsDraft(true);
                if (res.data.article_id) {history(`/dashboard/posts`);}
              })
              .catch((err) => {
                setSubmittingDraft(false);
              });
              }).catch((err) => {
                setTimeout(() => {
                      setActiveLabel(false);
                    }, 5000);
                    let textL=t('404.loginissue');
                    setLabelText(textL);
                    setActiveLabel(true);
                    setSubmittingDraft(false);
                if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
                {
                    handleLogout();
                    history("/login");
                }
            });
        }
  };


  const theme = createTheme({
  components: {
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                backgroundColor:"transparent",
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",


        },
        input:{
                background:"transparent",

        },
        inputRoot: {
            color:"black",
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
          borderRadius:"10px",
          fontSize:"14px"

        },




      },
    },

  },
});

const themeDark = createTheme({

  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{

                background:"transparent",
                backgroundColor:"transparent",
                color:"#fff",


           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
           color:"white",
        },
        input:{
                background:"transparent",
                color:"#fff",
        },
        inputRoot: {
          background:"transparent",
          color:"#fff",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#374151",
              color:"#fff"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489",
              color:"#fff"
            },

            "& .MuiChip-label": {
              color: "#fff"
            },
            "& .MuiChip-deleteIcon": {
              color: "#1b8489"
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "#fff"
            },


          borderRadius:"10px",
          fontSize:"14px"
        },

      },
    },
  },
});

  return (
    <Layout>
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit} >
        <label className="block md:col-span-2">
          <Label>📓 {t('dashboard.post.title')}</Label>
          <Input
            type="text"
            className="mt-1"
            name="title"
            value={article.title}
            onChange={handleChange}
            pattern="^[a-zA-Z0-9À-ú/\D/g ]+"
            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.nameProfile'))}
            onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
            required
          />
        </label>
        <label className="block md:col-span-2">
          <Label>📜 {t('dashboard.post.summary')}</Label>

          <Textarea
            className="mt-1"
            rows={3}
            name="excerpt"
            value={article.excerpt}
            onChange={handleChange}
            required
          />
          <p className="mt-1 text-sm text-neutral-500">
            {t('dashboard.post.summarydesc')}
          </p>
        </label>
        <label className="block">
          <Label>🗂 {t('dashboard.post.category')}</Label>
          <Select className="mt-1" name="category" onChange={handleChange} >
            <option value="select">{t('dashboard.post.select')}</option>
            {categoryList.map((category:any) => (
              <option key={category.category_id} value={category.category_id}>
                {category.name}
              </option>
            ))}
          </Select>
        </label>
        <label className="block">
          <Label>🔖 {t('dashboard.post.tag')}</Label>
          <Stack spacing={3} sx={{ width: "100%" }}>
            <ThemeProvider theme={isDarkMode ? themeDark : theme}>
            <Autocomplete
              multiple
              id="tags"
              //key={uuid()}
              options={tagArray}
              style={{ color: "black"}}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              onChange={(event, value) => handleTagChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="New Tag"
                  onInput={handleInput}

                />
              )}

            />
          </ ThemeProvider>
          </Stack>
        </label>
        <label className="block">
          <Label>🗾 {t('dashboard.post.country')}</Label>
          <Select className="mt-1" name="country" onChange={handleChange} required>
            <option value="select">{t('dashboard.post.select')}</option>
            {Countries.map((country) => (
              <option key={country.country_id} value={country.country_id}>
                {country.name}
              </option>
            ))}
          </Select>
        </label>
        <label className="block">
          <Label>🏢 {t('dashboard.post.city')}</Label>


          <Stack spacing={3} sx={{ width: "100%" }}>
            <ThemeProvider theme={isDarkMode ? themeDark : theme}>
            <Autocomplete
              id="cities"
              options={cityArray}
              getOptionLabel={(option:string) => option}
              filterSelectedOptions
              style={isDarkMode ? { color: "white", borderColor:"white"}:{ color: "black"} }
              sx={isDarkMode ? { color: "white", borderColor:"white"}:{ color: "black"} }
              onChange={(event, value) => handleCityChange(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="City"
                  onInput={handleInput}


                />
              )}

            />
          </ThemeProvider>
          </Stack>
      {/*    <Input
            type="text"
            className="mt-1"
            name="city"
            value={article.city}
            onChange={handleChange}
            required
          />*/}

        </label>
        <div className="block md:col-span-2">
          <Label>🏞 {t('dashboard.post.image')}</Label>
          <div className="relative mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
            {image ? (
              <div className="relative ">
                <img src={URL.createObjectURL(image)} alt="" />
                <label
                  className="absolute top-0 flex justify-center items-center bg-black bg-opacity-50 h-full w-full opacity-0 hover:opacity-100 cursor-pointer rounded-md font-medium text-white"
                  htmlFor="file-upload"
                >
                  <p>{t('dashboard.post.upload')}</p>
                  <input
                    id="file-upload"
                    name="file"
                    type="file"

                    className="sr-only"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleImageChange}
                  />
                </label>
              </div>
            ) : (
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-neutral-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <div className="flex text-sm text-neutral-6000 justify-center">
                  <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer rounded-md font-medium text-primary-6000 
                  hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 text-center"
                  >
                    <p>{t('dashboard.post.upload')}</p>
                    <input
                      id="file-upload"
                      name="file"
                      type="file"
                      className="sr-only"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleImageChange}
                      required
                    />
                  </label>
                </div>
                <p className="text-xs text-neutral-500">
                  {t('dashboard.post.uploadTo')}
                </p>
              </div>
            )}
          </div>
        </div>

        {/*quill for visit section*/}
        <label className="block md:col-span-2">
          <Label>🌆 {t('dashboard.post.titleVisit')}*</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarVisit />
            <ReactQuillVisit
              id="qEditorVisit"
              theme="snow"
              value={contentVisit || " "}
              onChange={handleContentVisitChange}
              placeholder={t('dashboard.post.contentdescvisit')}
              ref={(el) => { reactQuillRefVisit = el }}
              modules={modulesVisit}
              formats={formatsVisit}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentVisitLength}/800 </Label>
          </div>

        </label>

        {/*quill for Move On section*/}
        <label className="block md:col-span-2">
          <Label>🛴 {t('dashboard.post.titleMoveOn')}*</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarMoveOn />
            <ReactQuillMoveOn
              id="qEditorMoveOn"
              theme="snow"
              value={contentMoveOn || " "}
              onChange={handleContentMoveOnChange}
              placeholder={t('dashboard.post.contentdescmoveon')}
              ref={(el) => { reactQuillRefMoveOn = el }}
              modules={modulesMoveOn}
              formats={formatsMoveOn}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentMoveOnLength}/800 </Label>
          </div>

        </label>

        {/*quill for Ospitality section*/}
        <label className="block md:col-span-2">
          <Label>🏢 {t('dashboard.post.titleOspitality')}*</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarOspitality />
            <ReactQuillOspitality
              id="qEditorOspitality"
              theme="snow"
              value={contentOspitality || " "}
              onChange={handleContentOspitalityChange}
              placeholder={t('dashboard.post.contentdescospitality')}
              ref={(el) => { reactQuillRefOspitality = el }}
              modules={modulesOspitality}
              formats={formatsOspitality}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentOspitalityLength}/500 </Label>
          </div>

        </label>

        {/*quill for Meat section*/}
        <label className="block md:col-span-2">
          <Label>🌮 {t('dashboard.post.titleMeat')}*</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarMeat />
            <ReactQuillMeat
              id="qEditorMeat"
              theme="snow"
              value={contentMeat || " "}
              onChange={handleContentMeatChange}
              placeholder={t('dashboard.post.contentdescmeat')}
              ref={(el) => { reactQuillRefMeat = el }}
              modules={modulesMeat}
              formats={formatsMeat}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentMeatLength}/800 </Label>
          </div>

        </label>


        {/*quill for experiences and tips section*/}
        <label className="block md:col-span-2">
          <Label>📝 {t('dashboard.post.content')}</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <Toolbar />
            <ReactQuill
              id="qEditor"
              theme="snow"
              value={content || " "}
              onChange={handleContentChange}
              placeholder={t('dashboard.post.contentdesctips')}
              modules={modules}
              formats={formats}
            />
          </div>
        </label>

        <ButtonPrimary className="md:col-span-2" >
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('dashboard.post.publish')}</span>
          )}
        </ButtonPrimary>

        <ButtonSecondary className="md:col-span-2" type="button" onClick={handleSubmitDraft}>
          {submittingDraft ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('saveDraft')}</span>
          )}
        </ButtonSecondary>


        {activeLabel ? (
          <Label className="mt-10">{labelText}</Label>
         ) : ( <div />)}
      </form>

         <ModalFillAllFields
            show={isMessage}
            onCloseModalMessage={closeModalMessage}
            onClickSubmit={handleClickDropDown}
         />
    </div>
    </Layout>
  );
};

export default DashboardSubmitPost;


