import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<React.PropsWithChildren<React.PropsWithChildren<LogoProps>>> = ({
  img = logoImg,
  imgLight = logoLightImg,
}) => {
  return (
    <Link to="/" className="ttnc-logo inline-block text-primary-6000">
      <img src={logoImg} alt="" style={{ width: "100%", height: "80px", padding: "5px 0" , objectFit: "contain" }} />
    </Link>
  );
};

export default Logo;
