import React, { FC, useState, useEffect } from "react";
import DateRangePickerBox from 'components/DatePicker/ReactDates';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import Autocomplete from "@mui/material/Autocomplete";

import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import {FaMapMarkerAlt, FaRegCalendar, FaUserFriends,FaSort,FaSortUp,FaBriefcase} from 'react-icons/fa';
import {ComponentWrapper,FlightGuestWrapper,ItemWrapper,SingleDateWrapper} from './Search.style';
import { Button } from 'antd';
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {getCurrency} from "locale-currency";
import { setStateToUrl } from 'helpers/url_handler';
import { FLIGHT_DETAILS_PAGE } from 'data/constant';
import { useNavigate } from "react-router";
import axios from "../../axios";
import type {} from '@mui/lab/themeAugmentation';
import Heading from "components/Heading/HeadingSearch";
import { isMobile} from 'react-device-detect';


export interface SectionSearchFlightProps {
  className?: string;
}

export interface AirportType {
  value: string;
  name: string;
  city?:string;
}

interface QuerySearch {
  adults_count: number;
  child_counts: number;
  infants_count:number;
  departure_airport:string;
  arrival_airport:string;
  with_return:boolean;
  flight_class:string;
  currency:string;
  outbound_date?:any;
  return_date?:any;
  range_departure?:number[];
  range_return?:number[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  city_departure?:string;
  city_return?:string;
  airlines?:string;
}



const SectionSearchFlight: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSearchFlightProps>>> = ({ className = "" }) => {
  const {t} = useTranslation();
  const [userInput, setUserInput] = useState<any>("City");
    const tagArray = [userInput];
    const [submitting, setSubmitting] = useState(false);
    /* to manage search variables */

   const [searchDate, setSearchDate] = useState({
    setStartDate: null,
    setEndDate: null,
  });

  const [oneWayDate, setOneWayDate] = useState({
    setDate: null,
  });
  const history = useNavigate();

  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (1);
  const [flightChild,setFlightChild] = useState (0);
  const [flightInfant,setFlightInfant] = useState (0);
  const [departureAirportValue, setDepartureAirportValue] = useState("");
  const [arrivalAirportValue, setArrivalAirportValue] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [airValueCity, setAirValueCity] = useState("");
  const [airValueArrCity, setAirValueArrCity] = useState("");

  const [bagAdult,setBagAdult] = useState (0);
  const [hBagAdult,setHBagAdult] = useState (0);
  const [bagTotal,setBagTotal] = useState (0);


  const [value, setValue] = useState<AirportType | null>(null);
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:"",city:""}]);
  const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:"",city:""}]);

  ///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  let oneWayReturn=true;

      //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };


    const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
          let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }
                setAirValue(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });

    }


  const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }
                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });

    }


  const updateDepartureAirportFunc = (value:any) => {
    if(value!=null){
     setDepartureAirportValue(value.value);
     setAirValueCity(value.city);

     }
     else
     {
      return;
     }
   };

  const updateArrivalAirportFunc = (value:any) => {
    if(value!=null){
        setArrivalAirportValue(value.value);
        setAirValueArrCity(value.city)
    }
    else{return;}
   };

   const handleInput = (e: any) => {
    setUserInput(e.target.value);
  };

  // Flight Guest data handling

   const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
  };


  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);

  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);

  };

  //set guests number
  const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
    }
  };


  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
    }
  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
    }
  };

  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };




  const handleTagChange = (value:any) => {

  };



  const setMode = (e:any) => {
    e.preventDefault();
    if(switchState){
    setSwitchState(false);
   } else { setSwitchState(true);}
  };


   let oneOrReturn;

    switch (switchState)
   {
        default:
        case true:
                oneWayReturn=true;
                    oneOrReturn=(

                            <DateRangePickerBox
                               id="date_picker"
                               item={calendarItem}
                               startDateId="startDateId-id-home"
                               endDateId="endDateId-id-home"
                               updateSearchData={(setDateValue:any) => setSearchDate(setDateValue)}
                               showClearDates={true}
                               small={true}
                               numberOfMonths={1}
                               required={true}
                               startDatePlaceholderText={t('startDate')}
                               endDatePlaceholderText={t('endDate')}
                               firstDayOfWeek={0}
                               readOnly={isMobile ? true : false}
                             />

                    );
                    break;
        case false:oneWayReturn=false;
                oneOrReturn=(
                    <SingleDateWrapper  style={{marginLeft:"3rem",width:"100%"}}>
                      <SingleDatePicker
                        numberOfMonths={1}
                        small={true}
                        required={true}
                        placeholder= {t('startDate')}
                        onDateChange={(dateValue:any) => setOneWayDate(dateValue)}
                        displayFormat="DD-MM-YYYY"
                        readOnly={isMobile ? true : false}
                      />
                    </SingleDateWrapper>
                 );
                    break;
   }




 const defaultDepProps = {
    options: airValue,
    getOptionLabel: (option: AirportType) => option.name,
  };

   const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => option.name,
  };

   const goToSearchPage = () => {

        var query : QuerySearch= {
              departure_airport: departureAirportValue,
              arrival_airport: arrivalAirportValue,
             city_departure:airValueCity,
            city_return:airValueArrCity,
              adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
              child_counts: flightChild,
              infants_count: flightInfant,
              flight_class: "Y",
              with_return: oneWayReturn,
              currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
              locale: navigator.language,
              adult_hold_bag:bagAdult,
              adult_hand_bag:hBagAdult,
              child_hold_bag:0,
              child_hand_bag:0,
              range_departure:[0,23],
              airlines:""
            };

    if(oneWayReturn){
        query["outbound_date"] = searchDate.setStartDate;
        query["return_date"] = searchDate.setEndDate;
        query["range_return"] = [0,23];
    }else{
        query["outbound_date"]= oneWayDate.setDate;
    }

    const search = setStateToUrl(query);


    history({
      pathname: FLIGHT_DETAILS_PAGE,
      search: search,
    });
  };




  const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{

                background:"transparent",

           },

        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot

        root: {
          // Some CSS
           background:"transparent",
            color:"grey",

        },

        input:{
                background:"transparent",
        },
        inputRoot: {
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },

          fontSize:"14px"
        },

      },
    },

  },
});

return (

    <div
      className={`relative`}
      data-nc-id="SectionSearchFlight"
    >


    <div className="relative">
        <Heading
           className="nc-HeadingSearch text-sm w-full text-neutral-500 dark:text-neutral-300"
           desc={t('flightBodyArt')}>
            {t('flightHeaderArt')}
          </Heading>
       <button
          onClick={(e:any)=>setMode(e)}
          className={`text-2xl md:text-3xl w-12 h-12 rounded-full text-neutral-700  hover:bg-transparent focus:outline-none flex items-center justify-center text-neutral-500 dark:text-neutral-300 ${className}`}
        >
          {switchState ? (
            <div className="flex justify-start absolute right-2 text-base w-auto">{t('withReturn')}<FaSort className="w-7 h-7" aria-hidden="true" /></div>
          ) : (
            <div className="flex justify-start absolute right-2 text-base w-auto">{t('oneway')}<FaSortUp className="w-7 h-7" aria-hidden="true" /></div>
          )}
        </button>

          <form onSubmit={goToSearchPage}>
             <ComponentWrapper>
                <FaMapMarkerAlt className="map-marker" />
                <Stack spacing={4}
                    sx={{
                    width:"90%",
                    ml: 6 }}
                >
                    <ThemeProvider theme={theme}>
                    <Autocomplete
                        {...defaultDepProps}
                        id="departure"
                        autoComplete
                        onInputChange={(e:any, newValue: any) => onChangeDepartureAirport(e,newValue)}
                        onChange={(event:any, newValue:any)=>updateDepartureAirportFunc(newValue)}
                        isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                        style={{ color: "black"}}
                        className="text-gray-800"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={t('city')}
                            variant="standard"

                              />
                        )}
                        loading={true}
                        noOptionsText="Please type"
                  />
                  </ThemeProvider>
               </Stack>
            </ComponentWrapper>

            <ComponentWrapper>
                <FaMapMarkerAlt className="map-marker" />
                 <Stack spacing={4}
                    sx={{
                    width:"90%",
                    ml: 6 }}
                >
                  <ThemeProvider theme={theme}>

                  <Autocomplete
                    {...defaultArrProps}
                    id="arrival"
                    autoComplete
                    onInputChange={(event: any, newValue: any) => onChangeArrivalAirport(event,newValue)}
                    onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                    isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                    style={{ color: "black"}}
                        className="text-gray-800"
                    renderInput={(params) => (
                      <TextField {...params} required label={t('tocity')} variant="standard"  />
                    )}
                  />
                  </ThemeProvider>
                  </Stack>
            </ComponentWrapper>

            <ComponentWrapper>
                       <FaRegCalendar className="calendar" />
                       {oneOrReturn}
            </ComponentWrapper>


         <ComponentWrapper>
                    <ViewWithPopup
                      key={200}
                      noView={true}
                      className="room_guest"
                      style={{position:"relative"}}
                      view={


                        <Button type="default">
                                <FaUserFriends className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}}/>
                                <span className="text-neutral-500" style={{fontSize:"18px"}}>
                                    {flightAdult+flightChild+flightInfant >= 1 && `${flightAdult+flightChild+flightInfant}`}
                                </span>
                                <FaBriefcase className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}} />
                                <span className="text-neutral-500" style={{fontSize:"18px"}}>
                                    {bagTotal >= 0 && `${bagTotal}`}
                                </span>
                        </Button>


                      }

                      /*popup to choose room and guest*/
                      popup={
                        <FlightGuestWrapper>

                          <ItemWrapper>
                            <strong>{t('adult')}</strong>
                            <InputIncDec
                              id="adult"
                              className="text-neutral-500"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={flightAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong>{t('child')}</strong>
                            <InputIncDec
                              id="child"
                              className="text-neutral-500"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={flightChild}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <strong>{t('infant')}</strong>
                            <InputIncDec
                              id="infant"
                              className="text-neutral-500"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={flightInfant}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                                <div className="flex-grow border-t border-neutral-900"></div>
                                <span className="flex flex-wrap gap-2 items-center flex-shrink mx-4 text-neutral-500"><FaBriefcase className="user-friends" style={{color: "#008783", width:"12px", height:"12px"}} />{t("bags.total")}</span>
                                <div className="flex-grow border-t border-neutral-900"></div>
                          </ItemWrapper>

                        <ItemWrapper>
                            <strong>{t("bag.adult")}</strong>
                            <InputIncDec
                              id="bagAdult"
                              className="text-neutral-500"
                              increment={() => handleIncrementBagAdult()}
                              decrement={() => handleDecrementBagAdult()}
                              value={bagAdult}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <strong>{t("bag.hand")}</strong>
                            <InputIncDec
                              id="hBag"
                              className="text-neutral-500"
                              increment={() => handleIncrementHBagAdult()}
                              decrement={() => handleDecrementHBagAdult()}
                              value={hBagAdult}
                            />
                          </ItemWrapper>

                        </FlightGuestWrapper>
                      }
                    />
                  </ComponentWrapper>




            <ButtonPrimary className="nc-BtnSubmitSearch">
              {submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#eee",
                  }}
                />
              ) : (
                <span>{t('findtripflight')}</span>
              )}
            </ButtonPrimary>

          </form>
        </div>
    </div>
);


};

export default SectionSearchFlight;
