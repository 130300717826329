import Heading from "components/Heading/HeadingSearch";
import React, { FC, useState, useEffect } from "react";

import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import {FaUserFriends,FaBriefcase} from 'react-icons/fa';
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";

import {ComponentWrapper,FlightGuestWrapper,ItemWrapper} from '../Search.style';
import { Button } from 'antd';
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {getCurrency} from "locale-currency";
import { setStateToUrl } from 'helpers/url_handler';
import { MULTICITY_DETAILS_PAGE } from 'data/constant';
import { useNavigate } from "react-router";
import type {} from '@mui/lab/themeAugmentation';
import MultiCityDropdown from 'components/NomadDropdown/MultiCityDropdown';
import { useThemeMode } from "hooks/useThemeMode";
import ButtonPrimary from "components/Button/ButtonPrimary";

export interface SectionFlightProps {
  className?: string;
  heading?: string;
}


interface QuerySearch {
  adults_count: number;
  child_counts: number;
  infants_count:number;
  multi_city:string;
  flight_class:string;
  currency:string;
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  airlines?:string;
  depart_time?:string;
}



const PageContainerSearchFlight: FC<React.PropsWithChildren<React.PropsWithChildren<SectionFlightProps>>> = ({
  heading = "",
  className = "",
}) => {
const {isDarkMode}=useThemeMode();
  const {t} = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [checkField, setCheckField] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const today=new Date();
    const [destinations,setDestinations]=useState<any>(
      [
          {
            id: 1,
            departure_airport: t('startDate'),
            arrival_airport: t('multicity.destination'),
            city_departure:t('dashboard.post.city'),
            city_return:t('dashboard.post.city'),
            outbound_date:today.getDate()+"-"+today.getMonth()+"-"+today.getFullYear(),
            checkCompleteness:false,
            deparTime:0,
            deparTimeTo:23,
          },
          {
            id: 2,
            departure_airport: t('startDate'),
            arrival_airport: t('multicity.destination'),
            city_departure:t('dashboard.post.city'),
            city_return:t('dashboard.post.city'),
            outbound_date:today.getDate()+"-"+today.getMonth()+"-"+today.getFullYear(),
            checkCompleteness:false,
            deparTime:0,
            deparTimeTo:23,
          }
      ]);






  const history = useNavigate();
  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (1);
  const [flightChild,setFlightChild] = useState (0);
  const [flightPeopleTotal,setFlightPeopleTotal] = useState (1);
  const [flightInfant,setFlightInfant] = useState (0);
  const [bagAdult,setBagAdult] = useState (0);
  const [hBagAdult,setHBagAdult] = useState (0);
  const [bagTotal,setBagTotal] = useState (0);

  ///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);


  // Flight Guest data handling

   const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
        setFlightPeopleTotal(flightPeopleTotal+1);
  };


  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);
    setFlightPeopleTotal(flightPeopleTotal+1);

  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);
    setFlightPeopleTotal(flightPeopleTotal+1);

  };

  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
      if(flightPeopleTotal<=1)
    {
        setFlightPeopleTotal(1);
    } else{
      setFlightPeopleTotal(flightPeopleTotal-1);
    }
    }



  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
      if(flightPeopleTotal<=1)
        {
            setFlightPeopleTotal(1);
        } else{
          setFlightPeopleTotal(flightPeopleTotal-1);
        }
    }



  };

  //set guests number
  const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
      if(flightPeopleTotal<=1)
        {
            setFlightPeopleTotal(1);
        } else{
          setFlightPeopleTotal(flightPeopleTotal-1);
        }
    }

  };


  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };

  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}
  };

  /**/


  const destData = (userDestination:any) =>
  {
        let tempArray=[];
        let tempData=[]
        for (let key in userDestination){
            tempArray.push({
                id:key,
                departure_airport:userDestination[key].name,
                arrival_airport:userDestination[key].nameTo,
                city_departure:userDestination[key].city,
                city_return:userDestination[key].cityTo,
                outbound_date:userDestination[key].date,
                checkCompleteness:userDestination[key].modified,
                deparTime:0,
                deparTimeTo:23,
            });

        }
        setDestinations(tempArray);
  }



  const goToSearchPage = () => {


     let check=false;
    for(let key in destinations){
        if(destinations[key].departure_airport!=="" &&
        destinations[key].arrival_airport!=="" &&
        destinations[key].city_departure!=="" &&
        destinations[key].city_return!=="" &&
        destinations[key].outbound_date!==null && destinations[key].outbound_date!==undefined && destinations[key].outbound_date!==""){
            check=true;

        }
    }

    if(!check){
       setShowAlert(true);
       setShowMessage(false);
    }



    let multi_city_string="";
    let depart_time_string="";
    for(let i=0;i<destinations.length;i++){
        if(destinations.length===i+1){
            multi_city_string+="__";
            depart_time_string+="__";
        }
        multi_city_string+=destinations[i].departure_airport+"_"+destinations[i].arrival_airport+"_"+destinations[i].outbound_date;
        depart_time_string+=destinations[i].id+"_"+destinations[i].deparTime+"_"+destinations[i].deparTimeTo;
    }

    var query : QuerySearch= {
      multi_city:multi_city_string,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      child_counts: flightChild,
      infants_count: flightInfant,
      flight_class: "Y",
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      depart_time:depart_time_string

    };



    if(check){
        const search = setStateToUrl(query);
    history({
      pathname: MULTICITY_DETAILS_PAGE,
      search: search,
    },
      { state: {
        query: query ,
        destinations:destinations
      } },


    );
    }

  };







  return (

      <div className="relative" >
       {/* SWITCH */}
      <Heading
       className="nc-HeadingSearch text-sm w-full"
      desc={t('flightBody')}>
        {t('flightHeader')}
      </Heading>

        <Collapse in={showAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <SvgIcon>
                    <path
                      fill="inherit"
                      d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                    />
                  </SvgIcon>
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <p style={{ marginTop: ".2rem" }}>{showMessage ? t('profile.success') : t('nomad.compileFields')}</p>
            </Alert>
          </Collapse>





      <div className="relative mt-5 rounded-xl bg-neutral-50 dark:bg-gray-50 md:border md:border-neutral-400 dark:border-neutral-400 sm:p-6">

        <div>
             <ComponentWrapper>
                 <MultiCityDropdown  destData={destData} destTemp={destinations} />
            </ComponentWrapper>



         <ComponentWrapper className="mt-1">

                    <ViewWithPopup
                      key={200}
                      noView={true}
                      className="room_guest"
                      style={{position:"relative"}}
                      view={
                       <Button type="default">
                                <FaUserFriends className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}}/>
                                <span className="text-neutral-500" style={{fontSize:"18px"}}>
                                    {flightAdult+flightChild+flightInfant >= 1 && `${flightAdult+flightChild+flightInfant}`}
                                </span>
                                <FaBriefcase className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}} />
                                <span className="text-neutral-500" style={{fontSize:"18px"}}>
                                    {bagTotal >= 0 && `${bagTotal}`}
                                </span>
                        </Button>
                      }

                      /*popup to choose room and guest*/
                      popup={
                        <FlightGuestWrapper>

                          <ItemWrapper>
                            <strong>{t('adult')}</strong>
                            <InputIncDec
                              id="adult"
                              className="text-neutral-500"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={flightAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <div>
                                  <p className="text-neutral-900">{t('child')}</p>
                                  <p className="text-xs text-neutral-900">(2-11)</p>
                            </div>
                            <InputIncDec
                              id="child"
                              className="text-neutral-500"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={flightChild}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <div>
                                    <p className="text-neutral-900">{t('setInfant')}</p>
                                    <p className="text-xs text-neutral-900">(under 2)</p>
                            </div>
                            <InputIncDec
                              id="infant"
                              className="text-neutral-500"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={flightInfant}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                                <div className="flex-grow border-t border-neutral-900"></div>
                                <span className="flex flex-wrap gap-2 items-center flex-shrink mx-4 text-neutral-500"><FaBriefcase className="user-friends" style={{color: "#008783", width:"12px", height:"12px"}} />{t("bags.total")}</span>
                                <div className="flex-grow border-t border-neutral-900"></div>
                          </ItemWrapper>

                        <ItemWrapper>
                            <strong>{t("bag.adult")}</strong>
                            <InputIncDec
                              id="bagAdult"
                              className="text-neutral-500"
                              increment={() => handleIncrementBagAdult()}
                              decrement={() => handleDecrementBagAdult()}
                              value={bagAdult}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <strong>{t("bag.hand")}</strong>
                            <InputIncDec
                              id="hBag"
                              className="text-neutral-500"
                              increment={() => handleIncrementHBagAdult()}
                              decrement={() => handleDecrementHBagAdult()}
                              value={hBagAdult}
                            />
                          </ItemWrapper>

                        </FlightGuestWrapper>
                      }
                    />
                  </ComponentWrapper>



            <ButtonPrimary className="nc-BtnSubmitSearch mb-2 mt-2" onClick={goToSearchPage}>
              {submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "#eee",
                  }}
                />
              ) : (
                <span>{t('findtripflight')}</span>
              )}
            </ButtonPrimary>


          </div>
        </div>
        </div>


  );
};

export default PageContainerSearchFlight;


