import React, { CSSProperties, FC } from "react";

interface Props extends Partial<Omit<HTMLImageElement, "style">> {
  fill?: boolean;
  style?: CSSProperties;
  onLoadingComplete?: () => void;
  src:string;

}

const Image: FC<Props> = ({
  fill = false,
  className,
  alt = "",
  style,
  onLoadingComplete,
  src,
  ...args
}) => {

  function importAll(r:any) {
      let images:any = {};
      r.keys().map((item:any, index:any) => { images[item.replace('./', '')] = r(item); });
      return images;
    }

  const images = importAll(require.context('images/flags', false, /\.(svg)$/));
  let url="";
  for(let key in images){
    if(images[key].split("/")[3].split(".")[0]===src.split(".")[0]){
        url=images[key];
    }
  }

  return (
    //   @ts-ignore
    <img
      {...args}
      src={url}
      className={
        className + (fill ? " object-cover absolute inset-0 w-full h-full" : "")
      }
      alt={alt}
      style={style}
      onLoad={onLoadingComplete}
    />
  );
};

export default Image;
