import React, { FC, useState } from "react";
import Avatar from "components/Avatar/Avatar";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";

export interface PostMeta2Props {
  className?: string;
  meta: Pick<PostDataType, "date" | "author" | "categories" | "readingTime" | "content" | "content_visit" | "content_moveon" | "content_meat" | "content_ospitality">;
  hiddenCategories?: boolean;
  size?: "large" | "normal";
  avatarRounded?: string;
}


const PostMeta2: FC<React.PropsWithChildren<React.PropsWithChildren<PostMeta2Props>>> = ({
  className = "leading-none",
  meta,
  hiddenCategories = false,
  size = "normal",
  avatarRounded,
}) => {
  const { date, author, categories, readingTime, content, content_visit,content_meat,content_ospitality,content_moveon} = meta;

  /*evaluate reading time*/
   let tempText=((content? content : "")+(content_visit? content_visit : "")+(content_moveon? content_moveon : "")+(content_ospitality? content_ospitality : "")+(content_meat? content_meat : ""));
  let numberWords= tempText.split(" ").length;
  const [rTime,setRTime]= useState (Math.round(numberWords/200));
  /*end*/
  return (
    <div
      className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${
        size === "normal" ? "text-xs" : "text-sm"
      } ${className}`}
      data-nc-id="PostMeta2"
    >
      <Link to={author.href} className="flex items-center space-x-2">
        {author.avatar ? (
          <Avatar
            radius={avatarRounded}
            sizeClass={
              size === "normal"
                ? "h-6 w-6 text-sm"
                : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
            }
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        ) : (
          <Avatar
            radius={avatarRounded}
            sizeClass={
              size === "normal"
                ? "h-6 w-6 text-sm"
                : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
            }
            imgUrl={author.avatar}
            userName={author.displayName}
          />
        )}
      </Link>
      <div className="ml-3">
        <div className="flex items-center">
          <Link to={author.href} className="block font-semibold">
            {author.displayName}
          </Link>

          {!hiddenCategories && (
            <>
              <span className="mx-2 font-semibold">·</span>
              <div className="ml-0">
                <span className="text-xs">🏷 </span>
                {categories.map((cat, index) => (
                  <Link key={cat.id} to={cat.href} className="font-semibold">
                    {cat.name}
                    {index < categories.length - 1 && <span>, </span>}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">{date}</span>
          <span className="mx-2 font-semibold">·</span>
          <span className="text-neutral-700 dark:text-neutral-300">
            {rTime} min read
          </span>
        </div>
      </div>
    </div>
  );
};

export default PostMeta2;
