import { FC } from "react";
import { PostDataType } from "data/types";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  className: "";
}

const PagePrivacy: FC<React.PropsWithChildren<React.PropsWithChildren<PageSingleProps>>> = ({ className = "" }) => {


  const { t } = useTranslation();
  return (
    <div
      className={`nc-PageSingle pt-8 px-6 lg:pt-16 ${className}`}
      data-nc-id="PageSingle"
    >
    <Helmet>
        <title>Privacy Policy || Gonnaway</title>
      </Helmet>
            <BgGlassmorphism />
        <div>
          <header className="container rounded-xl">
            <div className="mx-auto flex justify-center">
                <Heading desc="Monday 4th April, 2022">Privacy Policy️</Heading>
            </div>
          </header>

          <div className="relative prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
            <div className="relative">
                <p style={{textAlign:"justify"}}>
                 The present privacy notice provides the website users a clear framework of understanding about the personal data
                  that are collected from the website owner, under the provisions of the General Data Protection Regulation (GDPR).
                  The personal data collected will be processed only by the data controller.
                </p>
                <p style={{textAlign:"justify"}}>
                  <strong>This Policy describes:</strong>
                </p>
                <div>
                   <ol>
                      <li>How We Collect & Use Your Personal Data</li>
                      <li>Data Subjects Rights</li>
                      <li>Data Retention</li>
                      <li>Cookies & Similar Technologies</li>
                      <li>Updates to This Policy</li>
                      <li>How to Contact Us</li>
                   </ol>
                </div>

      </div>
      <div className="mt-10" />

      <div id="collect" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>1. How We Collect & Use Your Personal Data</strong></p>
           <p style={{textAlign:"justify"}}>
             We collect personal data about you in different ways, such as through our website and social media channels.
           </p>
           <p style={{textAlign:"justify"}}>We may collect the following types of personal data:</p>
                  <ol>
                    <li>Contact information (e.g. name, email address, telephone number)</li>
                    <li>Username and password when you register on our site</li>
                    <li>Other information you may provide to us, such as through the "Contact us" section of our website</li>
                    <li>IP address, approximately position of your IP, browser type and few technical data needed for the service</li>
                  </ol>

            <p style={{textAlign:"justify"}}>We collect and use the data described above for the following purposes:</p>
                  <ol>
                    <li>Let you use our services</li>
                    <li>Enable you to access the Gonnaway.com platform</li>
                    <li>Enable you to communicate with us</li>
                    <li>Send you messages, updates and account notifications if you decide to apply to our newsletter</li>
                    <li>If consent is given, sending commercial information and offers</li>
                    <li>If consent is given, gathering information to personalize and customize your experience based on your interactions with the platform, your searches and booking history, your profile information and preferences, and other content you process </li>
                  </ol>
        </div>


      </div>
      <div className="mt-10" />

      <div id="rights" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
            <p style={{textAlign:"justify"}}>
                <strong>2. Data Subject Rights</strong>
            </p>
            <p style={{textAlign:"justify"}}> We inform you that under the GDPR the data subject can exercise the following specific rights:</p>

                      <ol>
                        <li><strong>Right of access:</strong> the data subject has the right to access his/her personal data that are processed</li>
                        <li><strong>Right to rectification:</strong> the data subject has the right to request the rettification of any innacurate of incomplete information related to him or her, in order to protect the accuracy of such information and adapt it to the data processing</li>
                        <li><strong>Right to erasure:</strong> the data subject has the right to request the deletion of data and to no longer processed those data</li>
                        <li><strong>Right to restriction of processing:</strong> the data subject has the right the the data controller restricts the processing of data</li>
                        <li><strong>Right to data portability:</strong> the data subject has the right to request the data portability that means to receive his or her data in a structured and commonly used format and to transfer data to another data controller</li>
                        <li><strong>Right not to be subject of automated individual decision-making:</strong> the data subject has the right not to be subject to a decision based solely on automated processing, including profiling, which produces legal effect concerning the data subject or similarly significantly affects him or her</li>
                        <li><strong>Right to object:</strong> the data subject who provides the data controller with personal data has the right to object data processing within the cases stipulated in the GDPR, at any time, and without needing to justify the decision</li>
                      </ol>
                      <p>
                      If you want to have more information on the processing of your personal data, even exercivse you rights as described above, please send us an email to <a href="info@gonnaway.com">info@gonnaway.com</a>.
                    </p>
        </div>
      </div>
      <div className="mt-10" />

      <div id="retention" className="relative flex gap-1 justify-center items-center">

        <div  className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
            <p style={{textAlign:"justify"}}>
                <strong>3. Data Retention</strong>
            </p>
            <p style={{textAlign:"justify"}}>
                The storage of personal data will take place in electronic / computer form for the time strictly necessary to fulfill the purposes referred to in point 1, in compliance with your privacy and current regulations.
                For direct marketing and profiling purposes we process your data, according to the consent you have provided, for a maximum period equal to that provided for by the applicable legislation (respectively equal to 24 and 12 months).
                You can ask to revoke the consent and delete your personal data at any time sending an email to <a href="info@gonnaway.com">info@gonnaway.com</a>.
             </p>
        </div>
      </div>
      <div className="mt-10" />

      <div id="cookies" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
            <p style={{textAlign:"justify"}}>
                <strong>4. Cookies & Similar Technologies</strong>
            </p>
            <p style={{textAlign:"justify"}}>
                  When you visit our sites (“Sites”), we may collect certain information by automated means, such as cookies, web beacons and web server logs.
                    The information we may collect in this manner includes IP address, unique device identifier,
                    browser characteristics, device characteristics, operating system, language preferences,
                    referring URLs, information on actions taken on our Sites, dates and times of visits to our Sites
                    and other usage statistics. For more information on the cookies used by Gonnaway, visit <NavLink to={"/cookie"}> Cookie Policy</NavLink>

             </p>
        </div>
      </div>
      <div className="mt-10" />

      <div id="update" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
            <p style={{textAlign:"justify"}}>
                <strong>5. Updates to This Policy</strong>
            </p>
            <p style={{textAlign:"justify"}}>
                   These privacy policy may be updated periodically to reflect changes in our personal information practices.
                  In case of significant changes, we will notify you by posting a prominent notice on our Website.
             </p>
        </div>
      </div>
      <div className="mt-10" />

      <div id="contact" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
            <p style={{textAlign:"justify"}}>
                <strong>6. How to Contact Us</strong>
            </p>
            <p style={{textAlign:"justify"}}>
                If you have any questions or comments about this Privacy Policy, or if you would like to exercise your rights, please type to <a href="info@gonnaway.com">info@gonnaway.com</a>.

             </p>
        </div>
      </div>

     </div>
        <div className="mt-10" />
        </div>
    </div>
  );
};



export default PagePrivacy;
