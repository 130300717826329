import { FC} from "react";
import Heading from "components/Heading/Heading";
import { useTranslation } from 'react-i18next';
import ListingMap from 'components/MapComponent/ListingMap';


export interface SectionSliderBestCountriesProps {
  className?: string;
  heading: string;
  subHeading: string;
  url?:string;
}

const SectionSliderBestCountries: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSliderBestCountriesProps>>> = ({
  heading,
  subHeading,
  className = "",
  url
}) => {

  const {t} = useTranslation();
  return (
    <div className={`nc-SectionSliderNewAuthors ${className} container`}>
      <div className="">
      <Heading desc={subHeading}>
            🎡 {heading}
        </Heading>

        <div className="mt-5 relative mb-5">
            <ListingMap url={url} />
        </div>
      </div>
    </div>
  );
};

export default SectionSliderBestCountries;

