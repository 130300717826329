import React, { FC, useState } from "react";
import ButtonCircle from "components/Button/ButtonCircle";
import NcImage from "components/NcImage/NcImage";
// import Badge from "components/Badge/Badge";
import Input from "components/Input/Input";
import { CircularProgress } from "@mui/material";
import axios from "../../axios";
import { useTranslation } from 'react-i18next';
import Label from 'components/Label/Label';


export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSubscribe2Props>>> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [email, setEmail] = useState("");
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setSubscribed(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post("/subscribe/add", {
        email: email,
        date: new Date()
      })
      .then((res: any) => {
        setEmail("");
        setSubmitting(false);
        setSubscribed(true);

        setTimeout(() => {
          setActiveLabel(false);
        }, 5000);
        setActiveLabel(true);


      })
      .catch((err) => {
        setEmail("");
        setSubmitting(false);
        setSubscribed(true);
        setActiveLabel(false);
      });
  };
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">{t('sectionSubscribe.title')} 🎉</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          {t('sectionSubscribe.heading')}
        </span>

        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder={t('sectionSubscribe.enterMail')}
            type="email"
            value={email}
            onChange={handleChange}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            {submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#eee",
                }}
              />
            ) : subscribed ? (
              <i className="las la-check text-xl"></i>
            ) : (
              <i className="las la-arrow-right text-xl"></i>
            )}
          </ButtonCircle>
        </form>
        {activeLabel ? (
            <Label className="mt-10">{t('check.subscr')} 🎊 🎉</Label>
         ) : ( <div />)}
      </div>
      <div className="flex-grow">
        <NcImage src="/static/newsletter.png" />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
