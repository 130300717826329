import Heading2 from "components/Heading/Heading2";
import { Route } from "routers/types";
import Link from "components/NcLink/Link";
import React, { ReactNode } from "react";
import usePathname from "hooks/usePathname";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from "app/hooks";
import { removeUser } from "app/user/user";



const Layout = ({ children }: { children: ReactNode }) => {
  const pathname = usePathname();
  const {t} = useTranslation();
const subPages: { href: Route; pageName: string; emoij: string }[] = [
  {
    href: "/dashboard/root",
    emoij: "📲",
    pageName: "Dashboard",
  },
  {
    href: "/dashboard/posts",
    emoij: "📕",
    pageName: t('dashboard.articles'),
  },
  {
    href: "/dashboard/edit-profile",
    emoij: "🛠",
    pageName: t("dashboard.edit"),
  },
  {
    href: "/dashboard/change-password",
    emoij: "🔐",
    pageName: t("dashboard.pwd"),
  },

  {
    href: "/dashboard/submit-post",
    emoij: " 📝",
    pageName: t("dashboard.publish"),
  },
];

 const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(removeUser());
  };


  return (
    <div className={`nc-PageDashboard container`}>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-24">
        <Heading2 emoji="">Dashboard</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          {t('dashboard.subtitle')}
        </span>
      </header>

      <div className="flex flex-col space-y-8 xl:space-y-0 xl:flex-row">
        {/* SIDEBAR */}

        <div className="flex-shrink-0 max-w-xl xl:w-80 xl:pr-8">
          <ul className="text-base space-y-1 text-neutral-700 dark:text-neutral-400">
            {subPages.map(({ href, pageName, emoij }, index) => {
              return (
                <li key={index}>
                  <Link
                    className={`px-6 py-3 font-medium rounded-full flex items-center ${
                      pathname === href
                        ? "bg-neutral-100 dark:bg-neutral-800 text-neutral-900 dark:text-neutral-100"
                        : "hover:text-neutral-800 hover:bg-neutral-100 dark:hover:bg-neutral-800 dark:hover:text-neutral-100"
                    }`}
                    href={href}
                  >
                    <span className="w-8 mr-2 text-lg">{emoij}</span>
                    <span> {pageName}</span>
                  </Link>
                </li>
              );
            })}

          {/*}  <li className=" border-t border-neutral-200 dark:border-neutral-700" />
                <li>
                  <Link
                    className={`flex items-center px-6 py-3 font-medium text-neutral-700 dark:text-neutral-400`}
                    href={"/dashboard/privacy"}
                  >
                    <span className="w-8 mr-2 text-lg">🗂</span>
                    Privacy
              </Link>
            </li>*/}
            <li className=" border-t border-neutral-200 dark:border-neutral-700" />
            <li>
              <Link
                className={`flex items-center px-6 py-3 font-medium text-red-500`}
                href={"/login"}
                onClick={handleLogout}
              >
                <span className="w-8 mr-2 text-lg">💡</span>
                Sign out
              </Link>
            </li>
          </ul>
        </div>

        <div className="border-t border-neutral-500 dark:border-neutral-300 md:hidden"></div>

        <div className="flex-1">{children}</div>
      </div>
    </div>
  );
};

export default Layout;
