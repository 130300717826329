import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectRecentLikeds,
  selectRecentRemoveds,
  removeLikedByPostId,
  addNewLikedByPostId,
} from "app/postLikes/postLikes";

import { PostDataType } from "data/types";
import PostCardLikeAction, {
  PostCardLikeActionProps,
} from "components/PostCardLikeAction/PostCardLikeAction";
import { selectUser } from "app/user/user";
import axios from "../../axios";

export interface PostCardLikeContainerProps
  extends Omit<PostCardLikeActionProps, "isLiked" | "likeCount"> {
  like: PostDataType["like"];
}

const PostCardLikeContainer: FC<React.PropsWithChildren<React.PropsWithChildren<PostCardLikeContainerProps>>> = ({
  like,
  postId,
  onClickLike,
  ...args
}) => {
  const recentLikeds = useAppSelector(selectRecentLikeds);
  const recentRemoveds = useAppSelector(selectRecentRemoveds);
  const { user }: any = useAppSelector(selectUser);

  const dispatch: any = useAppDispatch();

  const isLiked = () => {
    if (recentLikeds.includes(postId)) {
      return true;
    }
    if (like.isLiked && !recentRemoveds.includes(postId)) {
      return true;
    }
    return false;
  };

  const getLikeCount = (): number => {
    // Recent Liked
    if (!like.isLiked && recentLikeds.includes(postId)) {
      return like.count + 1;
    }
    if (like.isLiked && recentRemoveds.includes(postId)) {
      return like.count - 1;
    }
    return like.count;
  };

  const handleClickLike = () => {
    let payload = { postId: postId };
    if (isLiked()) {
      dispatch(removeLikedByPostId(payload));
      changeLike("remove");
    } else {
      dispatch(addNewLikedByPostId(payload));
      changeLike("add");
    }
    onClickLike && onClickLike(postId);
  };

  const changeLike = (type: any) => {
    axios
      .post(`/articles/like/${type}`, {
        article_id: postId,
        author_id: user.id,
      })
      .then((result) => {
      })
      .catch((err) => {
        console.log("There was an error");
      });
  };

  return (
    <PostCardLikeAction
      {...args}
      isLiked={isLiked()}
      likeCount={getLikeCount()}
      postId={postId}
      onClickLike={handleClickLike}
    />
  );
};

export default PostCardLikeContainer;
