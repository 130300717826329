import { Popover, Transition } from "@headlessui/react";
import Input from "components/Input/Input";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';

const SearchDropdown = () => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [query, setQuery] = useState("");
  const history = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);
  const {t} = useTranslation();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    history(`/blog/search?q=${query}`);
    setQuery("");
    setOpenSearch(false);
  };


  return (
    <React.Fragment>
      <Popover className="relative">
        {({ open }) => {
          if (open) {
            setOpenSearch(true);
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }

          return (
            <>
              <Popover.Button className="text-2xl md:text-[28px] w-12 h-12 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center">
                <i className="las la-search"></i>
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                {openSearch ? (
                  <Popover.Panel
                    static
                    className="absolute right-0 z-10 w-screen max-w-sm mt-3"
                  >
                    <form onSubmit={handleSubmit}>
                      <Input
                        ref={inputRef}
                        type="search"
                        placeholder={t('type.press')}
                        pattern="[A-Za-z0-9 ]+"
                        onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.search'))}
                        onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                        value={query}
                        onChange={(e: any) => setQuery(e.target.value)}

                      />
                      <input type="submit" hidden value="" />
                    </form>
                  </Popover.Panel>
                ) : (
                  <></>
                )}
              </Transition>
            </>
          );
        }}
      </Popover>
    </ React.Fragment>
  );
};

export default SearchDropdown;
