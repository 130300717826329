import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';


export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-height: 5vh;

  .componentBlock {
    @media (min-width: 1400px) {
      flex-direction:column;
    }
  }

  /* map auto complete style */
  .map_autocomplete {
    width: 100%;
    position: relative;
    zIndex:200;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: auto;
    }

    @media (max-width: 1400px) {
      &::after {
        top: auto;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }
    }
    @media (min-width: 1400px) {
      &::after {
        top: auto;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
        flex-direction:column;
      }
    }

    @media (max-width: 480px) {
      &::after {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    input {
      border: 0;
      font-size: 15px;
      @media (max-width: 380px) {
                font-size: 13px;
        }
      font-weight: 400;
      border-radius: 0;
      height: 60px;
      padding: 0 30px 0 40px;
      background: transparent;

      @media (max-width: 480px) {
        height: 47px;
        padding: 0 30px 0 36px;
      }

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }

      &::placeholder {
      }
    }
  }

   .map_autocomplete_fixed {
        width: 100%;
        height:50px;
        position: relative;
        zIndex:200;


        &::after {
          content: '';
          width: 1px;
          height: 20px;
          display: inline-block;
          position: absolute;
          top: calc(50% - 10px);
          right: auto;
          left: auto;

        }
          @media (min-width: 1400px) {
          &::after {
            top: auto;
            bottom: 0;
            height: 1px;
            width: calc(100% - 40px);
            left: 20px;
            flex-direction:column;
          }
        }

        @media (max-width: 1400px) {
          &::after {
            top: auto;
            bottom: 0;
            height: 1px;
            width: calc(100% - 40px);
            left: 20px;
          }
        }

        @media (max-width: 480px) {
          &::after {
            width: calc(100% - 30px);
            left: 15px;
          }
        }

        input {
          //border: 0;
          font-size: 15px;
          @media (max-width: 380px) {
                font-size: 13px;
        }
          font-weight: 400;
          border-radius: 0;
          height: 60px;
          padding: 0 30px 0 40px;
          background: transparent;


          @media (max-width: 480px) {
            height: 47px;
            padding: 0 30px 0 36px;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
          }
        }


  }

    .select-search {
        min-width: calc(250px - 14px);
        width: calc(100% - 40px);
        margin: 0 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom:1px solid;
        border-width: 1px !important;
        border-color: #e6e6e6 !important;


        @media (min-width: 1400px) {
          justify-content: flex-start;
          height: 60px;
        }

        @media (max-width: 1400px) {
          justify-content: flex-start;
          height: 60px;
        }

        @media (max-width: 480px) {
          height: 47px;
        }

    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {

        background-color: transparent;
    }

    /**
     * Value wrapper
     */
    .select-search__value {
        position: relative;
        z-index: 1;
    }

    .select-search__value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
     * Input
     */
    .select-search__input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 40px 0 16px;

        background: transparent;
        border: 0px solid transparent;
        //box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        outline: none;
        font-family: 'Lato', sans-serif;
        color:#000;
        font-size: 15px;
        @media (max-width: 380px) {
                font-size: 13px;
        }
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        -webkit-appearance: none;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
     * Options wrapper, box options
     */
    .select-search__select {
        background: #fff;
        height:200px;
        //box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    }

    /**
     * Options
     */
    .select-search__options {
        list-style: none;
    }

    /**
     * Option row
     */
    .select-search__row:not(:first-child) {
        border-top: 1px solid RGB(0,136,137,0.3);
    }

    /**
     * Option
     */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: #fff;
        border: none;
        outline: none;
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        @media (max-width: 380px) {
                font-size: 13px;
        }
        text-align: left;
        cursor: pointer;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: #008489;
        color: #fff;
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: rgba(0, 136, 137, 0.1);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: #2eb378;
        color: #fff;
    }

    /**
     * Group
     */
    .select-search__group-header {
        font-size: 10px;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
     * States
     */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
       // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }

    /**
     * Modifiers
     */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(45deg);
        border-right: 0px solid #000;
        border-bottom: 0px solid #000;
        pointer-events: none;
    }
    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: #2FCC8B;
    }
    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 44px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 360px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: #888;
    }

    /*Single Date Picker*/

    .SingleDatePicker {
              display: block;
              width: 100%;
              .SingleDatePickerInput {
                    border: 0;
                    padding-right: 0;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: transparent;

                    .SingleDatePickerInput_clearDates,
                    .SingleDatePickerInput_clearDates {
                      &.SingleDatePickerInput_clearDates__small {
                        display: none;
                      }

                    }
               }

               .DateInput {
                  width: 100%;
                  padding: 0 0px 0 0px;
                  background: transparent;
                  margin-top:5px;

                  @media (min-width: 569px) and (max-width: 1400px) {
                    padding: 0 25px 0 10px;
                  }

                  @media (max-width: 568px) {
                    padding: 0 0.6rem 0 0.7rem;
                  }

                  .DateInput_fang {
                    display: none;
                  }

                  .DateInput_input {
                    padding: 3px;
                    font-weight: 400;
                    background: transparent;
                    //margin-left:22px;
                    border-color:#C2C2C2;


                    &.DateInput_input__focused {
                      border-color: transparent;
                    }
                    font-size:15px;
                      @media (max-width: 380px) {
                        font-size: 13px;
                      }
                     &.DateInput_input__small {
                      font-size:15px;
                    }

                    &::placeholder {
                    }
                 }
        }

      }


.quantity {
    height: 30px;

    input {
      font-size: 15px;
      color:#ADA8BA;

    }

    button.btn svg {
      width: 18px;
      height: 18px;
    }
  }



  /* date picker style */
  .date_picker {
    min-width: calc(250px - 14px);
    width: 100%;
    margin: 0 0 0 0rem;
    display: flex;

    justify-content: center;
    align-items: center;



    @media (max-width: 1400px) {
      justify-content: flex-start;
      height: 60px;
    }

    @media (max-width: 480px) {
      height: 47px;

    }

    .DayPicker__withBorder {
      box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);

    }


    .DateRangePicker {
      display: block;
      width: 100%;

      .DateRangePickerInput {
        border: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        .DateRangePickerInput_clearDates,
        .DateRangePickerInput_clearDates {
          &.DateRangePickerInput_clearDates__small {
            display: none;
          }
        }

        .DateInput {
          width: 100%;
          //padding: 0 15px 0 30px;
          background: transparent;


          .DateInput_fang {

          }



          .DateInput_input {
            padding: 3px;
            font-weight: 400;
            background: transparent;
            border-color:#C2C2C2;


            &.DateInput_input__focused {
              border-color: transparent;
            }
            &.DateInput_input__small {
              font-size:15px;
              @media (max-width: 380px) {
                font-size: 15px;
              }
            }

            &::placeholder {
            }
          }
        }

        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
      }

      .DateRangePicker_picker {


        margin-top: -12px;

        z-index: 55;



        @media (max-width: 1400px) {
          top: 47px !important;
        }

        @media (max-width: 320px) {
          left: -29px !important;
          .DayPicker,
          .DayPicker > div > div,
          .DayPicker > div > div .DayPicker_transitionContainer {
            width: 294px !important;
          }

          .DayPicker {
            .DayPicker_weekHeader {
              padding: 0 !important;
            }

            .CalendarMonth {
              padding: 0 !important;
            }
          }
        }
      }
    }



  }



  /* view with popup component style */
  .view_with__popup {
    max-width: 100%;
    position: relative;
    margin: 0 0 0 1.6rem;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: 0;
      z-index: 1;

      @media (max-width: 1400px) {
        top: 0;
        bottom: auto;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }

      @media (max-width: 480px) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    /* popup handler style */
    .popup_handler {
      width: calc(100% - 14px);


      button.ant-btn {
        min-width: auto;
        width: 100%;
        height: 60px;
        border: 0;
        padding: 0 0px;
        border-radius: 0;
        box-shadow: none;
        font-weight: 400;
        font-size: 15px;
        @media (max-width: 380px) {
                font-size: 13px;
        }




        &::after {
          display: none;
        }
      }
    }

    &.room_guest {
      .popup_handler {
        button.ant-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    &.active {
      .popup_container {
        margin-top: 0;
        padding: 22px;
        box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);

        @media (max-width: 1400px) {
          margin-top: 0;
        }

        @media (max-width: 480px) {
          min-width: auto;
        }
      }
    }
  }

  /* icon style */
  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    &.map-marker,
    &.calendar,
    &.user-friends {
      left: 15px;
      right: auto;
      color: #008783;
      top:15px;
    }

    &.map-marker {
      @media (max-width: 480px) {
        width: 20px;
        height: 20px;
        color: #008783;
      }
    }
    

    
    &.calendar {
      @media (max-width: 480px) {
        width: 40px;
        height: 40px;
        color: #008783;
      }
    }

    &.user-friends {
      width: 17px;
      height: 17px;
      color: #008783;
      @media (max-width: 480px) {
        width: 16px;
        height: 16px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;

      @media (max-width: 1400px) {
        right: 20px;
      }

      @media (max-width: 1400px) {
        right: 15px;
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color:rgb(247,247,247);

  &:last-child {
    margin-bottom: 5px;
  }

  .quantity {
    height: 30px;

    input {
      font-size: 15px;
      @media (max-width: 1120px) {
                font-size: 11px;
      }
    }

    button.btn svg {
      width: 18px;
      height: 18px;
    }
  }
`;

export const RoomGuestWrapper = styled.div`
  width: 100%;
background-color:rgb(247,247,247);

  strong {
    font-size: 15px;
    @media (max-width: 1120px) {
                font-size: 11px;
              }

    font-weight: 400;
    color: ${themeGet('text.0', '#2C2C2C')};
  }
`;







export default ComponentWrapper;
