// @ts-nocheck
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageLoading from "containers/PageLoading/PageLoading";
import { useTranslation } from 'react-i18next';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import SectionGridPosts from "../PageSearchFlight/SectionGridPostsFlights";
import { getUrl,getStateFromUrl } from 'helpers/url_handler';
import useFetch from 'hooks/useFetchNomad';
import { useNavigate } from "react-router";
import { FlightType, FlightSearch,AirlineList} from "data/types";
import ButtonSecondary from "components/Button/ButtonSecondary";
import NotFound from 'images/Gonnaway_NotFound.png';
import NomadFilterSearch from 'components/SearchPropUI/Flight/Nomad/NomadFilterSearchView';
import NomadFilterSearchDark from 'components/SearchPropUI/Flight/Nomad/NomadFilterSearchViewDark';
import { useAppSelector } from "app/hooks";
import ListingWrapper from 'components/MapComponent/Listing.style';
import Sticky from 'react-stickynode';
import Toolbar from 'components/Toolbar/Toolbar';
import allCountriesList from "data/jsons/__countries.json";
import Heading from "components/Heading/Heading";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import {  useLocation } from 'react-router-dom';
import { useThemeMode } from "hooks/useThemeMode";
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import airportsJson from "data/jsons/airports.json";
import HereMap from 'components/HereMapComponent/HEREMap';
import ncNanoId from "utils/ncNanoId";
import SectionLatestPosts from "containers/PageHome/SectionLatestPosts";
import axios from "../../../axios";




export interface PageGonnawayTravelProps {
  className?: string;
}

const PageGonnawayTravelDetails: FC<PageGonnawayTravelProps> = ({ className = "" }) => {
  const history = useNavigate();
  const {t} = useTranslation();
  const {isDarkMode} = useThemeMode();
  const location=useLocation();
  const query=location.state.query;
  const userDestination=location.state.destinations;
  const [resultsData,setResultsData]=useState<any>("");
  const [flights,setFlights]=useState<any>([]);
  const [bkflights,setBkFlights]=useState<any>([]);
  const [showPosts, setShowPosts] = useState(10);
  const [filterLoading, setFilterLoading] = useState(false);

  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filterFH") },
    {id: 1, name: t('filter.best') },
    {id: 2, name: t('filter.cheapest') },

  ];
  const [selected, setSelected] = useState(FILTERS[1]);





/*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/

  /*RETRIEVE DATA MANAGEMENT*/
    let url="/nomad_travel"+location.search;
    const { loading, error, data }= useFetch(url,query);
    useEffect(() => {
        if (data) {
            if(data[0]){
                setFlights(data);
                setBkFlights(data);
            }
        }
    },[data]);
  /*END*/


    //// gestione del refresh
    let timeLastMove = new Date().getTime();
    let timeCurrentMove;
    const setActivityTime = (e) => {
      timeCurrentMove = new Date().getTime();
      if(timeCurrentMove-timeLastMove>=600000)
      {
          window.location.reload(true);
          timeLastMove=new Date().getTime();

      }
      else
      {
         timeLastMove=new Date().getTime();
      }
    }
    document.body.addEventListener("mousemove", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);

    /*manage show more card*/
      const showMore = () =>
      {
        setShowPosts(showPosts*2);
      }
    /*end*/

    /*CHANGE SORT*/
    const changeSelectedFilter = (e:any) =>
    {
         setTimeout(() => {
            setFilterLoading(false);
          }, 500);
          setFilterLoading(true);
          setSelected(e);
                switch (e.id){
                    default: setFlights(bkflights);break;
                    case 1: flights.sort((a:any, b:any) => (a.quality  < b.quality) ? 1 : -1);break;
                    case 2: flights.sort((a:any, b:any) => (a.price > b.price) ? 1 : -1);break;
                }
    }
    /*END*/

  /// lat and long cities
  function nCountry(){
    let tempArray=[];
    let latLongArray=[]
    for(let key in flights[0].route){
       tempArray.push(flights[0].route[key].countryFrom.code);
    }

    for(let key in tempArray){
        let codTemp=allCountriesList.filter((item:any) => {return item.alpha === tempArray[key];});
        latLongArray.push({
            country_id:codTemp[0].country_id,
            name:codTemp[0].name,
            flag:codTemp[0].flag,
            postcount:codTemp[0].postcount,
            lat:codTemp[0].lat,
            lng:codTemp[0].lng
        });
    }
   return latLongArray;
  }

  function distanceItinerary(){
    let distance=0
    for(let key in flights[0].route){
        distance+=flights[0].route[key].distance
    }
    return Math.round(distance);
  }




  if (loading) return <PageLoading />;
  return (
    <div className={`nc-PageCategory min-h-screen ${className}`} data-nc-id="PageCategory">
         <Helmet><title>Flights || Gonnaway Travel</title></Helmet>

         <div>
             <ListingWrapper>
               <Sticky top={0} innerZ={30} activeClass="isHeaderSticky">
                  <div className="bg-white dark:bg-neutral-900">
                      <Toolbar
                         left={
                                    isDarkMode ? (
                                        <NomadFilterSearchDark
                                            query={query}
                                            destinations={userDestination}
                                        />
                                    ):(
                                        <NomadFilterSearch
                                            query={query}
                                            destinations={userDestination}
                                        />
                                    )
                               }
                         right={
                                    <div className={`nc-ArchiveFilterListBox md:-mr-1 `} data-nc-id="ArchiveFilterListBox">
                                        <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                                        <div className="relative md:min-w-[200px]">
                                          <Listbox.Button as={"div"}>
                                            <ButtonDropdown>{selected.name}</ButtonDropdown>
                                          </Listbox.Button>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900  bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700 dark:text-neutral-100">
                                              {FILTERS.map((item: ListBoxItemType, index: number) => (
                                                <Listbox.Option
                                                  key={index}
                                                  className={({ active }) =>
                                                    `${
                                                      active
                                                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                                        : ""
                                                    } cursor-default select-none relative py-2 pl-10 pr-4`
                                                  }
                                                  value={item}
                                                >
                                                  {({ selected }) => (
                                                    <>
                                                      <span
                                                        className={`${
                                                          selected ? "font-medium" : "font-normal"
                                                        } block truncate`}
                                                      >
                                                        {item.name}
                                                      </span>
                                                      {selected ? (
                                                        <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                    </Listbox>
                                    </div>


                                }
                      />
                  </div>
               </Sticky>
             </ListingWrapper>
              {flights.length===0 && (
                <div className="relative">
                    <center><h2>{t('filterNoDataFlight')}</h2>
                     <img src={NotFound} width="625px" height="500px"
                         alt="Not Found page banner"/></center>
                </div>
            )}

             {flights[0] &&(

                 windowSize.innerWidth>1024 ? (
                    !filterLoading ? (
                    <div className="grid grid-cols-8 gap-4">
                        <div className="relative col-span-4 h-full max-h-screen overflow-y-auto flex flex-col flex-grow border-r container">
                            <SectionGridPosts
                              className=""
                              postCardName="cardNomad"
                              flights={flights.filter((_:any, i:any) => i < showPosts)}
                              gridClass="mt-5 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 mt-5"
                              headingIsCenter
                            />
                             {flights.length > 9 && flights.length>showPosts &&  (
                                 <div className="text-center mx-auto mt-8 md:mt-10">
                                   <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                 </div>
                             )}
                             <div className="mt-10" />
                        </div>


                         <div className="relative col-span-4 container">
                             <Heading
                                    className="mt-5 mb-5"
                                    desc=""
                                >
                                {t('nomad.youritinerary')} 🚀️
                             </Heading>

                             <div className="grid grid-cols-3 gap-4 ">

                                    <p className="flex justify-center text-lg font-semibold leading-none text-neutral-800 dark:text-neutral-200 gap-4">
                                        🛫️ ~{distanceItinerary()} km
                                    </p>


                                    <p className="flex justify-center text-lg font-semibold leading-none text-neutral-800 dark:text-neutral-200 gap-4">
                                        🌎 {flights[0].route.length-1} {t('nomad.visitedcountry')}
                                    </p>

                                    <p className="flex justify-center text-lg font-semibold leading-none text-neutral-800 dark:text-neutral-200 gap-4">
                                         💰 Min. {Math.min(...flights.map(o => o.price))} {flights[0].currency}
                                    </p>

                             </div>


                          <div
                              className="relative mt-10 mb-5"
                               style={{ width: "100%", height: "50vh", minHeight: "350px" }}
                          >

                              <HereMap
                                  mapContainerId={"map"+ncNanoId()}
                                  center={{ lat: 52.5321472, lng: 13.3935785 }}
                                  zoom={3}
                                  animateZoom={true}
                                  interactive={true}
                                  secure={true}
                                  nomadCountry={true}
                                  countriesNomad={nCountry()}

                                />
                          </div>

                         </div>
                    </div>
                    ):(<PageLoading />)
                 ):(
                    !filterLoading ? (
                    <div className="relative col-span-4 h-full max-h-screen overflow-y-auto flex flex-col flex-grow border-r container">

                        <SectionGridPosts
                          className=""
                          postCardName="cardNomad"
                          flights={flights.filter((_:any, i:any) => i < showPosts)}
                          gridClass="mt-5 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 mt-5"
                          headingIsCenter
                        />
                         {flights.length > 9 && flights.length>showPosts &&  (
                             <div className="text-center mx-auto mt-8 md:mt-10">
                               <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                             </div>
                         )}
                         <div className="mt-10" />

                    </div>
                    ):(<PageLoading />)
                 )


             )}

         </div>
    </div>

  );
};

export default PageGonnawayTravelDetails;
