import React, { FC, useState } from "react";
import axios from "../../axios";
import { useTranslation } from 'react-i18next';
import sustainabilityImage from 'images/sustainability.png'


export interface SectionSustainabilityProps {
  className?: string;
}

const SectionSustainability: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSustainabilityProps>>> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [email, setEmail] = useState("");
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setSubscribed(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post("/subscribe/add", {
        email: email,
        date: new Date()
      })
      .then((res: any) => {
        setEmail("");
        setSubmitting(false);
        setSubscribed(true);

        setTimeout(() => {
          setActiveLabel(false);
        }, 5000);
        setActiveLabel(true);


      })
      .catch((err) => {
        setEmail("");
        setSubmitting(false);
        setSubscribed(true);
        setActiveLabel(false);
      });
  };
  return (

    <div
      className={`relative flex flex-col lg:flex-row items-center mt-6 `}
      data-nc-id="SustainSection"
    >
      <div className=" lg:mb-0 ">
        <h2 className="font-semibold text-4xl">Sustainability Tips</h2>
          <div className="block mt-6 text-neutral-500 dark:text-neutral-200 gap-4 grid grid-cols-2">
            <p><span>🗓</span><span className="ml-4">{t('sustain.tip1')}</span></p>
            <p><span>🛩</span><span className="ml-4">{t('sustain.tip2')}</span></p>
            <p><span>🗑</span><span className="ml-4">{t('sustain.tip3')}</span></p>
            <p><span>🥡</span><span className="ml-4">{t('sustain.tip4')}</span></p>
            <p><span>💡</span><span className="ml-4">{t('sustain.tip5')}</span></p>
            <p><span>💧</span><span className="ml-4">{t('sustain.tip6')}</span></p>
          </div>
      </div>
    {/*}  <div className="flex-grow container ">
        <NcImage  src="/static/sustainability.png" alt="Designed by Freepik" />
      </div>*/}
    </div>
  );
};

export default SectionSustainability;
