//Flights or Hotels details
export const FLIGHT_DETAILS_PAGE = '/searchflightdetails';
export const FLIGHT_HOTELS_DETAILS_PAGE = '/flighthoteldetails';
export const HOTELS_DETAILS_PAGE = '/searchhotelsdetails';
export const NOMAD_DETAILS_PAGE = '/searchflightailored';
export const MULTICITY_DETAILS_PAGE = '/searchmulticitydetails';


// Flight Api Params
export const DEPARTURE_AIRPORT = "departure_airport";
export const ARRIVAL_AIRPORT = "arrival_airport";
export const OUTBOUND_DATE = "outbound_date";
export const RETURN_DATE = "return_date";
export const ADULTS_COUNT = "adults_count";
export const CHILD_COUNTS = "child_counts";
export const INFANTS_COUNT = "infants_count";
export const FLIGHT_CLASS = "flight_class";
export const WITH_RETURN = "with_return";
export const CURRENCY = "currency";
export const BUDGET = "budget";
