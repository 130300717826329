import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionGridPosts from "./SectionGridPosts";
import { selectTopAuthors,selectTopCategories } from "app/content/content";
import { useAppSelector } from "app/hooks";
import useFetch from "hooks/useFetch";
import PageLoading from "containers/PageLoading/PageLoading";
import { convertDate } from "utils/convertDate";
import { useTranslation } from 'react-i18next';
import ModalCategories from "../PageCategory/ModalCategories";
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import ButtonSecondary from "components/Button/ButtonSecondary";
import {backendAddress} from "../../index";
import Page404 from 'containers/Page404/Page404';



export interface PageArticlesProps {
  className?: string;
}


const PageArticles: FC<React.PropsWithChildren<React.PropsWithChildren<PageArticlesProps>>> = ({ className = "" }) => {
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const topCategories: any = useAppSelector(selectTopCategories);

  const { loading, error, data } = useFetch(`/articles`);
  const [allArticles, setAllArticles] = useState<any>([]);
  const [allArticlesBkc, setAllArticlesBkc] = useState<any>([]);

  const {t} = useTranslation();
  const [showPosts, setShowPosts] = useState(24);


  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filterFH") },
    {id: 1, name: t("filter.early") },
    {id: 2, name: t("filter.likes") },
    {id: 3, name: t("filter.comments") },
    //{id: 3, name: "Most Viewed" },
  ];

  const [selected, setSelected] = useState(FILTERS[0]);

  useEffect(() => {
    let articles: any = [];
    if (data) {
      data.map((article: any) => {
        articles.push({
          id: article.article_id,
          featuredImage: `${backendAddress}/${article.imgurl}`,
          title: article.title,
          desc: article.excerpt,
          date: convertDate(article.created_date),
          href: `/blog/article/${article.article_id}`,
          commentCount: article.commentcount | 0,
          readingTime: article.readingtime,
          country_name: article.country_name,
          bookmark: {
            isBookmarked: false,
          },
          like: {
            count: article.likes.length | 0,
            isLiked: false,
          },
          author: {
            id: article.author_id,
            displayName: article.author_name,
            avatar: `${backendAddress}/${article.author_imgurl}`,
            href: `/blog/author/${article.author_id}`,
          },
          categories: [
            {
              id: article.category_id,
              name: article.category_name,
              href: `/blog/category/${article.category_id}`,
              thumbnail: article.category_thumbnail,
              color: article.category_color,
            },
          ],
        });
      });
    }
    setAllArticles(articles);
    setAllArticlesBkc(articles);

  }, [data]);
   if (loading) return <PageLoading />;
  if (error) return <Page404 />;



  let articleTemp=allArticles;



  const changeSelectedFilter = (e:any) =>
  {
    setSelected(e);
     switch (e.id){
    case 0: setAllArticles(allArticlesBkc);
            break;
    case 1: articleTemp.sort((a:any, b:any) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()));
            setAllArticles(articleTemp);
            break;
    case 2: articleTemp.sort((a:any, b:any) => ((a.like.count)  < (b.like.count)) ? 1 : -1);
            setAllArticles(articleTemp);
            break;
    case 3: articleTemp.sort((a:any, b:any) => ((a.commentCount)  < (b.commentCount)) ? 1 : -1);
            setAllArticles(articleTemp);
            break;
    case 4:
            break;

  }
  }

   const showMore = () =>
  {
    setShowPosts(showPosts*2);
  }

  /*if (allArticles.length > 8)
  {
    setShowMoreButton(true);
  }*/



  return (

    <div className={`nc-PageArchive ${className}`} data-nc-id="PageArchive">
      <Helmet>
        <title>Articles || Community Travel</title>
      </Helmet>

      <div className="container space-y-16 lg:space-y-28 right-0 mt-10">
        <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row gap-4">
            <div className={`nc-ArchiveFilterListBox ${className}`}>
              <ModalCategories categories={topCategories} />
           {/*   <ModalTags tags={DEMO_TAGS} /> */}
            </div>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row sm:gap-y-2 justify-end">
              <div
                  className={`nc-ArchiveFilterListBox ${className}`}
                  data-nc-id="ArchiveFilterListBox"
                >

      <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
        <div className="relative md:min-w-[200px]">
          <Listbox.Button as={"div"}>
            <ButtonDropdown>{selected.name}</ButtonDropdown>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900 dark:text-neutral-200 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700">
              {FILTERS.map((item: ListBoxItemType, index: number) => (
                <Listbox.Option
                  key={index}
                  className={({ active }) =>
                    `${
                      active
                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                        : ""
                    } cursor-default select-none relative py-2 pl-10 pr-4`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`${
                          selected ? "font-medium" : "font-normal"
                        } block truncate`}
                      >
                        {item.name}
                      </span>
                      {selected ? (
                        <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
            </div>
        </div>


        {allArticles[0] ? (
          <div className="relative">
            <SectionGridPosts
              className=""
              postCardName="card11"
              heading={t('article.all')}
              subHeading={t('article.explore',{allAr: allArticles.length})}
              posts={allArticles.filter((_:any, i:any) => i < showPosts)}
              gridClass="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8"
              headingIsCenter
            />
            {allArticles.length> 24 && allArticles.length>showPosts &&  (
             <div className="text-center mx-auto mt-8 md:mt-10">
                <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
             </div>
             )}
             <div className="mt-10" />

          </div>
        ):(

           <span>{t('article.issue')}</span>

        )}



        {/* === SECTION 5 === */}
      <div className="relative py-16">
        <BackgroundSection />
        {topAuthors && topAuthors[0] && (
        <SectionSliderNewAuthors
          heading={t('author.eliteHeading')}
          subHeading={t('author.eliteSubHeading')}
          authors={topAuthors}
        />
        )}
      </div>

        {/* SUBCRIBES */}
        <SectionSubscribe2 className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default PageArticles;




