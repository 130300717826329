import React, { FC,useState,useEffect } from "react";
import NcImage from "components/NcImage/NcImage";
import { SuggestionsItinerariesType } from "data/types";
import { useNavigate } from "react-router";
import { NOMAD_DETAILS_PAGE } from 'data/constant';
import {getCurrency} from "locale-currency";
import NcModal from "components/NcModal/NcModalItinerary";
import { useThemeMode } from "hooks/useThemeMode";
import Autocomplete from "@mui/material/Autocomplete";
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import { useTranslation } from 'react-i18next';
import ncNanoId from "utils/ncNanoId";
import { Button } from 'antd';
import {FaMapMarkerAlt, FaRegCalendar} from "react-icons/fa";
import {TbPlusMinus} from 'react-icons/tb';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import Input from "components/Input/Input";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import axios from "../../axios";
import {ComponentWrapper} from './CardSuggestion.style';
import { setStateToUrl } from 'helpers/url_handler';



export interface CardCategory5Props {
  className?: string;
  taxonomy: SuggestionsItinerariesType;
}

interface QuerySearch {
  adults_count: number;
  childs_count: number;
  infants_count:number;
  departure_airport:string;
  return_airport:string;
  flight_class:string;
  currency:string;
  date_from?:any;
  date_from_up_to?:any,
  date_to?:any;
  places_to_visit?:any[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  flexibility?:number | undefined;
  id?:string;
}

export interface AirportType {
  value: string;
  name: string;
}

const CardSuggestions: FC<React.PropsWithChildren<React.PropsWithChildren<CardCategory5Props>>> = ({
  className = "",
  taxonomy,
}) => {
  const { days, name, href = "/", image, color,itinerary } = taxonomy;
  const [openDialog, setOpenDialog] = useState(false);
  const {isDarkMode}=useThemeMode();
  const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:""}]);
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:""}]);
  const [labelCheck, setLabelCheck] = useState(false);
  const [flexDays,setFlexDays] = useState (3);
  const [returnDiff, setReturnDiff] = useState(false);

  const [arrivalAirportValue, setArrivalAirportValue] = useState("");
  const [departureAirportValue, setDepartureAirportValue] = useState<string>("");

  const {t} = useTranslation();
     //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };

  /* to manage search variables */
   const [searchDate, setSearchDate] = useState({
    setStartDate: null,
    setEndDate: null,
  });

  //Manage days flexibility

  const [oneWayDate, setOneWayDate] = useState({
    setDate: null,
  });

    const addDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()+days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return formattedToday;
        }
        return oneWayDate.setDate;
    }

    const decreaseDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            var today = new Date();
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()-days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return (date.getTime()-today.getTime()>=0) ? formattedToday : oneWayDate.setDate;
        }
        return oneWayDate.setDate;
    }

  const handleIncrementDays = () => {
        setFlexDays(flexDays+1);
  };
  const handleDecrementDays = () => {
    if(flexDays<=1){setFlexDays(1);}else{setFlexDays(flexDays-1);}
  };

  ///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);


  //AIRPORT
  const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => (option.name),
  };

  const defaultDepProps = {
    options: airValue,
    getOptionLabel: (option: AirportType) => option.name,
  };

  const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=it`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
              }
                setAirValue(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }

  const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=it`)
            .then((results:any) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
              }
                setAirValueArr(airTemp);
            })
            .catch((err:any) => {
              console.log("There was an error");
            });
    }

    const updateDepartureAirportFunc = (value:any) => {
    if(value!=null){
     setDepartureAirportValue(value.value);
     }else{return;}
   };

    const updateArrivalAirportFunc = (value:any) => {
        if(value!=null){
            setArrivalAirportValue(value.value);
        }
        else{return;}
   };


   function getRandomInt(max:number) {
      let temp=Math.floor(Math.random() * max);
      return temp;
    }

    /*GO To Search*/
  const history=useNavigate();
  const goToSearchPage = (queryArray:any,userDestArray:any) => {
    if(departureAirportValue==="" || !oneWayDate.setDate ){
        setTimeout(() => {
          setLabelCheck(false);
        }, 3000);
        setLabelCheck(true);
        return;
    }

    var query : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: 1,
      childs_count: 0,
      infants_count: 0,
      flight_class: "Y",
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:0,
      adult_hand_bag:1,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      flexibility:flexDays,
      places_to_visit:queryArray

    };

 var querySearchParam : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: 1,
      childs_count: 0,
      infants_count: 0,
      flight_class: "Y",
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:0,
      adult_hand_bag:1,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      flexibility:flexDays,
      id:ncNanoId()
    };
   /* let qASerialize:any=JSON.stringify(queryArray);
    let addSer="&";
    for (let key in queryArray){

        addSer += ("place=" + queryArray[key].location+"&min_days="+queryArray[key].min_days+"&max_days="+queryArray[key].max_days);
    }*/

    //const search = setStateToUrl(querySearchParam)+addSer;
    const search = setStateToUrl(querySearchParam);
    history(
      {
        pathname: NOMAD_DETAILS_PAGE,
        search: search,
      },
      { state: {
        query: query ,
        destinations:userDestArray
      } },
    );
  };


  /*END*/

  //ThemeProvider
  const theme = createTheme({
      components: {
        MuiTextField: {
            styleOverrides: {
                root:{
                    background:"transparent",
                    backgroundColor:"transparent",
               },
            },
        },
        MuiAutocomplete: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
               background:"transparent",
            },
            input:{
               background:"transparent",
            },
            inputRoot: {
                color:"black",
                background:"transparent",
              "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e5e7eb",
                  background:"transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#008489"
                },
              borderRadius:"10px",
              fontSize: windowSize.innerWidth>500 ? "18px" : "16px"
            },
          },
        },
      },
   });

    const themeDark = createTheme({
  components: {
    // Name of the component

    MuiInput: {
        styleOverrides: {
            root:{

                "&:before": {
                  borderBottom: "1px solid rgb(255,255,255)",
                },
                "&:hover": {
                  borderBottom: "1px solid #008489",
                },
           },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root:{
                color:"white",

           },


        },
    },


    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                color:"white"
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          // Some CSS
           background:"transparent",
        },
        input:{
                background:"transparent",
                color:"white"
        },
        inputRoot: {
            background:"transparent",
            color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
          fontSize: windowSize.innerWidth>500 ? "18px" : "16px"
        },
      },
    },
  },
});

  const renderContent = () => {
    return(
        <div className={(windowSize.innerWidth<500) ? " h-full max-h-screen " : " h-full max-h-screen"}>
             <div className="flex flex-wrap items-center">
                 <p>{t('nomad.addDestinationSubHeadingSuggestion')}</p>
             </div>
             <div>
                <div className="flex grid grid-cols-10 items-center gap-3 mt-5 ">
                    <p className="mt-2 col-span-1"><FaMapMarkerAlt style={{width:"30px", height:"30px", color:"#008487"}}/></p>
                    <ThemeProvider theme={isDarkMode ? themeDark : theme}>
                       <Autocomplete
                                {...defaultDepProps}
                                id="departure"
                                autoComplete={true}
                                autoSelect={true}
                                onInputChange={(e:any, newValue: any) => onChangeDepartureAirport(e,newValue)}
                                onChange={(event:any, newValue:any)=>updateDepartureAirportFunc(newValue)}
                                isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                style={{ color: "black", marginLeft:"0.7rem"}}
                                className="text-gray-800 dark:text-gray-100 zIndex-10000 col-span-9 ml-2"
                                autoHighlight={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label={t('city')}
                                    variant="standard"

                                      />
                                )}
                                loading={true}
                                noOptionsText={t('noOptionTextAutoComplete')}
                          />
                    </ThemeProvider>
                </div>
                {returnDiff &&(
                    <div className="flex grid grid-cols-10 items-center gap-3 mt-5 ">
                    <p className="mt-2 col-span-1"><FaMapMarkerAlt style={{width:"30px", height:"30px", color:"#008487"}}/></p>
                    <ThemeProvider theme={isDarkMode ? themeDark : theme}>
                       <Autocomplete
                                {...defaultArrProps}
                                id="arrival"
                                autoComplete={true}
                                autoSelect={true}
                                onInputChange={(e:any, newValue: any) => onChangeArrivalAirport(e,newValue)}
                                onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                                isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                style={{ color: "black", marginLeft:"0.7rem"}}
                                className="text-gray-800 dark:text-gray-100 zIndex-10000 col-span-9 ml-2"
                                autoHighlight={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t('tocity')}
                                    variant="standard"
                                      />
                                )}
                                loading={true}
                                noOptionsText={t('noOptionTextAutoComplete')}
                          />
                    </ThemeProvider>
                </div>
                )}


                <div className="flex grid grid-cols-10 items-center gap-3 mt-12">
                  <p className="col-span-1"><FaRegCalendar style={{width:"30px", height:"30px", color:"#008487"}}/></p>

                  <ComponentWrapper className="col-span-9">
                    <SingleDatePicker
                      numberOfMonths={1}
                      small={true}
                      required={true}
                      placeholder= {t('startDate')}
                      onDateChange={(dateValue:any) => setOneWayDate(dateValue)}
                      displayFormat="DD-MM-YYYY"
                      withPortal={false }
                    />
                  </ComponentWrapper>

                 </div>
                 <div className="flex grid grid-cols-10 items-center gap-2 mt-12">
                   <p className="col-span-1"><TbPlusMinus style={{width:"30px", height:"30px", color:"#008487"}}/></p>

                  <p className="col-span-5 ml-2">{t('itinerary.flex')}</p>
                  <ComponentWrapper className="col-span-4  text-neutral-500 dark:text-neutral-100 flex justify-end">

                        <InputIncDec
                             id="flexDays"
                             className="text-neutral-500 dark:text-neutral-100"
                             increment={() => handleIncrementDays()}
                             decrement={() => handleDecrementDays()}
                             value={flexDays}
                        />
                 </ComponentWrapper>
                 </div>



                 <div className="flex justify-end mt-10">
                    <Button style={{backgroundColor:"#008487"}} onClick={()=>modelizeItinerary()}><span className="text-white">{t('nomad.set')}</span></Button>
                 </div>
                 <p className="text-neutral-500 ml-3 mt-2 flex flex-wrap gap-2 items-center font-semibold">
                    <Input
                            type="checkbox"
                            className="mt-1 w-1 h-1 self-center dark:bg-neutral-200"
                            name="comeBack"
                            value="comeBack"
                            style={{height:"10px", width:"10px"}}
                            onChange={()=>setReturnDiff(!returnDiff)}

                          />
                    <span>{windowSize.innerWidth>700 ?  t('itinerary.diffReturn') : t('itinerary.diffReturnMobile')}</span>
                 </p>

                 {labelCheck &&(
                        <p className="text-neutral-900 dark:text-neutral-100">{t("nomad.compileFields")}</p>
                 )}
             </div>
        </div>

    );
  }
  const renderTrigger = () => {
    return null;
  };

  const handleClose = () => {
    setOpenDialog(!openDialog)
  };



  const handleClickClose = () => {
    setOpenDialog(!openDialog);

  };



   const filterData = (userDestination:any) =>
    {
        let tempArray=[];
        for (let key in userDestination){
            tempArray.push({
                location:userDestination[key].name,
                min_days:userDestination[key].min_days,
                max_days:userDestination[key].max_days
            });
        }
        goToSearchPage(tempArray,userDestination);
    }

   function modelizeItinerary(){
      let userItinerary=itinerary[getRandomInt(itinerary.length)];
      filterData(userItinerary);
   }







  return (
    <div>
        <div

          className={`nc-CardCategory5 relative block group ${className}`}
          onClick={()=>setOpenDialog(!openDialog)}
        >
          <div
            className={`flex-shrink-0 relative w-full aspect-w-7 aspect-h-7 sm:aspect-h-5 h-0 rounded-2xl sm:rounded-3xl overflow-hidden group`}
          >
            <NcImage
              src={image}
              className="object-cover w-full h-full rounded-2xl"
            />
            <span className="absolute inset-0 bg-black bg-opacity-20 group-hover:bg-opacity-30 transition-opacity"></span>

          </div>



          <div className="absolute inset-0 flex items-center justify-center">
            <h2
              className={`text-base font-medium px-4 py-2 sm:px-6 sm:py-3 bg-white text-neutral-900 bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-full border-2 border-white border-opacity-60`}
              style={{ marginBottom: "20px" }}
            >
              <span className=" flex items-center justify-center line-clamp-1">{name}</span>
              <span className="flex items-center  justify-center  line-clamp-1 text-sm">{days} {t('nomad.days')}</span>
            </h2>

          </div>

        </div>
        <NcModal
              isOpenProp={openDialog}
              onCloseModal={()=>handleClickClose()}
              contentExtraClass="max-w-screen-sm"
              renderContent={()=>renderContent()}
              renderTrigger={renderTrigger}
              modalTitle={t('nomad.flightheader')}
        />
    </div>
  );
};

export default CardSuggestions;
