import {useAppDispatch, useAppSelector} from "app/hooks";
import {removeUser, selectUser} from "app/user/user";
import axios from "../../axios";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";
import { useTranslation } from 'react-i18next';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import { useNavigate } from "react-router";
import {useEffect} from "react";
import Layout from "./layout";



const DashboardChangePassword = () => {
  const { user }: any = useAppSelector(selectUser);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<any>({});
  const [showAlert, setShowAlert] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const history = useNavigate();

  const [value, setValue] = useState<any>({
    id: user.id,
    currentPassword: "",
    newPassword: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setShowAlert(false);
  };

  useEffect(() => {
    getAccessToken().then((access_token: any) => {
    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                dispatch(removeUser());
                history("/login");
        }
    });


  }, []);


  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
     getAccessToken().then((access_token: any) => {
      axios
        .post("/authors/change-password", value,{
        headers: {
          'Authorization': `Bearer ${access_token}`,
          }
        })
        .then((res: any) => {
          setSubmitting(false);
          setMessage(res.data);
          setShowAlert(true);
          if (res.data.status === "success") {
            setValue({
              id: user.id,
              currentPassword: "",
              newPassword: "",
            });
          }
        })
        .catch((err) => {
          setSubmitting(false);
        });
     }).catch((err) => {
      if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        dispatch(removeUser());
        history("/login");
    });
  };

  return (
    <Layout>
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <Collapse in={showAlert}>
        <Alert
          severity={message.status}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <SvgIcon>
                <path
                  fill="inherit"
                  d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                />
              </SvgIcon>
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <p style={{ marginTop: ".2rem" }}>{message.message}</p>
        </Alert>
      </Collapse>
      <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <label className="block">
          <Label>{t('password.current')}</Label>
          <Input
            placeholder="***"
            type="password"
            className="mt-1"
            name="currentPassword"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.password'))}
            value={value.currentPassword}
            onChange={handleChange}
            required
          />
        </label>
        <label className="block">
          <Label>{t('password.new')}</Label>
          <Input
            type="password"
            minLength={8}
            className="mt-1"
            name="newPassword"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.password'))}
            value={value.newPassword}
            onChange={handleChange}
          />
        </label>
        <ButtonPrimary className="md:col-span-2 mb-5">
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('password.update')}</span>
          )}
        </ButtonPrimary>
      </form>
    </div>
    </Layout>
  );
};

export default DashboardChangePassword;
