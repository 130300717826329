import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import { useAppSelector } from "app/hooks";
import { selectTopAuthors } from "app/content/content";
import PageLoading from "containers/PageLoading/PageLoading";
import useFetch from "hooks/useFetch";
import { useTranslation } from 'react-i18next';
import Page404 from 'containers/Page404/Page404';


export interface PageTrendingProps {
  className?: string;
}

const PageTrending: FC<React.PropsWithChildren<React.PropsWithChildren<PageTrendingProps>>> = ({ className = "" }) => {
  const { loading, error, data } = useFetch(`/categories`);
  const [topCategories, setTopCategories] = useState<any>();
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const {t} = useTranslation();

  useEffect(() => {
    let categories: any = [];
    if (data) {
      data.map((category: any) => {
        categories.push({
          id: category.category_id,
          name: category.name,
          count: category.postcount | 0,
          href: `/blog/category/${category.category_id}`,
          thumbnail: category.thumbnail,
          color: category.color,
        });
      });
    }
    setTopCategories(categories);
  }, [data]);

  if (loading) return <PageLoading />;
  if (error) return <Page404 />;
  return (
    <div className={`nc-PageArchive ${className}`} data-nc-id="PageArchive">
      <Helmet>
        <title>Trending || Community Travel</title>
      </Helmet>

      <div className="container space-y-16 lg:space-y-28">
        {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox categories={topCategories} />
        </div>

        {/* === SECTION 5 === */}
        {topAuthors && topAuthors[0] && (
          <SectionSliderNewAuthors
            heading={t('author.eliteHeading')}
            subHeading={t('author.eliteSubHeading')}
            authors={topAuthors}
          />
        )}

        {/* SUBCRIBES */}
        <SectionSubscribe2 />
      </div>
    </div>
  );
};

export default PageTrending;
