import ReactDOM from "react-dom/client";
import { Suspense } from 'react';
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import App from "./App";
import { persistor, store } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import './i18n';
import * as env from "env-var";



export const backendAddress: string = env.get('REACT_APP_BACKEND_ADDRESS').default("").asString();
export const uploadImageEndpoint =`${backendAddress}/articles/images/1/`;
export const fetchFeaturedImageEndpoint = `${backendAddress}/articles/featured_image`;

const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store} >
    <PersistGate loading={null} persistor={persistor} >
      <Suspense fallback="">
        <App />
      </Suspense>
    </PersistGate>
  </Provider>
);


