import { FC } from "react";
import Card3 from "components/Card3/CardFlightDesktop";
import CardMobile from "components/Card3/CardFlightPlatform";
import CardMultiCity from "components/Card3/CardFlightMultiCity";
import CardNomad from "components/CardNomad/CardNomad";

const postsDemo: any[] = [];

export interface SectionGridPostsProps {
  flights:any[];
  destinations?:any[];
  className?: string;
  gridClass?: string;
  headingIsCenter?: boolean;
  postCardName?:
    | "card3"
    | "cardMobile"
    | "cardMultiCity"
    | "cardNomad"
    | "card4"
    | "card7"
    | "card9"
    | "card10"
    | "card10V2"
    | "card11"
    | "card14"
    | "card15Podcast";
}

const SectionGridPosts: FC<React.PropsWithChildren<React.PropsWithChildren<SectionGridPostsProps>>> = ({
  flights = postsDemo,
  destinations,
  postCardName = "card3",
  className = "",
  gridClass = "",
  headingIsCenter,
}) => {

  
  const renderCard = (flight: any) => {
    switch (postCardName) {
      case "card3":
        return <Card3 key={flight.id} className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-5" dataFlight={flight}
          />;
      case "cardMobile":
        return <CardMobile key={flight.id} className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-5" dataFlight={flight}
          />;

      case "cardMultiCity":
        return <CardMultiCity key={flight.id} className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-5" dataFlight={flight} destinations={destinations}
          />;

      case "cardNomad":
        return <CardNomad key={flight.id} className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] mt-5" dataFlight={flight}
          />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionGridPosts relative ${className}`}>

     {/*} <div className={`grid gap-6 md:gap-8 ${gridClass}`}>*/}
     <div className={`w-full ${gridClass}`}>
        {flights.map((flight:any) => renderCard(flight))}
      </div>

    </div>
  );
};

export default SectionGridPosts;
