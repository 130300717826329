import axios from "../axios";
import { useEffect, useState } from "react";

const useFetch = (url:any,query:any) => {
  const [data, setData] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [loading, setLoading] = useState<any>(true);
  const [controller] = useState(new AbortController());

  useEffect(() => {
    let countError=0;
    const fetchData = async () => {
      setLoading(true);
       await axios
        .post(url,{
            travel:query
        })
        .then((result) => {
          setData(result.data);
          setLoading(false);
        })
        .catch((err) => {
          countError++;
          setError(err.message);
          if(countError<=2 && err.response.status===409){
            fetchData();
          }
          if(err.response.status!==409){
            setLoading(false);
          }
        });
    };

    fetchData();
  }, [url]);

  const abort = () => {
    controller.abort();
    setLoading(false);
  };



  return { loading, error, data };
};

export default useFetch;
