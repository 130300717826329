import React, { FC, ReactNode } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { useAppSelector } from "app/hooks";
import { selectUser } from "app/user/user";
import SlideImage from "images/Kohn_Slide.jpg";
import { useTranslation } from 'react-i18next';



export interface SectionHero2Props {
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  href: string;
  youtubeID: string;
}
const SectionHero2: FC<React.PropsWithChildren<React.PropsWithChildren<SectionHero2Props>>> = ({ heading, subHeading, href }) => {
  const user: any = useAppSelector(selectUser);
  const {t} = useTranslation();


  return (
    <div className="relative py-16 md:py-32 lg:py-50  bg-black">
      <img
        src={SlideImage}
        alt=""
        style={{
          position: "absolute",
          bottom: "0",
          minWidth: "100%",
          minHeight: "100%",
          objectFit: "cover",
        }}
      />
      <div className="md:block absolute top-0 left-0 bottom-0 w-full from-black bg-gradient-to-r"></div>
      {/* <div className="flex w-full mb-10 md:w-1/2 xl:w-3/5 md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
        <div className="hidden md:block absolute top-0 left-0 bottom-0 w-40 from-black bg-gradient-to-r"></div>
        <img className="w-full h-full object-cover" src={rightImg} alt="" />
      </div> */}
      <div className="container relative z-10 text-neutral-100">
        <div className="max-w-3xl">
          <h1 className="font-bold text-4xl md:text-4xl xl:text-5xl mt-3 md:!leading-[110%] ">
            {heading}
          </h1>
          <p className="mt-7 text-base lg:text-xl text-neutral-300 ">
            {subHeading}
          </p>
          <div className="flex space-x-4 mt-11">
            {user.isLoggedIn ? (
              <ButtonPrimary href="/dashboard/submit-post">
              {user.user.name} {t('exp.1')}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary href="/signup">{t('join.us')}</ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionHero2;
