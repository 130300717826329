import React, { FC, useEffect, useRef, useState } from "react";
import NcModal from "components/NcModal/NcModal";
import Textarea from "components/Textarea/Textarea";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import { RadioGroup } from "@headlessui/react";
import twFocusClass from "utils/twFocusClass";
import { useTranslation } from "react-i18next";
import Label from 'components/Label/Label';
import axios from '../../axios';
import { CircularProgress } from "@mui/material";




export interface ProblemPlan {
  name: string;
  id: string;
  label: string;
}

const problemPlansDemo=[
      { name: "Violence", id: "Violence", label: "Violence" },
      { name: "Trouble", id: "Trouble", label: 'Trouble' },
      { name: "Spam", id: "Spam", label: "Spam" },
      { name: "Other", id: "Other", label: "Other" },
    ];

export interface ModalReportItemProps {
  id: number | string;
  show: boolean;
  problemPlans?: ProblemPlan[];
  onCloseModalReportItem: () => void;
}

const ModalReportItem: FC<React.PropsWithChildren<React.PropsWithChildren<ModalReportItemProps>>> = ({
  problemPlans=problemPlansDemo,
  id,
  show,
  onCloseModalReportItem,
}) => {
  const textareaRef = useRef(null);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

    problemPlans=[
      { name: "Violence", id: "Violence", label: t('reportAr.violence') },
      { name: "Trouble", id: "Trouble", label: t('reportAr.trouble') },
      { name: "Spam", id: "Spam", label: t('reportAr.spam') },
      { name: "Other", id: "Other", label: t('reportAr.other') },
    ];
  const [problemSelected, setProblemSelected] = useState(problemPlans[0]);
  const [descText, setDescText] = useState("");
  const [activeLabel, setActiveLabel] = useState(false);
  const [labelText, setLabelText] = useState(false);


  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);

  const handleDescText= (e:any) =>{
    e.preventDefault();
    setDescText(e.target.value);
  }


  const handleClickSubmitForm = async(e:any) => {
        e.preventDefault();
        const data = new FormData();

        setSubmitting(true);
        data.append("id",JSON.stringify(id));
        data.append("problem",JSON.stringify(problemSelected.name));
        data.append("message",JSON.stringify(descText));

        await axios
                .post("/articles/report", data)
                .then(async(res: any) => {
                   if(res.data.status==="success"){
                        setTimeout(() => {
                             setActiveLabel(false);
                          }, 5000);
                        setActiveLabel(true);
                        setLabelText(true);
                    }
                    setSubmitting(false);
                })
                .catch((err: any) => {

                    setTimeout(() => {
                         setActiveLabel(false);
                      }, 5000);
                    setActiveLabel(true);
                    setLabelText(false);
                    setSubmitting(false);
                });



  };

  const renderCheckIcon = () => {
    return (
      <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const renderContent = () => {
    return (
      <form onSubmit={handleClickSubmitForm}>
        {/* RADIO PROBLEM PLANS */}
        <RadioGroup value={problemSelected} onChange={setProblemSelected}>
          <RadioGroup.Label className="sr-only">Problem Plans</RadioGroup.Label>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
            {problemPlans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ checked }) => {
                  return (
                    `${
                      checked
                        ? "bg-primary-6000 text-white dark:bg-primary-700"
                        : "bg-white border-t border-neutral-50 "
                    } relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none ` +
                    twFocusClass(true)
                  );
                }}
              >
                {({ checked }) => (
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium line-clamp-1 ${
                            checked ? "text-white" : "text-neutral-900"
                          }`}
                        >
                          {plan.label}
                        </RadioGroup.Label>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-white">
                        {renderCheckIcon()}
                      </div>
                    )}
                  </div>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        {/* TEXTAREA MESSAGE */}
        <div className="mt-4">
          <h4 className="text-lg font-semibold text-neutral-700 dark:text-neutral-200">
            {t('contact.message')}
          </h4>
          <span className="text-sm text-neutral-6000 dark:text-neutral-400">
            {t('report.desc')}
          </span>
          <Textarea
            name="desc"
            placeholder="..."
            className="mt-3"
            ref={textareaRef}
            onChange={handleDescText}
            required={true}
            rows={4}
            id="report-message"
          />
        </div>
        <div className="mt-4 space-x-3">
          <ButtonPrimary>
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('comment.submit')}</span>
          )}
          </ButtonPrimary>
          <ButtonSecondary type="button" onClick={onCloseModalReportItem}>
            {t('comment.cancel')}
          </ButtonSecondary>
        </div>
         {activeLabel ? (
          <Label className="mt-10">{labelText ? t('report.success') : t('report.unsuccess')}</Label>
         ) : ( <div />)}
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalReportItem}
      contentExtraClass="max-w-screen-md"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle={t('report.abuse')}
    />
  );
};

export default ModalReportItem;
