// @ts-nocheck
import React, { FC}  from "react";
import { HotelType} from "data/types";
import getSymbolFromCurrency from 'currency-symbol-map';
import ButtonPrimary from "components/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';
import GallerySlider from 'components/PostFeaturedMedia/GallerySlider';
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {FaStar,FaAward} from 'react-icons/fa';
import { GiPathDistance } from "react-icons/gi";
import { FcOrganization, FcCurrencyExchange,FcHome,FcPlanner } from "react-icons/fc";
import { getStateFromUrl } from 'helpers/url_handler';
import { useNavigate } from "react-router";
import ncNanoId from "utils/ncNanoId";
import {  useLocation } from 'react-router-dom';

export interface Card3Props {
  className?: string;
  dataHotel: HotelType;
  size?: "normal" | "large";
}

const CardHotel: FC<React.PropsWithChildren<React.PropsWithChildren<Card3Props>>> = ({
  className = "",
  size = "large",
  dataHotel
}) => {
    const {t} = useTranslation();
    let rooms: SingleHotelType[]=dataHotel.rooms;
    const history = useNavigate();
    const apiParams = getStateFromUrl(useLocation());

   //count days
    let temp= apiParams.checkin_date.split("-");
    let checkIn=new Date((temp[1]+"/"+temp[0]+"/"+temp[2])).getTime();
    temp=apiParams.checkout_date.split("-");
    let checkOut=new Date((temp[1]+"/"+temp[0]+"/"+temp[2])).getTime();
    const days=Math.ceil((checkOut-checkIn) / (1000 * 3600 * 24));


  //define stars

  let stars;
  switch (dataHotel.stars){
    default: break;
    case 1: stars=(
            <span className="flex flex-wrap text-sm"><FaStar style={{ color: "#FFBF00" }} /></span>
    ); break;
    case 2: stars=(
            <span className="flex flex-wrap text-sm"><FaStar  style={{ color: "#FFBF00" }} /> <FaStar  style={{ color: "#FFBF00" }} /></span>
    ); break;
    case 3: stars=(
            <span className="flex flex-wrap text-sm"><FaStar style={{ color: "#FFBF00" }} /><FaStar  style={{ color: "#FFBF00" }} /><FaStar  style={{ color: "#FFBF00" }} /></span>
    ); break;
    case 4: stars=(
            <span className="flex flex-wrap text-sm"><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /></span>
    ); break;
    case 5: stars=(
            <span className="flex flex-wrap text-sm"><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /><FaStar style={{ color: "#FFBF00" }} /></span>
    ); break;
  }

  return (
    <div
      className={`nc-Card11 relative sm:items-center rounded-[40px] group border-gray-200 hover:border-gray-800  [ nc-box-has-hover-flight ] [ nc-dark-box-bg-has-hover-flight ] bg-primary-6000 bg-opacity-10 mt-5 ${className}`}
      data-nc-id={`Card ${ncNanoId()}`}
    >
     <div
        className={`block flex-shrink-0 relative w-full rounded-t-xl overflow-hidden aspect-w-4 aspect-h-3`}
      >
        <div>
          <GallerySlider className="h-full w-full" galleryImgs={dataHotel.images} />
        </div>
      </div>

      <div className="relative mt-5">
        <div className="space-y-3.5 mb-4">

             <div><b>{dataHotel.name}</b></div>
             <div>{stars}</div>
             <span className="text-sm mt-2">{dataHotel.street}</span>
             <div className="text-sm flex flex-row gap-2"><GiPathDistance />{t('distanceCenter')}{dataHotel.distanceCenter}</div>
             <div className="text-sm flex flex-row gap-2 mt-2"><FaAward />{t('rating')}<b>{dataHotel.customerRating}</b></div>
             <div className="relative">
              <p><b>{t('priceHotel')} {dataHotel.priceNumber*days} {getSymbolFromCurrency(dataHotel.currency)}</b></p>
            </div>
        </div>

        <div className="relative mt-2 w-full">
       <ViewWithPopup
         key={`View ${ncNanoId()}`}
         noView={true}
         className=""
         style={{position:"relative"}}
         view={<ButtonPrimary className="nc-DivChooseRooms 0 w-full text-center">{t('cameraChoice')}</ButtonPrimary>}
         popup={
            <div className="relative ">
            <div className="relative bg-transparent dark:bg-transparent grid grid-cols-4 sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base ">
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcHome /><b>{t('room.type')}</b></span>
                </div>
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcOrganization /><b>{t('room.agency')}</b></span>
                </div>
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcPlanner /><b>{t('room.freecan')}</b></span>
                </div>
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcCurrencyExchange /><b>{t('room.buy')}</b></span>
                </div>
            </div>

            {rooms.map((singleRoom:SingleHotelType,index:number) => (
                <div key={index} className="bg-transparent dark:bg-transparent grid grid-cols-4 gap-1 mt-4 border-t sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">

                  <div className="relative bg-transparent dark:bg-transparent break-words">
                     <span className="flex flex-wrap items-center justify-center mt-6 text-neutral-900 dark:text-neutral-50">{singleRoom.accommodation_type}</span>
                  </div>

                  <div className="relative bg-transparent dark:bg-transparent inline-block break-words">

                        <img src={`http://pics.avs.io/hl_gates/75/75/${singleRoom.agency_id}.png`} />
                  </div>

                  <div className="relative bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap items-center justify-center text-neutral-900 dark:text-neutral-50 mt-6">{(singleRoom.free_cancellation===true) ? "YES" : "NO"}</span>
                  </div>

                  <div className="relative bg-transparent dark:bg-transparent">
                     <a href={singleRoom.buy_link} target="_blank" rel="noopener noreferrer"><ButtonPrimary className="mt-2 px-0 w-full"><span className="">{singleRoom.price*days}{getSymbolFromCurrency(singleRoom.price_currency)}</span></ButtonPrimary></a>
                  </div>

                </div>
            ))}
            </div>
         }
       />

    </div>

      </div>

  </div>

);};

export default CardHotel;
