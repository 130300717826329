import { FC, useEffect,useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from 'react-i18next';
import CardCountry from "components/CardCategoryCountry/CardCategoryCountry";

export interface SectionSliderBestCountriesProps {
  className?: string;
  heading: string;
  subHeading: string;
  countries: [];
}

const SectionSliderBestCountries: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSliderBestCountriesProps>>> = ({
  heading,
  subHeading,
  className = "",
  countries,
}) => {

  const UNIQUE_CLASS = "glide_" + ncNanoId();
  const {t} = useTranslation();
  const [topCountries, setTopCountries] = useState<any>();


  const MY_GLIDE = new Glide(`.${UNIQUE_CLASS}`, {
    // @ts-ignore
    direction:
      document.querySelector("html")?.getAttribute("dir") === "rtl"
        ? "rtl"
        : "ltr",
    perView: 5,
    gap: 32,
    bound: true,
    breakpoints: {
      1280: {
        perView: 4,
      },
      1023: {
        gap: 24,
        perView: 3,
      },
      767: {
        gap: 20,
        perView: 2.3,
      },
      639: {
        gap: 20,
        perView: 2,
      },
      500: {
        gap: 20,
        perView: 1.3,
      },
    },
  });
  useEffect(() => {
    if (!MY_GLIDE) return;
    MY_GLIDE.mount();
  }, [MY_GLIDE]);



  useEffect(() => {
    let countryData: any = [];
    if (countries) {
      countries.map((country: any) => {


        countryData.push({
          id: country.country_id,
          name: country.name,
          count: country.postcount | 0,
          href: `/blog/country/${country.country_id}`,
          thumbnail: country.alpha.toLowerCase()+".svg",
          color: "red",
        });
      });
    }
    countryData.sort((a:any, b:any) => ((a.count)  < (b.count)) ? 1 : -1);
    setTopCountries(countryData.filter((_:any, i:any) => i < 10));
  }, [countries]);

  return (
    <div className={`nc-SectionSliderNewAuthors ${className} container`}>
      <div className={`${UNIQUE_CLASS} `}>
      <Heading desc={t('sectioncountry.subheading')} hasNextPrev>
          {t('sectioncountry.heading')}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {topCountries &&

              topCountries.map((item: any, i: any) => (
                <li
                  key={i}
                  className="glide__slide pb-12 md:pb-16"
                >
                <CardCountry
                    index={i < 3 ? `#${i + 1}` : undefined}
                    key={i}
                    taxonomy={item}
                  />

                </li>
              ))}
          </ul>
        </div>

      </div>
    </div>
  );
};

export default SectionSliderBestCountries;

