import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import ncNanoId from "utils/ncNanoId";

import CardCategory1 from "components/CardCategory1/CardCategory1";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { useTranslation } from 'react-i18next';


export interface SectionSliderNewCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading: string;
  subHeading: string;
  categories: {}[];
  categoryCardType?: "card1" | "card2" | "card3" | "card4" | "card5";
  itemPerRow?: 4 | 5;
}

const SectionSliderNewCategories: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSliderNewCategoriesProps>>> = ({
  heading,
  subHeading,
  className = "",
  itemClassName = "",
  categories,
  itemPerRow=4,
  categoryCardType = "",
}) => {
  const UNIQUE_CLASS = ncNanoId("sliderNewCategories_");
    const {t} = useTranslation();


  const MY_GLIDE = new Glide(`.${UNIQUE_CLASS}`, {
    // @ts-ignore
    direction:
      document.querySelector("html")?.getAttribute("dir") === "rtl"
        ? "rtl"
        : "ltr",
    perView: itemPerRow,
    gap: 32,
    bound: true,
    breakpoints: {
      1280: {
        perView: itemPerRow - 1,
      },
      1024: {
        gap: 24,
        perView: itemPerRow - 2,
      },
      768: {
        gap: 20,
        perView: itemPerRow - 2,
      },
      640: {
        gap: 20,
        perView: itemPerRow - 3,
      },
      500: {
        gap: 20,
        perView: 1.3,
      },
    },
  });

  useEffect(() => {
    if (!MY_GLIDE) return;
    MY_GLIDE.mount();
  }, [MY_GLIDE]);

  const renderCard = (item: TaxonomyType, index: number) => {
    const topIndex = index < 3 ? `#${index + 1}` : undefined;
    switch (categoryCardType) {
      case "card1":
        return <CardCategory1 taxonomy={item} />;

      case "card5":
        return <CardCategory5 taxonomy={item} />;
      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides ">
            {categories.map((item: any, index) => (
              <li
                key={index}
                className={`glide__slide ${itemClassName}`}
                style={{ paddingBottom: "20px" }}
              >
                {renderCard(item, index)}
              </li>
            ))}
          </ul>
        </div>
          <div className="flex justify-end">
            <ButtonPrimary href="/blog/trending">{t('slider.showMore')}</ButtonPrimary>
          </div>
      </div>

    </div>
  );
};

export default SectionSliderNewCategories;
