import React from "react";
import { Quill } from "react-quill";
import {backendAddress, uploadImageEndpoint} from "../../index";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import {removeUser} from "app/user/user";
import {useAppDispatch, useAppSelector} from "app/hooks";
import axios from "../../axios";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly
const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange(this:any) {
  this.quill.history.undo();
}
function redoChange(this:any): void {
 this.quill.history.redo();
}



// Append blob & save local file
function imageHandler(this:any): void {
    // https://www.c-sharpcorner.com/article/how-to-add-image-upload-control-in-react-quill-rich-text-editor/
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    // Careful here!
    // https://stackoverflow.com/questions/59825450/react-quill-custom-image-handler-module-causing-typing-issues-with-the-editor
    // https://github.com/quilljs/quill/issues/2287
    let tmp_quill: any = this.quill;

    input.onchange = () => {
      if (input.files){
        let file: any = input.files[0];
        let formData = new FormData();
        formData.append('image', file);
        if (/^image\//.test(file.type)) {
            getAccessToken().then((access_token: any) => {
                fetch(uploadImageEndpoint, {
                    method: 'POST',
                    body: formData,
                    headers: {'Authorization': `Bearer ${access_token}`}
                    }).then((response) => {
                        response.json().then((json_response) => {
                            const range = tmp_quill.selection.lastRange;
                            //in local backendAddress ${backendAddress}${json_response.image_url}
                            tmp_quill.editor.insertEmbed(range.index, 'image', `${json_response.image_url}`);
                            range.index = range.index + 1;
                            range.length = range.length + 1;
                            tmp_quill.selection.setRange(range);

                        }).catch((error) =>{
                            console.log("There was an error");
                        });
                    }).catch((error) => {
                        console.log("There was an error");
                    });
                }).catch((err) => {
                  if(err.code && err.code === UNAUTHORIZED_ERROR_CODE){console.log("There was an error");}
            });
        } else {
          alert('You could only upload images.');
        }
      }
    }
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida"
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler,

    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const modules_with_image_handler = (imageHandler: any) => {
    return {
        toolbar: {
            container: "#toolbar",
            handlers: {
                image: imageHandler,

            }
        }
    };
}

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const Toolbar = () => (
  <div id="toolbar" >
    <span className="ql-formats">
      <select className="ql-font" defaultValue="arial">
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size" defaultValue="medium">
        <option value="small">Small</option>
        <option value="medium">Medium</option>
        <option value="large">Large</option>
      </select>

    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
    </span>
    <span className="ql-formats">

    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default Toolbar;