import React, { useEffect,useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Italy from "images/slide/Italy.jpg";
import Maldives from "images/slide/Maldive.jpg";
import Spain from "images/slide/Minorca.jpg";
import Malta from "images/slide/Malta.jpg";
import Philippines from "images/slide/Philippine.jpg";
import { install } from 'resize-observer';

const images = [Italy,Maldives,Spain,Malta,Philippines];


const Slideshow = () => {

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [visualView,setVisualView]=useState(window.innerWidth*window.innerHeight);
  if (!window.ResizeObserver) install();

  const getOrientation = () =>
  window.screen?.orientation?.type

    const useScreenOrientation = () => {
      const [orientation, setOrientation] =
        useState(getOrientation())

      const updateOrientation = (event:any) => {
        setOrientation(getOrientation())
      }

      useEffect(() => {
        window.addEventListener(
          'orientationchange',
          updateOrientation
        )
        return () => {
          window.removeEventListener(
            'orientationchange',
            updateOrientation
          )
        }
      }, [])

      return orientation;
    }

  const [orientation,setOrientation]=useState(getOrientation());



  const updateDimensions = () => {
        let newVisual=window.innerWidth*window.innerHeight;
        if(newVisual>=visualView){
            setWidth(window.innerWidth);
            setHeight(window.innerHeight*0.9);
            setOrientation(getOrientation());
        }

    }


    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);






  return (
    <div className="">
      <SimpleImageSlider
        width={width}
        height={(width<991 && (orientation==="landscape-primary" || orientation==="landscape-secondary" )) ? width : height}
        images={images}
        showBullets={true}
        showNavs={false}
        autoPlay={true}
        autoPlayDelay={3.0}
      />

    </div>
  );
}

export default Slideshow;


