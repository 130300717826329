// @ts-nocheck
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageLoading from "containers/PageLoading/PageLoading";
import { useTranslation } from 'react-i18next';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import SectionGridPosts from "./SectionGridPostsHotels";
import { getStateFromUrl } from 'helpers/url_handler';
import useFetch from 'hooks/useFetch';
import Input from "components/Input/Input";
import { useAppSelector } from "app/hooks";
import {getCurrency} from "locale-currency";
import { useNavigate } from "react-router";
import ButtonSecondary from "components/Button/ButtonSecondary";
import NotFound from 'images/Gonnaway_NotFound.png';
import { HotelType, HotelSearch} from "data/types";
import Sticky from 'react-stickynode';
import Toolbar from 'components/Toolbar/Toolbar';
import ListingWrapper, { ShowMapCheckbox, } from 'components/MapComponent/Listing.style';
import CategorySearch from 'components/SearchPropUI/Hotel/CategorySearch';
import CategorySearchDark from 'components/SearchPropUI/Hotel/CategorySearchDark';
import MobileCategorySearch from 'components/SearchPropUI/Hotel/Mobile/MobileSearchView';
import MobileCategorySearchDark from 'components/SearchPropUI/Hotel/Mobile/MobileSearchViewDark';
import ButtonCircle from "components/Button/ButtonCircle";
import GetYourGuide from "containers/GetYourGuideSection/GetYourGuideSection";
import HereMap from 'components/HereMapComponent/HEREMap';
import ncNanoId from "utils/ncNanoId";
    import {  useLocation } from 'react-router-dom';
        import { useThemeMode } from "hooks/useThemeMode";



export interface PageCategoryProps {
  className?: string;
}


const PageContainerSearchDetailsHotels: FC<React.PropsWithChildren<React.PropsWithChildren<PageCategoryProps>>> = ({ className = "" }) => {
    const {t} = useTranslation();
    const history = useNavigate();
    const {isDarkMode} = useThemeMode();

    const FILTERS : ListBoxItemType[]= [
        {id: 0, name: t("filterFH") },
        {id: 1, name: t('filter.best') },
        {id: 2, name: t('filter.cheapest') },
        {id: 3, name: t('filter.close') },
    ];

    const [selected, setSelected] = useState(FILTERS[0]);
    const [dataHotels, setDataHotels] = useState<HotelType[]>([]);
    const [dataHotelsBk, setDataHotelsBk] = useState<HotelType[]>([]);
    const [showPosts, setShowPosts] = useState(50);
    const [dataLength, setDataLength] = useState(10);
    const [canShowMap, setCanShowMap] =  useState(false);
    const [showMap, setShowMap] = useState(false);
    const [noShowLabelHotel, setNoShowLabelHotel] = useState(false);
    const apiParams = getStateFromUrl(useLocation());
    const url="/hotels"+useLocation().search;
    const { loading, error, data }= useFetch(url);
    const [urlMap, setUrlMap] = useState("");

    const [filterLoading, setFilterLoading] = useState(false);
    const [noResult, setNoResult] = useState(false);

    let starsWidget="0%2C1%2C2%2C3%2C4%2C5";
    let locale=navigator.language;
    let localeSplit=locale.split('-');
    if(localeSplit.length<2){
        if(localeSplit[0]==='en'){
            locale="en-us"
        }else{
            locale=localeSplit[0]+"-"+localeSplit[0].toUpperCase();
        }
    }



    const currencyLocale=getCurrency(locale).toString().toLowerCase();

   const searchData: HotelSearch = {
        checkin_date: apiParams.checkin_date,
        checkout_date: apiParams.checkout_date,
        rooms_count: parseInt(apiParams.rooms_count),
        adults_count: parseInt(apiParams.adults_count),
        childs_count: parseInt(apiParams.childs_count),
        infants_count: parseInt(apiParams.infants_count),
        city_code: apiParams.city_code,
        accomodation_only: apiParams.accomodation_only,
        stars:apiParams.stars ? apiParams.stars : 0,
        cityName:apiParams.cityName
    }

    const [budgetData, setBudgetData] = useState(2500);

     //count days
    let temp= searchData.checkin_date.split("-");
    let checkIn=new Date((temp[1]+"/"+temp[0]+"/"+temp[2])).getTime();
    temp=searchData.checkout_date.split("-");
    let checkOut=new Date((temp[1]+"/"+temp[0]+"/"+temp[2])).getTime();
    const days=Math.ceil((checkOut-checkIn) / (1000 * 3600 * 24));


    /*data retrieve management*/
    useEffect(() => {
    if (data) {
      if (data[0])
      {
        let hotels: HotelType[] = [];
        setDataLength(data.length);
        data.map((hotel: any) => {
            let starsNumber=Number.parseInt(hotel.stars);
            let filterStars=Number.parseInt(searchData.stars);


            if(starsNumber>=filterStars){
              hotels.push({
                  id:hotel.id,
                  name: hotel.name,
                  stars: hotel.stars,
                  street:hotel.address,
                  distanceCenter:hotel.distance_from_city_center+" "+hotel.distance_from_city_center_unit,
                  distanceCenterNumber:hotel.distance_from_city_center,
                  customerRating:hotel.customer_rating,
                  images: hotel.rooms[0].images || [""],
                  rooms: hotel.rooms,
                  priceNumber: hotel.rooms[0].price,
                  price: hotel.rooms[0].price + " "+ hotel.price_currency,
                  currency: hotel.price_currency,
                  latitude: hotel.location ? hotel.location.latitude: "",
                  longitude: hotel.location ? hotel.location.longitude: "",

              });
              setCanShowMap(true);

            }
        });
        setDataHotels(hotels);
        setDataHotelsBk(hotels);

        if(dataHotels.length>0){
            let tempBudget=Math.max(...dataHotels.map(o => o.priceNumber));
             setBudgetData((Math.ceil(tempBudget/100)*100)+100);
        }
      }
      else{setDataLength(0);}
    }


  }, [data]);

    /*end*/


    /* Window size management*/
    function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());

        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [windowSize]);
    /* end*/



    const showMore = () =>
    {
        setShowPosts(showPosts*2);
    }

    switch (searchData.stars){
        default: starsWidget="0%2C1%2C2%2C3%2C4%2C5";
        case 1: starsWidget="1%2C2%2C3%2C4%2C5"
            break;
        case 2:starsWidget="2%2C3%2C4%2C5"
            break;
        case 3:starsWidget="3%2C4%2C5"
            break;
        case 4:starsWidget="4%2C5"
            break;
        case 5:starsWidget="5"
            break;
    }

    //// gestione del refresh
    let timeLastMove = new Date().getTime();
    let timeCurrentMove;
    const setActivityTime = (e) => {
      timeCurrentMove = new Date().getTime();
      if(timeCurrentMove-timeLastMove>=500000)
      {
          window.location.reload(true);
          timeLastMove=new Date().getTime();

      }
      else
      {
         timeLastMove=new Date().getTime();
      }
    }
    document.body.addEventListener("mousemove", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);



  let tempLocale=locale.split("-").length>1 ? locale : (locale + "-"+locale.toUpperCase());
  let tempCityName=searchData.cityName+'';
  let tempCity=tempCityName.split(" ");
  let cityTour=tempCity[0];
  for (let i=1;i<tempCity.length;i++){
      cityTour=cityTour+"%20"+tempCity[i];

  }

  //let cityTour=searchData.cityName.replaceAll(" ","%20");
  if(locale==="en"){tempLocale="en-US";}
  let itemsTour=3;
  if(windowSize.innerWidth>1500){
    itemsTour=12;
  }else{
    if(windowSize.innerWidth>991){
        itemsTour=8;
    }else{itemsTour=3;}
  }

  let urlTour=`//c108.travelpayouts.com/content?promo_id=4039&shmarker=336755&trs=149796&place=${cityTour}&tour=&items=${itemsTour}&locale=${tempLocale}&powered_by=false&theme=${isDarkMode ? "dark" : "light"}`;

    if (loading) return <PageLoading />;

  const handleMapToggle = () => {
        if (canShowMap) {

            setShowMap((showMap) => !showMap);
        }
  };

    /*search specific hotel*/
    const searchHotel = (e:any) =>
   {
        let tempText=document.getElementById("searchHotelInput").value;

        if(tempText===""){
            setDataHotels(dataHotelsBk);
            setNoShowLabelHotel(false);
        }else{
            let tempHotelFilter=dataHotelsBk.filter((h:any)=>h.name.toLowerCase().includes((tempText+'').toLowerCase()));
            if(tempHotelFilter.length>0){
                setDataHotels(tempHotelFilter);
                setNoShowLabelHotel(false);
            }else{
                setDataHotels(dataHotelsBk);
                setNoShowLabelHotel(true);
            }
        }

   }
  /*end*/

  //CALLBACKS management

  const changeSelectedFilter = (e:any) =>
  {
         setTimeout(() => {
            setFilterLoading(false);
          }, 500);
          setFilterLoading(true);
         setSelected(e);
               switch (e.id){
                        default: setDataHotels(dataHotelsBk);
                                break;
                        case 1: dataHotels.sort((a, b) => (a.customerRating < b.customerRating) ? 1 : -1);
                                break;
                        case 2: dataHotels.sort((a:any, b:any) => (a.priceNumber < b.priceNumber) ? -1 : 1);
                                break;
                        case 3: dataHotels.sort((a, b) => (a.distanceCenterNumber < b.distanceCenterNumber) ? -1 : 1);
                                break;
                }


  }

  const filterByBudget = (budgetFee:number,hotels:any) =>
   {
      let resNull=false;
      setNoResult(false);
      setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
      }, 1500);
      setFilterLoading(true);
     let tempData=hotels.filter((item:any)=> (item.priceNumber*days) <= budgetFee);
     if(tempData.length<1){resNull=true;}
     return tempData;


   }

   const filterByStars = (hotels:any,starsData:number) =>
   {
      let resNull=false;
      setNoResult(false);
      setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
      }, 1500);
      setFilterLoading(true);
     let tempData=hotels.filter((item:any)=> Number.parseInt(item.stars,10) >= starsData);
     if(tempData.length<1){resNull=true;}

      switch (starsData){
        default: starsWidget="0%2C1%2C2%2C3%2C4%2C5";
        case 1: starsWidget="1%2C2%2C3%2C4%2C5"
            break;
        case 2:starsWidget="2%2C3%2C4%2C5"
            break;
        case 3:starsWidget="3%2C4%2C5"
            break;
        case 4:starsWidget="4%2C5"
            break;
        case 5:starsWidget="5"
            break;
    }


     return tempData;
   }

   const filterByRating = (hotels:any, rating:any) =>
   {
      let resNull=false;
      setNoResult(false);
      setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
      }, 1500);
      setFilterLoading(true);
     let tempData=hotels.filter((item:any)=> ((item.customerRating>= rating[0])&&(item.customerRating<= rating[1])));


     if(tempData.length<1){resNull=true;}
     return tempData;


   }



  const filterData = (budgetFee:number,starsData:number,customerRating:any) =>
   {
        let hotelTemp=dataHotelsBk;
        if(hotelTemp.length>0 && budgetFee>0){hotelTemp=filterByBudget(budgetFee,hotelTemp);}
        if(hotelTemp.length>0){hotelTemp=filterByStars(hotelTemp,starsData);}
        if(hotelTemp.length>0){hotelTemp=filterByRating(hotelTemp,customerRating);}
        if(hotelTemp.length>0){setDataHotels(hotelTemp);}

   }


  ///management width filter section
  let wFilterSecDesktop=(windowSize.innerWidth>1440) ? "28%" : "35%";
  const mapId="map"+ncNanoId();
  //




  return (
    <div className={`nc-PageCategory ${className}`} data-nc-id="PageCategory">
        <Helmet><title>Hotels || Gonnaway</title></Helmet>
        <div className="space-y-0 lg:space-y-24 right-0 mt-10 border-t">
            {noResult &&(
                <div className="w-full container bg-primary-6000 flex items-center justify-center mx-auto text-neutral-100 h-20 align-middle">
                    <b><span className="mt-10 text-center text-sm md:text-md lg:text-xl">{t('labelHotel.noShow')}</span></b>
                </div>
            )}

            {dataLength===0 && (
                <div className="relative">
                    <center><h2>{t('filterNoDataHotel')}</h2>
                     <img src={NotFound} width="625px" height="500px"
                         alt="Not Found page banner"/></center>
                </div>
            )}

            {dataLength>0 && (
                windowSize.innerWidth > 1279 ? (
                    <div className="w-full flex flex-row ">
                        <div className="border-r border-b" style={{width:wFilterSecDesktop}}>
                            <div className="p-2">
                            {isDarkMode ? (
                                <CategorySearchDark
                                    dataSearch={searchData}
                                    changeSelectedFilter={changeSelectedFilter}
                                    filterData={filterData}
                                    budgetData={budgetData}


                                />
                            ):(
                                <CategorySearch
                                    dataSearch={searchData}
                                    changeSelectedFilter={changeSelectedFilter}
                                    filterData={filterData}
                                    budgetData={budgetData}

                                />
                            )}
                            </div>
                        </div>
                         <div className="relative border-b w-full  ">

                            { !filterLoading ? (
                            <div className="relative p-6">
                                <div className="flex flex-wrap items-center mt-5">
                                  <div className="relative text-left" style={{width:"70%"}}>
                                            <label
                                              htmlFor="search-input"
                                              className="text-neutral-500 dark:text-neutral-300"
                                            >
                                                    <form>
                                                      <Input
                                                        id="searchHotelInput"
                                                        type="search"
                                                        placeholder={t('hotel.search')}
                                                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                                        onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.search'))}
                                                        onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                                                        pattern="[A-Za-z0-9 ]+"
                                                      />
                                                      <ButtonCircle
                                                        className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                                        size=" w-11 h-11"
                                                        onClick={searchHotel}
                                                      >
                                                        <i className="las la-arrow-right text-xl"></i>
                                                      </ButtonCircle>
                                                      <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                                                        <svg
                                                          width="24"
                                                          height="24"
                                                          fill="none"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path
                                                            stroke="currentColor"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1.5"
                                                            d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                                                          ></path>
                                                        </svg>
                                                      </span>
                                                      </form>
                                            </label>
                                  </div>

                                          {canShowMap &&(
                                            <div className="ml-10">
                                            <ShowMapCheckbox>
                                              <label className="flex flex-wrap items-center sm:text-sm md:text-base gap-2 mt-1block inline-block align-middle">
                                                  <Input
                                                      id="mapCheckbox"
                                                      name="mapCheckbox"
                                                      type="checkbox"
                                                      className="focus:ring-action-primary text-primary border-primary"
                                                      onChange={handleMapToggle}
                                                      checked={showMap}
                                                      style={{height:"10px",width:"8px"}}
                                                    />
                                                  <strong><span className="mt-1">{t('show.map')}</span></strong>
                                                </label>

                                            </ShowMapCheckbox>
                                            </div>
                                          )}
                                            </div>
                                            {noShowLabelHotel && (<label className="block container inline-block align-middle">{t('labelHotel.noShow')}</label>)}

                                              {showMap &&(
                                                 <div className="mt-5" id="fixedMap">

                                                    <div className="relative mt-5" style={{ width: "100%", height: "70vh", minHeight: "400px" }}>
                                                        <HereMap
                                                          mapContainerId="hereMap"
                                                          center={{ lat: dataHotels[0].latitude, lng: dataHotels[0].longitude }}
                                                          zoom={13}
                                                          animateZoom={true}
                                                          interactive={true}
                                                          secure={true}
                                                          dataSearch={dataHotels}
                                                          hotelDashboardMap={true}
                                                        />
                                                   </div>
                                                 </div>
                                              )}

                                                <SectionGridPosts
                                                  key={`nc-Card11${ncNanoId()}`}
                                                  className=""
                                                  postCardName="card3"
                                                  hotels={dataHotels.filter((_:any, i:any) => i < showPosts)}
                                                  gridClass="ml-3 mr-3 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-6 lg:gap-8 mt-5 "
                                                  headingIsCenter
                                                />


                                                {dataLength > 9 && dataLength>showPosts &&  (
                                                     <div className="text-center mx-auto mt-8 md:mt-10">
                                                        <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                                     </div>
                                                 )}
                                                 <div className="mt-10" />

                                        </div>
                                   ):(<PageLoading />)}

                         </div>

                    </div>

                ):(
                    <div>
                        <div className="space-y-0 lg:space-y-24 right-o">
                            <ListingWrapper>
                            <Sticky top={0} innerZ={30} activeClass="isHeaderSticky">
                                <div className="bg-white dark:bg-neutral-900">
                                    <Toolbar
                                      left={
                                        isDarkMode ? (
                                                <MobileCategorySearchDark
                                                        dataSearch={searchData}
                                                        changeSelectedFilter={changeSelectedFilter}
                                                        filterData={filterData}
                                                        budgetData={budgetData}
                                                    />
                                              ):(
                                                <MobileCategorySearch
                                                        dataSearch={searchData}
                                                        changeSelectedFilter={changeSelectedFilter}
                                                        filterData={filterData}
                                                        budgetData={budgetData}
                                                    />
                                              )

                                      }
                                      right={
                                        canShowMap && (
                                                <div className="ml-10">
                                                    <ShowMapCheckbox>
                                                      <label className="flex flex-wrap content-center items-center sm:text-sm md:text-base gap-2 mt-1 block inline-block align-middle">
                                                          <Input
                                                              id="mapCheckbox"
                                                              name="mapCheckbox"
                                                              type="checkbox"
                                                              className="focus:ring-action-primary h-1 w-1 text-primary border-primary"
                                                              onChange={handleMapToggle}
                                                              style={{height:"10px",width:"8px"}}
                                                            />
                                                          <strong><span className="mt-1">{t('show.map')}</span></strong>
                                                        </label>

                                                    </ShowMapCheckbox>
                                                </div>
                                        )

                                      }
                                    />
                                </div>
                                </Sticky>
                        </ListingWrapper>
                        <div className="relative container w-full mt-2 ">
                            <div className="relative text-left w-full mt-5" >
                                        <label
                                          htmlFor="search-input"
                                          className="text-neutral-500 dark:text-neutral-300"
                                        >
                                        <form>
                                          <Input
                                            id="searchHotelInput"
                                            type="search"
                                            placeholder={t('hotel.search')}
                                            sizeClass="pl-14 py-5 pr-5 md:pl-16"
                                            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.search'))}
                                            onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                                            pattern="[A-Za-z0-9 ]+"
                                          />
                                          <ButtonCircle
                                            className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                                            size=" w-11 h-11"
                                            onClick={searchHotel}
                                          >
                                            <i className="las la-arrow-right text-xl"></i>
                                          </ButtonCircle>
                                          <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                                            <svg
                                              width="24"
                                              height="24"
                                              fill="none"
                                              viewBox="0 0 24 24"
                                            >
                                              <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.5"
                                                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                                              ></path>
                                            </svg>
                                          </span>
                                          </form>
                                        </label>
                                      </div>

                        </div>

                        { !filterLoading ? (
                            <div className="mt-10">
                                {showMap &&(
                                             <div className="mt-5">
                                                <div className="relative mt-5" style={{ width: "100%", height: "70vh", minHeight: "400px" }}>
                                                        <HereMap
                                                          mapContainerId="hereMap"
                                                          center={{ lat: dataHotels[0].latitude, lng: dataHotels[0].longitude }}
                                                          zoom={13}
                                                          animateZoom={true}
                                                          interactive={true}
                                                          secure={true}
                                                          dataSearch={dataHotels}
                                                          hotelDashboardMap={true}
                                                        />
                                                   </div>

                                                     <div className="block my-4 border-b w-full border-neutral-100"></div>
                                                 </div>
                                          )}
                                {noShowLabelHotel && (<label className="block container inline-block align-middle">{t('labelHotel.noShow')}</label>)}
                                <SectionGridPosts
                                  className=""
                                  postCardName="card3"
                                  hotels={dataHotels.filter((_:any, i:any) => i < showPosts)}
                                  gridClass="ml-3 mr-3 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-3 gap-6 lg:gap-8 mt-5 "
                                  headingIsCenter
                                />

                                {dataLength > 9 && dataLength>showPosts &&  (
                                     <div className="text-center mx-auto mt-8 md:mt-10">
                                        <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                     </div>
                                 )}
                                 <div className="mt-10" />
                            </div>
                        ):(<PageLoading />)}



                        </div>
                    </div>

                )


            )}


                        {dataHotels.length>0 && (
                            <div className="p-2 relative mt-5 mb-10">
                              <GetYourGuide
                                 heading={t('tour.heading')}
                                 subHeading={t('tour.subheading')}
                                 url={urlTour}
                              />
                           </div>

                        )}


        </div>

    </div>



  );
};

export default PageContainerSearchDetailsHotels;


