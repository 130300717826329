const CategoriesArray = [
  {
    category_id: 5,
    name: "Sport",
  },
  {
    category_id: 10,
    name: "Culture",
  },
  {
    category_id: 2,
    name: "Luxury",
  },
  {
    category_id: 8,
    name: "Voluntary",
  },
  {
    category_id: 11,
    name: "Romantic",
  },
  {
    category_id: 12,
    name: "Food",
  },
  {
    category_id: 13,
    name: "On the road",
  },
  {
    category_id: 3,
    name: "Adventure",
  },
  {
    category_id: 6,
    name: "Sea",
  },
  {
    category_id: 14,
    name: "Backpack",
  },
  {
    category_id: 7,
    name: "Exotic",
  },
  {
    category_id: 1,
    name: "Nature",
  },
  {
    category_id: 9,
    name: "Party",
  },
  {
    category_id: 4,
    name: "Relax",
  },
  {
    category_id: 15,
    name: "Curiosity",
  },
  {
    category_id: 16,
    name: "Pets",
  },
];
const Categories = CategoriesArray.sort((a: any, b: any) =>   a.category_id - b.category_id
);


export default Categories;
