import axios from "../axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../TokenAuth";
import {useAppDispatch} from "app/hooks";
import {removeUser} from "app/user/user";



const useFetch = (url:any) => {
  const [data, setData] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [loading, setLoading] = useState<any>(true);
  const [controller] = useState(new AbortController());
  const history = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      getAccessToken().then((access_token: any) => {
      setLoading(true);
      axios
        .get(url,{headers: {'Authorization': `Bearer ${access_token}`}})
        .then((result) => {
          setData(result.data);
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
        }).catch((err) => {
       if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
       {
               dispatch(removeUser());
               history("/login");
       }
    });
    };
    fetchData();
  }, [url]);

  const abort = () => {
    controller.abort();
    setLoading(false);
  };



  return { loading, error, data };
};

export default useFetch;
