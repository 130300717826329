import CircularProgress from "@mui/material/CircularProgress";

function PageLoading() {
  return (
    <div
    className="relative  flex justify-center items-center  h-full w-full"
    style={{ color: "#008489", height: "50vh" }}
    >
      <CircularProgress color="inherit" />
    </div>
  );
}

export default PageLoading;
