export const convertDate = (date: any) => {
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let newDate = new Date(date.replace(" ", "T"));
    return (
      newDate.getDate() +
      " " +
      months[newDate.getMonth()] +
      ", " +
      newDate.getFullYear()
    );
  };