import React, { FC,useState } from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import Label from 'components/Label/Label';
import axios from "../../axios";
import { useNavigate } from "react-router";





export interface PageForgotPassProps {
  className?: string;
}

const PageForgotPass: FC<React.PropsWithChildren<React.PropsWithChildren<PageForgotPassProps>>> = ({ className = "" }) => {
   const {t} = useTranslation();
   const [userInput, setUserInput] = useState("");
   const history = useNavigate();
   const handleChange = (e: any) => {
   const { name, value } = e.target;
            setUserInput(value);
   };
   const [activeLabel, setActiveLabel] = useState(false);

   const handleSubmit = (e: any) => {
    e.preventDefault();
    axios
      .post("/api/password/request/reset/", {"email": userInput})
      .then((res: any) => {
        setTimeout(() => {
          setActiveLabel(false);
          history("/login");
        }, 5000);
        setActiveLabel(true);
      })
      .catch((err) => console.log("There was an error"));
  };

  return (
    <div
      className={`nc-PageForgotPass ${className}`}
      data-nc-id="PageForgotPass"
    >
      <Helmet>
        <title>Forgot Password || Community Travel</title>
      </Helmet>
      <LayoutPage
        subHeading={t('dashboard.forgot.descr')}
        headingEmoji="🔐"
        heading={t('dashboard.forgot.heading')}
      >
        <div className="max-w-md mx-auto space-y-6">
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('dashboard.forgot.mail')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                pattern="[a-zA-Z0-9._%+-]+@(gmail|hotmail|outlook|yahoo|icloud|live|me|tiscali|libero)+\.[a-z]{2,4}$"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.email'))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                className="mt-1"
                name="email"
                value={userInput}
                onChange={handleChange}
              />
            </label>
            <ButtonPrimary type="submit">{t('dashboard.forgot.continue')}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('dashboard.passforgot')} {` `}
            <NcLink to="/login">{t('login.1')}</NcLink>
            {` / `}
            <NcLink to="/signup">{t('signup.create')}</NcLink>
          </span>
        </div>
        {activeLabel ? (
            <Label className="mt-10 flex flex-wrap justify-center">{t('forget.message')}</Label>
         ) : ( <div />)}
      </LayoutPage>
    </div>
  );
};

export default PageForgotPass;
