import { useEffect, useState } from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import Textarea from "components/Textarea/Textarea";
import Label from "components/Label/Label";
import axios from "../../axios";
import {useAppDispatch, useAppSelector} from "app/hooks";
import {removeUser, selectUser} from "app/user/user";
import "./Tags.css";
import "react-quill/dist/quill.snow.css";
import ModalFillAllFields from 'components/CommentCard/ModalFillAllFields';

/*import toolbars and Quills, different for the IDs*/
import ReactQuill from 'react-quill';
import ReactQuillVisit from 'react-quill';
import ReactQuillOspitality from 'react-quill';
import ReactQuillMoveOn from 'react-quill';
import ReactQuillMeat from 'react-quill';
import Toolbar, {formats, modules} from "./Toolbar";
import ToolbarMeat, {formatsMeat, modulesMeat} from "./Toolbars/ToolbarMeat";
import ToolbarMoveOn, {formatsMoveOn, modulesMoveOn} from "./Toolbars/ToolbarMoveOn";
import ToolbarOspitality, {formatsOspitality, modulesOspitality} from "./Toolbars/ToolbarOspitality";
import ToolbarVisit, {formatsVisit, modulesVisit} from "./Toolbars/ToolbarVisit";


import "./TextEditor.css";
import { CircularProgress } from "@mui/material";
import Heading from "components/Heading/Heading";
import useFetch from "hooks/useFetchAuthn";
import PageLoading from "containers/PageLoading/PageLoading";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import {backendAddress} from "../../index";
import { useLocation } from 'react-router-dom';
import Page404 from 'containers/Page404/Page404';


const DashboardEditPost = () => {
  const location = useLocation();
  let path = location.pathname.split("/");
  const articleId = path[3];

  const user: any = useAppSelector(selectUser);
  const {t} = useTranslation();
  const [image, setImage] = useState<any>(null);
  const [activeLabel, setActiveLabel] = useState(false);
  const [isMessage, setIsMessage] = useState(false);
  const openModalMessage = () => setIsMessage(true);
  const closeModalMessage = () => setIsMessage(false);

  /*text edit consts*/
  const [content, setContent] = useState<any>(null);
  const [contentMeat, setContentMeat] = useState<any>(null);
  const [contentMeatLength, setContentMeatLength] = useState(0);
  const [contentVisit, setContentVisit] = useState<any>(null);
  const [contentVisitLength, setContentVisitLength] = useState(0);
  const [contentMoveOn, setContentMoveOn] = useState<any>(null);
  const [contentMoveOnLength, setContentMoveOnLength] = useState(0);
  const [contentOspitality, setContentOspitality] = useState<any>(null);
  const [contentOspitalityLength, setContentOspitalityLength] = useState(0);

  var reactQuillRef:any = null;
  var reactQuillRefMeat:any = null;
  var reactQuillRefOspitality:any = null;
  var reactQuillRefMoveOn:any = null;
  var reactQuillRefVisit:any = null;

  /*end*/


const [labelText, setLabelText] = useState("");
  const [article, setArticle] = useState<any>({
    id: "",
    title: "",
    author_id: user.user.id,
    excerpt: "",
    city: "",
    //is_draft:true
  });
  const dispatch = useAppDispatch();
  let timeLastMove=new Date().getTime();
  const { loading, error, data } = useFetch(`/articles/${articleId}`);

  useEffect(() => {
   if (data) {
          setArticle({
            id: data.article_id,
            author_id: user.user.id,
            title: data.title,
            excerpt: data.excerpt,
            city: data.city,
            is_draft:data.is_draft
          });
          setArticle((prevstate: any) => ({
            ...prevstate,
            tags: data.tags,
          }));
          setContent(data.content);
          setContentMeat(data.content_meat);
          setContentVisit(data.content_visit);
          setContentOspitality(data.content_ospitality);
          setContentMoveOn(data.content_moveon);
          setContentMeatLength((data.content_meat && data.content_meat!=="<p><br></p>" ? data.content_meat.substring(3,data.content_meat.length-4).length : 0));
          setContentMoveOnLength((data.content_moveon && data.content_moveon!=="<p><br></p>" ? data.content_moveon.substring(3,data.content_moveon.length-4).length : 0));
          setContentVisitLength(((data.content_visit && data.content_visit!=="<p><br></p>") ? data.content_visit.substring(3,data.content_visit.length-4).length : 0));
          setContentOspitalityLength((data.content_ospitality && data.content_ospitality!=="<p><br></p>" ? data.content_ospitality.substring(3,data.content_ospitality.length-4).length : 0));
        }
      }, [data]);

  const [submitting, setSubmitting] = useState(false);
  const [submittingDraft, setSubmittingDraft] = useState(false);


  const handleContentChange = (value: any) => {
    setContent(value);
  };

  /*management of changes for quill edit*/
  const handleContentVisitChange = (value: any) => {
   var limit=800;
   if(reactQuillRefVisit){
       let quill=reactQuillRefVisit.getEditor();
       quill.on('text-change', function (delta:any, old:any, source:any) {
          if (quill.getLength() > limit) {
           quill.deleteText(limit, quill.getLength());
          }
        });
        setContentVisit(value);
        setContentVisitLength(quill.getLength()-1);
    }
  };

  const handleContentMoveOnChange = (value: any) => {
   var limit=800;
   if(reactQuillRefMoveOn){
       let quill=reactQuillRefMoveOn.getEditor();
       quill.on('text-change', function (delta:any, old:any, source:any) {
          if (quill.getLength() > limit) {
           quill.deleteText(limit, quill.getLength());
          }
        });
        setContentMoveOn(value);
        setContentMoveOnLength(quill.getLength()-1);
   }
  };

  const handleContentOspitalityChange = (value: any) => {
   var limit=500;
   if(reactQuillRefOspitality){
       let quill=reactQuillRefOspitality.getEditor();
       quill.on('text-change', function (delta:any, old:any, source:any) {
          if (quill.getLength() > limit) {
           quill.deleteText(limit, quill.getLength());
          }
        });
        setContentOspitality(value);
        setContentOspitalityLength(quill.getLength()-1);
   }
  };

  const handleContentMeatChange = (value: any) => {
   var limit=800;
   if(reactQuillRefMeat){
       let quill=reactQuillRefMeat.getEditor();
       quill.on('text-change', function (delta:any, old:any, source:any) {
          if (quill.getLength() > limit) {
           quill.deleteText(limit, quill.getLength());
          }
        });
        setContentMeat(value);
        setContentMeatLength(quill.getLength()-1);
   }
  };

  /*end management*/



  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setArticle((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleLogout (){
    dispatch(removeUser());
  };

  const handleImageChange = (e: any) => {
    setImage(e.target.files[0]);
  };

  const handleClickDropDown = () => {
    openModalMessage();
  };

  const history = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    let checkMO=false;
    let checkVisit=false;
    let checkOspitality=false;
    let checkMeat=false;
    //check on the length of quill
    if(reactQuillRefOspitality){
       let quillOsp=reactQuillRefOspitality.getEditor();
       checkOspitality=(quillOsp.getLength()-1) > 0 ? true : false;
    }
    if(reactQuillRefMoveOn){
       let quillMO=reactQuillRefMoveOn.getEditor();
       checkMO=(quillMO.getLength()-1) > 0 ? true : false;
    }
    if(reactQuillRefVisit){
       let quillVi=reactQuillRefVisit.getEditor();
       checkVisit=(quillVi.getLength()-1) > 0 ? true : false;
    }
    if(reactQuillRefMeat){
       let quillMe=reactQuillRefMeat.getEditor();
       checkMeat=(quillMe.getLength()-1) > 0 ? true : false;
    }

    if (!checkMeat || !checkMO || !checkOspitality || !checkVisit)
    {
     handleClickDropDown();
     return;
    }
    setSubmitting(true);

    let articleBk=article;
    articleBk["is_draft"]=false;
    const data = new FormData();
    data.append("file", image);
    data.append("article", JSON.stringify(articleBk));
    data.append("contentVisit", contentVisit);
    data.append("contentMoveOn", contentMoveOn);
    data.append("contentMeat", contentMeat);
    data.append("contentOspitality", contentOspitality);
    data.append("content", content);
    getAccessToken().then((access_token: any) => {
      axios
        .post("/articles/update", data, {headers: {'Authorization': `Bearer ${access_token}`}})
        .then((res: any) => {
          setSubmitting(false);
          if (res.data.article_id) {history(`/blog/article/${res.data.article_id}#fetchData`);}
        })
        .catch((err) => {
          setSubmitting(false);
        });
    }).catch((err) => {
      setTimeout(() => {
         setActiveLabel(false);
      }, 5000);
            let textL=t('404.loginissue');
            setLabelText(textL);
            setActiveLabel(true);
            setSubmitting(false);
      if(err.code && err.code === UNAUTHORIZED_ERROR_CODE){
              handleLogout();
              history("/login");
      }
    });
  };

  //SUBMIT DRAFT
  const handleSubmitDraft = () => {
    setSubmittingDraft(true);
    let articleBk=article;
    articleBk["is_draft"]=true;
    const data = new FormData();
    data.append("file", image);
    data.append("article", JSON.stringify(articleBk));
    data.append("contentVisit", contentVisit);
    data.append("contentMoveOn", contentMoveOn);
    data.append("contentMeat", contentMeat);
    data.append("contentOspitality", contentOspitality);
    data.append("content", content);

    getAccessToken().then((access_token: any) => {
      axios
        .post("/articles/update", data, {headers: {'Authorization': `Bearer ${access_token}`}})
        .then((res: any) => {
          setSubmittingDraft(false);
          if (res.data.article_id) {history(`/dashboard/posts`);}
        })
        .catch((err) => {
          setSubmittingDraft(false);
        });
    }).catch((err) => {
      setTimeout(() => {
         setActiveLabel(false);
      }, 5000);
                let textL=t('404.loginissue');
                setLabelText(textL);
                setActiveLabel(true);
                setSubmittingDraft(false);
      if(err.code && err.code === UNAUTHORIZED_ERROR_CODE){
              handleLogout();
              history("/login");
      }
    });
  };

  let timeCurrentMove=new Date().getTime();
   if((timeCurrentMove-timeLastMove)>240000)
   {
          handleSubmitDraft();
          timeLastMove=new Date().getTime();

   }

    const setActivityTime = (e:any) => {
      timeLastMove=new Date().getTime();
    }
    document.body.addEventListener("mousedown", setActivityTime);
    document.body.addEventListener("touchstart", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);

  if (loading) return <PageLoading />;
  if (error) return <Page404 />
  return (
    <div className="p-6 ">
      <Heading desc={article.title} isCenter>
        {t('dashboard.post.edit')}
      </Heading>
      <div className="container m-auto rounded-xl border md:border-neutral-100 dark:border-neutral-800 p-6 m-3">
        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
          <label className="block md:col-span-2">
            <Label>📓 {t('dashboard.post.title')}</Label>
            <Input
              type="text"
              className="mt-1"
              name="title"
              value={article.title}
              onChange={handleChange}
              pattern="^[a-zA-Z0-9À-ú/\D/g ]+"
              onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.nameProfile'))}
              onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
              required
            />
          </label>
          <label className="block md:col-span-2">
            <Label>📜 {t('dashboard.post.summary')}</Label>
            <Textarea
              className="mt-1"
              rows={3}
              name="excerpt"
              value={article.excerpt}
              onChange={handleChange}

              required
            />
            <p className="mt-1 text-sm text-neutral-500">
              {t('dashboard.post.summarydesc')}
            </p>
          </label>

          <label className="block md:col-span-2">
            <Label>🏢 {t('dashboard.post.city')}</Label>
            <Input
              type="text"
              className="mt-1"
              name="city"
              value={article.city}
              onChange={handleChange}
              disabled= {true}

            />
          </label>
          <div className="block md:col-span-2">
            <Label>🏞 {t('dashboard.post.image')}</Label>
            <div className="relative mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
              {image || data.imgurl ? (
                <div className="relative ">
                  {!image && data.imgurl ? (
                    <img
                      src={`${backendAddress}/${data.imgurl}`}
                      alt=""
                    />
                  ) : (
                    image && <img src={URL.createObjectURL(image)} alt="" />
                  )}
                  <label
                    className="absolute top-0 flex justify-center items-center bg-black bg-opacity-50 h-full w-full opacity-0 hover:opacity-100 cursor-pointer rounded-md font-medium text-white"
                    htmlFor="file-upload"
                  >
                    <p>{t('dashboard.post.upload')}</p>
                    <input
                      id="file-upload"
                      name="file"
                      type="file"
                      className="sr-only"
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={handleImageChange}
                    />
                  </label>
                </div>
              ) : (
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-neutral-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <div className="flex text-sm text-neutral-6000 justify-center">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md font-medium text-primary-6000
                  hover:text-primary-800 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 text-center"
                    >
                      <p>{t('dashboard.post.upload')}</p>
                      <input
                        id="file-upload"
                        name="file"
                        type="file"
                        className="sr-only"
                        accept="image/png, image/jpeg, image/jpg"
                        onChange={handleImageChange}
                        required
                      />
                    </label>
                  </div>
                  <p className="text-xs text-neutral-500">
                    {t('dashboard.post.uploadTo')}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/*quill for visit section*/}
        <label className="block md:col-span-2">
          <Label>🌆 {t('dashboard.post.titleVisit')}</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarVisit />
            <ReactQuillVisit
              id="qEditorVisit"
              theme="snow"
              value={(contentVisit && contentVisit!=="<p>null</p>")? contentVisit : " "}
              onChange={handleContentVisitChange}
              placeholder={t('dashboard.post.contentdescvisit')}
              ref={(el) => { reactQuillRefVisit = el }}
              modules={modulesVisit}
              formats={formatsVisit}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentVisitLength}/800 </Label>
          </div>

        </label>

        {/*quill for Move On section*/}
        <label className="block md:col-span-2">
          <Label>🛴 {t('dashboard.post.titleMoveOn')}</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarMoveOn />
            <ReactQuillMoveOn
              id="qEditorMoveOn"
              theme="snow"
              value={(contentMoveOn && contentMoveOn!=="<p>null</p>")? contentMoveOn : " "}
              onChange={handleContentMoveOnChange}
              placeholder={t('dashboard.post.contentdescmoveon')}
              ref={(el) => { reactQuillRefMoveOn = el }}
              modules={modulesMoveOn}
              formats={formatsMoveOn}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentMoveOnLength}/800 </Label>
          </div>

        </label>

        {/*quill for Ospitality section*/}
        <label className="block md:col-span-2">
          <Label>🏢 {t('dashboard.post.titleOspitality')}</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarOspitality />
            <ReactQuillOspitality
              id="qEditorOspitality"
              theme="snow"
              value={(contentOspitality && contentOspitality!=="<p>null</p>")? contentOspitality : " "}
              onChange={handleContentOspitalityChange}
              placeholder={t('dashboard.post.contentdescospitality')}
              ref={(el) => { reactQuillRefOspitality = el }}
              modules={modulesOspitality}
              formats={formatsOspitality}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentOspitalityLength}/500 </Label>
          </div>

        </label>

        {/*quill for Meat section*/}
        <label className="block md:col-span-2">
          <Label>🌮 {t('dashboard.post.titleMeat')}</Label>
          <div className="text-editor text-sm rounded-lg border-neutral-200 ">
            <ToolbarMeat />
            <ReactQuillMeat
              id="qEditorMeat"
              theme="snow"
              value={(contentMeat && contentMeat!=="<p>null</p>")? contentMeat : " "}
              onChange={handleContentMeatChange}
              placeholder={t('dashboard.post.contentdescmeat')}
              ref={(el) => { reactQuillRefMeat = el }}
              modules={modulesMeat}
              formats={formatsMeat}
            />

          </div>
          <div className="relative">
             <Label className="absolute right-0"> {contentMeatLength}/800 </Label>
          </div>

        </label>



          {/*quill for experiences and tips section*/}
          <label className="block md:col-span-2">
            <Label>📝 {t('dashboard.post.content')}</Label>
            <div className="text-editor w-full text-sm rounded-lg border-neutral-200 ">
              <Toolbar />
              <ReactQuill
                id="qEditor"
                theme="snow"
                value={(content && content!=="<p>null</p>")? content : " "}
                onChange={(e)=>handleContentChange(e)}
                ref={(el) => { reactQuillRef = el }}
                placeholder={t('dashboard.post.contentdesc')}
                modules={modules}
                formats={formats}
              />
            </div>
          </label>
          <ButtonPrimary className="md:col-span-2">
            {submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#eee",
                }}
              />
            ) : (
              <span>{article.is_draft ? t('dashboard.publish') : t('dashboard.post.edit')}</span>
            )}
          </ButtonPrimary>
          {article.is_draft && (
          <ButtonSecondary className="md:col-span-2" type="button" onClick={handleSubmitDraft}>
          {submittingDraft ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('saveDraft')}</span>
          )}
        </ButtonSecondary>
        )}
          {activeLabel ? (
          <Label className="mt-10">{labelText}</Label>
         ) : ( <div />)}
        </form>
      </div>
      <ModalFillAllFields
            show={isMessage}
            onCloseModalMessage={closeModalMessage}
            onClickSubmit={handleClickDropDown}
      />
    </div>
  );
};

export default DashboardEditPost;
