import Heading from "components/Heading/Heading";
import NcImage from "components/NcImage/NcImage";
import Francesco from "images/Francesco.jpg";
import Francesca from "images/Francesca.jpg";
import Mattia from "images/Mattia.jpg";
import { useTranslation } from 'react-i18next';




import React from "react";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Francesco`,
    job: "Co-founder and traveller",
    avatar:
      Francesco
  },
  {
    id: "2",
    name: `Francesca`,
    job: "Co-founder and traveller",
    avatar:
      Francesca,
  },
  {
    id: "3",
    name: `Mattia`,
    job: "Co-founder and traveller",
    avatar:
      Mattia,
  },


];

const SectionFounder = () => {
      const {t} = useTranslation();

  return (
    <div className="nc-SectionFounder px-6 relative">
      <Heading
        desc={t('founder.1')}      >
        ⛱ Founder
      </Heading>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <NcImage
              containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
              className="absolute inset-0 object-cover"
              src={item.avatar}
            />
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
