// @ts-nocheck
import React, { FC,useState,useEffect}  from "react";
import { FlightType} from "data/types";
import getSymbolFromCurrency from 'currency-symbol-map';
import ButtonPrimary from "components/Button/ButtonPrimary";
import {FaPlaneArrival, FaPlaneDeparture} from "react-icons/fa";
import {IoAirplane} from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import axios from "../../axios";
import { avatarColors } from "contains/contants";
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {FcShop,FcRating } from "react-icons/fc";
import ncNanoId from "utils/ncNanoId";
import { getStateFromUrl } from 'helpers/url_handler';
import { useNavigate } from "react-router";
import {  useLocation } from 'react-router-dom';
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import airportsJson from "data/jsons/airports.json";
import { selectUser } from "app/user/user";
import { useAppSelector } from "app/hooks";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import {getCurrency} from "locale-currency";


export interface Card3Props {
  className?: string;
  dataFlight: FlightType;
  size?: "normal" | "large";
}

export interface FlightSegmentArray {
  departureAirport?: string;
  arrivalAirport?: string;
  departure_time?:string;
  arrival_time?:string;
  carrier?:string;
  flight_number?:string;
  carrier_operating?:string;
  class?:string;
}

interface QuerySearch {
  adults_count?: number;
  child_counts?: number;
  infants_count?:number;
  departure_airport?:string;
  arrival_airport?:string;
  with_return?:boolean;
  flight_class?:string;
  currency?:string;
  outbound_date?:any;
  return_date?:any;
  rangeDep?:number[];
  rangeArr?:number[];
  locale?:string;
  adult_hold_bag?:number;
  adult_hand_bag?:number;
  child_hold_bag?:number;
  child_hand_bag?:number;
  departure_flight_available_duration?:number;
  return_flight_available_duration?:number;
  timeDepart?:string;
  timeReturn?:string;
  airlineDepart?:string;
  airlineReturn?:string;
}


const CardFlight: FC<React.PropsWithChildren<React.PropsWithChildren<Card3Props>>> = ({
  className = "",
  size = "large",
  dataFlight,
}) => {
  const history = useNavigate();
  let apiParams = getStateFromUrl(useLocation());
  let withReturn=false;
  let buy_link;
  const {t} = useTranslation();

  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 0, name: "Any", type:"Y" },
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];




  function tpToTime(tp,airport_code)
  {
        let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let timestampFormat=moment(tp*1000).format();
       let formattedTime=momentTimezone.tz(timestampFormat,tzCod[0].timezone).format('HH:mm')
       return formattedTime;
  }

  function logoAirline(name:any){
    let airlogo="https://images.kiwi.com/airlines/64x64/"+name+".png";
    return airlogo;
  }




  /*set var to understand flight type*/
  if(dataFlight){
    buy_link=dataFlight.buy_link;
  }
  if (dataFlight.itineraries.length>1) withReturn=true;



  /*set all variable needed for the graphics*/
  let departure_flight_takeoff_datetime = tpToTime(dataFlight.itineraries[0].flight_segments[0].departure_timestamp,dataFlight.itineraries[0].flight_segments[0].departure_airport_code);
  let departure_flight_landing_time = tpToTime(dataFlight.itineraries[0].flight_segments[dataFlight.itineraries[0].flight_segments.length -1].arrival_timestamp,dataFlight.itineraries[0].flight_segments[dataFlight.itineraries[0].flight_segments.length -1].arrival_airport_code);

  let departure_flight_airlines=dataFlight.itineraries[0].flight_segments[0].display_airline_name;
  let departure_flight_segments_detail = dataFlight.itineraries[0].flight_segments;
  let departure_flight_available_seats = dataFlight.itineraries[0].available_seats;
  let departure_currency = getSymbolFromCurrency(dataFlight.itineraries[0].currency_code)? getSymbolFromCurrency(dataFlight.itineraries[0].currency_code): itineraries[0].currency_code;
  let departStopOver=dataFlight.itineraries[0].flight_segments.length-1;
  let depSegLength=departure_flight_segments_detail.length-1;
  //let depAirLogo="http://pics.avs.io/100/100/"+dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString()+"@2x.png";
  let depAirLogo=logoAirline(dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString());

  let return_flight_takeoff_datetime = (dataFlight.itineraries.length > 1) ? tpToTime(dataFlight.itineraries[1].flight_segments[0].departure_timestamp,dataFlight.itineraries[1].flight_segments[0].departure_airport_code) : undefined;
  let return_flight_landing_time = (dataFlight.itineraries.length > 1) ? tpToTime(dataFlight.itineraries[1].flight_segments[dataFlight.itineraries[1].flight_segments.length -1].arrival_timestamp,dataFlight.itineraries[1].flight_segments[dataFlight.itineraries[1].flight_segments.length -1].arrival_airport_code): undefined;



  let return_flight_segments_detail =  dataFlight.itineraries.length > 1 ? dataFlight.itineraries[1].flight_segments : undefined;
  let return_flight_available_seats = dataFlight.itineraries.length > 1? dataFlight.itineraries[1].available_seats : undefined;
  let return_currency = dataFlight.itineraries.length > 1? (getSymbolFromCurrency(dataFlight.itineraries[1].currency_code)? getSymbolFromCurrency(dataFlight.itineraries[1].currency_code): dataFlight.itineraries[1].currency_code): undefined;
  let return_flight_airlines=dataFlight.itineraries.length > 1 ? (dataFlight.itineraries[1].flight_segments[0].display_airline_name) : undefined;
  let returnStopOver=dataFlight.itineraries.length > 1 ? (dataFlight.itineraries[1].flight_segments.length-1) : undefined;
  let returnSegLength=dataFlight.itineraries.length > 1 ? (return_flight_segments_detail.length-1):0;
  //let retAirLogo=dataFlight.itineraries.length > 1 ? "http://pics.avs.io/100/100/"+dataFlight.itineraries[1].flight_segments[0].display_airline_code.toString()+"@2x.png":"";
  let retAirLogo=dataFlight.itineraries.length > 1 ? logoAirline(dataFlight.itineraries[1].flight_segments[0].display_airline_code.toString()) : "";
  //evaluation diff Days in dep/ret
    let arrivalDateDep=new Date(departure_flight_segments_detail[departure_flight_segments_detail.length-1].arrival_timestamp*1000);
    let departDate= new Date(apiParams.outbound_date.split("-")[2]+"/"+apiParams.outbound_date.split("-")[1]+"/"+apiParams.outbound_date.split("-")[0]);
    let diffTime = (arrivalDateDep.getTime() - departDate.getTime());
    let diffDays = Math.floor(diffTime / (1000 * 3600 * 24));
    let departure_flight_available_duration=dataFlight.durationFirstPath;

    let arrivalDateRet=dataFlight.itineraries.length > 1 ? new Date(return_flight_segments_detail[return_flight_segments_detail.length-1].arrival_timestamp*1000) : undefined;
    let retDate= dataFlight.itineraries.length > 1 ? new Date(apiParams.return_date.split("-")[2]+"/"+apiParams.return_date.split("-")[1]+"/"+apiParams.return_date.split("-")[0]) : undefined;
    let diffTimeRet = dataFlight.itineraries.length > 1 ? (arrivalDateRet.getTime() - retDate.getTime()) : undefined;
    let diffDaysRet = dataFlight.itineraries.length > 1 ? Math.floor(diffTimeRet / (1000 * 3600 * 24)) : undefined;
    let return_flight_available_duration = dataFlight.itineraries.length > 1 ? dataFlight.durationReturn : undefined;

  let transport_classDep= dataFlight.transport_classDep;
  for (let key in FILTERS_CLASS){
    if(FILTERS_CLASS[key].type==dataFlight.transport_classDep) transport_classDep=FILTERS_CLASS[key].name;
  }
  let transport_classRet=dataFlight.transport_classRet;
  for (let key in FILTERS_CLASS){
    if(FILTERS_CLASS[key].type==dataFlight.transport_classRet) transport_classRet=FILTERS_CLASS[key].name;
  }

  /*map all stopover*/
  let returnStopCities=[];
  let departStopCities=[];
  let stringDepartStopCities="";
  for(let i=0;i<returnStopOver;i++)
  {
        if(return_flight_segments_detail[i].arrival_airport_name && return_flight_segments_detail[i].arrival_airport_name!=""){
            returnStopCities.push(return_flight_segments_detail[i].arrival_airport_name);
        }
        else{
            returnStopCities.push(return_flight_segments_detail[i].arrival_airport_code);
        }
        if((i+1)!==returnStopOver) returnStopCities.push(" / ");
  }
  let stringReturnStopCities="";
  for(let key in returnStopCities)
  {
        stringReturnStopCities+=returnStopCities[key];
  }

  for(let i=0;i<departStopOver;i++)
  {
    if(departure_flight_segments_detail[i].arrival_airport_name && departure_flight_segments_detail[i].arrival_airport_name!=""){
        departStopCities.push(departure_flight_segments_detail[i].arrival_airport_name);
    }
    else{
        departStopCities.push(departure_flight_segments_detail[i].arrival_airport_code);
    }
    if((i+1)!==departStopOver) departStopCities.push(" / ");
  }

  for(let key in departStopCities)
  {
    stringDepartStopCities+=departStopCities[key];
  }

  //sort flight flight_prices
  let flight_prices=dataFlight.flight_prices || undefined;
  if(flight_prices) flight_prices.sort((a:any, b:any) => (a.amount_to_pay > b.amount_to_pay) ? 1 : -1);


//manage the buy request
 const BuyRedirect = (e:any,b_link:string) =>
  {
    e.preventDefault();
    //let urlBuy="/"+b_link;
    const buyWindow = window.open('about:blank');
       axios
      .get(b_link)
              .then((result) => {
                let data: any = result.data;

                buyWindow.document.location.href = data.buy_link;
              })
              .catch((err) => {
                console.log("There was an error");
              });
 }

 const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  /*MANAGE FOLLOW FLIGHT FUNCTIONALITY*/
  const [selectionFlight,setSelectionFlight] = useState(false);
  const [logged,setLogged]=useState(false);
  const [activeLabel,setActiveLabel]=useState(false);



  function manageFollowFlight(){
     if(!selectionFlight){
        setTimeout(() => {
          setActiveLabel(false);
        }, 5000);
        setActiveLabel(true);


    var query : QuerySearch= {
      departure_airport: apiParams.departure_airport,
      arrival_airport: apiParams.arrival_airport,
      with_return: dataFlight.itineraries.length > 1 ? true : false,
      outbound_date:apiParams.outbound_date,
      return_date:dataFlight.itineraries.length > 1 ? apiParams.return_date : undefined,
      adults_count: parseInt(apiParams.adults_count),
      child_counts: parseInt(apiParams.child_counts),
      infants_count: parseInt(apiParams.infants_count),
      flight_class: apiParams.flight_class,
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:parseInt(apiParams.adult_hold_bag),
      adult_hand_bag:parseInt(apiParams.adult_hand_bag),
      child_hold_bag:parseInt(apiParams.child_hold_bag),
      child_hand_bag:parseInt(apiParams.child_hand_bag),
      departure_flight_available_duration:parseInt(departure_flight_available_duration.split(":")[0]*60)+parseInt(departure_flight_available_duration.split(":")[1].split(" ")[0]),
      return_flight_available_duration:dataFlight.itineraries.length > 1 ? parseInt(return_flight_available_duration.split(":")[0]*60)+parseInt(return_flight_available_duration.split(":")[1].split(" ")[0]) : undefined,
      timeDepart:departure_flight_takeoff_datetime,
      timeReturn:dataFlight.itineraries.length > 1 ? return_flight_takeoff_datetime : undefined,
      airlineDepart:departure_flight_airlines,
      airlineReturn:dataFlight.itineraries.length > 1 ? return_flight_airlines : undefined
    };



    /*axios
      .post("/subscribe/add", {
          departure_airport: departureAirportValue,
          arrival_airport: departure_flight_segments_detail[depSegLength].arrival_airport_code,
          adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
          child_counts: flightChild,
          infants_count: flightInfant,
          flight_class: "Y",
          with_return: oneWayReturn,
          currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
          locale: navigator.language,
          adult_hold_bag:bagAdult,
          adult_hand_bag:hBagAdult,
          child_hold_bag:0,
          child_hand_bag:0
      })
      .then((res: any) => {


        setTimeout(() => {
          setActiveLabel(false);
        }, 5000);
        setActiveLabel(true);


      })
      .catch((err) => {

        setActiveLabel(false);
      });*/


    }
    setSelectionFlight(!selectionFlight);

  }



  const user:any = useAppSelector(selectUser);

  useEffect(() => {
    getAccessToken().then((access_token: any) => {
        setLogged(true);
    }).catch((err) => {
            setLogged(false)
            if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
            {setLogged(false)}
    });
  }, [user]);



  return (
    <div
      className={`nc-Card3 relative sm:items-center rounded-[40px] group border-gray-200 hover:border-gray-800  [ nc-box-has-hover-flight ] [ nc-dark-box-bg-has-hover-flight ] bg-white bg-opacity-10  ${className}`}
      data-nc-id="Card3"
    >
      <div className="w-full  gap-6">

        <div className="container w-full">
            {size === "large" && (
               <div id="descCard" className="relative mt-2">
               <div className="flex flex-row">
               <div className="w-1/4 h-11/13 border-r flex flex-wrap items-center">
                    <h2
                      className={`prose dark:prose-dark nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${
                        size === "large" ? "text-xl" : "text-base"
                      }`}
                    >
                    <div className=" relative ">
                        <div className="h-full flex items-center left-2 gap-4 text-neutral-900 dark:text-neutral-50 text-2xl md:text-3xl font-semibold">
                          <div
                              className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-xl ring-1 ring-neutral-300 dark:ring-neutral-900`}
                              style={{ backgroundColor: "#fff"}}
                            >
                            <img
                                className="wil-avatar__name"
                                alt="logo"
                                src={depAirLogo}
                             />
                          </div>
                            <b> {departure_flight_airlines.split(" ")[0]+" " + ((!departure_flight_airlines.split(" ")[1]  ? "" : (departure_flight_airlines.split(" ")[1]==="International" ? "Int." : departure_flight_airlines.split(" ")[1])))}</b>
                        </div>
                    </div>
                 </h2>
              </div>
                <div className="relative w-3/4 ml-5 mt-5  inset-x-3">
                <span className="text-md w-full">
                 {t('flightDetailDuration')}: {departure_flight_available_duration} {transport_classDep && (<span>| {t('flightDetailClass')}: {transport_classDep}</span>) }
                </span>
                    <div className="w-full flex flex-row gap-5 items-center">

                        <FaPlaneDeparture />
                        <div className="w-auto">
                            <p className="text-2xl">{departure_flight_takeoff_datetime}</p>
                            <p className="text-xs">({departure_flight_segments_detail[0].departure_airport_code})</p>
                        </div>

                        <div className="relative flex py-5 items-center">
                            <div className="flex-grow border-t border-gray-900 dark:border-neutral-100 w-40"></div>
                                <span className="flex-shrink mx-4 text-neutral-900 dark:text-neutral-100"><IoAirplane /></span>
                            <div className="flex-grow border-t border-gray-900 dark:border-neutral-100 w-40"></div>
                        </div>

                        <div className="w-auto">
                            <p className="text-2xl">{departure_flight_landing_time}{diffDays!==0 && (<sup>+{diffDays}</sup>)}</p>
                            <p className="text-xs">({departure_flight_segments_detail[depSegLength].arrival_airport_code})</p>
                        </div>
                        <FaPlaneArrival />
                    </div>
                    {(departStopOver>0) ? (
                      <div className="text-sm w-full flex flex-row gap-2 mt-2"><b>{t('flightDetailStopover')}({departStopOver}):</b>

                        {stringDepartStopCities}
                      </div>

                    ):(<span></span>)}

                    </div>
                   </div>

                    {(withReturn) ? (

                          <div className="relative">
                            <div className="relative flex py-5 items-center">
                                <div className="flex-grow border-t border-gray-900 dark:border-neutral-100"></div>
                                <span className="flex-shrink mx-4 text-neutral-900 dark:text-neutral-100">{t('returnFlight')}</span>
                                <div className="flex-grow border-t border-gray-900 dark:border-neutral-100"></div>
                            </div>

                            <div id="descCard" className="relative mt-2">
                                <div className="flex flex-row">
                                    <div className="w-1/4 h-11/13 border-r flex flex-wrap items-center">
                                        <h2
                                              className={`prose dark:prose-dark nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 ${
                                                size === "large" ? "text-xl" : "text-base"
                                              }`}
                                            >

                                            <div className="w-full relative">
                                                <div className="h-full flex items-center left-2 gap-4 text-neutral-900 dark:text-neutral-50 text-2xl md:text-3xl font-semibold">
                                                     <div
                                                          className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-xl ring-1 ring-neutral-300 dark:ring-neutral-900`}
                                                          style={{ backgroundColor: "#fff"}}
                                                        >
                                                        <img
                                                            className="wil-avatar__name"
                                                            alt="logo"
                                                            src={retAirLogo}
                                                         />


                                                      </div>
                                                    <b> {return_flight_airlines.split(" ")[0]+" " + ((!return_flight_airlines.split(" ")[1]  ? "" : (return_flight_airlines.split(" ")[1]==="International" ? "Int." : return_flight_airlines.split(" ")[1])))}</b>

                                                </div>
                                            </div>
                                         </h2>
                                    </div>
                                 <div className="relative w-3/4 ml-5 mt-5 inset-x-3">
                                                <span className="text-md w-full">
                                                 {t('flightDetailDuration')}: {return_flight_available_duration} {transport_classRet && (<span>| {t('flightDetailClass')}: {transport_classRet}</span>) }
                                                </span>
                                                    <div className="w-full flex flex-row gap-5 items-center">

                                                        <FaPlaneDeparture />
                                                        <div className="w-auto">
                                                            <p className="text-2xl">{return_flight_takeoff_datetime}</p>
                                                            <p className="text-xs">({return_flight_segments_detail[0].departure_airport_code})</p>
                                                        </div>

                                                        <div className="relative flex py-5 items-center">
                                                            <div className="w-20 md:w-20 lg:w-40 flex-grow border-t border-gray-900 dark:border-neutral-100 "></div>
                                                                <span className="flex-shrink mx-4 text-neutral-900 dark:text-neutral-100"><IoAirplane /></span>
                                                            <div className="flex-grow border-t border-gray-900 dark:border-neutral-100 w-20 xl:w-40"></div>
                                                        </div>

                                                        <div className="w-auto">
                                                            <p className="text-2xl">{return_flight_landing_time}{diffDaysRet!==0 && (<sup>+{diffDaysRet}</sup>)}</p>
                                                            <p className="text-xs">({return_flight_segments_detail[returnSegLength].arrival_airport_code})</p>
                                                        </div>
                                                        <FaPlaneArrival />



                                                    </div>
                                                    {(returnStopOver>0) ? (
                                                      <div className="text-sm w-full flex flex-row gap-2 mt-2"><b>{t('flightDetailStopover')}({returnStopOver}): </b>

                                                        {stringReturnStopCities}
                                                      </div>

                                                    ):(<span></span>)}

                                                    </div>
                            </div>
                            </div>



                          </div>


                   ):(<span></span>)}


              </div>
            )}
        </div>
      </div>

      <ViewWithPopup
         key={`View ${ncNanoId()}`}
         noView={true}
         className=""
         style={{position:"relative"}}
         view={<ButtonPrimary className="nc-DivChooseRooms 0 w-full text-center mt-5">{t("buyFlight")}{dataFlight.minFee} {departure_currency}</ButtonPrimary>}
         popup={
            <div className="relative ">
            {flight_prices && flight_prices.map((flightPrice:FlightType,index:number) => (
                <div key={index} className="bg-transparent dark:bg-transparent grid grid-cols-3 gap-2 border-t sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">

                  <div className="relative flex flex-wrap items-center justify-center col-span-1 bg-transparent dark:bg-transparent break-words">

                    {flightPrice.is_airline===true ? (
                      <img
                        src={(flightPrice.airline_id!==null && flightPrice.airline_id!==undefined) ? "http://pics.avs.io/as_gates/50/50/"+flightPrice.airline_id+"@2x.png" : "http://pics.avs.io/50/50/"+dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString()+"@2x.png" }
                        alt="logo"
                      />
                    ):(
                        <img
                        src={"https://pics.avs.io/as_gates/50/50/"+flightPrice.agency_id+"@2x.png"}
                        alt="logo"
                     />
                    )}
                  </div>

                  <div className="relative flex flex-wrap items-center justify-center col-span-1 bg-transparent dark:bg-transparent break-words text-black">
                     {flightPrice.is_airline===false ?(
                        <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50"><FcShop style={{ width: "40px", height:"40px" }} /><span className="mt-2 font-bold">{t('room.agency')}</span></span>
                     ):(
                        <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50"><FcRating style={{ width: "40px", height:"40px" }} /><span className="mt-2 font-bold">{t('agency.airline')}</span></span>
                     )}
                  </div>

                  <div className="relative col-span-1 py-10 bg-transparent dark:bg-transparent inline-block break-words py-3">
                     <ButtonPrimary className="nc-BtnSubmitSearch mt-3" onClick={(e:any) => BuyRedirect(e,flightPrice.buy_link)}>
                        <b><span>{Math.round(flightPrice.amount_to_pay)} {departure_currency}</span></b>
                    </ButtonPrimary>
                  </div>

                </div>
            ))}

            {!flight_prices && (
                <div className="bg-transparent dark:bg-transparent grid grid-cols-3 border-t text-xl">
                    <div className="relative col-span-1 flex flex-wrap items-center justify-center bg-transparent dark:bg-transparent">
                     {dataFlight.is_airline===true ? (
                      <img
                        src={(dataFlight.airline_id!==null && dataFlight.airline_id!==undefined) ? "http://pics.avs.io/as_gates/60/50/"+dataFlight.airline_id+"@2x.png" : "http://pics.avs.io/50/50/"+dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString()+"@2x.png" }
                        alt="logo"
                      />
                    ):(
                        <img
                        src={"https://pics.avs.io/as_gates/50/50/"+dataFlight.agency_id+"@2x.png"}
                        alt="logo"
                     />
                    )}

                    </div>

                    <div className="relative flex flex-wrap items-center justify-center col-span-1 bg-transparent dark:bg-transparent break-words text-black">
                     {dataFlight.is_airline===false ?(
                        <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50"><FcShop style={{ width: "40px", height:"40px" }} /><span className="mt-2 font-bold	">{t('room.agency')}</span></span>
                     ):(
                        <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50"><FcRating style={{ width: "40px", height:"40px" }} /><span className="mt-2 font-bold	">{t('agency.airline')}</span></span>
                     )}
                  </div>


                  <div className="relative col-span-1 py-10 bg-transparent dark:bg-transparent ">
                     <ButtonPrimary className="nc-BtnSubmitSearch mt-3" onClick={(e:any) => BuyRedirect(e,dataFlight.buy_link)}>
                        <b><span>{Math.round(dataFlight.fee)} {departure_currency}</span></b>
                    </ButtonPrimary>
                  </div>

                </div>

            )}

            </div>
         }
       />
       <p></p>

    </div>
  );
};

export default CardFlight;
