import React, { FC } from "react";
import { PostAuthorType } from "data/types";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";
import Avatar from "components/Avatar/Avatar";
import NcImage from "components/NcImage/NcImage";
import {backendAddress} from "../../index";



export interface CardAuthorBox2Props {
  className?: string;
  author: PostAuthorType;
}

const CardAuthorBox2: FC<React.PropsWithChildren<React.PropsWithChildren<CardAuthorBox2Props>>> = ({
  className = "",
  author,
}) => {
  const { displayName, href = "/", avatar, count, bgImage, defaultBG } = author;

  return (
    <NavLink
      to={href}
      className={`nc-CardAuthorBox2 flex flex-col overflow-hidden [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox2"
    >
      <div className="relative flex-shrink-0 ">
        <div>
        { bgImage ? (
          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-5 sm:aspect-h-6 w-full h-0"
            src={bgImage}
          />

          ):(

          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-5 sm:aspect-h-6 w-full h-0"
            src={defaultBG}
          />

          )
        }
        </div>
        <div className="absolute top-3 inset-x-3 flex">
          <div className=" py-1 px-4 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center leading-none text-xs font-medium">
            {count} <ArrowRightIcon className="w-5 h-5 text-yellow-600 ml-3" />
          </div>
        </div>
      </div>

      <div className="-mt-8 m-8 text-center">
        {avatar ? (
          <Avatar
            containerClassName="ring-2 ring-white"
            sizeClass="w-16 h-16 text-2xl"
            radius="rounded-full"
            imgUrl={avatar}
            userName={displayName}
          />
        ) : (
          <Avatar
            containerClassName="ring-2 ring-white"
            sizeClass="w-16 h-16 text-2xl"
            radius="rounded-full"
            imgUrl={avatar}
            userName={displayName}
          />
        )}
        <div className="mt-3">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1">{displayName}</span>
          </h2>

        </div>
      </div>
    </NavLink>
  );
};

export default CardAuthorBox2;
