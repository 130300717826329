import { useAppDispatch, useAppSelector } from "app/hooks";
import {removeUser, selectUser, setUser} from "app/user/user";
import axios from "../../axios";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import { useState, useEffect, useReducer } from "react";
import { CircularProgress } from "@mui/material";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";
import { useTranslation } from 'react-i18next';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import {backendAddress} from "../../index";
import { useNavigate } from "react-router";
import Layout from "./layout";
import { Helmet } from "react-helmet";


const DashboardEditProfile = () => {

  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<any>("Success");
  const [showAlert, setShowAlert] = useState(false);
  const [image, setImage] = useState<any>("");
  const [imageBG, setImageBG] = useState<any>("");
  const [imageBkd, setImageBkd] = useState<any>("");
  const [imageBGBkd, setImageBGBkd] = useState<any>("");
  const [imageBgChanged, setImageBgChanged] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [checkAvatar, setCheckAvatar] = useState(false);
  const [checkImgUrl, setCheckImgUrl] = useState(false);
  const { user }: any = useAppSelector(selectUser);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const history = useNavigate();


  //manage useEffect
   useEffect(() => {
      getAccessToken().then((access_token: any) => {
          let splitAuthor, splitBGAuthor;
          if(user.imgUrl) { splitAuthor=user.imgUrl.split("/"); }
          if(user.imgBGUrl) { splitBGAuthor=user.imgBGUrl.split("/"); }
          if(splitAuthor[0]==="authors"){setImageBkd(`${backendAddress}/${user.imgUrl}`);}else{setImageBkd(user.imgUrl);}
          if(splitBGAuthor[0]==="authors"){setImageBGBkd(`${backendAddress}/${user.imgBGUrl}`);}else{setImageBGBkd(user.imgBGUrl);}
          setSubmitting(false);
      }).catch((err) => {
            if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
            {
                    handleLogout();
                    history("/login");
            }
      });


   }, [setImageBgChanged,setImageChanged]);

   const [value, setValue] = useState<any>({
    id: user.id || "",
    name: user.name || "",
    desc: user.desc || "",
    facebookUrl: user.facebookUrl || "",
    twitterUrl: user.twitterUrl || "",
    instagramUrl: user.instagramUrl || "",
    currentPassword: "",
    newPassword: "",
  });

  const handleImageChange = (e: any) => {
    setImage(e.target.files[0]);
    setImageChanged(true);
  };

  const handleBGImageChange = (e: any) => {
    setImageBG(e.target.files[0]);
    setImageBgChanged(true);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));

  };

  function handleLogout (){
    dispatch(removeUser());
  };

  //manage first part of the update
  function handleEditProfile(res:any)
  {
        if (res.data.status === "success")
        {
          setSubmitting(false);
          setMessage(res.data);
          setShowMessage(true);
          dispatch(setUser(res.data.user));
          setValue({
            id: res.data.user.id,
            name: res.data.user.name,
            desc: res.data.user.desc,
            facebookUrl: res.data.facebookUrl,
            twitterUrl: res.data.twitterUrl,
            instagramUrl: res.data.instagramUrl,
            currentPassword: "",
            newPassword: "",
          });
      }
  }

   function handleFeaturedUpdate (resbg:any){
    if (resbg.data.status === "success") {
        setSubmitting(false);
        setShowMessage(true);
        dispatch(setUser(resbg.data.user));
    }
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    const data = new FormData();
    if(imageChanged){data.append("file", image);}
    data.append("profile", JSON.stringify(value));

    await getAccessToken().then((access_token: any) => {
      axios
       .post("/authors/update", data, {headers: {'Authorization': `Bearer ${access_token}`}})
       .then(async (res: any) => {
          handleEditProfile(res);

          if(imageBgChanged){
               const databg = new FormData();
               databg.append("filebg", imageBG);
               databg.append("profile", JSON.stringify(value));

              axios
                 .post("/authors/updateBGIMGAuthor", databg, {headers: {'Authorization': `Bearer ${access_token}`}})
                 .then(async(resbg: any) => {handleFeaturedUpdate(resbg);})
                 .catch((err) => {
                    setImageBgChanged(false);
                   setSubmitting(false);
                   setShowMessage(false);
                 });
          }
          setShowAlert(true);
      }).catch((err) => {
        setSubmitting(false);
        setShowMessage(false);
        setImageChanged(false);
      });

    }).catch((err) => {
      setSubmitting(false);
      setShowMessage(false);
      setImageChanged(false);
      setImageBgChanged(false);
      if(err.code && err.code === UNAUTHORIZED_ERROR_CODE){
        handleLogout();
        history("/login");
      }
    });
 };

  return (
    <Layout>
    <Helmet>
        <title>Edit Profile || Gonnaway</title>
      </Helmet>
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <Collapse in={showAlert}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <SvgIcon>
                <path
                  fill="inherit"
                  d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                />
              </SvgIcon>
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <p style={{ marginTop: ".2rem" }}>{showMessage ? t('profile.success') : t('profile.unsuccess')}</p>
        </Alert>
      </Collapse>
      <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <div className="block">
          <label className="block">
            <Label>{t('dashboard.profile.name')}</Label>
            <Input
              value={value.name || ""}
              type="text"
              className="mt-1"
              name="name"
              onChange={handleChange}
              pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
              onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.nameProfile'))}
              onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
              required
            />
          </label>

          <label className="block mt-2">
            <Label>{t('dashboard.profile.facebook')}</Label>
            <Input
              id="facebook"
              placeholder="https://facebook.com/username"
              type="url"
              className="mt-1"
              name="facebookUrl"
              pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
              onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.social'))}
              onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
              value={value.facebookUrl || ""}
              onChange={handleChange}
            />
          </label>
          <label className="block mt-2">
            <Label>{t('dashboard.profile.twitter')}</Label>
            <Input
              placeholder="https://twitter.com/username"
              type="url"
              className="mt-1"
              name="twitterUrl"
              pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
              onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.social'))}
              onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
              value={value.twitterUrl || ""}
              onChange={handleChange}
            />
          </label>
          <label className="block mt-2">
            <Label>{t('dashboard.profile.instagram')}</Label>
            <Input
              placeholder="https://instagram.com/username"
              type="url"
              className="mt-1"
              name="instagramUrl"
              pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
              onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.social'))}
              onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
              value={value.instagramUrl || ""}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="block">
          <Label>{t('dashboard.profile.image')}</Label>
          <div className="mt-1 flex justify-center px-6 pt-8 pb-12 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">

              <div className="relative ">
                {(!imageChanged && imageBkd) && (
                  <img src={`${imageBkd}?${Date.now()}`} alt="Gonnaway" />
                )}

                {imageChanged && (
                    <img src={URL.createObjectURL(image)} alt="Gonnaway" />
                )}

                <label
                  className="absolute top-0 flex justify-center items-center bg-black bg-opacity-50 h-full w-full opacity-0 hover:opacity-100 cursor-pointer rounded-md font-medium text-white"
                  htmlFor="file-upload"
                >
                  <p>{t('dashboard.profile.upload')}</p>
                  <input
                    id="file-upload"
                    name="file"
                    type="file"
                    className="sr-only"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleImageChange}
                  />
                </label>
              </div>

          </div>
        </div>

    {/*/IMG background*/}
        <div className="block md:col-span-2">
          <Label>Background image</Label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md">
              <div className="relative">
                {(!imageBgChanged && imageBGBkd) && (
                  <img src={`${imageBGBkd}?${Date.now()}`} alt="Gonnaway" />
                )}

                {imageBgChanged &&(
                    <img src={URL.createObjectURL(imageBG)} alt="Gonnaway" />
                )}

                <label
                  className="absolute top-0 flex justify-center items-center bg-black bg-opacity-50 h-full w-full opacity-0 hover:opacity-100 cursor-pointer rounded-md font-medium text-white"
                  htmlFor="filebg"
                >
                  <p>Background image</p>
                  <input
                    id="filebg"
                    name="file"
                    type="file"
                    className="sr-only"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={handleBGImageChange}
                  />
                </label>
              </div>

          </div>
        </div>

        <label className="block md:col-span-2 ">
          <Label> {t('dashboard.profile.content')}</Label>
          <Textarea
            className="mt-1"
            rows={4}
            name="desc"
            value={value.desc}
            onChange={handleChange}
            required
          />
        </label>

        <ButtonPrimary className="md:col-span-2 mb-5">
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('dashboard.profile.update')}</span>
          )}
        </ButtonPrimary>
      </form>
    </div>
    </Layout>
  );
};

export default DashboardEditProfile;
