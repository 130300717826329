import React, { FC, useEffect, useState } from "react";
import Logo from "components/Logo/Logo";
import Navigation from "components/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import MenuBar from "components/MenuBar/MenuBar";
import { useAppSelector } from "app/hooks";
import { selectUser } from "app/user/user";
import { useLocation } from "react-router";
import { useTranslation } from 'react-i18next';
import AvatarDropdown from "components/Header/AvatarDropdown";
import { NavLink } from "react-router-dom";
import SwitchDarkMode from "components/SwitchDarkMode/SwitchDarkMode";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import { isSafari,isIOS } from 'react-device-detect';


export interface MainNav1Props {
  isTop: boolean;
}

const MainNav1: FC<React.PropsWithChildren<React.PropsWithChildren<MainNav1Props>>> = ({ isTop }) => {
  const user: any = useAppSelector(selectUser);
  const pathname = useLocation().pathname;
  const [openSearch, setOpenSearch] = useState(true);
  const {t} = useTranslation();

  const [logged,setLogged]=useState(false);

  useEffect(() => {
    if (pathname === "/search") {
      setOpenSearch(false);
    }else {
      setOpenSearch(true)
    }
  }, [pathname]);

  useEffect(() => {
    getAccessToken().then((access_token: any) => {
        setLogged(true);
    }).catch((err) => {
            setLogged(false)
            if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
            {setLogged(false)}
    });
  }, [user]);



  return (
    <div
      className={`nc-MainNav1 relative z-50 ${
        isTop ? "onTop " : "notOnTop backdrop-filter"
      }`}
    >
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-4 sm:space-x-10 2xl:space-x-14">
          <Logo />
          <Navigation />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-1">
            {/*}<DarkModeContainer />*/}
            {openSearch && <SearchDropdown />}
            <div className="px-1" />
            {logged ? (
              <div className="flex-1 flex items-center justify-end gap-4 ">
              {/*}  <NotifyDropdown />*/}
                <AvatarDropdown />
              </div>
            ) : (
              <>
              {!isSafari && !isIOS && (
                <SwitchDarkMode />
              )}
              <NavLink
                      to="/login"
                      className="flex flex-wrap items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
               >

              <svg
                className=" w-6 h-6"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-2">{t('sign.in')}</span>
              </NavLink>
              </>

            )}
          </div>
          <div className="flex items-center xl:hidden gap-4">
            {logged ? (
                <>
               {/*} <NotifyDropdown className="relative"/>*/}
                <MenuBar />
                </>

            ):(
                <MenuBar />
            )}


          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
