// @ts-nocheck
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageLoading from "containers/PageLoading/PageLoading";
import { useTranslation } from 'react-i18next';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import SectionGridPosts from "../PageSearchFlight/SectionGridPostsFlights";
import { getUrl,getStateFromUrl } from 'helpers/url_handler';
import useFetch from 'hooks/useFetch';
import { useNavigate } from "react-router";
import ButtonSecondary from "components/Button/ButtonSecondary";
import NotFound from 'images/Gonnaway_NotFound.png';
import MultiCityFilterSearch from 'components/SearchPropUI/Flight/MultiCity/MultiCityFilterSearchView';
import MultiCityFilterSearchDark from 'components/SearchPropUI/Flight/MultiCity/MultiCityFilterSearchViewDark';
import ListingWrapper from 'components/MapComponent/Listing.style';
import Sticky from 'react-stickynode';
import Toolbar from 'components/Toolbar/Toolbar';
import allCountriesList from "data/jsons/__countries.json";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import {  useLocation } from 'react-router-dom';
import { useThemeMode } from "hooks/useThemeMode";
import HereMap from 'components/HereMapComponent/HEREMap';
import ncNanoId from "utils/ncNanoId";

export interface PageMultiCityDetailsProps {
  className?: string;
}

const PageMultiCityDetails: FC<PageMultiCityDetailsProps> = ({ className = "" }) => {
  const history = useNavigate();
  const {t} = useTranslation();
  const {isDarkMode} = useThemeMode();
  const location=useLocation();
  const query=location.state.query;
  const userDestination=location.state.destinations;
  const [resultsData,setResultsData]=useState<any>("");
  const [flights,setFlights]=useState<any>([]);
  const [bkflights,setBkFlights]=useState<any>([]);
  const [showPosts, setShowPosts] = useState(10);
  const [filterLoading, setFilterLoading] = useState(false);

  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filterFH") },
    {id: 1, name: t('filter.best') },
    {id: 2, name: t('filter.cheapest') },
    {id: 3, name: t('filter.duration') },

  ];
  const [selected, setSelected] = useState(FILTERS[1]);

/*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/

  /*RETRIEVE DATA MANAGEMENT*/

    let url="/flights"+location.search;
    const { loading, error, data }= useFetch(url);
    let apiParams = getStateFromUrl(location);

    function minFee(flight:any){
        let fee=flight.flight_prices[0].price_per_passenger
        for (let key in flight.flight_prices){
            if(flight.flight_prices[key].price_per_passenger<fee){
                fee=flight.flight_prices[key].price_per_passenger;
            }
        }
        return Math.round(fee);
  }


    useEffect(() => {
        if (data) {
            if(data[0]){
                let flightsTemp: any = [];
                let idF=0;


                data.map((flight: any) => {
                    flightsTemp.push({
                                    id:idF,
                                    airline_icon:flight.airline_icon,
                                    airline_code:flight.airline_code,
                                    amount_to_pay:flight.amount_to_pay,
                                    buy_link: flight.buy_link,
                                    departure_stopovers:flight.departure_stopovers,
                                    discount_per_passenger:flight.discount_per_passenger,
                                    duration:flight.duration,
                                    fees_per_passenger:flight.fees_per_passenger,
                                    flight_prices:flight.flight_prices,
                                    itineraries:flight.itineraries,
                                    price_per_passenger:flight.price_per_passenger,
                                    provider:flight.provider,
                                    return_stopovers:flight.return_stopovers,
                                    taxes_per_passenger:flight.taxes_per_passenger,
                                    trip_type:flight.trip_type,
                                    minFee:minFee(flight)


                               });
                               idF++;
                });

                setFlights(flightsTemp);
                setBkFlights(flightsTemp);
            }
        }
    },[data]);
  /*END*/


    //// gestione del refresh
    let timeLastMove = new Date().getTime();
    let timeCurrentMove;
    const setActivityTime = (e) => {
      timeCurrentMove = new Date().getTime();
      if(timeCurrentMove-timeLastMove>=600000)
      {
          window.location.reload(true);
          timeLastMove=new Date().getTime();

      }
      else
      {
         timeLastMove=new Date().getTime();
      }
    }
    document.body.addEventListener("mousemove", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);

    /*manage show more card*/
      const showMore = () =>
      {
        setShowPosts(showPosts*2);
      }
    /*end*/

    /*CHANGE SORT*/
    const changeSelectedFilter = (e:any) =>
    {
         setTimeout(() => {
            setFilterLoading(false);
          }, 500);
          setFilterLoading(true);
          setSelected(e);
                switch (e.id){
                    default: setFlights(bkflights);break;
                    case 1: flights.sort((a:any, b:any) => (a.duration*a.minFee  > b.duration*b.minFee) ? 1 : -1);break;
                    case 2: flights.sort((a:any, b:any) => (a.minFee > b.minFee) ? 1 : -1);break;
                    case 3: flights.sort((a:any, b:any) => (a.duration > b.duration) ? 1 : -1);break;
                         break;
                }
    }
    /*END*/

  /// lat and long cities
  function nCountry(){
    let tempArray=[];
    let latLongArray=[]
    for(let key in flights[0].route){
       tempArray.push(flights[0].route[key].countryFrom.code);
    }

    for(let key in tempArray){
        let codTemp=allCountriesList.filter((item:any) => {return item.alpha === tempArray[key];});
        latLongArray.push({
            country_id:codTemp[0].country_id,
            name:codTemp[0].name,
            flag:codTemp[0].flag,
            postcount:codTemp[0].postcount,
            lat:codTemp[0].lat,
            lng:codTemp[0].lng
        });
    }
   return latLongArray;
  }

  function distanceItinerary(){
    let distance=0
    for(let key in flights[0].route){
        distance+=flights[0].route[key].distance
    }
    return Math.round(distance);
  }




  if (loading) return <PageLoading />;
  return (
    <div className={`nc-PageCategory min-h-screen ${className}`} data-nc-id="PageCategory">
         <Helmet><title>Flights || Gonnaway</title></Helmet>
         <div className=" space-y-16 lg:space-y-28 right-0 mt-10 border-t">

         <ListingWrapper>
               <Sticky top={0} innerZ={30} activeClass="isHeaderSticky">
                  <div className="bg-white dark:bg-neutral-900">
                      <Toolbar
                         left={
                                    isDarkMode ? (
                                        <MultiCityFilterSearchDark
                                            query={query}
                                            destinations={userDestination}
                                        />
                                    ):(
                                        <MultiCityFilterSearch
                                            query={query}
                                            destinations={userDestination}
                                        />
                                    )
                               }
                         right={
                                    <div className={`nc-ArchiveFilterListBox md:-mr-1 `} data-nc-id="ArchiveFilterListBox">
                                        <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                                        <div className="relative md:min-w-[200px]">
                                          <Listbox.Button as={"div"}>
                                            <ButtonDropdown>{selected.name}</ButtonDropdown>
                                          </Listbox.Button>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900  bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700 dark:text-neutral-100">
                                              {FILTERS.map((item: ListBoxItemType, index: number) => (
                                                <Listbox.Option
                                                  key={index+ncNanoId()}
                                                  className={({ active }) =>
                                                    `${
                                                      active
                                                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                                        : ""
                                                    } cursor-default select-none relative py-2 pl-10 pr-4`
                                                  }
                                                  value={item}
                                                >
                                                  {({ selected }) => (
                                                    <>
                                                      <span
                                                        className={`${
                                                          selected ? "font-medium" : "font-normal"
                                                        } block truncate`}
                                                      >
                                                        {item.name}
                                                      </span>
                                                      {selected ? (
                                                        <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                    </Listbox>
                                    </div>


                                }
                      />
                  </div>
               </Sticky>
             </ListingWrapper>



            {flights.length===0 &&(
                <div className="w-full bg-primary-6000 flex items-center justify-center mx-auto text-neutral-100 h-20 align-middle">
                    <b><span className="mt-10 text-sm md:text-md lg:text-xl">{t('labelHotel.noShow')}</span></b>
                </div>
            )}

            {flights.length===0 && (
                <div className="relative">
                    <center><h2>{t('filterNoDataFlight')}</h2>
                     <img src={NotFound} width="625px" height="500px"
                         alt="Not Found page banner"/></center>
                </div>
            )}

           {flights[0] && (
                 windowSize.innerWidth > 1024 ? (
                     <div className="w-full flex flex-row gap-6">

                        <div className="relative h-full overflow-y-auto flex flex-col flex-grow overflow-hidden w-full"  style={{maxHeight:"400vh"}} >
                            { !filterLoading ? (
                                <div>

                                    <SectionGridPosts
                                      className=""
                                      postCardName="cardMultiCity"
                                      flights={flights.filter((_:any, i:any) => i < showPosts)}
                                      destinations={userDestination}
                                      gridClass={"container grid grid-cols-3 gap-2 "}
                                      headingIsCenter
                                    />

                                    {flights.length > 9 && flights.length>showPosts &&  (
                                         <div className="text-center mx-auto mt-8 md:mt-10">
                                            <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                         </div>
                                     )}
                                     <div className="mt-10" />
                                </div>
                            ):(<PageLoading />)}
                         </div>
                     </div>


                 ):(
                         <div>



                          <div className="relative container w-full mt-2 ">
                            { !filterLoading ? (
                                <div className="mt-10  h-full max-h-screen	 overflow-y-auto flex flex-col flex-grow ">
                                    <SectionGridPosts
                                      className=""
                                      postCardName="cardMultiCity"
                                      flights={flights.filter((_:any, i:any) => i < showPosts)}
                                      destinations={userDestination}
                                      gridClass="mt-5  gap-6 lg:gap-8 mt-5"
                                      headingIsCenter
                                    />

                                    {flights.length > 9 && flights.length>showPosts &&  (
                                         <div className="text-center mx-auto mt-8 md:mt-10">
                                            <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                         </div>
                                     )}
                                     <div className="mt-10" />
                                </div>
                            ):(<PageLoading />)}
                         </div>
                       </div>



                 )
             )}
         </div>
    </div>


  );
};

export default PageMultiCityDetails;
