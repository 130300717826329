import { FC, useEffect, useState } from "react";
import Card3 from "components/Card3/Card3";
import Heading from "components/Heading/Heading";
import { PostAuthorType, PostDataType, TaxonomyType } from "data/types";
import Card4 from "components/Card4/Card4";
import Card7 from "components/Card7/Card7";
import Card9 from "components/Card9/Card9";
import Card10 from "components/Card10/Card10";
import Card11 from "components/Card11/Card11";
import Card14 from "components/Card14/Card14";
import twFocusClass from "utils/twFocusClass";
import { useTranslation } from 'react-i18next';
import { isMobile} from 'react-device-detect';


export interface SectionLatestPostsProps {
  posts?: any;
  heading?: string;
  subheading?:string;
  widgetPosts?: PostDataType[];
  categories?: TaxonomyType[];
  tags?: TaxonomyType[];
  authors?: PostAuthorType[];
  gridClass?: string;
  className?: string;
  postCardName?:
    | "card3"
    | "card4"
    | "card7"
    | "card9"
    | "card10"
    | "card11"
    | "card14"
}

const SectionLatestPosts: FC<React.PropsWithChildren<React.PropsWithChildren<SectionLatestPostsProps>>> = ({
  posts = [],
  postCardName = "card3",
  gridClass = "",
  className = "",
  heading = "",
  subheading = ""
}) => {


  const [pages, setPages] = useState<any>([]);

  const [page, setPage] = useState<any>({ start: 0, end: 3 });
  const [currentPage, setCurrentPage] = useState(1);

  const pageChange = (e: any) => {
    var number = parseInt(e.currentTarget.id);
    setCurrentPage(number);
    let position=window.location.toString().split("/");
    window.scrollTo({
          top: isMobile ? window.pageYOffset-900 : window.pageYOffset-600,
          behavior: "smooth",
        });

  };
  const {t} = useTranslation();


  useEffect(() => {
    switch (currentPage) {
      case 1:
        return isMobile ? setPage({ start: 0, end: 3 }) : setPage({ start: 0, end: 6 })
      case 2:
        return isMobile ? setPage({ start: 3, end: 6 }) : setPage({ start: 6, end: 12 })
      case 3:
        return isMobile ? setPage({ start: 6, end: 9 }) : setPage({ start: 12, end: 18 })
      default:
        return isMobile ? setPage({ start: 3, end: 6 }) : setPage({ start: 6, end: 12 })
    }
  }, [currentPage]);

   useEffect(() => {
     if(isMobile){
         if (posts.length <= 3) {
          setPages([]);
        } else if (posts.length <= 6) {
          setPages([1, 2]);
        } else if (posts.length <= 9) {
          setPages([1, 2, 3]);
        } else {setPages([1, 2, 3]);}
     }else{
        if (posts.length <= 6) {
          setPages([]);
        } else if (posts.length <= 12) {
          setPages([1, 2]);
        } else if (posts.length <= 18) {
          setPages([1, 2, 3]);
        } else {setPages([1, 2, 3]);}
     }
  }, [posts]);


  const renderCard = (post: PostDataType) => {

    switch (postCardName) {
      case "card3":
        return (
          <Card3
            key={post.id}
            className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]"
            post={post}
          />
        );
      case "card4":
        return <Card4 key={post.id} post={post} />;
      case "card7":
        return (
          <Card7 key={post.id} post={post} ratio="aspect-w-3 aspect-h-5" />
        );
      case "card9":
        return <Card9 key={post.id} post={post} />;
      case "card10":
        return <Card10 key={post.id} post={post} />;
      case "card11":
        return <Card11 key={post.id} post={post} />;
      case "card14":
        return <Card14 key={post.id} post={post} />;
      default:
        return null;
    }
  };

  const postsSort = posts.filter(
    (_: any, i: any) => i >= page.start && i < page.end
  );

  return (
    <>
      {/* <div className="w-full space-y-7 mb-24" >
        <WidgetTags tags={tagsSort} />
       <WidgetCategories categories={categoriesDemo} />
      </div>*/}
      <div
        className={`nc-SectionLatestPosts relative  ${className}`}
        id="latest-articles"
      >
        <div className="flex flex lg:flex-col">
          <div id="latest-articles">
            <h2 className={`text-3xl md:text-4xl font-semibold`}>
              {(heading!=="") ? heading : t('article.latest')}
            </h2>

              <p className="mt-2 md:mt-3 mb-5 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400" style={{textAlign:"justify"}}>
                {(subheading!=="") ? subheading : t("sectionLatest.subheading")}
              </p>



            <div
              className={`grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8  ${gridClass}`}
            >
              {postsSort.map((post: any) => renderCard(post))}
            </div>
            <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              <nav
                className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
              >
                {!postsSort[0] ? (
                  <span>{t('article.issue')}</span>
                ) : (
                  pages &&
                  pages.map((page: any) => (
                    <div key={page}>
                      {page === currentPage ? (
                        <span
                          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
                        >
                          {page}
                        </span>
                      ) : (
                        <button
                          className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                          id={`${page}`}
                          onClick={pageChange}
                        >
                          {page}
                        </button>
                      )}
                    </div>
                  ))
                )}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionLatestPosts;
