import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionLatestPosts from "./SectionLatestPosts";
import Heading from "components/Heading/Heading";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero2 from "./SectionHero2";
import HereMap from "./MapHomeContainer";
import SectionHero3 from "./SectionHero3";
import SlideImage from "images/Kohn_Slide.jpg";
import { useAppSelector } from "app/hooks";
import FetchData from '../../FetchData';



import {
  selectTopArticles,
  selectTopCategories,
  selectLatestArticles,
  selectTopAuthors,
} from "app/content/content";
import { useTranslation } from 'react-i18next';
//import SectionSearchFlight from "components/SectionSearchFlight/SectionSearchFlight";

const PageHome: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const topArticles: any = useAppSelector(selectTopArticles);
  const topCategories: any = useAppSelector(selectTopCategories);
  const latestArticles: any = useAppSelector(selectLatestArticles);
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const {t} = useTranslation();
 // const checkValidityUser:any= checkValidityToken().then(result=>console.log(result.data));

//  await iAmAPromise.then(result => result.data);
//console.log(checkValidityUser);
  //if(checkValidityUser) dispatch(removeUser());

  useEffect(() => {

    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-orange-grey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  return (
    <div className="nc-PageHome overflow-hidden relative">
      <Helmet>
        <title>Gonnaway.com || Community Travel</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* ======= START CONTAINER ============= */}
      <div className="relative">
        {/* === SECTION 1 === */}
        <SectionHero2
          href="/login"
          youtubeID=""
          rightImg={SlideImage}
          heading={t('section1.heading')}
          subHeading={t('section1.subheading')}
        />
        <div className="container relative">

          {/* === SECTION 2 === */}
          <div className="pb-16 lg:pb-28" style={{ marginTop: "50px" }}>
            <Heading
                desc={t("sectionHereMap.subheading")}
            >
            {t('section2.heading')} 🚀️</Heading>
            <HereMap />
          </div>

          {/* === SECTION 3 === */}
          <Heading
                desc={t("sectionPreferred.subheading")}
            >
            {t('section3.heading')} ❤️</Heading>
          <SectionHero3 className="pb-16 lg:pb-28" posts={topArticles} />

          {/* === SECTION 4 ===  Top trending topics*/}
          {topCategories && topCategories[0] && (
            <SectionSliderNewCategories
              className="pb-16 lg:pb-28"
              heading={t('section4.heading')}
              categoryCardType="card5"
              itemPerRow={4}
              subHeading={t('section4.subheading',{topCat: topCategories.length})}
              categories={topCategories}
            />
          )}
          {/* === SECTION 5 === */}

              <SectionLatestPosts
                className="pb-16 "
                posts={latestArticles}
                postCardName="card14"
                gridClass="sm:grid-cols-2"

              />




          {/* === SECTION 6 === */}
          <div className="relative py-16">
            <BackgroundSection />
            {topAuthors && topAuthors[0] && (
                  <SectionSliderNewAuthors
                    heading={t('section6.heading')}
                    subHeading={t('section6.subheading')}
                    authors={topAuthors}
                  />
            )}
          </div>

          {/* === SECTION 7 === */}
          <SectionSubscribe2 className="py-16 lg:py-28" />


        </div>

      </div>

    </div>
  );
};

export default PageHome;






