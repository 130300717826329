// @ts-nocheck
import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import PageLoading from "containers/PageLoading/PageLoading";
import { useTranslation } from 'react-i18next';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import SectionGridPosts from "./SectionGridPostsFlights";
import { getStateFromUrl } from 'helpers/url_handler';
import useFetch from 'hooks/useFetch';
import { FlightType, FlightSearch,AirlineList} from "data/types";
import ButtonSecondary from "components/Button/ButtonSecondary";
import NotFound from 'images/Gonnaway_NotFound.png';
import MobileCategorySearch from 'components/SearchPropUI/Flight/Mobile/MobileSearchView';
import MobileCategorySearchDark from 'components/SearchPropUI/Flight/Mobile/MobileSearchViewDark';
import ListingWrapper from 'components/MapComponent/Listing.style';
import Sticky from 'react-stickynode';
import Toolbar from 'components/Toolbar/Toolbar';
import CategorySearch from 'components/SearchPropUI/Flight/CategorySearch';
import CategorySearchDark from 'components/SearchPropUI/Flight/CategorySearchDark';
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {  useLocation } from 'react-router-dom';
import { useThemeMode } from "hooks/useThemeMode";
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import airportsJson from "data/jsons/airports.json";
import {FaCheck} from 'react-icons/fa';



export interface PageCategoryProps {
  className?: string;
}

const PageFlightDetails: FC<React.PropsWithChildren<React.PropsWithChildren<PageCategoryProps>>> = ({ className = "" }) => {
  const {t} = useTranslation();
  const {isDarkMode} = useThemeMode();


  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filterFH") },
    {id: 1, name: t('filter.best') },
    {id: 2, name: t('filter.cheapest') },
    {id: 3, name: t('filter.duration') },
  ];
  const [selected, setSelected] = useState(FILTERS[1]);
  const [dataFlights, setDataFlights] = useState<FlightType[]>([]);
  const [dataFlightsBk, setDataFlightsBk] = useState<FlightType[]>([]);

  const [showPosts, setShowPosts] = useState(30);
  const [listAirline, setListAirline] = useState<AirlineList[]>([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [noResult, setNoResult] = useState(false);
  const [budgetData, setBudgetData] = useState(3000);


/*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/



   const showMore = () =>
  {
    setShowPosts(showPosts*2);
  }

///receive data
    const location=useLocation();
    let url="/flights"+location.search;
    const { loading, error, data }= useFetch(url);
    let apiParams = getStateFromUrl(location);

    let searchData: FlightSearch = {
        departAirport: apiParams.departure_airport,
        arrivalAirport: apiParams.arrival_airport,
        city_departure:apiParams.city_departure,
        city_return:apiParams.city_return,
        departDate:apiParams.outbound_date,
        arrivalDate:apiParams.return_date,
        adults:parseInt(apiParams.adults_count),
        children:parseInt(apiParams.child_counts),
        infants:parseInt(apiParams.infants_count),
        currency:apiParams.currency,
        with_return:apiParams.with_return,
        budget:budgetData,
        range_departure:apiParams.range_departure || "0,23",
        range_return:apiParams.range_return || "0,23",
        adult_hold_bag:parseInt(apiParams.adult_hold_bag),
        adult_hand_bag:parseInt(apiParams.adult_hand_bag),
        class:apiParams.flight_class,
        max_return_stopovers:(apiParams.max_return_stopovers===null || apiParams.max_return_stopovers===undefined) ? 100 : parseInt(apiParams.max_return_stopovers),
        max_departure_stopovers:(apiParams.max_departure_stopovers===null || apiParams.max_departure_stopovers===undefined) ? 100 : parseInt(apiParams.max_departure_stopovers),
        airlines:apiParams.airlines || ""
    }

    let searchDataLocalStorage: FlightSearch = {
        departAirport: apiParams.departure_airport,
        arrivalAirport: apiParams.arrival_airport,
        city_departure:apiParams.city_departure,
        city_return:apiParams.city_return,
        departDate:apiParams.outbound_date,
        arrivalDate:apiParams.return_date,
        adults:parseInt(apiParams.adults_count),
        children:parseInt(apiParams.child_counts),
        infants:parseInt(apiParams.infants_count),
        currency:apiParams.currency,
        with_return:apiParams.with_return,
        range_departure:apiParams.range_departure || "0,23",
        range_return:apiParams.range_return || "0,23",
        adult_hold_bag:parseInt(apiParams.adult_hold_bag),
        adult_hand_bag:parseInt(apiParams.adult_hand_bag),
        class:apiParams.flight_class,
        max_return_stopovers:(apiParams.max_return_stopovers===null || apiParams.max_return_stopovers===undefined) ? 100 : parseInt(apiParams.max_return_stopovers),
        max_departure_stopovers:(apiParams.max_departure_stopovers===null || apiParams.max_departure_stopovers===undefined) ? 100 : parseInt(apiParams.max_departure_stopovers),

    }




    //// gestione del refresh
    let timeLastMove = new Date().getTime();
    let timeCurrentMove;
    const setActivityTime = (e) => {
      timeCurrentMove = new Date().getTime();
      if(timeCurrentMove-timeLastMove>=600000)
      {
          window.location.reload(true);
          timeLastMove=new Date().getTime();

      }
      else
      {
         timeLastMove=new Date().getTime();
      }
    }
    document.body.addEventListener("mousemove", setActivityTime);
    document.body.addEventListener("keypress", setActivityTime);

    let count;
    let lengthData=0;
    let durationAmount=0;
    for(let key in data){
       durationAmount=0;
       for(count=0;count<= data[key].itineraries.length-1;count++)
       {
                durationAmount=durationAmount+data[key].itineraries[count].duration;
       }
       data[key]["entireDuration"]=durationAmount;
       lengthData++;
    }

    function durationToTime(duration)
      {
        let time=duration;
        var hours = Math.floor(time /60)
        var minutes = Math.round(time % 60);
        if(minutes<10) {minutes='0'+minutes}
        return hours+":"+minutes+" h";
      }




    useEffect(() => {
    if (data) {
      if (data[0]) {
        let flights: FlightType[] = [];
        let flightsAirlineList: AirlineList[] = [];
        let idF=0;
        let withReturn=false;
        data.map((flight: any) => {
            if (flight.itineraries.length>1) withReturn=true;
            let departureFirst=Number.parseInt(flight.itineraries[0].flight_segments[0].departure_time.split(":")[0],10);
            let departureReturn=flight.itineraries.length > 1 ? Number.parseInt(flight.itineraries[1].flight_segments[0].departure_time.split(":")[0],10) : undefined;
            let rangeDepCheck=searchData.range_departure.split(",") || "0,23";
            let rangeArrCheck=searchData.range_return.split(",") || "0,23";
            let transport_classDep=flight.itineraries[0].flight_segments[0].transport_class;
            let transport_classRet=flight.itineraries.length > 1 ? flight.itineraries[1].flight_segments[0].transport_class : undefined;

            //evaluation diff Days in dep/ret
            let depTimeStamp=new Date(flight.itineraries[0].flight_segments[0].departure_timestamp*1000);
            let arrivalDateDep=new Date(flight.itineraries[0].flight_segments[flight.itineraries[0].flight_segments.length-1].arrival_timestamp*1000);
            let retTimeStamp=flight.itineraries.length > 1 ? new Date(flight.itineraries[1].flight_segments[0].departure_timestamp*1000) : undefined;
            let arrivalDateRet=flight.itineraries.length > 1 ? new Date(flight.itineraries[1].flight_segments[flight.itineraries[1].flight_segments.length-1].arrival_timestamp*1000):undefined;
            let depDurTemp=((arrivalDateDep.getTime() - depTimeStamp.getTime())/(1000*3600))*60
            let retDurTemp=flight.itineraries.length > 1 ? ((arrivalDateRet.getTime() - retTimeStamp.getTime())/(1000*3600))*60 : undefined;
            let departure_flight_available_duration=durationToTime(depDurTemp);
            let return_flight_available_duration = flight.itineraries.length > 1 ? durationToTime(retDurTemp) : undefined;


            if(withReturn)
            {
                                let minPrice=Math.round(flight.amount_to_pay);
                                if(flight.flight_prices){
                                    for (let key in flight.flight_prices){
                                        if(flight.flight_prices[key].amount_to_pay<minPrice){minPrice=Math.round(flight.flight_prices[key].amount_to_pay);}
                                    }
                                }

                                flights.push({
                                    id: idF++,
                                    api_provider:flight.api_provider,
                                    buy_link: flight.buy_link,
                                    fee: Math.round(flight.amount_to_pay),
                                    itineraries: flight.itineraries,
                                    href:flight.airline_icon,
                                    entireDuration:flight.entireDuration,
                                    agency_id:flight.agency_id,
                                    agency_label:flight.agency_label,
                                    airline_id:flight.airline_id,
                                    airline_label:flight.airline_label,
                                    is_airline:flight.is_airline,
                                    flight_prices:flight.flight_prices,
                                    minFee:minPrice || Math.round(flight.amount_to_pay),
                                    durationFirstPath:departure_flight_available_duration,
                                    durationReturn:return_flight_available_duration || undefined,
                                    transport_classDep:transport_classDep || undefined,
                                    transport_classRet:transport_classRet || undefined,
                                    city_departure:apiParams.city_departure,
                                    city_return:apiParams.city_return,
                                    return_stopovers:flight.return_stopovers,
                                    departure_stopovers:flight.departure_stopovers

                               });
                               let countAir=0;
                                flight.itineraries.map((item: any, index: number) => {
                                    item.flight_segments.map((seg:any,i:number) => {
                                        flightsAirlineList.push({
                                            icon:seg.display_airline_code,
                                            name: seg.display_airline_name,
                                            checked:false
                                        });
                                        countAir++;
                                    });
                               });


            }else{

                                let minPrice=Math.round(flight.amount_to_pay);
                                if(flight.flight_prices){
                                    for (let key in flight.flight_prices){
                                        if(flight.flight_prices[key].amount_to_pay<minPrice){minPrice=Math.round(flight.flight_prices[key].amount_to_pay);}
                                    }
                                }
                                flights.push({
                                    id: idF++,
                                    api_provider:flight.api_provider || "",
                                    buy_link: flight.buy_link,
                                    fee: Math.round(flight.amount_to_pay),
                                    itineraries: flight.itineraries,
                                    href:flight.airline_icon,
                                    entireDuration:flight.entireDuration,
                                    agency_id:flight.agency_id,
                                    agency_label:flight.agency_label,
                                    airline_id:flight.airline_id,
                                    airline_label:flight.airline_label,
                                    is_airline:flight.is_airline,
                                    flight_prices:flight.flight_prices,
                                    minFee:minPrice || Math.round(flight.amount_to_pay),
                                    durationFirstPath:departure_flight_available_duration,
                                    durationReturn:return_flight_available_duration || undefined,
                                    transport_classDep:transport_classDep || undefined,
                                    transport_classRet:transport_classRet || undefined,
                                    city_departure:apiParams.city_departure,
                                    city_return:apiParams.city_return,
                                    departure_stopovers:flight.departure_stopovers
                                });

                               let countAir=0;
                                flight.itineraries.map((item: any, index: number) => {
                                    item.flight_segments.map((seg:any,i:number) => {
                                        flightsAirlineList.push({
                                            id:countAir,
                                            icon:seg.display_airline_code,
                                            name: seg.display_airline_name,
                                            checked:false

                                        });
                                        countAir++;
                                    });
                               });

            }
            });

        let flightsAirlineListUnique: AirlineList[] = [];

        flightsAirlineList.map((item:any) => {
            var findItem = flightsAirlineListUnique.find((x:any) => x.icon === item.icon);
            if (!findItem) flightsAirlineListUnique.push(item);
        });



        //airlines management within local storage
        const items=JSON.parse(localStorage.getItem('airlines_list'));
        if(items===null || items ===undefined){
            localStorage.setItem('searchData', JSON.stringify(searchDataLocalStorage));
            localStorage.setItem('airlines_list', JSON.stringify(flightsAirlineListUnique));
            setListAirline(flightsAirlineListUnique);

        }else{
            let sData=localStorage.getItem('searchData');
            if(sData!=JSON.stringify(searchDataLocalStorage)){
                localStorage.setItem('searchData', JSON.stringify(searchDataLocalStorage));
                localStorage.setItem('airlines_list', JSON.stringify(flightsAirlineListUnique));
                setListAirline(flightsAirlineListUnique);
            }

        }
        //



        flights.sort((a:any, b:any) =>
                        (
                             a.flight_prices[0].provider==="tp" ?
                                (b.flight_prices[0].provider==="tp" ?
                                    (a.minFee*(a.entireDuration) > b.minFee*(b.entireDuration) ? 1 : -1) :
                                        (a.minFee*(a.entireDuration) > b.minFee*(b.entireDuration/60) ? 1 : -1)) :
                                            (b.flight_prices[0].provider==="tp" ?
                                                (a.minFee*(a.entireDuration/60) > b.minFee*(b.entireDuration) ? 1 : -1) :
                                                    (a.minFee*(a.entireDuration/60) > b.minFee*(b.entireDuration/60) ? 1 : -1))
                         ));



        setDataFlights(flights);
        setDataFlightsBk(flights);

        if(flights.length>0){
            let tempBudget=Math.max(...flights.map(o => o.fee));
            setBudgetData(tempBudget);

        }

      }
    }

  }, [data]);


  if (loading) return <PageLoading />;
  //if (error) return ();

  const changeSelectedFilter = (e:any) =>
  {
         setTimeout(() => {
            setFilterLoading(false);
          }, 500);
          setFilterLoading(true);
         setSelected(e);

                switch (e.id){
                default: setDataFlights(dataFlightsBk);
                        break;
                case 1: dataFlights.sort((a:any, b:any) =>
                        (
                             a.flight_prices[0].provider==="tp" ?
                                (b.flight_prices[0].provider==="tp" ?
                                    (a.minFee*(a.entireDuration) > b.minFee*(b.entireDuration) ? 1 : -1) :
                                        (a.minFee*(a.entireDuration) > b.minFee*(b.entireDuration/60) ? 1 : -1)) :
                                            (b.flight_prices[0].provider==="tp" ?
                                                (a.minFee*(a.entireDuration/60) > b.minFee*(b.entireDuration) ? 1 : -1) :
                                                    (a.minFee*(a.entireDuration/60) > b.minFee*(b.entireDuration/60) ? 1 : -1))
                         ));

                        break;
                case 2: dataFlights.sort((a:any, b:any) => (a.fee > b.fee) ? 1 : -1);
                        break;
                case 3: dataFlights.sort((a, b) => (a.entireDuration > b.entireDuration) ? 1 : -1);
                        break;
              }
  }


   const filterByBudget = (budgetFee:number,flights:any) =>
   {
      let resNull=false;
      setNoResult(false);
      setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
      }, 1500);
      setFilterLoading(true);
     let tempData=flights.filter((item:any)=> item.minFee < budgetFee);
     if(tempData.length<1){resNull=true;}
     return tempData;
   }

   const filterByAirlines = (nameAir:any) =>
   {
     let resNull=false;
     setNoResult(false);
     let withReturn= dataFlights[0].itineraries.length>1 ? true : false;
     setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
      }, 1500);
      setFilterLoading(true);
      let tempData;
      if(withReturn){
        tempData=dataFlightsBk.filter((item:any)=> nameAir.includes(item.itineraries[0].flight_segments[0].display_airline_name || item.itineraries[1].flight_segments[0].display_airline_name));
        if(tempData.length<1){resNull=true;}

      }else{
        tempData=dataFlightsBk.filter((item:any)=> nameAir.includes(item.itineraries[0].flight_segments[0].display_airline_name));
        if(tempData.length<1){resNull=true;}

      }
      return tempData;
   }


   function tpToTime(tp,airport_code)
  {

       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let timestampFormat=moment(tp*1000).format();
       let formattedTime=momentTimezone.tz(timestampFormat,tzCod[0].timezone).format('HH:mm')
       return formattedTime;
  }


   const filterByTime = (dep,arr,flightsArray:any) =>
   {
       let resNull=false;
       setNoResult(false);
       setTimeout(() => {
        setFilterLoading(false);
        if(resNull){setNoResult(true);}
       }, 1500);
      setFilterLoading(true);
       let withReturn= dataFlights[0].itineraries.length>1 ? true : false;
       let flights: FlightType[] = [];
       if(flightsArray){

        flightsArray.map((flight: any) => {
            let departureFirst=Number.parseInt(tpToTime(flight.itineraries[0].flight_segments[0].departure_timestamp,flight.itineraries[0].flight_segments[0].departure_airport_code).split(":")[0],10);
            let departureReturn=flight.itineraries.length > 1 ? Number.parseInt(tpToTime(flight.itineraries[1].flight_segments[0].departure_timestamp,flight.itineraries[1].flight_segments[0].departure_airport_code).split(":")[0],10) : undefined;

            if(withReturn)
            {
                if(departureFirst >= Number.parseInt(dep[0],10) && departureFirst <= Number.parseInt(dep[1],10))
                {
                    if(departureReturn>=Number.parseInt(arr[0],10) && departureReturn<=Number.parseInt(arr[1],10))
                    {
                        flights.push(flight);
                    }
                }
            }else{
                if(departureFirst >= Number.parseInt(dep[0],10) && departureFirst <= Number.parseInt(dep[1],10))
                {
                    flights.push(flight);
                }

            }
        });

      }
      if(flights.length<1){resNull=true;}
      return flights;


   }

   const filterData = (budgetFee:number,nameAir:any,dep:any,arr:any) =>
   {
        let flightsTemp=dataFlightsBk;
        if(nameAir.length>0){flightsTemp=filterByAirlines(nameAir);}
        if(flightsTemp.length>0 && budgetFee>0){flightsTemp=filterByBudget(budgetFee,flightsTemp);}
        if(flightsTemp.length>0 && dep!==null && arr !==null){flightsTemp=filterByTime(dep,arr,flightsTemp);}
        if(flightsTemp.length>0){setDataFlights(flightsTemp);}
   }




  return (
    <div className={`nc-PageCategory min-h-screen ${className}`} data-nc-id="PageCategory">
         <Helmet><title>Flights || Gonnaway</title></Helmet>
         <div className=" space-y-16 lg:space-y-28 right-0 mt-10 border-t">
            {noResult &&(
                <div className="w-full bg-primary-6000 flex items-center justify-center mx-auto text-neutral-100 h-20 align-middle">
                    <b><span className="mt-10 text-sm md:text-md lg:text-xl">{t('labelHotel.noShow')}</span></b>
                </div>
            )}

            {dataFlights.length===0 && (
                <div className="relative">
                    <center><h2>{t('filterNoDataFlight')}</h2>
                     <img src={NotFound} width="625px" height="500px"
                         alt="Not Found page banner"/></center>
                </div>
            )}

           {dataFlights[0] && (
                 windowSize.innerWidth > 1024 ? (
                     <div className="w-full flex flex-row gap-6">


                        <div className=" border-r" style={{width:"25vw"}}>
                            {isDarkMode ? (
                                <CategorySearchDark
                                    dataSearch={searchData}
                                    listAir={listAirline}
                                    budgetData={budgetData}
                                    changeSelectedFilter={changeSelectedFilter}
                                    filterData={filterData}
                                />
                            ):(
                                <CategorySearch
                                    dataSearch={searchData}
                                    listAir={listAirline}
                                    budgetData={budgetData}
                                    changeSelectedFilter={changeSelectedFilter}
                                    filterData={filterData}
                                />
                            )}
                        </div>

                        <div className="relative h-full overflow-y-auto flex flex-col flex-grow overflow-hidden "  style={{width:"70vw",maxHeight:"400vh"}} >
                            { !filterLoading ? (
                                <div>

                                    <SectionGridPosts
                                      className=""
                                      postCardName="cardMobile"
                                      flights={dataFlights.filter((_:any, i:any) => i < showPosts)}
                                      gridClass="container ml-1 grid grid-cols-2 gap-2 "
                                      headingIsCenter
                                    />

                                    {dataFlights.length > 9 && dataFlights.length>showPosts &&  (
                                         <div className="text-center mx-auto mt-8 md:mt-10">
                                            <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                         </div>
                                     )}
                                     <div className="mt-10" />
                                </div>
                            ):(<PageLoading />)}
                         </div>
                     </div>


                 ):(
                         <div>
                            <ListingWrapper>
                            <Sticky top={0} innerZ={30} activeClass="isHeaderSticky">
                                <div className="bg-white dark:bg-neutral-900">
                                    <Toolbar
                                      left={
                                        isDarkMode ? (

                                                <MobileCategorySearchDark
                                                        dataSearch={searchData}
                                                        listAir={listAirline}
                                                        budgetData={budgetData}
                                                        changeSelectedFilter={changeSelectedFilter}
                                                        filterData={filterData}
                                                    />
                                              ):(
                                                <MobileCategorySearch
                                                        dataSearch={searchData}
                                                        listAir={listAirline}
                                                        budgetData={budgetData}
                                                        change SelectedFilter={changeSelectedFilter}
                                                        filterData={filterData}
                                                    />
                                              )

                                      }
                                      right={
                                        <div
                                              className={`nc-ArchiveFilterListBox md:-mr-1 `}
                                              data-nc-id="ArchiveFilterListBox"
                                    >

                                    <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                                        <div className="relative md:min-w-[200px]">
                                          <Listbox.Button as={"div"}>
                                            <ButtonDropdown>{selected.name}</ButtonDropdown>
                                          </Listbox.Button>
                                          <Transition
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900  bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700 dark:text-neutral-100">
                                              {FILTERS.map((item: ListBoxItemType, index: number) => (
                                                <Listbox.Option
                                                  key={index}
                                                  className={({ active }) =>
                                                    `${
                                                      active
                                                        ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                                        : ""
                                                    } cursor-default select-none relative py-2 pl-10 pr-4`
                                                  }
                                                  value={item}
                                                >
                                                  {({ selected }) => (
                                                    <>
                                                      <span
                                                        className={`${
                                                          selected ? "font-medium" : "font-normal"
                                                        } block truncate`}
                                                      >
                                                        {item.name}
                                                      </span>
                                                      {selected ? (
                                                        <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                                          <FaCheck className="w-5 h-5"  />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              ))}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                    </Listbox>
                            </div>


                                      }
                                    />
                                </div>
                                </Sticky>
                        </ListingWrapper>


                          <div className="relative container w-full mt-2 ">
                            { !filterLoading ? (
                                <div className="mt-10  h-full max-h-screen	 overflow-y-auto flex flex-col flex-grow ">


                                    <SectionGridPosts
                                      className=""
                                      postCardName="cardMobile"
                                      flights={dataFlights.filter((_:any, i:any) => i < showPosts)}
                                      gridClass="mt-5 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-8 mt-5"
                                      headingIsCenter
                                    />

                                    {dataFlights.length > 9 && dataFlights.length>showPosts &&  (
                                         <div className="text-center mx-auto mt-8 md:mt-10">
                                            <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                                         </div>
                                     )}
                                     <div className="mt-10" />
                                </div>
                            ):(<PageLoading />)}
                         </div>
                       </div>



                 )
             )}
         </div>
    </div>

  );
};

export default PageFlightDetails;
