import React, { FC } from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

export interface SocialsShareProps {
  className?: string;
  itemClass?: string;
  socials?: any[];
}

export interface SocialType {
  id: string;
  name: string;
  icon: string;
  href: string;
}

const socialsSample = [
  { id: "Facebook", name: "Facebook", icon: "lab la-facebook-f", href: "#" },
  { id: "Twitter", name: "Twitter", icon: "lab la-twitter", href: "#" },
  { id: "Linkedin", name: "Linkedin", icon: "lab la-linkedin-in", href: "#" },
  { id: "Whatsapp", name: "Whatsapp", icon: "lab la-whatsapp", href: "#" },
];

export const SOCIALS_DATA = socialsSample;

const SocialsShare: FC<React.PropsWithChildren<React.PropsWithChildren<SocialsShareProps>>> = ({
  className = "grid gap-[6px]",
  itemClass = "w-7 h-7 text-base hover:bg-neutral-100",
  socials,
}) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <span
        key={index}
        className={`rounded-full leading-none flex items-center justify-center bg-white text-neutral-6000 ${itemClass}`}
        title={`Share on ${item.name}`}
      >
        {item.name === "Facebook" ? (
          <FacebookShareButton url={item.href}>
            <i className={item.icon}></i>
          </FacebookShareButton>
        ) : item.name === "Twitter" ? (
          <TwitterShareButton url={item.href}>
            <i className={item.icon}></i>
          </TwitterShareButton>
        ) : item.name === "Linkedin" ? (
          <LinkedinShareButton url={item.href}>
            <i className={item.icon}></i>
          </LinkedinShareButton>
        ) : (
          item.name === "Whatsapp" && (
            <WhatsappShareButton url={item.href}>
              <i className={item.icon}></i>
            </WhatsappShareButton>
          )
        )}
      </span>
    );
  };

  return (
    <div className={`nc-SocialsShare ${className}`} data-nc-id="SocialsShare">
      {socials && socials.map(renderItem)}
    </div>
  );
};

export default SocialsShare;
