import { FC, useEffect, useState } from "react";
import { PostDataType } from "data/types";
import NcImage from "components/NcImage/NcImage";
import SingleContent from "./SingleContent";
import { CommentType } from "components/CommentCard/CommentCard";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { changeCurrentPage } from "app/pages/pages";
import SingleHeader from "./SingleHeader";
import SingleRelatedPosts from "./SingleRelatedPosts";
import { convertDate } from "utils/convertDate";
import useFetch from "hooks/useFetch";
import PageLoading from "containers/PageLoading/PageLoading";
import { selectTopArticles } from "app/content/content";
import isLiked from "utils/isLiked";
import { selectUser } from "app/user/user";
import FetchData from "FetchData";
import { useTranslation } from "react-i18next";
import * as env from 'env-var';
import {backendAddress} from "../../index";
import axios from "../../axios";
import { useLocation } from 'react-router-dom';
import Page404 from 'containers/Page404/Page404';
import Sidebar from "./Sidebar";




export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  tags: any;
  content: any;
  content_visit: any;
  content_moveon: any;
  content_meat: any;
  content_ospitality: any;
  comments: CommentType[];
}

const PageSingle: FC<React.PropsWithChildren<React.PropsWithChildren<PageSingleProps>>> = ({ className = "" }) => {
  const location = useLocation();
  let path = location.pathname.split("/");
  const articleId = path[3];

  let fetchDataHash = window.location.hash;
  const dispatch = useAppDispatch();
  const [article, setArticle] = useState<any>(false);
  const { user } = useAppSelector(selectUser);
  const { loading, error, data } = useFetch(`/articles/${articleId}`);
  const topArticles: any = useAppSelector(selectTopArticles);
  const [relatedPosts, setRelatedPosts] = useState<any>(topArticles);


  const { t } = useTranslation();

  useEffect(() => {

    if (data) {
      let splitAuthor,avatarHref;
      if(data.author_imgurl) { splitAuthor=data.author_imgurl.split("/"); }
      if(splitAuthor[0]==="authors"){avatarHref=`${backendAddress}/${data.author_imgurl}`;}else{avatarHref=data.author_imgurl;}
      const articleData: any = {
        id: data.article_id,
        featuredImage: backendAddress+"/"+data.imgurl,
        title: data.title,
        desc: data.excerpt,
        date: convertDate(data.created_date),
        content: data.content,
        content_visit : data.content_visit,
        content_moveon : data.content_moveon,
        content_meat : data.content_meat,
        content_ospitality : data.content_ospitality,
        href: `/blog/article/${data.article_id}`,
        commentCount: data.commentcount,
        tags: data.tags,
        readingTime: data.readingtime,
        bookmark: {
          isBookmarked: false,
        },
        country: data.country,
        city: data.city,
        like: isLiked(data.likes, user),
        author: {
          id: data.author_id,
          displayName: data.author_name,
          avatar: avatarHref,
          desc: data.author_desc,
          href: `/blog/author/${data.author_id}`,
        },
        categories: [
          {
            id: data.category_id,
            name: data.category_name,
            href: `/blog/category/${data.category_id}`,
            thumbnail: data.category_thumbnail,
            color: data.category_color,
          },
        ],
      };
      setArticle(articleData);

        let relArticles:any=[];

      for(let key in topArticles)
      {

        if(topArticles[key].categories[0].name===articleData.categories[0].name && topArticles[key].id !== articleData.id)
        {
            relArticles.push(topArticles[key]);
        }
      }
      setRelatedPosts(relArticles);

      dispatch(
        changeCurrentPage({ type: "/blog/article/:slug", data: articleData })
      );
    }

    return () => {
      dispatch(changeCurrentPage({ type: "/", data: {} }));
    };
  }, [data]);

  if (loading) return <PageLoading />;
  if (error) return (<Page404 />);



  return (
    <div
      className={`nc-PageSingle pt-8 lg:pt-16  ${className}`}
      data-nc-id="PageSingle"
    >
      {fetchDataHash === "#fetchData" && <FetchData />}
      {article && (
        <div>
          <header className="container rounded-xl">
            <div className="max-w-screen-md mx-auto">
              <SingleHeader pageData={article} />
            </div>
          </header>
          <NcImage
            containerClassName="container my-10 sm:my-12"
            className="object-cover w-full h-full rounded-xl"
            src={article.featuredImage}
            prevImageHorizontal
          />

            <div className="container flex flex-col my-10 lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
            <SingleContent articleData={article} />
          </div>
          <div className=" w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3">
            <Sidebar />
          </div>
        </div>

          {relatedPosts.length > 0 && (<SingleRelatedPosts relatedPosts={relatedPosts} />)}
        <div className="mt-10" />
        </div>
      )}
    </div>
  );
};



export default PageSingle;
