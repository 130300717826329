import React from 'react';
import debounce from 'lodash.debounce';
import MapContext from 'utils/mapUtils/map-context';
import { HEvents, events, Events } from 'utils/mapUtils/map-events';
import { usePlatform } from 'hooks/mapHooks/use-platform';
import { useScript } from 'hooks/mapHooks/use-script';
import { useLink } from 'hooks/mapHooks/use-link';
import MarkerMap from './Marker';
import ncNanoId from "utils/ncNanoId";
import "./HereMap.css";

export interface CountriesProps{
  name?: string;
  lat?: string;
  lng?: string;
  country_id?: number;
  flag?: string;

}

export interface HEREMapProps extends H.Map.Options, HEvents {
  apikey?: string;
  mapContainerId?: string;
  animateCenter?: boolean;
  animateZoom?: boolean;
  hidpi?: boolean;
  interactive?: boolean;
  secure?: boolean;
  rasterType?: keyof H.service.DefaultLayers['raster'];
  vectorType?: keyof H.service.DefaultLayers['vector']['normal'];
  stateCountry?:any;
  countries?:any;
  dataSearch?:any;
  personalDashboardMap?:boolean;
  homeMap?:boolean;
  hotelDashboardMap?:boolean;
  nomadCountry?:boolean;
  countriesNomad?:any;

}


export const HEREMap: React.FC<React.PropsWithChildren<React.PropsWithChildren<HEREMapProps>>> = ({
  animateCenter,
  animateZoom,
  apikey=process.env.REACT_APP_HEREAPI_SECRET_KEY as string,
  mapContainerId = 'map-container',
  center,
  hidpi,
  interactive,
  secure,
  zoom,
  rasterType,
  vectorType,
  children,
  stateCountry,
  countries,
  dataSearch,
  personalDashboardMap,
  homeMap,
  hotelDashboardMap,
  nomadCountry,
  countriesNomad,
  ...rest
}) => {
  const [map, setMap] = React.useState<H.Map | undefined>(undefined);
  const [behavior, setBehavior] = React.useState<
    H.mapevents.Behavior | undefined
  >(undefined);
  const [ui, setUi] = React.useState<H.ui.UI | undefined>(undefined);
  const debouncedResizeMap = debounce(resizeMap, 200);
  const [,] = useLink(
    'https://js.api.here.com/v3/3.1/mapsjs-ui.css',
    'map-styles',
  );
  const [coreLoaded] = useScript(
    'https://js.api.here.com/v3/3.1/mapsjs-core.js',
    'core',
  );
  const [serviceLoaded] = useScript(
    'https://js.api.here.com/v3/3.1/mapsjs-service.js',
    'service',
  );
  const [uiLoaded] = useScript(
    'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
    'ui',
  );
  const [mapeventsLoaded] = useScript(
    'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
    'mapevents',
  );
  const platform = usePlatform(
    {
      apikey,
      useHTTPS: secure === true,
    },
    coreLoaded && serviceLoaded && uiLoaded && mapeventsLoaded,
  );

  React.useEffect(() => {
    if (platform) {
      const defaultLayers = platform.createDefaultLayers({
        ppi: hidpi ? 320 : 72,
      });

      const mapElement = document.querySelector(`#${mapContainerId}`);

      let customLayer;
      if (rasterType) {
        customLayer = defaultLayers.raster[rasterType].map;
      } else if (vectorType) {
        customLayer = defaultLayers.vector.normal[vectorType];
      } else {
        customLayer = defaultLayers.raster.normal.map;
      }

      if (mapElement && !map) {
        const newMap = new H.Map(mapElement, customLayer, {
          center,
          zoom,
          pixelRatio: hidpi ? 2 : 1,
        });
        setMap(newMap);
        if (interactive) {
          const newBehavior = new H.mapevents.Behavior(
            new H.mapevents.MapEvents(newMap),
          );

          const newUi = H.ui.UI.createDefault(newMap, defaultLayers);
          setBehavior(newBehavior);
          setUi(newUi);


        }
      }

      if (typeof window !== 'undefined') {
        window.addEventListener('resize', debouncedResizeMap);
      }
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', debouncedResizeMap);
      }
    };
  }, [
    center,
    debouncedResizeMap,
    hidpi,
    interactive,
    map,
    mapContainerId,
    platform,
    rasterType,
    vectorType,
    zoom,
  ]);

  React.useEffect(() => {
    if (map) {
      Object.entries(events).forEach(([event, hereEvent]) => {
        const e = rest[event as keyof Events];
        if (typeof e === 'function') {
          map.addEventListener(hereEvent, e);
        }
      });
    }
    return () => {
      if (map) {
        Object.entries(events).forEach(([event, hereEvent]) => {
          const e = rest[event as keyof Events];
          if (typeof e === 'function') {
            map.removeEventListener(hereEvent, e);
          }
        });
      }
    };
  }, [map, rest]);

  React.useEffect(() => {
    if (map && center) {
      map.setCenter(center, animateCenter === true);
    }
  }, [animateCenter, center, map]);

  React.useEffect(() => {
    if (map && zoom) {
      map.setZoom(zoom, animateZoom === true);
    }
  }, [animateZoom, map, zoom]);

  function resizeMap() {
    if (map) {
      map.getViewPort().resize();
    }
  }

  return (
    <MapContext.Provider value={{ map, behavior, ui }}>
      <div
        id={mapContainerId}
        data-testid="map-container"
        style={{ height: '100%' }}
      >
        {map ? children : null}
      </div>
      {personalDashboardMap && stateCountry && stateCountry.map((item:CountriesProps,index:any) => (
         <MarkerMap
                    key={`${index}${ncNanoId()}`}
                    lat={Number(item.lat)}
                    lng={Number(item.lng)}
                    bitmap={`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"><path d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0zm0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987z" fill="#008489"/></svg>`}
                    ui={ui ? ui : undefined}
                    stateCountry={item}
                    personalDashboardMap={personalDashboardMap || false}
         />
      ))}

      {homeMap && countries && countries.map((item:any,index:any) => (
         <MarkerMap
                    key={`${index}${ncNanoId()}`}
                    lat={Number(item.lat)}
                    lng={Number(item.lng)}
                    bitmap={`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"><path d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0zm0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987z" fill="#008489"/></svg>`}
                    ui={ui ? ui : undefined}
                    countries={item}
                    homeMap={homeMap || false}
         />
      ))}

      {hotelDashboardMap && dataSearch && dataSearch.map((item:any,index:any) => (
         <MarkerMap
                    key={`${index}${ncNanoId()}`}
                    lat={Number(item.latitude)}
                    lng={Number(item.longitude)}
                    bitmap={`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"><path d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0zm0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987z" fill="#008489"/></svg>`}
                    ui={ui ? ui : undefined}
                    hotelData={item}
                    hotelDashboardMap={hotelDashboardMap || false}
         />
      ))}

      {nomadCountry && countriesNomad && countriesNomad.map((item:any,index:any) => (
         <MarkerMap
                    key={`${index}${ncNanoId()}`}
                    lat={Number(item.lat)}
                    lng={Number(item.lng)}
                    bitmap={`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"><path d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0zm0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987z" fill="#008489"/></svg>`}
                    ui={ui ? ui : undefined}
                    countriesNomad={item}
                    nomadCountry={nomadCountry || false}
         />
      ))}


    </MapContext.Provider>
  );
};

export default HEREMap;
