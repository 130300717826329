import { FC, useEffect, useState } from "react";
import Nav from "components/Nav/Nav";
import NavItem from "components/NavItem/NavItem";
import Input from "components/Input/Input";
import HeadBackgroundCommon from "components/HeadBackgroundCommon/HeadBackgroundCommon";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useNavigate, useLocation } from "react-router";
import PageLoading from "containers/PageLoading/PageLoading";
import { convertDate } from "utils/convertDate";
import isLiked from "utils/isLiked";
import { useAppSelector } from "app/hooks";
import { selectUser } from "app/user/user";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import axios from "../../axios";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import { useTranslation } from 'react-i18next';
import SectionGridPosts from "../PageHome/SectionGridPosts";
import ButtonPrimary from "components/Button/ButtonPrimary";
import {backendAddress} from "../../index";



export interface PageSearchProps {
  className?: string;
}



const PageSearch: FC<React.PropsWithChildren<React.PropsWithChildren<PageSearchProps>>> = ({ className = "" }) => {
  const {t} = useTranslation();
  const TABS = [t('dashboard.articles'), t('filter.categories'), t('filter.authors')];
  const [isArticlesLoading, setIsArticlesLoading] = useState(false);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(false);
  const [isAuthorsLoading, setIsAuthorsLoading] = useState(false);
  const [searchActive,setSearchActive] = useState(false);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [tabActive, setTabActive] = useState<string>(TABS[0]);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [showPosts, setShowPosts] = useState(24);
  const [showAuthors, setShowAuthors] = useState(24);


  const handleClickTab = (item: string) => {
    if (item === tabActive) {
      return;
    }
    setTabActive(item);
  };

  const history = useNavigate();
  const { search } = useLocation();
  let path = search.split("?q=");
  const query = path[1];
  const { user } = useAppSelector(selectUser);
  const [searchQuery, setSearchQuery] = useState(query);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (query !== searchQuery) {
    setSearchActive(true);
    history(`/blog/search?q=${searchQuery}`);
    }
  };

  const showMoreClickPosts = () =>
  {
    setShowPosts(showPosts*2);
  }

  const showMoreClickAuthors = () =>
  {
    setShowAuthors(showAuthors*2);
  }

  useEffect(() => {
      setIsArticlesLoading(true);
      setIsCategoriesLoading(true);
      setIsAuthorsLoading(true);
      const fetchArticlesData = async () =>

        await axios
          .get(`/search/articles/${query}`)
          .then(async (result: any) => {
            if (result.data) {
              if (result.data[0]) {
                let articles: any = [];
                await result.data.map((article: any) => {
                  articles.push({
                    id: article.article_id,
                    featuredImage: `${backendAddress}/${article.imgurl}`,
                    title: article.title,
                    desc: article.excerpt,
                    date: convertDate(article.created_date),
                    href: `/blog/article/${article.article_id}`,
                    commentCount: article.commentcount,
                    readingTime: article.readingtime,
                    country_name: article.country_name,
                    bookmark: {
                      isBookmarked: false,
                    },
                    like: isLiked(article.likes, user),

                    author: {
                      id: article.author_id,
                      displayName: article.author_name,
                      avatar: article.author_imgurl,
                      bgImg: article.author_imgbgurl,
                      href: `/blog/author/${article.author_id}`,
                    },
                    categories: [
                      {
                        id: article.category_id,
                        name: article.category_name,
                        href: `/blog/category/${article.category_id}`,
                        thumbnail: article.category_thumbnail,
                        color: article.category_color,
                        count: article.category_postcount
                      },
                    ],
                  });
                  setArticles(articles);
                  setSearchActive(false);
                });

                let fetchedAuthors: any = [];
                let fetchedCat:any=[];
                for (let key in articles)
                {
                    fetchedAuthors.push({
                        id: articles[key].author.id,
                        displayName:articles[key].author.displayName,
                        bgImg:articles[key].author.bgImg,
                        href:articles[key].author.href,
                        avatar:articles[key].author.avatar
                    });

                    fetchedCat.push({
                        id: articles[key].categories[0].id,
                        name: articles[key].categories[0].name,
                        href: articles[key].categories[0].href,
                        thumbnail: articles[key].categories[0].thumbnail,
                        color: articles[key].categories[0].color,
                        count: articles[key].categories[0].count | 0
                    });
                }
                let unique: any = [];
                unique=[];
                fetchedAuthors.map((item:any) => {
                   var findItem = unique.find((x:any) => x.id === item.id);
                   if (!findItem) unique.push(item);
                });
                setAuthors(unique);

                let uniqueCat: any =[];
                uniqueCat=[];
                fetchedCat.map((item:any) => {
                   var findItem = uniqueCat.find((x:any) => x.id === item.id);
                   if (!findItem) uniqueCat.push(item);
                });
                setCategories(uniqueCat);
              } else {
                setArticles([]);
                setCategories([]);
                setAuthors([]);
              }
            } else {
              setArticles([]);
              setCategories([]);
              setAuthors([]);

            }
            setIsArticlesLoading(false);
            setIsAuthorsLoading(false);
            setIsCategoriesLoading(false);
          })
          .catch((err) => {
            console.log("There was an error");
          });
          fetchArticlesData();
      return () => {
        //fetchArticlesData();
      };
  }, [search]);

  return (
    <div className={`nc-PageSearch ${className}`} data-nc-id="PageSearch">
      <HeadBackgroundCommon className="h-24 2xl:h-28" />
      <Helmet>
        <title>
          {t('search.heading.part1')} {query || "query"} {t('search.heading.part2')}
        </title>
      </Helmet>
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative" onSubmit={handleSubmit}>
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">{t('search.heading.icon')}</span>
              <Input
                id="search-input"
                type="search"
                placeholder={t('search.search.desc')}
                className="shadow-lg rounded-xl border-opacity-0"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                pattern="[^'\x22][A-Za-z0-9]+"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.search'))}
                value={searchQuery}
                onChange={(e: any) => setSearchQuery(e.target.value)}
              />
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                  ></path>
                </svg>
              </span>
            </label>
          </form>
          {!isArticlesLoading && articles[0] && (
            <span className="block text-sm mt-4 text-neutral-500 dark:text-neutral-300">
              {t("search.found")} &nbsp;
              <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
                {articles[0] && articles.length} &nbsp;
              </strong>
              {articles[0] && articles.length === 1 ? t("search.result") : t("search.results")}
              &nbsp;
              <strong className="font-semibold text-neutral-800 dark:text-neutral-100">
                "{query || "query"}"
              </strong>
            </span>
          )}
        </header>
      </div>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <main>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row ">
            <Nav
              containerClassName="w-full overflow-x-auto hiddenScrollbar"
              className=" sm:space-x-2"
            >
              {TABS.map((item, index) => (
                <NavItem
                  key={index}
                  isActive={tabActive === item}
                  onClick={() => handleClickTab(item)}
                >
                  {item}
                </NavItem>
              ))}
            </Nav>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            {/* <div className="flex justify-end">
              <ArchiveFilterListBox lists={FILTERS} />
            </div> */}
          </div>
          {tabActive === t('dashboard.articles') &&
            (isArticlesLoading ? (
              <PageLoading />
            ) : (
              <div className="mt-10">
                {articles[0] ? (

                  <SectionGridPosts
                      className=""
                      postCardName="card11"
                      heading=""
                      subHeading=""
                      posts={articles.filter((_:any, i:any) => i < showPosts)}
                      gridClass="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8"
                      headingIsCenter
                  />

                ) : (
                  <p style={{ textTransform: "none" }}>
                    {t('search.search.noresult')}
                  </p>
                )}

                {(articles.length > showPosts) && (
                     <div className="text-center mx-auto mt-8 md:mt-10">
                        <ButtonPrimary onClick={showMoreClickPosts}>{t('slider.showMore')}</ButtonPrimary>
                     </div>
                  )}
              </div>
            ))}

          {tabActive === t('filter.categories') &&
            (isCategoriesLoading ? (
              <PageLoading />
            ) : (
              <div className="relative py-16 mt-8 lg:mt-10">
                <BackgroundSection />
                {categories[0] ? (
                  <SectionGridCategoryBox
                    categories={categories.sort((a:any, b:any) => ((a.count)  < (b.count)) ? 1 : -1)}
                    isNotHeading
                  />
                ) : (
                   <p style={{ textTransform: "none" }}>
                    {t('search.search.noresult')}
                  </p>
                )}
              </div>
            ))}
          {tabActive === t('filter.authors') &&
            (isAuthorsLoading ? (
              <PageLoading />
            ) : (
              <div className="relative py-16 mt-8 lg:mt-10">
                {authors[0] ? (
                  <SectionGridAuthorBox authors={authors.filter((_:any, i:any) => i < showAuthors)} isNotHeading />
                ) : (
                   <p style={{ textTransform: "none" }}>
                    {t('search.search.noresult')}
                  </p>
                )}

              {(authors.length > showAuthors) && (
                     <div className="text-center mx-auto mt-8 md:mt-10">
                        <ButtonPrimary onClick={showMoreClickAuthors}>{t('slider.showMore')}</ButtonPrimary>
                     </div>
                  )}

              </div>


            ))}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {/* <Pagination />
                <ButtonPrimary>Show me more</ButtonPrimary> */}
          </div>
        </main>

        {/* SUBCRIBES */}
        <SectionSubscribe2 className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default PageSearch;
