//@ts-nocheck
import React,{ FC } from "react";
import useScriptWidget from "hooks/widgetKiwi/useScript";

export interface ListingInterface {
   currency:string;
    lang:string;
    dataFrom:string;
    datadep:any;
    dataret:any;
    dataLimit:string;
    dataPassengers:string;
    dataBags:string;
}



const ListingMap : FC<React.PropsWithChildren<React.PropsWithChildren<ListingInterface>>> = ({
    currency,
    lang,
    dataFrom,
    datadep,
    dataret,
    dataLimit,
    dataPassengers,
    dataBags
}) => {

  let scriptTemp=useScriptWidget("https://widgets.kiwi.com/scripts/widget-search-iframe.js","kiwi",currency,lang,dataFrom+"",datadep+"",dataret+"",dataLimit+"",dataPassengers,dataBags);


  return (
    <div id="gonnaway_widget" className="relative">
        {scriptTemp}
    </div>


  );
};

export default ListingMap;
