import { FC } from "react";
import { PostDataType } from "data/types";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  className: "";
}

const PageCookie: FC<React.PropsWithChildren<React.PropsWithChildren<PageSingleProps>>> = ({ className = "" }) => {


  return (
    <div
      className={`nc-PageSingle px-6 pt-8 lg:pt-16 ${className}`}
      data-nc-id="PageSingle"
    >
    <Helmet>
        <title>Cookie Policy || Gonnaway</title>
      </Helmet>
            <BgGlassmorphism />
        <div>
          <header className="container rounded-xl">
            <div className="mx-auto flex justify-center">
                <Heading desc="">Cookie Policy️</Heading>
            </div>
          </header>
        </div>

          <div className="relative prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark ">
            <div className="relative ">
                <p style={{textAlign:"justify"}}>
                 A "cookie" is a text file that websites send to your device to uniquely identify the visitor's browser or to store information or settings in the browser.
                    Cookies can be stored permanently on the device and have a variable duration (persistent cookies),
                    or they can be deleted by closing the browser or even have a limited duration (session cookies).
                    Cookies can be installed by Gonnaway.com or can be installed by other websites (third party cookies).
                </p>
                <p style={{textAlign:"justify"}}>
                    In accordance with the information banner on the Gonnaway.com homepage relating to
                    the use of Cookies, also displayed on the first use of the platform services,
                    by clicking on the "Accept" button you are consenting to the use of the Cookies
                    described below, unless the browser settings have not been changed to disable their use.
                </p>
                <p style={{textAlign:"justify"}}>
                    The main functions of the cookies installed by Gonnaway.com are:
                </p>
                    <ol>
                      <ol>
                      <li><strong>Techniques</strong>: they are used to improve the service</li>
                      <li><strong>Statistics</strong>: these cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site.    All information these cookies collect is aggregated and therefore anonymous.  for counting visits</li>
                      <li><strong>Profiling</strong>: store your favorite announcements and searches. In the light of the highly invasive nature of these cookie, the legislation requires users to be informed appropriately on thei use so as to give their valid consent.</li>
                    </ol>
                    </ol>


                    <p style={{textAlign:"justify"}}>
                        <strong>How to provide consent for cookies usage</strong>
                        </p>
                        <p>
                            Provide consent to the use of profiling cookies in the following ways:
                             closing the banner containing the brief information and clicking the relevant "Accept" button and it can be revoked at any time.
                             All technical cookies do not require explicit consent from the user as they are necessary for the normal operation of the platform, therefore they are installed automatically following access to the site or service.
                         </p>

                     <p style={{textAlign:"justify"}}>
                        <strong>How to revoke cookies usage consent</strong>
                        </p>
                        <p style={{textAlign:"justify"}}>
                            Cookies can be totally disabled from your web browser using the appropriate default function.
                             We inform you, however, that by completely disabling cookies some features provided by Gonnaway may not be usable.
                             So we do not recommend disabling cookies, but it is totally up to you! In any case
                             in order to provide you with all the information necessary to disable cookies, below
                             we report the websites of the main web browsers used, which present the instructions for deactivating cookies:
                            <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noopener noreferrer">Chrome</a>,
                            <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noopener noreferrer"> Firefox</a>,
                            <a href="http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank" rel="noopener noreferrer"> Internet Explorer</a>,
                            <a href="https://support.apple.com/it-it/HT201265" target="_blank" rel="noopener noreferrer"> Safari</a>,
                            <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener noreferrer"> Edge</a>.
                        </p>

                    <p style={{textAlign:"justify"}}>
                        <strong>The usage of third party cookies</strong>
                        </p>
                        <div style={{textAlign:"justify"}}> The main objectives of third party cookies installed by Gonnaway are:
                            <ol>
                              <li><strong>Analytical</strong>: we may use these cookies to improve the service (e.g. number of visitors, pages visited). We use these information in an aggregated and anonymous way. In particular we use the following service: <a href="https://logrocket.com/privacy/" target="_blank" rel="noopener noreferrer">LogRocket</a> and in some cases <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">GoogleAdSense</a></li>
                              <li><strong>Profiling</strong>: we may store your favorite announcements and searches. In the light of the highly invasive nature of these cookie, the legislation requires users to be informed appropriately on their use so as to give their valid consent.</li>
                            </ol>
                            The usage of the above technologies is regulated directly by the privacy notice of the technology companies and not by Gonnaway.
                            In case of usage of information for profiling objectives, the data gathered are processed for a maximum time of 12 months from the time of the processing consent is provided to Gonnaway.
                        </div>

                </div>
      </div>
      <div className="mt-10" />

    </div>
  );
};



export default PageCookie;
