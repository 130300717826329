import {useEffect,useState} from 'react';
import { useAppSelector } from "app/hooks";
import { useThemeMode } from "hooks/useThemeMode";



const useScript =(src: string, name: string, currency:string, lang:string, dataFrom:string, datadep:string, dataret:string, dataLimit:string,dataPassengers:string,dataBags:string ) => {

    const [lib,setLib]=useState({});
    const {isDarkMode}=useThemeMode();

    useEffect(()=> {
    // Create script
      const script = document.createElement('script');
      script.type='application/javascript';
      script.src = src;
      script.async = true;
      script.setAttribute("data-currency", currency);
      script.setAttribute("data-lang", lang);
      script.setAttribute("data-affilid", "gonnawaycomgwayadvice");
      script.setAttribute("data-brand", "Gonnaway");
      if(dataFrom!==""){script.setAttribute("data-from", dataFrom)};
      if(datadep!==""){script.setAttribute("data-departure", datadep)};
      if(dataret!==""){script.setAttribute("data-return", dataret)};
      script.setAttribute("data-passengers", dataPassengers);
      script.setAttribute("data-bags", dataBags);

      script.setAttribute("data-primary-color", "008487");
      script.setAttribute("data-background-primary-color", (isDarkMode ? "f5f3f3":"f5f3f3"));
      script.setAttribute("data-background-secondary-color", (isDarkMode ? "f5f3f3":"f5f3f3"));
      script.setAttribute("data-transport-types", "FLIGHT");
      script.setAttribute("data-limit", dataLimit);
      script.setAttribute("data-tile-limit", dataLimit);
      script.setAttribute("data-sort-by", "price");
      script.setAttribute("data-container-id", "gonnaway_widget");
      script.setAttribute("data-results-only", "true");
      script.setAttribute("data-iframe-id", "kiwiwidget");
      document.getElementById("gonnaway_widget").appendChild(script);
    },[src,currency,lang,dataFrom,datadep,dataret,dataLimit,isDarkMode,dataPassengers,dataBags]);
};

export default useScript;