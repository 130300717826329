import {useEffect,useState} from 'react';

const useScript =url => {

    const [lib,setLib]=useState({});

    useEffect(()=> {
        const script=document.createElement('script');
        script.type='application/javascript';
        script.src=url;
        script.async=true;
        script.id="HotellokMap"
        document.getElementById("fixedMap").appendChild(script);

    },[url]);
};

export default useScript;