import React, { FC, useState } from "react";
import LayoutPage from "components/LayoutPage/LayoutPage";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import axios from "../../axios";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { DEMO_AUTHORS } from "data/authors";
import { setUser } from "app/user/user";
import { useAppDispatch } from "app/hooks";
import CheckPrivacy from 'components/Checkbox/Checkbox';
import CheckNewsLetter from 'components/Checkbox/Checkbox';
import Label from 'components/Label/Label';
import { CircularProgress } from "@mui/material";



export interface PageSignUpProps {
  className?: string;
}


const PageSignUp: FC<React.PropsWithChildren<React.PropsWithChildren<PageSignUpProps>>> = ({ className = "" }) => {
  const history = useNavigate();
  const dispatch = useAppDispatch()
  const [message, setMessage] = useState<any>(false);
  const [privacy, setPrivacy] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [labelText, setLabelText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  //const [checkBgImage, setCheckBgImage] = useState<any>(false);
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    password: "",
  });

  const {t} = useTranslation();

  const loginSocials = [
  {
    name: t('login.fb'),
    href: "#",
    icon: facebookSvg,
  },
  {
    name: t('login.twitter'),
    href: "#",
    icon: twitterSvg,
  },
  {
    name: t('login.google'),
    href: "#",
    icon: googleSvg,
  },
];


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserInput((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

 /* function getRandomInt(max:number) {
      return Math.floor(Math.random() * max);
  }*/



  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    if(privacy){
        axios
          .post("/authors/register", userInput)
          .then((res: any) => {
            //subscription to newsletter in case of consent
            if(newsletter)
            {
                axios
                  .post("/subscribe/add", {
                    email: userInput.email,
                    date: new Date()
                  })
                  .then((res: any) => {
                    console.log("Registered for Newsletter");
                  })
                  .catch((err) => {
                    console.log("There was an error");
                  });
            }

            setUserInput({
              name: "",
              email: "",
              password: "",
            });

            if (res.data.status==="success") {
            setMessage(true);
              dispatch(setUser(res.data.user))
              history(`/login`);
            }
            setSubmitting(false);
          })
          .catch((err) => {
                    setTimeout(() => {
                  setActiveLabel(false);
                }, 5000);
                let textL=t('error.register');
                setLabelText(textL);
                setActiveLabel(true);
                setSubmitting(false);
          });
    }

  };
  let emailDomain= ['gmail.com', 'outlook.com'];

  return (
    <div className={`nc-PageSignUp ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign up || Community Travel</title>
      </Helmet>
      <LayoutPage
        subHeading={t('signup.subheading')}
        headingEmoji="🎉"
        heading={t('signup.heading')}
      >
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
         {/*   {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}*/}
          </div>
          {/* OR */}
          <div className="relative text-center">
          {/*}  <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t("login.or")}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          */}
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <Collapse in={message}>
              <Alert
                severity={message.status}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setMessage(false);
                    }}
                  >
                    <SvgIcon>
                      <path
                        fill="inherit"
                        d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                      />
                    </SvgIcon>
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <p style={{ marginTop: ".2rem" }}>{message.message}</p>
              </Alert>
            </Collapse>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('contact.fullname')}
              </span>
              <Input
                type="text"
                className="mt-1"
                name="name"
                value={userInput.name}
                onChange={handleChange}
                pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.nameProfile'))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                required
              />
            </label><label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('contact.email')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                pattern="[a-zA-Z0-9._%+-]+@(gmail|hotmail|outlook|yahoo|icloud|live|me|tiscali|libero)+\.[a-z]{2,4}$"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.email'))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                value={userInput.email}
                onChange={handleChange}
                required
              />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t('password.normal')}
              </span>
              <Input
                type="password"
                className="mt-1"
                name="password"
                pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.password'))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                value={userInput.password}
                onChange={handleChange}
                required
              />
            </label>

            <label className="block inline-block align-middle">
            <span className="flex flex-wrap content-center items-center sm:text-sm md:text-base gap-2">

                  <Input
                    type="checkbox"
                    className="mt-1 w-1 h-1 self-center"
                    name="privacy"
                    style={{height:"10px", width:"10px"}}
                    value="privacy"
                    onChange={()=>setPrivacy(!privacy)}
                    required
                  />

              <span className="mt-1 gap-2">{t('check.privacy')} <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></span>
            </span>
            </label>

            <label className="block inline-block align-middle">
            <span className="flex flex-wrap content-center items-center sm:text-sm md:text-base gap-2">
              <Input
                type="checkbox"
                className="mt-1 w-1 h-1 self-center"
                name="subscribe"
                value="subscribe"
                style={{height:"10px", width:"10px"}}
                onChange={()=>setNewsletter(!newsletter)}

              />
              <span className="mt-1 gap-2">{t('check.newsletter')}</span>
            </span>
            </label>


            <ButtonPrimary type="submit">
            {submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#eee",
                }}
              />
            ) : (
              <span>{t('signup.continue')}</span>
            )}

            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('signup.account')} {` `}
            <NcLink to="/login">{t('sign.in')}</NcLink>
          </span>
           {activeLabel ? (
            <Label className="mt-10">{labelText}</Label>
         ) : ( <div />)}
        </div>

      </LayoutPage>
    </div>
  );
};

export default PageSignUp;
