import { FC,useEffect,useState } from "react";
import {FaTrashRestore} from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import Autocomplete from "@mui/material/Autocomplete";
import Box from '@mui/material/Box';
import airportIcon from "images/airport_destination.svg";
import { isMobile} from 'react-device-detect';
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { useThemeMode } from "hooks/useThemeMode";
import axios from "../../axios";
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import {SingleDateWrapper,SingleDateWrapperDark} from './MultiCityDropDown.style';
import moment from "moment";
interface AirportType {
  value: string;
  name: string;
  city:string;
}

interface Props {
  className?: string;
  destData?: any;
  destTemp?:any
}

const NomadDropdownMultiCityFilter: FC<Props> = ({
  className = "hidden sm:block" ,
  destData = (userDestination:any) => {},
  destTemp
}) => {
  const {t} = useTranslation();
  const {isDarkMode}=useThemeMode();
  const [openFrag, setOpenFrag] = useState(false);
  const [idCount,setIdCount]=useState(2);
  const [labelCheck, setLabelCheck] = useState(false);
  const [lengthDestinations, setLengthDestinations] = useState(5);
  const today=new Date();

  let initiationDestinations:any=[];
  for (let key in destTemp){
        initiationDestinations.push({
                id:key,
                name:destTemp[key].departure_airport || t('startDate'),
                nameTo:destTemp[key].arrival_airport || t('multicity.destination'),
                city:destTemp[key].city_departure || t('startDate'),
                cityTo:destTemp[key].city_return || t('flightDetailArrival'),
                date:destTemp[key].outbound_date || "Date",
                modified:destTemp[key].modified || false,
                deparTime:destTemp[key].deparTime || 0,
                deparTimeTo:destTemp[key].deparTimeTo || 23,
                dialogOpen:false
            });
    }


  const [destinations,setDestinations]=useState(initiationDestinations);

  const [toggle, setToggle] = useState(false);
  /*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

   const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);
  /*end windows size*/


   const theme = createTheme({
      components: {
        MuiTextField: {
            styleOverrides: {
                root:{
                    background:"transparent",
                    backgroundColor:"transparent",
                    width:"100%",
               },
            },
        },
        MuiAutocomplete: {
          styleOverrides: {
            // Name of the slot


            root: {
              // Some CSS
               background:"transparent",
               width:"100%",
               "& .MuiInput-root .MuiInput-input":{
                padding: "0px 0px 0px 0px"
               },


            },

            input:{
               background:"transparent",
               width:"100%",



            },
            inputRoot: {
                color:"black",
                background:"transparent",
                width:"100%",
                paddingTop:"15px",


              "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e5e7eb",
                  background:"transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#008489"
                },
              borderRadius:"10px",
              fontSize:windowSize.innerWidth>600 ? "16px": "13px"
            },

          },
        },
        MuiDrawer: {
        styleOverrides: {
            root:{
                height:"100%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "28%" : "100%",
                height:"100%",
                backgroundColor:"#fff"
           },
        },
    },
      },
   });

    const themeDark = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                color:"white"
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
           color:"white"
        },
        input:{
                background:"transparent",
                color:"white"
        },
        inputRoot: {
            background:"transparent",
            color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
              color:"white"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489",
              color:"white",
            },
            "& .MuiChip-label": {
              color: "#fff"
            },
            "& .MuiChip-deleteIcon": {
              color: "#1b8489"
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "#fff"
            },
          fontSize:"14px"
        },

      },
    },

    MuiInput: {
        styleOverrides: {
            root:{

          color:"white",
                "&:before": {
                  borderBottom: "1px solid rgb(255,255,255)",
                },
                "&:hover": {
                  borderBottom: "1px solid #008489",
                },
           },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root:{
                color:"white",

           },


        },
    },

    MuiDrawer: {
        styleOverrides: {
            root:{
                height:"100%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "28%" : "100%",
                height:"100%",
                backgroundColor:"#111827"
           },
        },
    },
    },
});



    /*MANAGE ADD AND REMOVE OF DESTINATION COMPONENT*/
    const addComponent=(idItem:number)=>{
            //let id=idItem;
            setIdCount(idItem);
            setDestinations((currInputs:any) =>
            [...currInputs,
                {
                    id: idItem,
                    name: t('startDate'),
                    nameTo: t('multicity.destination'),
                    city:t('startDate'),
                    cityTo:t('flightDetailArrival'),
                    date:"Date",
                    deparTime:0,
                    deparTimeTo:23,
                    modified:false,
                    dialogOpen:false

                }
            ]);
            destData(destinations);

    }

    const deleteRow=(item:any)=>{

            var array = destinations.filter((obj:any) => obj !== item);
            setDestinations(array);
            destData(array);
    }
  /*END*/



  /*MANAGE DIALOG FUNCTIONALITIES OPEN AND CLOSE*/

  const handleClickOpen = (item:any) => {

    const newState = destinations.map((obj:any) => {
        if (obj.id === item.id) {
          return {...obj, dialogOpen:true};
        }
        return obj;
      });
      setDestinations(newState);
      destData(newState);
      setToggle(true);

  };

  const handleClickClose = (item:any) => {

    const newState = destinations.map((obj:any) => {
        if (obj.id === item.id) {
          return {...obj, dialogOpen:false};
        }
        setToggle(false);
        return obj;
      });
      setDestinations(newState);
      destData(newState);
  };


  ////
  const [arrivalAirportValue, setArrivalAirportValue] = useState("");
      const [arrivalCity, setArrivalCity] = useState("");
      const [depAirportValue, setDepAirportValue] = useState("");
      const [depCity, setDepCity] = useState("");
      const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:"",city:""}]);
      const [airValueDep, setAirValueDep] = useState<AirportType[]>([{value:"",name:"",city:""}]);
      const [valueArr, setValueArr] = useState<number[]>([3, 5]);
      const [oneWayDate, setOneWayDate] = useState({
        setDate: null,
      });

      /*manage autocomplete*/
  const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => (option.name),
  };

  const defaultDepProps = {
    options: airValueDep,
    getOptionLabel: (option: AirportType) => (option.name),
  };

  const onChangeArrivalAirport = async (e:any, value:any,item:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }

                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }

    const onChangeDepAirport = async (e:any, value:any, item:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }

                setAirValueDep(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }


    const updateArrivalAirportFunc = (value:any,item:any) => {
        if(value!=null){
            setArrivalAirportValue(value.value);
            setArrivalCity(value.city);

            const newState = destinations.map((obj:any) => {
                if (obj.id === item.id) {
                  return {...obj, cityTo:value.city, nameTo:value.value};
                }
                return obj;
              });
              setDestinations(newState);
              destData(newState);

            }
            else{return;}
   };

   const updateDepAirportFunc = (value:any,item:any) => {
        if(value!=null){
            setDepAirportValue(value.value);
            setDepCity(value.city);
            const newState = destinations.map((obj:any) => {
                if (obj.id === item.id) {
                  return {...obj, city:value.city, name:value.value};
                }
                return obj;
              });
              setDestinations(newState);
              destData(newState);
        }
        else{return;}
   };

   /*RETURN TEXT FOR LABEL SLIDER AND MANAGE DAYS*/
  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChangeArrTime = (event: Event, newValue: number | number[]) => {
    setValueArr(newValue as number[]);
  };

  /*END*/

   const handleClose = (item:any) => {
   let checkCompleteModified=false;

   if(depAirportValue!=="" && arrivalAirportValue!=="" && depCity!=="" && arrivalCity!=="" && (oneWayDate.setDate!==null && oneWayDate.setDate!==undefined && oneWayDate.setDate!=="")) checkCompleteModified=true;
    const newState = destinations.map((obj:any) => {
        if (obj.id === item.id) {
          return {...obj,
                  name: depAirportValue,
                  nameTo:arrivalAirportValue,
                  city:depCity,
                  cityTo:arrivalCity,
                  date:oneWayDate.setDate,
                  modified:checkCompleteModified,
                  deparTime:0,
                  deparTimeTo:23,
                  dialogOpen:false};
        }
        return obj;
      });
      setDestinations(newState);
      destData(newState);
     // setToggle(false);

  };

  const changeDateHandle = (dateValue:any,item:any, index:any) => {
   setOneWayDate(dateValue);
        const newState = destinations.map((obj:any) => {
                if (obj.id === item.id) {
                  return {...obj, date:dateValue.setDate};
                }
                return obj;
              });
              setDestinations(newState);
              destData(newState);



  };


  const letterTo="❯";

  return (
    <div className="w-full h-full mr-2">
            <div>
                {destinations && destinations.map((item:any, index:any) => (
                        <div key={index} className={windowSize.innerWidth>600 ? "flex  items-center gap-2" : "flex  items-center gap-1"}>
                            <div className={windowSize.innerWidth>600 ? "gap-2 w-full ml-2 mr-2 border rounded-xl mb-1" : "gap-2 w-full ml-1 mr-1 border rounded-xl mb-1"}>
                                <div className={windowSize.innerWidth>600 ? "grid grid-cols-12 items-center gap-2 mb-1 w-full mr-2" : "grid grid-cols-12 items-center gap-1 mb-1 w-full mr-1"}>
                                    <p className="text-lg text-neutral-900 dark:text-neutral-100 cols-span-1 flex flex-wrap justify-center">{index+1}</p>
                                    <ThemeProvider theme={isDarkMode ? themeDark : theme}>
                                    <div className="col-span-11">
                                        <div className={windowSize.innerWidth>600 ? "items-center gap-2 mr-2" : "items-center mr-2"}>
                                               <Autocomplete
                                                  {...defaultDepProps}
                                                  id={item.id.toString()}
                                                  autoComplete={true}
                                                  autoSelect={true}
                                                  sx={{
                                                  "& .MuiAutocomplete-hasPopupIcon": {
                                                    paddingRight: "0px!important"
                                                  },
                                                  "& .MuiAutocomplete-hasClearIcon":{
                                                    paddingRight: "0px!important"
                                                  },

                                                  "& .MuiAutocomplete-inputRoot":{
                                                    paddingRight: "0px!important"
                                                  },
                                                }}

                                                  componentsProps={{
                                                    paper: {
                                                        sx: {
                                                            border: '1px solid lightgray',
                                                            width: windowSize.innerWidth>600 ? "300px" : "230px",
                                                        }
                                                    }
                                                }}

                                                  onInputChange={(event: any, newValue: any) => onChangeDepAirport(event,newValue,item)}
                                                  onChange={(event:any, newValue:any)=>updateDepAirportFunc(newValue,item)}
                                                  isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                                  style={isDarkMode ? { color: "black",gridColumn: "span 10 / span 10"} : {color:"white",gridColumn: "span 10 / span 10"}}
                                                  className="text-gray-900  dark:text-neutral-900 col-span-9"
                                                  renderOption={(props, option) => (
                                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                          <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={airportIcon}
                                                            alt="Airport"
                                                          />
                                                          {`${option.name || "Loading.."}`}
                                                        </Box>
                                                  )}
                                                  renderInput={(params) => (
                                                     <TextField
                                                        {...params}
                                                        required

                                                        label={(item.name!=="")?item.name:t('city')}
                                                        variant="standard"  />
                                                  )}
                                                  loading={true}
                                                  autoHighlight={true}
                                                  noOptionsText={t('noOptionTextAutoComplete')}
                                               />

                                    </div>
                                    <div className={windowSize.innerWidth>600 ? "items-center gap-1 mr-2" : "col-span-3 items-center gap-1 mr-2"}>
                                           <Autocomplete
                                              {...defaultArrProps}
                                              id={item.id.toString()}
                                              autoComplete={true}
                                              autoSelect={true}
                                              sx={{
                                                  "& .MuiAutocomplete-hasPopupIcon": {
                                                    paddingRight: "0px!important"
                                                  },
                                                  "& .MuiAutocomplete-hasClearIcon":{
                                                    paddingRight: "0px!important"
                                                  },

                                                  "& .MuiAutocomplete-inputRoot":{
                                                    paddingRight: "0px!important"
                                                  },
                                                }}
                                              componentsProps={{
                                                    paper: {
                                                        sx: {
                                                            border: '1px solid lightgray',
                                                            width: windowSize.innerWidth>600 ? "300px" : "230px",
                                                        }
                                                    }
                                                }}
                                              onInputChange={(event: any, newValue: any) => onChangeArrivalAirport(event,newValue,item)}
                                              onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue,item)}
                                              isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                              style={isDarkMode ? { color: "black",gridColumn: "span 10 / span 10"} : {color:"white",gridColumn: "span 10 / span 10"}}
                                              className="text-gray-900  dark:text-neutral-900 col-span-9"
                                              renderOption={(props, option) => (
                                                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                      <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={airportIcon}
                                                        alt="Airport"
                                                      />
                                                      {`${option.name || "Loading"}`}
                                                    </Box>
                                                  )}
                                                  renderInput={(params) => (
                                                     <TextField
                                                        {...params}
                                                        required
                                                        label={(item.nameTo && item.nameTo!=="")?item.nameTo:t('tocity')}
                                                        variant="standard"  />
                                                  )}
                                              loading={true}
                                              autoHighlight={true}
                                              noOptionsText={t('noOptionTextAutoComplete')}
                                           />
                                    </div>
                                    <div className={windowSize.innerWidth>600 ? "items-center mb-2" : "items-center mb-2"}>

                                           {isDarkMode ? (
                                            <SingleDateWrapperDark>
                                              <SingleDatePicker
                                                numberOfMonths={1}
                                                small={true}
                                                required={true}
                                                placeholder= "Date"
                                                onDateChange={(dateValue:any) => changeDateHandle(dateValue,item,index)}
                                                displayFormat="DD/MM/YYYY"
                                                date={item.date}
                                                initialDate={moment(destinations[index].date, 'DD/MM/YYYY').add(0,"day")}

                                                isOutsideRange={(day:any) => {
                                                     return !day.isAfter((index-1>=0) ? (destinations[index-1].date!=="Date" && destinations[index-1].date!==null ? moment(destinations[index-1].date, 'DD/MM/YYYY').add(0,"day") :moment(new Date().toLocaleString("it-IT"), 'DD/MM/YYYY').add(1,"day")) : moment(new Date().toLocaleString("it-IT"), 'DD/MM/YYYY').add(1,"day"))

                                                }}
                                                autoFocus={false}
                                                readOnly={isMobile ? true : false}

                                              />
                                            </SingleDateWrapperDark>
                                            ):(
                                                <SingleDateWrapper>
                                              <SingleDatePicker
                                                numberOfMonths={1}
                                                small={true}
                                                required={true}
                                                placeholder= "Date"
                                                onDateChange={(dateValue:any) => changeDateHandle(dateValue,item,index)}
                                                displayFormat="DD/MM/YYYY"
                                                date={item.date}
                                                initialDate={moment(destinations[index].date, 'DD/MM/YYYY').add(0,"day")}

                                                isOutsideRange={(day:any) => {
                                                     return !day.isAfter((index-1>=0) ? (destinations[index-1].date!=="Date" && destinations[index-1].date!==null ? moment(destinations[index-1].date, 'DD/MM/YYYY').add(0,"day") :moment(new Date().toLocaleString("it-IT"), 'DD/MM/YYYY').add(1,"day")) : moment(new Date().toLocaleString("it-IT"), 'DD/MM/YYYY').add(1,"day"))
                                                }}
                                                autoFocus={false}
                                                readOnly={isMobile ? true : false}
                                              />
                                            </SingleDateWrapper>
                                            )}

                                    </div>
                                    </div>
                                    </ThemeProvider>
                            </div>


                 </div>
                 <div className=" flex flex-wrap items-center  justify-center">
                                        {destinations.length> 2 &&(
                                            <p className="text-xs sm:text-sm  flex flex-wrap justify-center">
                                                 <FaTrashRestore style={{color:"red"}} onClick={()=>deleteRow(item)}/>

                                           </p>
                                        )}
                  </div>
                </div>
                 ))}

            </div>
            {destinations.length<lengthDestinations &&(
                <div className={windowSize.innerWidth>600 ? "ml-2 mr-4 border border-dashed rounded-xl mb-1" :"ml-2 mr-2 border border-dashed rounded-xl mb-1"} style={{height:"50px"}}>
                    <p className={windowSize.innerWidth>600 ? " flex flex-wrap  items-center justify-center mt-3 gap-3 text-sm text-neutral-200 font-semibold" : "flex flex-wrap  items-center justify-center mt-3 gap-3 text-sm text-neutral-200 font-semibold"} onClick={(e)=>addComponent(idCount+1)}>
                         {t('nomad.addDestination')}
                     </p>
                </div>
            )}


             {labelCheck &&(
                    <p>{t('nomad.compile')}</p>
             )}
    </div>
  );
};

export default NomadDropdownMultiCityFilter;
