import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import SectionUnSubscribe from "components/SectionSubscribe2/SectionUnSubscribe";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";


export interface PageUnsubscribeProps {
  className?: string;
}

const PageUnsubscribe= () => {

  return (
    <div className="nc-PageSingle overflow-hidden relative">
      <Helmet>
        <title>Gonnaway.com || Unsubscribe</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* ======= START CONTAINER ============= */}
      <div className="relative">

        <div className="container relative">

          {/* === SECTION 7 === */}
          <SectionUnSubscribe className="py-16 lg:py-28" />


        </div>

      </div>

    </div>
  );
};

export default PageUnsubscribe;






