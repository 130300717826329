import { NavItemType } from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";


const articlesChild: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Home Travel Blog",
  },
  {
    id: ncNanoId(),
    href: "/blog/trending",
    name: "Top Trending",
  },
  {
    id: ncNanoId(),
    href: "/blog/all_articles",
    name: "All Articles",
  },
];

const otherChild: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/about",
    name: "About us",
  },
  {
    id: ncNanoId(),
    href: "/privacy",
    name: "Privacy policy",

  },
  {
    id: ncNanoId(),
    href: "/cookie",
    name: "Cookie policy",
  },
  {
    id: ncNanoId(),
    href: "/sustainability",
    name: "Sustainability",
  },
  {
    id: ncNanoId(),
    href: "/terms",
    name: "Terms & Conditions",
  },
 /* {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact Us",
  },*/


];

export const NAVIGATION_DEMO: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "Gonnaway",
    type: "dropdown",
    children: otherChild,
  },
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Travel Blog",
    type: "dropdown",
    children: articlesChild,
  },
];
