import { FC } from "react";
import { PostDataType } from "data/types";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Heading from "components/Heading/Heading";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

export interface PageSingleProps {
  className?: string;
}

export interface SinglePageType extends PostDataType {
  className: "";
}

const PageTerms: FC<React.PropsWithChildren<React.PropsWithChildren<PageSingleProps>>> = ({ className = "" }) => {


  const { t } = useTranslation();
  return (
    <div
      className={`nc-PageSingle pt-8 px-6 lg:pt-16 ${className}`}
      data-nc-id="PageSingle"
    >
    <Helmet><title>Terms & Conditions || Gonnaway</title></Helmet>
    <BgGlassmorphism />

    <div>
      <header className="container rounded-xl">
        <div className="mx-auto flex justify-center">
                <Heading desc="Monday 4th April, 2022">Terms & Conditions</Heading>
        </div>
      </header>

      <div className="relative prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark">
        <div className="relative">
           <p style={{textAlign:"justify"}}><strong>This Policy describes:</strong></p>
                <div>
                   <ol>
                      <li>Who we are</li>
                      <li>Our Terms</li>
                      <li>Using our services</li>
                      <li>Sharing information via our platform</li>
                      <li>Property</li>
                      <li>Price accuracy and warranty disclaimer</li>
                      <li>Travel reservations via Gonnaway</li>
                      <li>Limitations and exclusions of liability</li>
                      <li>Termination</li>
                      <li>General provisions</li>
                      <li>Contact us</li>
                   </ol>
                </div>
        </div>
      <div className="mt-10" />

      <div id="whoweare" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>1. Who we are</strong></p>
           <p style={{textAlign:"justify"}}>
             Gonnaway.com provides travelers from all over the world with a travel search platform, comparing flights and hotels, and a forum for presenting travel itineraries. Gonnaway is not a travel agency and, therefore, is not responsible, in any way, for providing, setting or checking the prices applicable to any of the travel options or products found and / or booked through our Services ( "Third Party Travel Products"). All of the aforementioned Third Party Travel Products are provided by travel agencies, airlines, hotels or other independent third parties and are subject to the terms and conditions of these third parties.
           </p>
        </div>
      </div>
    <div className="mt-10" />

    <div id="terms" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>2. Our Terms</strong></p>
           <p style={{textAlign:"justify"}}>
             These terms and conditions ("Terms") govern your access and use of our terms Services and Platforms, together with our <a href="https://www.gonnaway.com/#/privacy">Privacy Policy</a> and the <a href="https://www.gonnaway.com/#/cookie">Cookie Policy</a>.
             By accessing the Services or Platforms, or using them, the user confirms that he has read, understood and accepted these Terms and those of the Privacy Policy and the Information on the use of cookies. In the event that the user does not accept these Terms, he is invited to stop using our Services or our Platforms.
             These Terms are subject to change at any time at our discretion. In the event that these Terms are subject to changes, the new terms and conditions will be published so that the user can read them.

           </p>
        </div>
    </div>
    <div className="mt-10" />

    <div id="services" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>3. Using our services</strong></p>
           <div style={{textAlign:"justify"}}>
             <span>You may only use our Services and Platforms in compliance with applicable laws and for legitimate purposes. In consideration of you agreeing to these Terms, we grant you a non-transferable, non-exclusive license to download, access and use our Services and Platforms for your own personal, non-commercial purposes and for no other purpose. We grant this license subject to you agreeing that you shall not:</span>
             <ol>
                <li>use our Services or Platforms for any purpose that is improper, unlawful, or to post, share or transmit any material that: (i) is defamatory, offensive, obscene or otherwise objectionable; (ii) is in breach of confidence or privacy or of any third party’s rights including copyright, trade mark or other intellectual property rights; (iii) is posted, shared or transmitted for the purpose of advertising or promoting yourself or any third party; or (iv) is misleading or misrepresentative as to your identity or which in any way suggests that you are sponsored, affiliated or connected with Gonnaway; or (v) you do not otherwise have any rights or the permissions you need to make available</li>
                <li>use our Services or Platforms for any commercial purpose or in any manner which may cause damage to Gonnaway or bring Gonnaway into disrepute</li>
                <li>disassemble, reverse engineer or otherwise decompile any software, applications, updates or hardware contained in or available via our Services or Platforms, except as legally permitted</li>
                <li>copy, distribute, communicate to the public, sell, rent, lend or otherwise use our Services or Platforms, or seek to violate or circumvent any security measures in place to restrict your access to or use of our Services or Platforms</li>
                <li>use or interfere with our Services or Platforms in a way that could damage, disable, overburden, impair or compromise our systems or security or interfere with other user</li>
                <li>introduce onto, or transmit via our Services or Platforms any disruptive or malicious code, virus, worm, or trojan horse, or a ‘denial of service’ or ‘spam’ attack</li>
                <li>remove, alter or replace any notices of authorship, trade marks, business names, logos or other designations of origin on our Services or Platforms, or pass off or attempt to pass off our Services or Platforms as the product of anyone other than Gonnaway</li>

             </ol>
             You also agree not to use any unauthorized automated computer program, software agent, bot, spider or other software or application to scan, copy, index, sort or otherwise exploit our Services or Platforms or the data contained on them. Gonnaway has made a substantial investment in collecting, processing and presenting thousands of travel data suppliers, and offers access to travel data on a commercial API basis, so any breach of this provision is a material breach of these terms and Gonnaway reserves the right to take any technical or legal action to detect and restrict unauthorized automated access to our Services and Platforms.
Where you sign up to our Services or Platforms with a log-in account or password, you are responsible for keeping those password and log-in details confidential and secure. If you become aware, or suspect for any reason, that the security of your log-in details has been compromised, please let us know via our email immediately (<a href="info@gonnaway.com">info@gonnaway.com</a>).
Our Services and Platforms are not intended for children under 16 years of age, and no one under the age of 16 may provide any information to or via our Services or Platforms. We do not knowingly collect personal information from children under 16, and if we learn that we have collected or received personal information from a child under 16, we will delete that information in accordance with our <a href="www.gonnaway.com/#/privacy">Privacy Policy</a>)..
To the extent that any part of our Services or Platforms is hosted on a third party website and there are separate terms of use relating to that website, you agree to comply with those terms and conditions in addition to these Terms.

           </div>
        </div>
    </div>
    <div className="mt-10" />

    <div id="sharing" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>4. Sharing information via our platform</strong></p>
           <div style={{textAlign:"justify"}}>
            <span> We take your privacy seriously and operate at all times in accordance with applicable data protection laws. You acknowledge that any personal data (as defined in our privacy policy) which you submit to or via our Services or Platforms may be used by us in accordance with our privacy policy. You agree to ensure that all personal data you provide to us is accurate and up to date and that you have all consents, licenses or approvals necessary to allow us to use that information in accordance with these Terms, our community guidelines and our privacy policy.
To the extent that our Services or Platforms allow you to post, upload, transmit or otherwise make available any information, images, video, or other data with Gonnaway or other Gonnaway users (“User Content”), you agree that:</span>
            <ol>
                <li>you are solely responsible for User Content that you upload and you represent and agree that you will not share anything which you do not have the permission or right to share or for which you cannot grant the license in paragraph below</li>
                <li>although all intellectual property rights subsisting in any User Content will be owned by you or your licensors and you are always free to share your User Content with anyone else, you hereby grant Gonnaway a non-exclusive, perpetual, royalty-free, worldwide, transferrable and sub-licensable right to host, use, reproduce both electronically and otherwise, publicly display, distribute, modify, adapt, publish, translate, and create derivative works from any and all such User Content (in accordance with our privacy policy) including for the purposes of advertising and marketing our Services and Platforms. You are in control of the User Content you upload to the Gonnaway Services and can terminate this license any time by either deleting the User Content or your Gonnaway account. You can ask to delete the account and any content published on the blog at any time sending an email to <a href="info@gonnaway.com">info@gonnaway.com</a></li>
                <li>we are under no obligation to store, retain, publish or make available any User Content uploaded by you and that you shall be responsible for creating backups of your own User Content</li>
            </ol>
            If you provide us with any suggestions, comments, improvements, ideas or other feedback (“Feedback”), you hereby irrevocably assign ownership of all intellectual property rights subsisting in that feedback to us and acknowledge that we can use and share such Feedback for any purpose at our discretion. You can provide feedback to us by sending an email to <a href="info@gonnaway.com">info@gonnaway.com</a>.
           </div>
        </div>
    </div>
    <div className="mt-10" />

    <div id="property" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>5. Property</strong></p>
           <div style={{textAlign:"justify"}}>
             <span>Except as set out elsewhere in these Terms, all intellectual property rights including copyright (including copyright in computer software), patents, trade marks or business names, design rights, database rights, know-how, trade secrets and rights of confidence in our Services and Platforms (together, the “Intellectual Property Rights”) are owned by or licensed to Gonnaway. You acknowledge that by using the Services or Platforms, you will not acquire any right, title or interest in or to them except for the limited license to use them granted to you by these Terms. You also acknowledge that you have no right to have access to any of the Services or Platforms in source-code form unless it is released under a license specifically permitting such access.
             We respect also the intellectual property rights of others. If you have reason to believe that your copyright is being infringed by any content on our Services or Platforms, please send a written notification of the alleged infringement to <a href="info@gonnaway.com">info@gonnaway.com</a>, for the attention of the Gonnaway founders.</span>
           </div>
        </div>
    </div>
    <div className="mt-10" />

    <div id="price" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>6. Price accuracy and warranty disclaimer</strong></p>
           <div style={{textAlign:"justify"}}>
             <span>Your use of the Services and Platforms is entirely at your own risk.
We provide the Services and Platforms on an ‘as is’ basis and expressly disclaim all warranties, conditions and guarantees of any kind, whether express or implied, including but not limited to, the implied warranties of title, non-infringement, merchantability and accuracy, as well as any warranties implied by usage of trade, course of dealing or course of performance. To the extent permitted by law, we make no representations and do not warrant to you that our Services or Platforms (a) are accurate, complete or up to date; (b) will always be available; (c) will meet your expectations; or (d) are secure or are free from errors, faults, defects, viruses or malware.
            </span>
           </div>
        </div>
      </div>
    <div className="mt-10" />

    <div id="travel" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>7. Travel reservations via Gonnaway</strong></p>
           <div style={{textAlign:"justify"}}>
             <span>Gonnaway is not a travel agent and is not responsible for providing any travel options displayed via the Services or Platforms or for setting or controlling the prices we display. Any Third Party Travel Products which you find and/or book via our Services or Platforms are provided by independent Travel Providers.
Gonnaway facilitates your bookings with Travel Providers but is not responsible and has no liability to you in respect of such bookings. The identity of the relevant Travel Provider and the terms and conditions applicable to your booking with them (“Travel Provider Terms”) will be notified to you at the time of booking and you should ensure that you read and fully understand them before completing your booking.
The Travel Provider Terms should set out what rights you have against the Travel Provider and will explain their liability to you in the event of anything going wrong.
You agree to comply in full with all applicable Travel Provider Terms. You acknowledge that breaching Travel Provider Terms could result in the cancellation of tickets or reservations, revocation of frequent flyer miles and other benefits, and additional charges.
        </span>
           </div>
        </div>
    </div>
    <div className="mt-10" />

    <div id="liability" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>8. Limitations and exclusions of liability</strong></p>
           <p style={{textAlign:"justify"}}>
             These Terms set out the full extent of Gonnaway’s obligations and liabilities in respect of our Services and Platforms.
Gonnaway has no responsibility whatsoever for any arrangements you make with any third party as a result of your use of our Services or Platforms (including, without limitation, any Travel Provider). If you encounter any problems with any booking you make or attempt to make via Gonnaway, you acknowledge that you should resolve that issue with the relevant Travel Provider and that your sole remedy in such circumstances, including any refund, lies with the relevant Travel Provider and not with Gonnaway. Where our Services or Platforms contain links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.
You may see advertising material submitted by third parties on our Services and Platforms. Each individual advertiser is solely responsible for the content of its advertising material and Gonnaway accepts no responsibility for the content of advertising material, including, without limitation, any errors, omissions or inaccuracies.
To the maximum extent permitted by law, we (together with our officers, directors, employees, representatives, affiliates, providers and third parties) do not accept any liability for (a) any inaccuracies or omissions in the content displayed on or via our Services or Platforms; or (b) any act of god, accident, delay or any special, exemplary, punitive, indirect, incidental or consequential loss or damage of any kind (including, without limitation, lost profits or lost savings), whether based in contract, tort (including negligence), strict liability or otherwise, incurred by you arising out of or in connection with your access to, use of, or inability to access or use, our Services or Platforms or their contents.
Nothing in these Terms shall limit or exclude (a) our liability in respect of death or personal injury caused by our negligence or for our fraud or fraudulent misrepresentation; (b) any other liability that cannot be excluded by law; or (c) your statutory rights.

           </p>
        </div>
    </div>
    <div className="mt-10" />

    <div id="termination" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>9. Termination</strong></p>
           <p style={{textAlign:"justify"}}>
             Gonnaway may in its absolute discretion immediately terminate any agreement with you under these Terms at any time upon notice to you and/or, if you have a log-in account, by cancelling your membership and your access to your account and removing any User Content you have uploaded to our Services or Platforms. Gonnaway may suspend your access and use in whole or in part without notice at any time, for example to prevent you from uploading any User Content, without incurring any liability to you whatsoever.
           </p>
        </div>
    </div>
    <div className="mt-10" />

    <div id="provision" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>10. General provisions</strong></p>
           <p style={{textAlign:"justify"}}>
             You acknowledge that your unauthorized use of our Services or Platforms may result in irreparable damage and injury to Gonnaway or licensors for which money damages would be inadequate. Consequently, in the event of such unauthorized use, we and our affiliates and/or licensors (as applicable) shall have the right, in addition to any other legal remedies available, to seek an immediate injunction against you.
The invalidity or unenforceability of any provision (in whole or part) of these Terms shall not affect the validity or enforceability of the remaining provisions (in whole or part). The whole or part of any provision which is held by a court of competent jurisdiction to be invalid or unenforceable shall be deemed deleted from these Terms.
These Terms are personal to you. You shall not be entitled to assign these Terms in whole or in part to any third party without our prior written consent.
These Terms represent the entire agreement between us and you and supersede and replace all and any previous terms, conditions, agreements and arrangements in respect of your use of our Services or Platforms.
We will act promptly to any indications of User Content that is in breach of these Terms. Where you know of or suspect any illegal activities, please contact us at <a href="info@gonnaway.com">info@gonnaway.com</a>.
Any failure by us to enforce any of these Terms shall not be a waiver of them or limit the right to subsequently enforce any of these Terms.
A person who is not a party to these Terms shall have no right to enforce any provision of these Terms.

           </p>
        </div>
    </div>
    <div className="mt-10" />

    <div id="contact" className="relative flex gap-1 justify-center items-center">

        <div className="border-gray-800 md:border-black dark:border-neutral-50 pl-6">
          <p style={{textAlign:"justify"}}><strong>11. Contact us</strong></p>
           <p style={{textAlign:"justify"}}>
             If you require further information about Gonnaway or have any suggestions concerning how to improve our Services or Platforms, please contact to <a href="info@gonnaway.com">info@gonnaway.com</a>.
           </p>
        </div>
    </div>
    <div className="mt-10" />




   </div>

  </div>
 </div>
);
};



export default PageTerms;
