import React, { FC } from "react";
import NcImage from "components/NcImage/ImageCountry";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export interface CardCategory2Props {
  className?: string;
  taxonomy: TaxonomyType;
  index?: string;
}

const CardCategoryCountry: FC<React.PropsWithChildren<React.PropsWithChildren<CardCategory2Props>>> = ({
  className = "",
  taxonomy,
  index,
}) => {
  const { count, name, href = "/", thumbnail, color } = taxonomy;
    const {t} = useTranslation();

  return (
    <Link
      to={href}
      className={`nc-CardCategory2 relative flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategory2"
    >


     <NcImage
        className={`flex-shrink-0 w-40 h-40 rounded-full overflow-hidden`}
        src={thumbnail || ""}
      />

      <div className="mt-3">
        <h2 className={`text-base sm:text-lg font-semibold `}>
          <span className="line-clamp-1">{name}</span>
        </h2>
        <span
          className={`block mt-[2px] text-sm text-neutral-500 dark:text-neutral-400`}
        >
          {count} {t('sectionHereMapArt')}
        </span>
      </div>
    </Link>
  );
};

export default CardCategoryCountry;
