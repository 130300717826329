import {
  setCountries,
  setTopArticles,
  setTopCategories,
  setLatestArticles,
  setTopAuthors,
} from "app/content/content";

import { useAppDispatch, useAppSelector } from "app/hooks";
import axios from "./axios";
import { DEMO_AUTHORS } from "data/authors";
import React, { useEffect } from "react";
import { convertDate } from "utils/convertDate";
import isLiked from "utils/isLiked";
import { selectUser } from "app/user/user";
import {backendAddress} from "./index";


function FetchData() {
  const dispatch = useAppDispatch();

  const {user } = useAppSelector(selectUser)
  useEffect(() => {
    axios
      .get("/countries")
      .then((res: any) => {
        dispatch(setCountries(res.data));
      })
      .catch((err) => console.log("There was an error"));

    axios
      .get("/articles/top")
      .then((res: any) => {
        let data: any = [];
        res.data.map((article: any) => {
        let splitAuthor=article.author_imgurl.split("/");
        let splitBGAuthor;
        if(article.author_imgbgurl)
        {
            splitBGAuthor=article.author_imgbgurl.split("/");
        }
          data.push({
            id: article.article_id,
            title: article.title,
            desc: article.excerpt,
            date: convertDate(article.created_date),
            href: `/blog/article/${article.article_id}`,
            commentCount: article.commentcount,
            readingTime: article.readingtime,
            bookmark: {
              isBookmarked: false,
            },
            like: isLiked(article.likes,user),
            author: {
              id: article.author_id,
              displayName: article.author_name,
              avatar: (splitAuthor[0]==="authors" ? `${backendAddress}/${article.author_imgurl}` : article.author_imgurl),
              avatarbg: ((splitBGAuthor && splitBGAuthor[0]==="authors") ? `${backendAddress}/${article.author_imgbgurl}` : article.author_imgbgurl),
              href: `/blog/author/${article.author_id}`,
            },
            categories: [
              {
                id: article.category_id,
                name: article.category_name,
                href: `/blog/category/${article.category_id}`,
                thumbnail: article.category_thumbnail,
                color: article.category_color,
              },
            ],
          });
        });
        dispatch(setTopArticles(data));
      })
      .catch((err) => console.log("There was an error"));

    axios
      .get("/categories/trending")
      .then((res: any) => {
        let data: any = [];
        res.data.map((category: any) => {
          data.push({
            id: category.category_id,
            name: category.name,
            count: category.postcount | 0,
            href: `/blog/category/${category.category_id}`,
            thumbnail: category.thumbnail,
            color: category.color,
          });
        });
        dispatch(setTopCategories(data));
      })
      .catch((err) => console.log("There was an error"));
    axios
      .get("/articles/latest")
      .then((res: any) => {
        let data: any = [];

        res.data.map((article: any) => {
        let splitAuthor=article.author_imgurl.split("/");
          data.push({
            id: article.article_id,
            title: article.title,
            desc: article.excerpt,
            date: convertDate(article.created_date),
            href: `/blog/article/${article.article_id}`,
            commentCount: article.commentcount,
            readingTime: article.readingtime,
            bookmark: {
              isBookmarked: false,
            },
            like: isLiked(article.likes,user),
            author: {
              id: article.author_id,
              displayName: article.author_name,
              avatar: (splitAuthor[0]==="authors" ? `${backendAddress}/${article.author_imgurl}` : article.author_imgurl),
              href: `/blog/author/${article.author_id}`,
            },
            categories: [
              {
                id: article.category_id,
                name: article.category_name,
                href: `/blog/category/${article.category_id}`,
                thumbnail: article.category_thumbnail,
                color: article.category_color,
              },
            ],
          });
        });
        dispatch(setLatestArticles(data));
      })
      .catch((err) => console.log("There was an error"));
    axios
      .get("/authors/top")
      .then((res: any) => {
        let data: any = [];
        res.data.map((author: any, index: any) => {
        let splitAuthor=author.imgurl.split("/");
        let splitBGAuthor;

        if(author.imgbgurl)
        {
            splitBGAuthor=author.imgbgurl.split("/");
        }
          data.push({
            id: author.author_id,
            displayName: author.name,
            bgImage: ((splitBGAuthor && splitBGAuthor[0]==="authors") ? `${backendAddress}/${author.imgbgurl}` : author.imgbgurl),
            avatar: (splitAuthor[0]==="authors" ? `${backendAddress}/${author.imgurl}` : author.imgurl),
            defaultBG: DEMO_AUTHORS[index].bgImage,
            href: `/blog/author/${author.author_id}`,
            count: author.postcount,
          });
        });
        dispatch(setTopAuthors(data));
      })
      .catch((err) => console.log("There was an error"));
  }, []);

  return <div></div>;
}

export default FetchData;
