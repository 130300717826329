import { FC, useEffect, useRef, useState } from "react";
import Tag from "components/Tag/Tag";
import { SinglePageType } from "./PageSingle";
import SingleAuthor from "./SingleAuthor";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import { useLocation } from "react-router";
import parse from "html-react-parser";
import { CommentType } from "components/CommentCard/CommentCard";
import useFetch from "hooks/useFetch";
import { convertDate } from "utils/convertDate";
import PageLoading from "containers/PageLoading/PageLoading";
import axios from "../../axios";
import isLiked from "utils/isLiked";
import { useAppSelector } from "app/hooks";
import { selectUser } from "app/user/user";
import { useTranslation } from 'react-i18next';
import {backendAddress} from "../../index";
import Page404 from 'containers/Page404/Page404';
import ncNanoId from "utils/ncNanoId";
import Heading from "components/Heading/Heading";



export interface SingleContentProps {
  articleData: SinglePageType;
}

const SingleContent: FC<React.PropsWithChildren<React.PropsWithChildren<SingleContentProps>>> = ({ articleData }) => {
  const { tags, author, content, content_visit,content_moveon,content_meat,content_ospitality, id } = articleData;

  const nest = (
    items: CommentType[],
    id: number | null | undefined
  ): CommentType[] => {
    return items
      .filter((item) => item.parentId === id)
      .map((item) => ({
        ...item,
        children: nest(items, item.id as number | null),
      }));
  };

  const { user } = useAppSelector(selectUser);
  const { loading, error, data }: any = useFetch(`/comments/${id}`);
  const [comments, setComments] = useState<any>([]);
  const [commentCount, setCommentCount] = useState(0);
  const {t} = useTranslation();

  const handleChange = () => {
    axios
      .get(`/comments/${id}`)
      .then((result: any) => {
        if (result.data && result.data[0]) {
          setCommentCount(result.data.length);
          let commentsArray: any = [];

          result.data.map((comment: any) => {
            commentsArray.push({
              id: comment.comment_id,
              content: comment.content,
              author: {
                id: comment.author_id,
                displayName: comment.author_name,
                avatar: backendAddress+"/"+comment.author_imgurl,
                href: `/blog/author/${comment.author_id}`,
              },
              date: convertDate(comment.created_date),
              articleId: comment.article_id,
              childrens: null,
              parentId: comment.parent_id,
              like: isLiked(comment.likes, user),
            });
          });
          setComments(nest(commentsArray, null));
        } else {
          setCommentCount(0);
          setComments([]);
        }
      })
      .catch((err) => {
        console.log("There was an error.");
      });
  };

  useEffect(() => {
    if (data && data[0]) {
      setCommentCount(data.length);
      let commentsArray: any = [];
      data.map((comment: any) => {
        commentsArray.push({
          id: comment.comment_id,
          content: comment.content,
          author: {
            id: comment.author_id,
            displayName: comment.author_name,
            avatar: backendAddress+"/"+comment.author_imgurl,
            href: `/blog/author/${comment.author_id}`,
          },
          date: convertDate(comment.created_date),
          articleId: comment.article_id,
          childrens: null,
          parentId: comment.parent_id,
          like: isLiked(comment.likes, user),
        });
      });
      setComments(nest(commentsArray, null));
    }
  }, [data]);

  const commentRef = useRef<HTMLDivElement>(null);
  //
  const location = useLocation();

  useEffect(() => {
    //  SCROLL TO COMMENT AREA
    if (location.hash !== "#comments") {
      return;
    }
    //
    if (location.hash === "#comments") {
      setTimeout(() => {
        if (commentRef.current) {
          commentRef.current.scrollIntoView();
        }
      }, 500);
    }
  }, [location]);

  if (loading) return <PageLoading />;
  if (error) return <Page404 />;

  return (
    <div className="nc-SingleContent space-y-10 word-break">
      {/* ENTRY CONTENT */}
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark word-break"
      >
        {(content_visit && content_visit!=="null" && content_visit!=="<p><br></p>") && (
            <div>
                <Heading desc="">🌆 {t('page.visit')}</Heading>
                <section style={{ wordWrap: "break-word", textAlign:"justify" }}>
                  {content_visit && parse(content_visit)}
                </section>
            </div>
        )}

        {(content_moveon && content_moveon!=="null" && content_moveon!=="<p><br></p>") && (
            <div>
                <Heading desc="">🛴 {t('page.moveon')}</Heading>
                <section style={{ wordWrap: "break-word", textAlign:"justify" }}>
                  {content_moveon && parse(content_moveon)}
                </section>
            </div>
         )}

        {(content_ospitality && content_ospitality!=="null" && content_ospitality!=="<p><br></p>") && (
          <div>
            <Heading desc="">🏢 {t('page.ospitality')}</Heading>
            <section style={{ wordWrap: "break-word", textAlign:"justify" }}>
              {content_ospitality && parse(content_ospitality)}
            </section>
          </div>
        )}

        {(content_meat && content_meat!=="null" && content_meat!=="<p><br></p>") && (
            <div>
                <Heading desc="">🌮 {t('page.meat')}</Heading>
                <section style={{ wordWrap: "break-word", textAlign:"justify" }}>
                  {content_meat && parse(content_meat)}
                </section>
            </div>
        )}


        {(content && content!=="null" && content!=="<p><br></p>") && (
           <div>
            <Heading desc=""> 💻 {t('page.description')}</Heading>
            <section style={{ wordWrap: "break-word", textAlign:"justify" }}>
              {content && parse(content)}
            </section>
           </div>
        )}
      </div>



      {/* TAGS */}
      <div className="max-w-screen-md mx-auto gap-4">
          <div className="max-w-screen-md flex flex-wrap left-1">
            {tags.map((item: any, index: any) => (
              <Tag key={`${index}${ncNanoId()}`} hideCount tag={item} className="mr-2 mb-2" />
            ))}
          </div>
      </div>

      {/* AUTHOR */}
      <div className="max-w-screen-md mx-auto border-b border-t border-neutral-200 dark:border-neutral-700"></div>
      <div className="max-w-screen-md mx-auto ">
        <SingleAuthor author={author} />
      </div>

      <div className="max-w-screen-md mx-auto border-b border-t border-neutral-200 dark:border-neutral-700"></div>



   {/*<div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
      <div
        id="comments"
        ref={commentRef}
        className="max-w-screen-md mx-auto pt-5"
      >
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          {t('comment.responses')} ({commentCount})
        </h3>
        <SingleCommentForm
          onClickSubmit={handleChange}
          onClickCancel={(id) => console.log("cancel")}
          articleId={id}
        />
      </div>
      {comments[0] && (
        <div className="max-w-screen-md mx-auto break-words">
          <SingleCommentLists
            comments={comments}
            onClickSubmit={handleChange}

          />
        </div>
      )} */}
    </div>
  );
};

export default SingleContent;
