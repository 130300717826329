import { FC } from "react";
import CardHotel from "components/Card3/CardHotel";
import { HotelType } from "data/types";
import ncNanoId from "utils/ncNanoId";

const postsDemo: HotelType[] = [];

export interface SectionGridPostsProps {
  hotels:HotelType[];
  className?: string;
  gridClass?: string;
  headingIsCenter?: boolean;
  postCardName?:
    | "card3"
    | "card4"
    | "card7"
    | "card9"
    | "card10"
    | "card10V2"
    | "card11"
    | "card14"
    | "card15Podcast";
}

const SectionGridPosts: FC<React.PropsWithChildren<React.PropsWithChildren<SectionGridPostsProps>>> = ({
  hotels = postsDemo,
  postCardName = "cardHotel",
  className = "",
  gridClass = "",
  headingIsCenter,
}) => {

  const renderCard = (hotel: HotelType) => {
    let keyHotel="cardHotel"+ncNanoId();
    switch (postCardName) {
      case "card3":
        return <CardHotel key={keyHotel} className="p-3 sm:p-5 2xl:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]" dataHotel={hotel}  />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionGridPosts relative ${className}`}>
      <div className={`gap-6 md:gap-8  ${gridClass}`}>
        {hotels.map((hotel) => renderCard(hotel))}

      </div>

    </div>
  );
};

export default SectionGridPosts;
