import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { DEMO_CATEGORIES } from "data/taxonomies";

interface ContentStateProps {
  countries: {}[];
  topArticles: {}[];
  latestArticles: {}[];
  topCategories: {}[];
  topAuthors: {}[];
  tagTrending:{}[];
}

const initialState: ContentStateProps = {
  countries: [],
  topArticles: [],
  latestArticles: [],
  topCategories: DEMO_CATEGORIES,
  topAuthors: [],
  tagTrending: [],
};

export const contentSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        countries: action.payload,
      };
      return state;
    },
    setTopArticles: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        topArticles: action.payload,
      };
      return state;
    },
    setLatestArticles: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        latestArticles: action.payload,
      };
      return state;
    },
    setTopCategories: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        topCategories: action.payload,
      };
      return state;
    },
    setTopAuthors: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        topAuthors: action.payload,
      };
      return state;
      },

      setTagTrending: (state, action: PayloadAction<[]>) => {
      state = {
        ...state,
        tagTrending: action.payload,
      };
      return state;
    },
  },
});

export const {
  setCountries,
  setTopArticles,
  setLatestArticles,
  setTopCategories,
  setTopAuthors,
  setTagTrending
} = contentSlice.actions;
export const selectCountries = (state: RootState) => state.content.countries;
export const selectTopArticles = (state: RootState) =>
  state.content.topArticles;
export const selectLatestArticles = (state: RootState) =>
  state.content.latestArticles;
export const selectTopAuthors = (state: RootState) => state.content.topAuthors;
export const selectTopCategories = (state: RootState) =>
  state.content.topCategories;

export const selectTagTrending = (state: RootState) =>
  state.content.tagTrending;

export default contentSlice.reducer;
