import React, { FC, useState,useEffect } from "react";
import Heading from "components/Heading/Heading";
import { useTranslation } from "react-i18next";


export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}



export interface SectionStatisticProps {
  className?: string;
}

const SectionStatistic: FC<React.PropsWithChildren<React.PropsWithChildren<SectionStatisticProps>>> = ({ className = "" }) => {
const { t } = useTranslation();
const SUSTAIN_DATA: Statistic[] = [
  {
    id: "1",
    heading: "1.998M tons of CO2",
    subHeading:
      t("sustain.static1"),
  },
  {
    id: "2",
    heading: "11,6%",
    subHeading: t('sustain.static2'),
  },
  {
    id: "3",
    heading: "10%",
    subHeading:
      t('sustain.static3'),
  },
];

///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);


  return (
    <div className={`nc-SectionStatistic relative ${className}`}>
      <Heading
        desc=""
      >
        🚀 Sustainability in a nutshell
      </Heading>
      <div className="grid md:grid-cols-2 gap-5 lg:grid-cols-3 xl:gap-8">

        {SUSTAIN_DATA.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-primary-6000 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-100 md:text-4xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-100 mt-3 sm:text-base dark:text-neutral-200">
              {item.subHeading}
            </span>

          </div>

        ))}

        {windowSize.innerWidth<1024 && (
            <div className="flex flex-wrap">
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2 ">(*) https://www.statista.com/statistics/1222798/carbon-footprint-of-tourism-related-transport-worldwide/</p>
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2 ">(**) https://ourworldindata.org/co2-emissions-from-transport</p>
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2 ">(**) https://greenerideal.com/news/1001-rise-of-the-eco-tourist/</p>
            </div>
        )}


      </div>
      {windowSize.innerWidth>1023 &&(
        <div className="grid grid-cols-3 gap-5 xl:gap-8 break-words">
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2 ">(*) https://www.statista.com/statistics/1222798/carbon-footprint-of-tourism-related-transport-worldwide/</p>
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2">(**) https://ourworldindata.org/co2-emissions-from-transport</p>
                <p className="text-sm leading-none text-neutral-900  dark:text-neutral-200 py-2">(**) https://greenerideal.com/news/1001-rise-of-the-eco-tourist/</p>
        </div>
      )}


    </div>
  );
};

export default SectionStatistic;
