import { FC, useEffect, useState } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import NcImage from "components/NcImage/NcImage";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import ButtonCircle from "components/Button/ButtonCircle";
import Input from "components/Input/Input";
import { useAppSelector } from "app/hooks";
import { selectTopAuthors, selectTopCategories } from "app/content/content";
import { convertDate } from "utils/convertDate";
import PageLoading from "containers/PageLoading/PageLoading";
import useFetch from "hooks/useFetch";
import isLiked from "utils/isLiked";
import { selectUser } from "app/user/user";
import { useTranslation } from 'react-i18next';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import SectionGridPosts from "../PageHome/SectionGridPosts";
import { Fragment } from "react";
import {backendAddress} from "../../index";
import axios from "../../axios";
import { useLocation } from 'react-router-dom';
import Page404 from 'containers/Page404/Page404';
import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";


export interface PageCategoryProps {
  className?: string;
}

const PageCategory: FC<React.PropsWithChildren<React.PropsWithChildren<PageCategoryProps>>> = ({ className = "" }) => {
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const topCategories: any = useAppSelector(selectTopCategories);
  const { user } = useAppSelector(selectUser);
  const {t} = useTranslation();
  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filter.selection") },
    {id: 1, name: t("filter.early") },
    {id: 2, name: t("filter.likes") },
    {id: 3, name: t("filter.comments") },
    //{id: 3, name: "Most Viewed" },
  ];
  const [selected, setSelected] = useState(FILTERS[0]);

  const [articlesCat, setArticlesCat] = useState([]);
  const [articlesBkc, setArticlesBkc] = useState<any>([]);

  const [showPosts, setShowPosts] = useState(24);
  const [text, setText] = useState("");

  const location = useLocation();
  let path = location.pathname.split("/");
  const categoryId = path[3];

  const [category, setCategory] = useState<any>("");
  const [categoryImage,setCategoryImage]=useState<any>("");


  const { loading, error, data } = useFetch(
    `/categories/articles/${categoryId}`
  );



  const showMoreClick = () =>
  {
    setShowPosts(showPosts*2);
  }

  const handleText = (event:any) =>
  {
    event.preventDefault();
    setText(event.target.value);
  }


  useEffect(() => {

    const fetchArticlesData = async () =>{
        setArticlesCat([]);
        setArticlesBkc([]);
        setCategory("");
        setCategoryImage("");

    await axios
      .get("/categories")
      .then(async(res: any) => {
        res.data.map((cat: any) => {

            if(cat.category_id.toString()===categoryId)
            {
                setCategory(cat.name);
                setCategoryImage(cat.thumbnail);
            }

        });
      })
      .catch((err:any)=>{
        console.log("There was an error");
      });


    };
    fetchArticlesData();

    if (data) {
      if (data[0]) {

        let articles: any = [];
        data.map((article: any) => {
          articles.push({
            id: article.article_id,
            title: article.title,
            desc: article.excerpt,
            date: convertDate(article.created_date),
            href: `/blog/article/${article.article_id}`,
            commentCount: article.commentcount | 0,
            readingTime: article.readingtime,
            city_name:article.city,
            content:article.content,
            bookmark: {
              isBookmarked: false,
            },
            like: isLiked(article.likes, user),

            author: {
              id: article.author_id,
              displayName: article.author_name,
              avatar: backendAddress+"/"+article.author_imgurl,
              href: `/blog/author/${article.author_id}`,
            },
            categories: [
              {
                id: article.category_id,
                name: article.category_name,
                href: `/blog/category/${article.category_id}`,
                thumbnail: article.category_thumbnail,
                color: article.category_color,
              },
            ],
          });
        });
        setArticlesCat(articles);
        setArticlesBkc(articles);
       // setCategory(data[0].category_name);

      }


    }

  }, [data]);

  if (loading) return <PageLoading />;
  if (error) return <Page404 />;

 let articleTemp=articlesCat;

 const changeSelectedFilter = (e:any) =>
  {
    setSelected(e);
     switch (e.id){
    default: setArticlesCat(articlesBkc);
            break;
    case 1: articleTemp.sort((a:any, b:any) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()));
            setArticlesCat(articleTemp);
            break;
    case 2: articleTemp.sort((a:any, b:any) => ((a.like.count)  < (b.like.count)) ? 1 : -1);
            setArticlesCat(articleTemp);
            break;
    case 3: articleTemp.sort((a:any, b:any) => ((a.commentCount)  < (b.commentCount)) ? 1 : -1);
            setArticlesCat(articleTemp);
            break;
  }
 }

  const searchArticle = (e:any) =>
  {
    let tempArticleSearch:any=[];
    for(let key in articlesBkc)
    {
        let title:string=articlesBkc[key]['title'].toLowerCase();
        let desc:string=articlesBkc[key]['desc'].toLowerCase();
        let city:string=articlesBkc[key]['city_name'].toLowerCase();
        let authorName:string=articlesBkc[key].author['displayName'].toLowerCase();
        let categoryName:string=articlesBkc[key].categories[0]['name'].toLowerCase();
        let textLower=text.toLowerCase();

        if(title.includes(textLower) || desc.includes(textLower) || city.includes(textLower) || authorName.includes(textLower) || categoryName.includes(textLower))
        {
            tempArticleSearch.push(articlesBkc[key]);
       }
    }
    setArticlesCat(tempArticleSearch);
  }

  return (

    <div className={`nc-PageCategory ${className}`} data-nc-id="PageCategory">
       {category && (
        <>
          <Helmet>
            <title>{category} || Community Travel</title>
          </Helmet>

          <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
            <div className="rounded-3xl relative aspect-w-16 aspect-h-16 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-6 overflow-hidden ">
              <NcImage
                containerClassName="absolute inset-0"
                src={categoryImage}
                className="object-cover w-full h-full"
              />
              <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
                <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
                  {category}
                </h2>
                <span className="block mt-4 text-neutral-300">
                  {data.length} {t('articles.name')}
                </span>
                <header className="w-full max-w-3xl mx-auto text-center flex flex-col items-center">
                  <div className="relative w-full mt-8 sm:mt-11 text-left ">
                    <label
                      htmlFor="search-input"
                      className="text-neutral-500 dark:text-neutral-300"
                    >
                      <span className="sr-only">{t('articles.icon')}</span>
                      <Input
                        id="searchCategory"
                        type="search"
                        placeholder="Type and press enter"
                        sizeClass="pl-14 py-5 pr-5 md:pl-16"
                        onChange={handleText}
                        //defaultValue={category.name}
                      />
                      <ButtonCircle
                            className="absolute right-2.5 top-1/2 transform -translate-y-1/2 bg-slate-700"
                            size=" w-11 h-11"
                            type="submit"
                          >
                        <ArrowSmallRightIcon className="w-6 h-6" />
                      </ButtonCircle>
                      <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                        <svg
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
                          ></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                </header>
              </div>
            </div>
          </div>
        </>
      )}

    <div className="container space-y-16 lg:space-y-28 right-0 mt-10">

        <div className="flex justify-end">
           <div className={`nc-ArchiveFilterListBox ${className}`} data-nc-id="ArchiveFilterListBox" >
              <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                <div className="relative md:min-w-[200px]">
                  <Listbox.Button as={"div"}>
                    <ButtonDropdown>{selected.name}</ButtonDropdown>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900 dark:text-neutral-200 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700">
                      {FILTERS.map((item: ListBoxItemType, index: number) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `${
                              active
                                ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                : ""
                            } cursor-default select-none relative py-2 pl-10 pr-4`
                          }
                          value={item}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`${
                                  selected ? "font-medium" : "font-normal"
                                } block truncate`}
                              >
                                {item.name}
                              </span>
                              {selected ? (
                                <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
      </div>
    </div>


        {articlesCat[0] ? (
          <div className="relative">
            <SectionGridPosts
              className=""
              postCardName="card11"
              heading=""
              subHeading=""
              posts={articlesCat.filter((_:any, i:any) => i < showPosts)}
              gridClass="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8"
              headingIsCenter
            />
            {articlesCat.length > 2 && articlesCat.length>showPosts && (
             <div className="text-center mx-auto mt-8 md:mt-10">
                <ButtonPrimary onClick={showMoreClick}>{t('slider.showMore')}</ButtonPrimary>
             </div>
             )}
             <div className="mt-10" />
          </div>
        ):(
            <span>{t('article.issue')}</span>
        )}



       {/* MORE SECTIONS */}
        {/* === SECTION 5 === */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox categories={topCategories} />
        </div>

        {/* === SECTION 5 === */}
        {topAuthors && topAuthors[0] && (
          <SectionSliderNewAuthors
            heading={t('author.eliteHeading')}
            subHeading={t('author.eliteSubHeading')}
            authors={topAuthors}
          />
        )}

        {/* SUBCRIBES */}
        <SectionSubscribe2 className="py-16 lg:py-28" />
      </div>
    </div>






  );
};

export default PageCategory;
