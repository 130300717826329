import axios from "./axios";
import {backendAddress} from "./index";

export const UNAUTHORIZED_ERROR_CODE = 401;
const REFRESH_TOKEN_LSTORAGE_KEY = "refresh_token"
const ACCESS_TOKEN_LSTORAGE_KEY = "access_token"
const ACCESS_TOKEN_TIMESTAMP_LSTORAGE_KEY = "access_token_timestamp"
const ACCESS_TOKEN_EXPIRE_TIME_IN_MINUTES = 4;

export function setRefreshToken(refresh_token: string){
  localStorage.setItem(REFRESH_TOKEN_LSTORAGE_KEY, refresh_token);
}
export function setAccessToken(access_token: string){
  localStorage.setItem(ACCESS_TOKEN_LSTORAGE_KEY, access_token);
  localStorage.setItem(ACCESS_TOKEN_TIMESTAMP_LSTORAGE_KEY, new Date().toString())
}

export function clearUpTokens(){
  localStorage.removeItem(REFRESH_TOKEN_LSTORAGE_KEY);
  localStorage.removeItem(ACCESS_TOKEN_LSTORAGE_KEY);
  localStorage.removeItem(ACCESS_TOKEN_TIMESTAMP_LSTORAGE_KEY);
}



// @ts-ignore
async function refreshAccessToken() {
  let refresh_token = localStorage.getItem(REFRESH_TOKEN_LSTORAGE_KEY)
  if (refresh_token) {
    return await axios
        .post(backendAddress + "/api/token/refresh/", {
          refresh: refresh_token, // This is the body part
        })
        .then((res: any) => {
          setAccessToken(res.data.access);
          return true;
        }).catch((error: any) => {
          return false;
        });
  }
  return false;
}

async function getAccessToken() {
  let access_token_timestamp = localStorage.getItem(ACCESS_TOKEN_TIMESTAMP_LSTORAGE_KEY)

  // noinspection PointlessBooleanExpressionJS
  let is_access_token_empty = !access_token_timestamp || access_token_timestamp == null;
  let is_access_token_expired = false;

  if (!is_access_token_empty) {
    let now_timestamp = new Date();
    // @ts-ignore
    let access_token_timestamp_as_datetime = new Date(access_token_timestamp);
    let diff_in_minutes = (now_timestamp.getTime() - access_token_timestamp_as_datetime.getTime()) / 1000.0 / 60
    is_access_token_expired = diff_in_minutes > ACCESS_TOKEN_EXPIRE_TIME_IN_MINUTES
  }

  if (is_access_token_empty || is_access_token_expired) {
    //Synchronous reload: refreshAccessToken
    let refresh_access_token_result = await refreshAccessToken();
    // Throw an exception if we couldn't refresh it ( refresh token expired or user not logged in!)
    if (!refresh_access_token_result){
      let error = new Error("User not logged in!");
      // @ts-ignore
      error.code = UNAUTHORIZED_ERROR_CODE
      throw error;
    }
  }

  return localStorage.getItem(ACCESS_TOKEN_LSTORAGE_KEY);
}

export default getAccessToken;
