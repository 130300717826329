import Header from "components/Header/Header";
import React, { FC,useEffect } from "react";
import { useAppSelector } from "app/hooks";
import { selectCurrentPageData } from "app/pages/pages";
import { useLocation } from "react-router-dom";


export interface HeaderContainerProps {
  className?: string;
}

const HeaderContainer: FC<React.PropsWithChildren<React.PropsWithChildren<HeaderContainerProps>>> = ({ className = "" }) => {
  const currentPage = useAppSelector(selectCurrentPageData);

  let pathname = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  return <Header currentPage={currentPage} />;
};

export default HeaderContainer;
