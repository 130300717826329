import { FC, useEffect,useState } from "react";
import {FaMapMarkerAlt, FaRegCalendar} from "react-icons/fa";
import {BsFillPlusSquareFill} from "react-icons/bs";
import {FaPlaneDeparture} from "react-icons/fa";
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import ncNanoId from "utils/ncNanoId";
import Autocomplete from "@mui/material/Autocomplete";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { useThemeMode } from "hooks/useThemeMode";
import axios from "../../axios";
import Slider from '@mui/material/Slider';
import ButtonPrimary from "components/Button/ButtonPrimary";
import { useNavigate } from "react-router";
import NcModal from "components/NcModal/NcModal";
import ButtonClose from "components/ButtonClose/ButtonClose";


interface AirportType {
  value: string;
  name: string;
  city:string;

}

interface Props {
  className?: string;
  itineraryDestination?:any;
  filterData?: any;

}

const NomadFilterDropdown: FC<Props> = ({
  className = "hidden sm:block" ,
  itineraryDestination,
  filterData = (userDestination:any) => {},


}) => {
  const {t} = useTranslation();
  const history = useNavigate();
  const {isDarkMode}=useThemeMode();

  const [openFrag, setOpenFrag] = useState(false);
  const [idCount,setIdCount]=useState(itineraryDestination[itineraryDestination.length-1].id);

  const [openDialog, setOpenDialog] = useState(false);
  const [labelCheck, setLabelCheck] = useState(false);

  const [destinations,setDestinations]=useState<any>(itineraryDestination);

  /*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

   const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/



   const theme = createTheme({
      components: {
        MuiTextField: {
            styleOverrides: {
                root:{
                    background:"transparent",
                    backgroundColor:"transparent",
               },
            },
        },
        MuiAutocomplete: {
          styleOverrides: {
            // Name of the slot
            root: {
              // Some CSS
               background:"transparent",
            },
            input:{
               background:"transparent",
            },
            inputRoot: {
                color:"black",
                background:"transparent",
              "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#e5e7eb",
                  background:"transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#008489"
                },
              borderRadius:"10px",
              fontSize:"13px"
            },
          },
        },
      },
   });

  const themeDark = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                color:"white"
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
           color:"white"
        },
        input:{
                background:"transparent",
                color:"white"
        },
        inputRoot: {
            background:"transparent",
            color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
              color:"white"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489",
              color:"white",
            },
            "& .MuiChip-label": {
              color: "#fff"
            },
            "& .MuiChip-deleteIcon": {
              color: "#1b8489"
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "#fff"
            },
          fontSize:"14px"
        },

      },
    },

    MuiInput: {
        styleOverrides: {
            root:{

          color:"white",
                "&:before": {
                  borderBottom: "1px solid rgb(255,255,255)",
                },
                "&:hover": {
                  borderBottom: "1px solid #008489",
                },
           },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root:{
                color:"white",

           },


        },
    },

    MuiDrawer: {
        styleOverrides: {
            root:{
                height:"130%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "25%" : "90%",
                backgroundColor:"#111827"
           },
        },
    },
    },
});


    /*MANAGE ADD AND REMOVE OF DESTINATION COMPONENT*/
    const addComponent=(idItem:number)=>{
            //let id=idItem;
            setIdCount(idItem);
            setDestinations((currInputs:any) =>
            [...currInputs,
                {
                    id: idItem,
                    name: t('nomad.destination'),
                    city:t('dashboard.post.city'),
                    min_days:3,
                    max_days:5,
                    modified:false,
                    dialogOpen:false

                }
            ]);
    }

    const deleteRow=(item:any)=>{
            var array = destinations.filter((obj:any) => obj !== item);; // make a separate copy of the array
            setDestinations(array);
    }
  /*END*/


  /*END*/

  /*MANAGE DIALOG FUNCTIONALITIES OPEN AND CLOSE*/

  const handleClickOpen = (item:any) => {

    const newState = destinations.map((obj:any) => {
        if (obj.id === item.id) {
          return {...obj, dialogOpen:true};
        }
        return obj;
      });
      setDestinations(newState);

  };

  const handleClickClose = (item:any) => {

    const newState = destinations.map((obj:any) => {
        if (obj.id === item.id) {
          return {...obj, dialogOpen:false};
        }
        return obj;
      });
      setDestinations(newState);

  };



  const filterCallback = () => {
    let check=true;
    for(let key in destinations){
        if(destinations[key].modified===false){
            check=false;
        }
    }
    if(check) {
        filterData(destinations);
    }else{
        setTimeout(() => {
          setLabelCheck(false);
        }, 3000);
        setLabelCheck(true);
    }

  };

  const RenderContent = (item:any) => {
      const [arrivalAirportValue, setArrivalAirportValue] = useState(item.name);
      const [arrivalCity, setArrivalCity] = useState(item.city);
      const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:item.name,name:item.city + " ("+item.name+")",city:item.city}]);
      const [valueArr, setValueArr] = useState<number[]>([item.min_days, item.max_days]);

      /*manage autocomplete*/
      const defaultArrProps = {
        options: airValueArr,
        getOptionLabel: (option: AirportType) => (option.name),
      };

  const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }

                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }

    const updateArrivalAirportFunc = (value:any) => {
        if(value!=null){
            setArrivalAirportValue(value.value);
            setArrivalCity(value.city);
        }
        else{return;}
   };

     const handleClose = (item:any) => {

        const newState = destinations.map((obj:any) => {
            if (obj.id === item.id) {
              return {...obj, name: arrivalAirportValue,min_days:valueArr[0],max_days:valueArr[1], city:arrivalCity,modified:true,dialogOpen:false};
            }
            return obj;
          });
          setDestinations(newState);
  };

   /*RETURN TEXT FOR LABEL SLIDER AND MANAGE DAYS*/
  function valuetext(value: number) {
    return `${value}`;
  }

  const handleChangeArrTime = (event: Event, newValue: number | number[]) => {
    setValueArr(newValue as number[]);
  };


    return(
        <div>
             <div className="flex flex-wrap items-center">

                 <p>{t('nomad.addDestinationSubHeading')}</p>

             </div>
             <div>
                <div className="flex grid grid-cols-10 items-center gap-2 mt-5 ">
                    <p className="mt-2 col-span-1"><FaMapMarkerAlt /></p>
                    <ThemeProvider theme={isDarkMode ? themeDark : theme}>
                       <Autocomplete
                          {...defaultArrProps}
                          id={item.id+ncNanoId()}
                          autoComplete={true}
                          autoSelect={true}
                          onInputChange={(event: any, newValue: any) => onChangeArrivalAirport(event,newValue)}
                          onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                          isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                          style={isDarkMode ? { color: "black"} : {color:"white"}}
                          className="text-gray-800  dark:text-neutral-100 col-span-9"
                          renderInput={(params) => (
                             <TextField {...params} required label={(item.city!=="")?item.city:t('dashboard.post.city')} variant="standard"  />
                          )}
                          loading={true}
                          autoHighlight={true}
                          noOptionsText={t('noOptionTextAutoComplete')}
                       />
                    </ThemeProvider>
                </div>
                 <div className="flex grid grid-cols-10 items-center gap-2 mt-12">
                    <p className="col-span-1"><FaRegCalendar /></p>
                    <div className="col-span-9">
                          <Slider
                               getAriaLabel={() => 'Departure Time'}
                               value={valueArr}
                               onChange={handleChangeArrTime}
                               getAriaValueText={valuetext}
                               min={1}
                               max={20}
                               valueLabelDisplay="on"
                          />
                    </div>
                 </div>
                 <div className="flex justify-end mt-10">
                    <Button style={{backgroundColor:"#008487"}} onClick={(e)=>handleClose(item)}><span className="text-white">{t('nomad.set')}</span></Button>
                 </div>
             </div>
        </div>

    );
  }
  const renderTrigger = () => {
    return null;
  };


  return (
    <div className="">
      {destinations.map((item:any, index:any) => (
                         <div key={index} className="grid grid-cols-10 gap-3 items-center rounded-xl mb-5">
                            <div className="col-span-9 ">
                              <Button type="default" className="  w-full h-full flex " onClick={()=>handleClickOpen(item)}>
                                 <div className="flex flex-wrap" >
                                    <FaPlaneDeparture style={{color:"#008487"}} />
                                    <div className="ml-3 sm:ml-4 space-y-1">
                                      <p className="text-left text-base font-medium text-gray-900 dark:text-gray-200">
                                        {item.city!=="" ? item.city : t('dashboard.post.city')}
                                      </p>
                                      <p className="text-left text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                                        {item.min_days}-{item.max_days} {t('nomad.days')}
                                      </p>
                                    </div>
                                 </div>
                              </Button>
                              <NcModal
                                  isOpenProp={item.dialogOpen}
                                  onCloseModal={()=>handleClickClose(item)}
                                  contentExtraClass="max-w-screen-sm"
                                  renderContent={()=>RenderContent(item)}
                                  renderTrigger={renderTrigger}
                                  modalTitle={t('nomad.addDestination')}
                                />
                            </div>

                            <div className="col-span-1">
                                <p className="col-span-1 text-xs sm:text-sm  justify-end">
                                     <ButtonClose
                                        onClick={()=>deleteRow(item)}
                                        className=""
                                     />
                               </p>
                            </div>
                         </div>
                    ))}

                    <p className="flex flex-wrap gap-3 text-sm font-semibold items-center " onClick={(e)=>addComponent(idCount+1)}>
                         <BsFillPlusSquareFill className="ml-1" style={{color:"#008487"}}  />
                         <span className="text-neutral-900 dark:text-neutral-100">{t('nomad.addDestination')}</span>
                    </p>

                    {destinations.length>=2 &&(
                           <ButtonPrimary className="nc-BtnSubmitSearch mb-2 mt-5" onClick={filterCallback}>{t('findtripflight')}</ButtonPrimary>
                    )}

                    {labelCheck &&(
                        <p className="text-neutral-900 dark:text-neutral-100">{t("nomad.compile")}</p>
                    )}

    </div>
  );
};

export default NomadFilterDropdown;
