import React, { FC, useState } from "react";
import ButtonCircle from "components/Button/ButtonCircle";
import NcImage from "components/NcImage/NcImage";
import Input from "components/Input/Input";
import { CircularProgress } from "@mui/material";
import axios from "../../axios";
import { useTranslation } from 'react-i18next';
import Label from 'components/Label/Label';
import ButtonPrimary from "components/Button/ButtonPrimary";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import {useLocation} from "react-router-dom";




export interface PageResetPwd {
  className?: string;
}

const PageResetPassword: FC<React.PropsWithChildren<React.PropsWithChildren<PageResetPwd>>> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [password, setPassword] =useState("");
  const [passwordRepeat, setPasswordRepeat] =useState("");
  const [activeLabel, setActiveLabel] = useState(false);
  const [activeLabelPwd, setActiveLabelPwd] = useState(false);
  const [email, setEmail] = useState("");
  const {t} = useTranslation();
  const history = useNavigate();
  const location = useLocation();
  let paths = location.pathname.split("/");
  let uid = "";
  let token = "";
  if(paths.length > 0){
    let path_last_element_index = paths[paths.length - 1] !== "" ?paths.length -1 : paths.length -2
    token = paths[path_last_element_index];
    uid = paths[path_last_element_index-1];
  }


  const handleChangePwd = (e: any) => {
    setPassword(e.target.value);
  };

  const handleChangePwdRepeat = (e: any) => {
    setPasswordRepeat(e.target.value);
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();

    if(password!=passwordRepeat){
       setTimeout(() => {
          setActiveLabelPwd(false);
        }, 5000);
        setActiveLabelPwd(true);
        return;
    }


    setSubmitting(true);
    axios
      .post("/api/password/reset/", {
        email: email,
        new_password1:password,
        new_password2:password,
        token: token,
        uidb64: uid
      })
      .then((res: any) => {
        setSubmitting(false);
        setTimeout(() => {
          setActiveLabel(false);
          history(`/login`);
        }, 2000);
        setActiveLabel(true);

      })
      .catch((err) => {
        //true for avoid to give information regarding emails in our db to attackers. In case the backend does not remove any account
        setSubmitting(true);
        history(`/page404`);
      });

  };

  return (
    <div className="nc-PageSingle overflow-hidden relative">
      <Helmet>
        <title>Gonnaway.com || Password Reset</title>
      </Helmet>

       {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      {/* ======= START CONTAINER ============= */}

        <div className="container relative flex items-center justify-center mb-14">

          <div className="container flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl"> Reset password 🔐</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          {t('reset.pwd')}
        </span>

        <form className="grid md:grid-cols-2 gap-6" onSubmit={handleSubmit}>
        <label className="block">
          <Label>{t('password.new')}</Label>
          <Input
            placeholder="***"
            type="password"
            className="mt-1"
            name="newPwd"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.password'))}
            value={password}
            onChange={handleChangePwd}
            required
          />
        </label>
        <label className="block">
          <Label>{t('password.newrep')}</Label>
          <Input
            type="password"
            minLength={8}
            className="mt-1"
            name="repeatPwd"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.password'))}
            value={passwordRepeat}
            onChange={handleChangePwdRepeat}
          />
        </label>
        <ButtonPrimary className="md:col-span-2">
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('password.update')}</span>
          )}
        </ButtonPrimary>
      </form>
        {activeLabelPwd ? (
            <Label className="mt-10">{t("password.mismatch")} 🔏</Label>
         ) : ( <div />)}

        {activeLabel ? (
            <Label className="mt-10">{t("password.changed")} 🔏</Label>
         ) : ( <div />)}
      </div>
        </div>
      </div>

  );
};

export default PageResetPassword;
