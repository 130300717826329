import { useAppSelector,useAppDispatch } from "app/hooks";
import {removeUser, selectUser} from "app/user/user";
import axios from "../../axios";
import NcImage from "components/NcImage/NcImage";
import NcLink from "components/NcLink/NcLink";
import PageLoading from "containers/PageLoading/PageLoading";
import { useEffect, useState } from "react";
import { convertDate } from "utils/convertDate";
import { useTranslation } from 'react-i18next';
import {backendAddress} from "../../index";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import ButtonSecondary from "components/Button/ButtonSecondary";
import {createTheme,  ThemeProvider } from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { TrashIcon } from "@heroicons/react/24/solid";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from "react-router";
import Layout from "./layout";


const DashboardPosts = () => {
  const user: any = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [articles, setArticles] = useState<any>([]);
  const [reload, setReload] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const [showPosts, setShowPosts] = useState(5);
  const [idToDelete, setIdToDelete] = useState();
  const [isDeleting, setIsDeleting] = useState(false);
  const openModalDeleteArticle = () => setIsDeleting(true);
  const closeModalDeleteArticle = () => setIsDeleting(false);
  const history = useNavigate();

  //POPOVER MANAGEMENT
  const [anchorEl, setAnchorEl] = useState <HTMLSpanElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLSpanElement>, id:any) => {
    setAnchorEl(event.currentTarget);
    openModalDeleteArticle();
    setIdToDelete(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsDeleting(false);
  };

  const handleClickSubmitForm = () => {
    setLoading(true);
    getAccessToken().then((access_token: any) => {
        axios
          .post(`/articles/delete/${idToDelete}`, {}, {headers: {'Authorization': `Bearer ${access_token}`}})
          .then((result) => {
            closeModalDeleteArticle();
            if (result.status === 200) {
                for(var key in articles)
                {
                    if(articles[key].article.id === idToDelete)
                    {
                       let tempData=articles;
                       var index = tempData.indexOf(articles[key]);
                       if (index !== -1) {
                            tempData.splice(index, 1);
                            setArticles(tempData);
                       }
                    }
                }
                setLoading(false);
            }
          })
          .catch((err) => {
            console.log("There was an error");
          });
    }).catch((err) => {
          if(err.code && err.code === UNAUTHORIZED_ERROR_CODE){
              dispatch(removeUser());
              history("/login");
          }
    });
  };
  //

  const theme = createTheme({
  components: {
    MuiPopover: {
      styleOverrides: {
        root: {
            marginTop:"5px",
        },
      },
    },
   }
});

   const showMore = () =>
  {
    setShowPosts(showPosts*2);
  }

  function handleLogout (){
    dispatch(removeUser());
  };

  useEffect(() => {
     //get post data
    setLoading(true);
    setArticles([]);
    getAccessToken().then((access_token: any) => {
    axios
      .get(`/authors/${user.user.id}/articles?include_drafts=True`, {headers: {'Authorization': `Bearer ${access_token}`}})
      .then((result) => {
        setLoading(false);
        let data: any = result.data;
        if (data.articles[0]) {
          let articlesData: any = [];
          data.articles.map((article: any) => {
            articlesData.push({
              article:{
                  id: article.article_id,
                  featuredImage: `${backendAddress}/${article.imgurl}`,
                  title: article.title,
                  desc: article.excerpt,
                  date: convertDate(article.created_date),
                  href: `/blog/article/${article.article_id}`,
                  commentCount: article.comments?.length | 0,
                  readingTime: article.readingtime,
                  country_name: article.country,
                  is_draft: article.is_draft,
              },

              bookmark: {
                isBookmarked: false,
              },
              like: {
                count: article.likes.length | 0,
                isLiked: false,
              },
              author: {
                id: article.author_id,
                displayName: article.author_name,
                avatar: `${backendAddress}/${article.author_imgurl}`,
                href: `/blog/author/${article.author_id}`,
              },
              categories: [
                {
                  id: article.category_id,
                  name: article.category_name,
                  href: `/blog/category/${article.category_id}`,
                  thumbnail: article.category_thumbnail,
                  color: article.category_color,
                },
              ],
            });
          });
          articlesData.sort((a:any, b:any) => ((a.article.id)  < (b.article.id)) ? 1 : -1);
          setArticles(articlesData);
        } else {setArticles([]);}
      }).catch((err) => {
        setLoading(false);
        setError(err);
      });
    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                handleLogout();
                history("/login");
        }
    });
  }, []);



  const onClickSubmit = () => {
    setReload(true);
  };

  if (error) return <h1>Something went wrong ! {error}</h1>;

  const handleClickDropDown = (id:any) => {
      openModalDeleteArticle();
      setIdToDelete(id);
  };

  return (
    <Layout>
    {loading ? (
        <PageLoading />

    ):(

    <div className="flex flex-col space-y-8">

      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full px-1 sm:px-6 lg:px-8">
          <div className="shadow dark:border dark:border-neutral-800 overflow-hidden sm:rounded-lg">
            {articles[0] ? (
              <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
                <thead className="bg-neutral-50 dark:bg-neutral-800">
                  <tr className="text-left text-xs font-medium text-neutral-500 dark:text-neutral-300 uppercase tracking-wider">
                    <th scope="col" className="px-6 py-3">
                      {t('dashboard.articleName')}
                    </th>
                    <th scope="col" className="px-6 py-3">
                      {t('dashboard.status')}
                    </th>
                    <th scope="col" className="px-6 py-3 text-right">
                      {t('dashboard.post.editremove')}
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white dark:bg-neutral-900 divide-y divide-neutral-200 dark:divide-neutral-800">
                  {articles.filter((_:any, i:any) => i < showPosts).map((item: any,index:any) => (
                    <tr key={item.article.id}>
                      <td className="px-6 py-4">
                        <section className="flex items-center w-96 lg:w-auto max-w-md overflow-hidden">
                          <NcImage
                            containerClassName="flex-shrink-0 h-12 w-12 rounded-lg overflow-hidden lg:h-14 lg:w-14"
                            src={item.article.featuredImage}
                          />
                          <section className="ml-4 flex-grow">
                            <h2 className="inline-flex line-clamp-2 text-sm font-semibold  dark:text-neutral-300">
                              {item.article.title}
                            </h2>

                          </section>
                        </section>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {!item.article.is_draft ? (
                          <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-teal-100 text-teal-900 lg:text-sm">
                            Active
                          </span>
                        ) : (
                          <span className="px-2 inline-flex text-sm text-neutral-500 dark:text-neutral-400 rounded-full">
                            Draft
                          </span>
                        )}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium text-neutral-300">
                        <NcLink
                          to={`/blog/edit-post/${item.article.id}`}
                          className="text-primary-800 dark:text-primary-500 hover:text-primary-900"
                        >
                          {t('dashboard.post.edit2')}
                        </NcLink>
                        {` | `}
                        <span
                          onClick={(e:any)=>handleClick(e, item.article.id)}
                          className="text-rose-600 hover:text-rose-900 cursor-pointer"
                        >
                          {t('dashboard.post.remove')}
                        </span>
                        <ThemeProvider theme={theme}>
                         <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={isDeleting}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                              <MenuItem onClick={handleClickSubmitForm}>
                                  <ListItemIcon>
                                    <TrashIcon className="w-5 h-5" style={{color:"#d4062c"}} aria-hidden="true" />
                                  </ListItemIcon>
                                  <ListItemText> {t('delete.delete')}</ListItemText>
                              </MenuItem>
                          </Menu>
                      </ThemeProvider>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="container px-6 py-4 whitespace-nowrap text-center text-sm font-medium text-neutral-300">
                {t('articles.notfound')}
              </div>
            )}
          </div>
         { articles.length > 5 && articles.length>showPosts &&  (
                 <div className="text-center mx-auto mt-8 md:mt-10">
                    <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                 </div>
          )}
          <div className="mt-10" />
        </div>
      </div>
    </div>
   )}
    </Layout>
  );
};

export default DashboardPosts;
