import React, {FC, useState,useEffect } from 'react';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import DateRangePickerBox from 'components/DatePicker/ReactDates';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router";
import {FaMapMarkerAlt,FaRegCalendar,FaStream, FaUserFriends,FaMoneyBillWave, FaStopwatch20,FaPlaneArrival,FaGem,FaBriefcase} from 'react-icons/fa';
import { setStateToUrl } from 'helpers/url_handler';
import { FlightSearch,AirlineList} from "data/types";
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import Slider from '@mui/material/Slider';
import {getCurrency} from "locale-currency";
import { FLIGHT_DETAILS_PAGE } from 'data/constant';
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Drawer from '@mui/material/Drawer';
import {IoFilterSharp} from 'react-icons/io5';
import ButtonPrimary from "components/Button/ButtonPrimary";
import ncNanoId from "utils/ncNanoId";
import Input from "components/Input/Input";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "../../../../axios";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import moment from 'moment';
import Button from "components/Button/Button";
import { isMobile} from 'react-device-detect';



import { IoIosArrowDown } from 'react-icons/io';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  FilterArea,
  FilterElementsWrapper,
  RoomGuestWrapper,
  ItemWrapper,
  DivHidden
} from './MobileSearchView.style';


export interface ModalCategoriesProps {
  dataSearch: FlightSearch;
  listAir: AirlineList[];
  budgetData:number;
  changeSelectedFilter?:()=>void;
  filterData?: () =>void;

}

export interface ListBoxClassItemType {
  id: number;
  name: string;
  type:string;


}



interface QuerySearch {
  adults_count: number;
  child_counts: number;
  infants_count:number;
  departure_airport:string;
  arrival_airport:string;
  with_return:boolean;
  flight_class:string;
  currency:string;
  outbound_date?:any;
  return_date?:any;
  range_departure?:number[];
  range_return?:number[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  city_departure?:string;
  city_return?:string;
  max_departure_stopovers?:number;
  max_return_stopovers?:number;
  budget?:number;
  airlines?:string[];

}

export interface AirportType {
  value: string;
  name: string;
  city?:string;
}



const FilterDrawer: FC<React.PropsWithChildren<React.PropsWithChildren<ModalCategoriesProps>>> = ({
    dataSearch,
    listAir,
    budgetData,
    changeSelectedFilter=(select:any)=>{},
    filterData = (budget:number, airName:any,dep:any,arr:any) => {},

    }) => {

  const {t} = useTranslation();

    const [searchDate, setSearchDate] = useState({
        setStartDate: dataSearch.departDate,
        setEndDate: dataSearch.arrivalDate,
      });

  const [oneWayDate, setOneWayDate] = useState({
    setDate: dataSearch.departDate,
  });

  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (dataSearch.adults);
  const [flightChild,setFlightChild] = useState (dataSearch.children);
  const [flightInfant,setFlightInfant] = useState (dataSearch.infants);
  const [flightBudget,setFlightBudget] = useState (budgetData);
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:"",city:""}]);
  const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:"",city:""}]);

  const [airValueCity, setAirValueCity] = useState(dataSearch.city_departure);
  const [airValueArrCity, setAirValueArrCity] = useState(dataSearch.city_return);
  const [departureAirportValue, setDepartureAirportValue] = useState(dataSearch.departAirport);
  const [arrivalAirportValue, setArrivalAirportValue] = useState(dataSearch.arrivalAirport);
  const [bagAdult,setBagAdult] = useState (dataSearch.adult_hold_bag || 0);
  const [hBagAdult,setHBagAdult] = useState (dataSearch.adult_hand_bag ||0);

    //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };


  //manage departure time

  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filterFH") },
    {id: 1, name: t('filter.best') },
    {id: 2, name: t('filter.cheapest') },
    {id: 3, name: t('filter.duration') },
  ];

  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 0, name: "Any", type:"Y" },
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];

  const [handleClass,setHandleClass] = useState (0);

   useEffect(() => {
      for (let key in FILTERS_CLASS){
        if(FILTERS_CLASS[key].type===dataSearch.class){
            setHandleClass(FILTERS_CLASS[key].id);
            break;
        }
      }
   },[]);

  const [selected, setSelected] = useState(FILTERS[1]);



  function valuetext(value: number) {
    return `${value}:00`;
  }



  const [valueDep, setValueDep] = React.useState<number[]>([dataSearch.range_departure!== undefined ? parseInt(dataSearch.range_departure.split(",")[0]) : 0,dataSearch.range_departure!== undefined ? parseInt(dataSearch.range_departure.split(",")[1]) : 23]);


  const handleChangeDepTime = (event: Event, newValue: number | number[]) => {
    setValueDep(newValue as number[]);
  };

  const [valueArr, setValueArr] = React.useState<number[]>([dataSearch.range_return!== undefined ? parseInt(dataSearch.range_return.split(",")[0]) : 0,dataSearch.range_return!== undefined ? parseInt(dataSearch.range_return.split(",")[1]) : 23]);

  const handleChangeArrTime = (event: Event, newValue: number | number[]) => {
    setValueArr(newValue as number[]);
  };

///////

  const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
  };


  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);
  };

   const handleIncrementBudget = () => {
    setFlightBudget(flightBudget+100);
  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);

  };


  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);}

  };

  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
    }
  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
    }
  };

  //set guests number
  const handleDecrementBudget = () => {
    if(flightBudget<=0)
    {
        setFlightBudget(0);
    } else {
      setFlightBudget(flightBudget-100);
    }
  };

   const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
    }
  };


///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);





  //DATE
  let oneOrReturn:any;
  let boolDate= (dataSearch.arrivalDate) ? true : false;
  let sDate=moment(dataSearch.departDate,'DD-MM-YYYY');
  let eDate=dataSearch.arrivalDate ? moment(dataSearch.arrivalDate,'DD-MM-YYYY') : "";
   switch (boolDate)
   {
        default:
        case true:
                    oneOrReturn=(
                                <DateRangePickerBox
                                   id="date_picker"
                                   item={calendarItem}
                                   startDateId="startDateId-id-home"
                                   endDateId="endDateId-id-home"
                                   updateSearchData={(setDateValue:any) => {setSearchDate(setDateValue);}}
                                   showClearDates={true}
                                   small={true}
                                   numberOfMonths={1}
                                   required={true}
                                   startDate={sDate}
                                   endDate={eDate}
                                   startDatePlaceholderText={dataSearch.departDate}
                                   endDatePlaceholderText={dataSearch.arrivalDate}
                                   firstDayOfWeek={0}
                                   displayFormat="DD-MM-YYYY"
                                   onFocusChange={(e:any) => e.target.blur()}
                                   readOnly={isMobile ? true : false}
                                 />

                    );
                    break;
        case false:
                oneOrReturn=(

                                <SingleDatePicker
                                numberOfMonths={1}
                                small={true}
                                required={true}
                                onDateChange={(dateValue:any) => {setOneWayDate(dateValue);}}
                                placeholder= {dataSearch.departDate}
                                initialDate={sDate}
                                displayFormat="DD-MM-YYYY"
                                readOnly={isMobile ? true : false}

                              />
                 );
                    break;
   }

   //CHECK Airport
  const defaultDepProps = {
    options: airValue,
    getOptionLabel: (option: AirportType) => option.name,
  };

   const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => option.name,
  };

  const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
              }
                setAirValue(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }
    const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}

        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")",city:item.city_name});
                    });
                }
                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });

    }


  const updateDepartureAirportFunc = (value:any) => {
    if(value!=null){
     setDepartureAirportValue(value.value);
     setAirValueCity(value.city);
     }else{return;}
   };

  const updateArrivalAirportFunc = (value:any) => {
    if(value!=null){
        setArrivalAirportValue(value.value);
        setAirValueArrCity(value.city)
    }
    else{return;}
   };


  ///



  const history = useNavigate();
  const handleApplyFilter = () => {
    setToggle(false);

    var query : QuerySearch= {
      departure_airport: departureAirportValue,
      arrival_airport: arrivalAirportValue,
      city_departure:airValueCity,
      city_return:airValueArrCity,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      child_counts: flightChild,
      infants_count: flightInfant,
      flight_class: FILTERS_CLASS[handleClass].type,
      with_return: boolDate,
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale:navigator.language,
      adult_hold_bag:bagAdult||0,
      adult_hand_bag:hBagAdult||0,
      child_hold_bag:0,
      child_hand_bag:0,
      range_departure:valueDep,
      budget:flightBudget,


    };

    if(boolDate){
        query["outbound_date"] = searchDate.setStartDate;
        query["return_date"] = searchDate.setEndDate;
        query["range_return"] = valueArr;
    }else{
        query["outbound_date"]= oneWayDate.setDate;
    }


    if(nameAirplane.length>0){
        query["airlines"] = nameAirplane;
    }


    if(stopPlanDepSelected !==100) query["max_departure_stopovers"] = stopPlanDepSelected;
    if(stopPlanRetSelected !==100 && boolDate) query["max_return_stopovers"] = stopPlanRetSelected;
    const search = setStateToUrl(query);


    history({
      pathname: FLIGHT_DETAILS_PAGE,
      search: search,
    });
  };



  const [toggle, setToggle] = useState(false);

  const handleToggleDrawer = () => {
    setToggle(!toggle);
    window.scrollTo(0, 0);
  };


   const [nameAirplane, setNameAirplane] = React.useState<string[]>([]);
   const [airNameCheck, setAirNameCheck] = React.useState<AirlineList[]>([]);
   useEffect(() => {
      function initAir(){
        let tempForChecked:AirlineList[]=[];
        let tempAirList : string[]=dataSearch.airlines!==undefined ? dataSearch.airlines.split(",") : [];
        let tempNameAir:any=[];
        for(let y in listAir){
            let checkBool=false;
            for(let key in tempAirList){
                 if(tempAirList[key]===listAir[y].icon) checkBool=true;
            }
            if(checkBool){
                    tempForChecked.push({
                        icon:listAir[y].icon,
                        name:listAir[y].name,
                        checked:true
                    });
                    tempNameAir.push(listAir[y].icon);
                }else{
                    tempForChecked.push({
                        icon:listAir[y].icon,
                        name:listAir[y].name,
                        checked:false
                    });
                }

        }

        setAirNameCheck(tempForChecked)
        setNameAirplane(tempNameAir);
      }
      initAir();
   },[]);


   const handleToggleAirP = (value:any) => () => {
        let nameAir=nameAirplane;
        let findItem = nameAir.find((x:any) => x===value.icon);
        if(!findItem){
            nameAir.push(value.icon);
            //update state
            const newState = airNameCheck.map((obj:AirlineList) => {
                if (obj.icon === value.icon) {
                  return {...obj, checked: true};
                }

                // 👇️ otherwise return the object as is
                return obj;
              });

            setAirNameCheck(newState);
        }else{
            let temp=nameAirplane.filter((item:string) => {return item !== value.icon;});
            nameAir=temp;
            //update state
            const newState = airNameCheck.map((obj:AirlineList) => {
                if (obj.icon === value.icon) {
                  return {...obj, checked: false};
                }
                return obj;
            });
            setAirNameCheck(newState);

        }

        setNameAirplane(nameAir);

  };



  const handleCloseDrawer = () => {
  window.scrollTo(0, 0);
    setToggle(false);
    handleApplyFilter();
  };

  /*management of Accordion expansion*/
  const [exCity,setExCity]=useState(true);
  const [exDate,setExDate]=useState(true);
  const [exGuest,setExGuest]=useState(true);
  const [exBudget,setExBudget]=useState(true);
  const [exTime,setExTime]=useState(true);
  const [exAirline,setExAirline]=useState(true);
  const [exClass,setExClass]=useState(true);
  const [exBags,setExBags]=useState(true);

  const handleSetExCity=(e:any)=>{
    e.preventDefault();
    setExCity(!exCity);
  }

  const handleSetExDate=(e:any)=>{
    e.preventDefault();
    setExDate(!exDate);
  }

  const handleSetExGuest=(e:any)=>{
    e.preventDefault();
    setExGuest(!exGuest);
  }

  const handleSetExBags=(e:any)=>{
    e.preventDefault();
    setExBags(!exBags);
  }

  const handleSetExClass=(e:any)=>{
    e.preventDefault();
    setExClass(!exClass);
  }

  const handleSetExBudget=(e:any)=>{
    e.preventDefault();
    setExBudget(!exBudget);
  }

  const handleSetExTime=(e:any)=>{
    e.preventDefault();
    setExTime(!exTime);
  }

  const handleSetExAirline=(e:any)=>{
    e.preventDefault();
    setExAirline(!exAirline);
  }
  /*end management of expanded Accordion*/



/*Management stopover*/
  const [stopPlansRet,setStopPlansRet]=useState([
      { id: 100, stopNumber: 100, stopLabel:"All",checked:(dataSearch.max_return_stopovers!==3 && dataSearch.max_return_stopovers!==2 && dataSearch.max_return_stopovers!==1 && dataSearch.max_return_stopovers!==0) ? true : false },
      { id: 0, stopNumber: 0, stopLabel:t('nomad.direct'),checked:dataSearch.max_return_stopovers===0 ? true : false },
      { id: 1, stopNumber: 1, stopLabel:t('stopover.mng')+1 +" "+t('stopover.one'),checked:dataSearch.max_return_stopovers===1 ? true : false },
      { id: 2, stopNumber: 2, stopLabel:t('stopover.mng')+2 +" "+t('stopover.plus'),checked:dataSearch.max_return_stopovers===2 ? true : false },
      { id: 3, stopNumber: 3, stopLabel:t('stopover.mng')+3 +" "+t('stopover.plus'),checked:dataSearch.max_return_stopovers===3 ? true : false },

    ]);

  const [stopPlansDep,setStopPlansDep]=useState([
      { id: 100, stopNumber: 100, stopLabel:"All",checked:(dataSearch.max_departure_stopovers!==3 && dataSearch.max_departure_stopovers!==2 && dataSearch.max_departure_stopovers!==1 &&dataSearch.max_departure_stopovers!==0 ) ? true : false },
      { id: 0, stopNumber: 0, stopLabel:t('nomad.direct'),checked:dataSearch.max_departure_stopovers===0 ? true : false },
      { id: 1, stopNumber: 1, stopLabel:t('stopover.mng')+1 +" "+t('stopover.one'),checked:dataSearch.max_departure_stopovers===1 ? true : false },
      { id: 2, stopNumber: 2, stopLabel:t('stopover.mng')+2 +" "+t('stopover.plus'),checked:dataSearch.max_departure_stopovers===2 ? true : false },
      { id: 3, stopNumber: 3, stopLabel:t('stopover.mng')+3 +" "+t('stopover.plus'),checked:dataSearch.max_departure_stopovers===3 ? true : false },
    ]);

  let idStopDepPlan=(dataSearch.max_departure_stopovers===0 ? 0 :(dataSearch.max_departure_stopovers===1 ? 1 :(dataSearch.max_departure_stopovers===2 ? 2 :(dataSearch.max_departure_stopovers===3 ? 3 : 100))));
  let idStopRetPlan=(dataSearch.max_return_stopovers===0 ? 0 :(dataSearch.max_return_stopovers===1 ? 1 :(dataSearch.max_return_stopovers===2 ? 2 :(dataSearch.max_return_stopovers===3 ? 3 : 100))));

  const [stopPlanDepSelected, setStopPlanDepSelected] = useState(idStopDepPlan);
  const [stopPlanRetSelected, setStopPlanRetSelected] = useState(idStopRetPlan);

   // handle checked radio button

   const handleStopPlanRetSelected = (value:number) => {
     setStopPlanRetSelected(value);
     let tempArray=stopPlansRet;

     for(let key in tempArray){
        if(tempArray[key].id===value){
            tempArray[key].checked=true;

        }
        else{tempArray[key].checked=false;}
     }
     setStopPlansRet(tempArray);
  };

  const handleStopPlanDepSelected = (value:number) => {
     setStopPlanDepSelected(value);
     let tempArray=stopPlansDep;

     for(let key in tempArray){
        if(tempArray[key].id===value){
            tempArray[key].checked=true;
        }
        else{tempArray[key].checked=false;}
     }
     setStopPlansDep(tempArray);
  };

  const [exStopOver,setExStopOver]=useState(true);
  const [exStopOverRet,setExStopOverRet]=useState(true);

  const handleSetExStopOver=(e:any)=>{
    e.preventDefault();
    setExStopOver(!exStopOver);
  }

  const handleSetExStopOverRet=(e:any)=>{
    e.preventDefault();
    setExStopOverRet(!exStopOverRet);
  }



  /*END*/
const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
            color:"grey",
        },
        input:{
                background:"transparent",
        },
        inputRoot: {
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
          fontSize:"14px"
        },

      },
    },


    MuiDrawer: {
        styleOverrides: {
            root:{
                height:"130%",
           },
           paper:{
                width:"90%",



           },
        },
    },
    },
});


  return (
    <FilterArea>
        <div className=" container flex flex-wrap items-center" onClick={handleToggleDrawer} >
           <IoFilterSharp  style={{color:"#008489",fontSize:"28"}} /><b><span className="text-lg ml-2">{t('filter.title')} </span></b>
        </div>


        <ThemeProvider theme={theme}>
            <Drawer
                anchor="left"
                open={toggle}
                onClose={handleCloseDrawer}

              >


        <div>



        <DivHidden>
        <div className="bg-primary-6000">
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-10"></div>
          <div className="w-full">
            <b><h2 className="text-white mt-5 text-center ">{t("filter.search")}</h2></b>
          </div>
          <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-5"></div>
          </div>
        <div className="container">
        <FilterElementsWrapper>

          <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>

            {/* Start city filter element */}
            <AccordionItem dangerouslySetExpanded={exCity}>
              <AccordionItemHeading onClick={(e)=>handleSetExCity(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaMapMarkerAlt className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900">{t('filter.airport')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <ThemeProvider theme={theme}>
                <Autocomplete
                        {...defaultDepProps}
                        id="departure"
                        autoComplete={true}
                        autoSelect={true}
                        onInputChange={(e:any, newValue: any) => onChangeDepartureAirport(e,newValue)}
                        onChange={(event:any, newValue:any)=>updateDepartureAirportFunc(newValue)}
                        isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                        style={{ color: "black"}}
                        className="text-gray-800"
                        autoHighlight={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={departureAirportValue}
                            variant="standard"

                              />
                        )}
                        loading={true}
                        noOptionsText={t('noOptionTextAutoComplete')}
                  />

                  <Autocomplete
                    {...defaultArrProps}
                    id="arrival"
                    autoComplete={true}
                    autoSelect={true}
                    onInputChange={(event: any, newValue: any) => onChangeArrivalAirport(event,newValue)}
                    onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                    isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                    style={{ color: "black"}}
                    className="text-gray-800 zIndex-10000"
                    renderInput={(params) => (
                      <TextField {...params} label={arrivalAirportValue} variant="standard"  />
                    )}
                    loading={true}
                    autoHighlight={true}
                    noOptionsText={t('noOptionTextAutoComplete')}
                  />
                </ThemeProvider>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of city filter element */}


            {/* Start date filter element */}
            <AccordionItem dangerouslySetExpanded={exDate}>
              <AccordionItemHeading onClick={(e)=>handleSetExDate(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaRegCalendar className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900">Date</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {oneOrReturn}
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of date filter element */}

            {/* Start stopover filter element */}
            <AccordionItem dangerouslySetExpanded={exStopOver}>
              <AccordionItemHeading onClick={(e)=>handleSetExStopOver(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaStream className="calendar w-4 h-4" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900">{t('stopover.title')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  {stopPlansDep && stopPlansDep.map((plan:any) => (
                            <ItemWrapper key={plan.id + ncNanoId()}>
                                    <strong className="text-neutral-900">{plan.stopLabel}</strong>
                                    <Button
                                        className={plan.checked ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>handleStopPlanDepSelected(plan.stopNumber)}
                                    ></Button>
                            </ItemWrapper>
                          ))}
                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of stopover filter element */}

            {/* Start stopoverRet filter element */}
            {boolDate && (
                <AccordionItem dangerouslySetExpanded={exStopOverRet}>
                      <AccordionItemHeading onClick={(e)=>handleSetExStopOverRet(e)}>
                        <AccordionItemButton>
                          <span className="flex flex-wrap text-neutral-900 ml-1"><FaStream className="calendar w-4 h-4" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900">{t('stopover.titleRet')}</span></span>
                          <IoIosArrowDown />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <RoomGuestWrapper>
                          {stopPlansRet && stopPlansRet.map((plan:any) => (
                            <ItemWrapper key={plan.id + ncNanoId()}>
                                    <strong className="text-neutral-900">{plan.stopLabel}</strong>
                                    <Button
                                        className={plan.checked ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>handleStopPlanRetSelected(plan.stopNumber)}
                                    />
                            </ItemWrapper>
                          ))}
                        </RoomGuestWrapper>
                      </AccordionItemPanel>
                    </AccordionItem>
                )}
            {/* End of stopoverRet filter element */}

            {/* Room & Guest type filter element */}
            <AccordionItem dangerouslySetExpanded={exGuest}>
              <AccordionItemHeading onClick={(e)=>handleSetExGuest(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaUserFriends className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">{t('pax')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong><span className="text-neutal-900">{t('adult')}</span></strong>

                            <InputIncDec
                              id="adult"
                              className="text-neutral-500"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={flightAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong>
                               <div>
                                 <p className="text-neutral-900 dark:text-neutral-100">{t('child')}</p>
                                 <p className="text-xs text-neutral-900 dark:text-neutral-100">(2-11)</p>
                               </div>
                            </strong>

                            <InputIncDec
                              id="child"
                              className="text-neutral-500"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={flightChild}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong>
                               <div>
                                  <p className="text-neutral-900 dark:text-neutral-100">{t('setInfant')}</p>
                                  <p className="text-xs text-neutral-900 dark:text-neutral-100">(under 2)</p>
                               </div>
                            </strong>

                            <InputIncDec
                              id="infant"
                              className="text-neutral-500"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={flightInfant}
                            />
                          </ItemWrapper>

                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>

            {/* BAGS */}
            <AccordionItem dangerouslySetExpanded={exBags}>
              <AccordionItemHeading onClick={(e)=>handleSetExBags(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaBriefcase className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">{t('bags.total')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong className="text-neutral-900">{t("bag.adult")}</strong>
                            <InputIncDec
                              id="bags"
                              className="text-neutral-500"
                              increment={() => handleIncrementBagAdult()}
                              decrement={() => handleDecrementBagAdult()}
                              value={bagAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <strong className="text-neutral-900">{t("bag.hand")}</strong>
                            <InputIncDec
                              id="child"
                              className="text-neutral-500"
                              increment={() => handleIncrementHBagAdult()}
                              decrement={() => handleDecrementHBagAdult()}
                              value={hBagAdult}
                            />
                          </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>

            {/*set class for flights*/}
            <AccordionItem dangerouslySetExpanded={exClass}>
              <AccordionItemHeading onClick={(e)=>handleSetExClass(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaGem className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">{t('flightDetailClass')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong className="text-neutral-900">Any</strong>
                            <Button
                                        className={handleClass===0 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>setHandleClass(0)}
                            />

                          </ItemWrapper>
                  <ItemWrapper>
                          <strong className="text-neutral-900">Economy</strong>
                          <Button
                                        className={handleClass===1 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>setHandleClass(1)}
                            />
                          </ItemWrapper>
                  <ItemWrapper>
                          <strong className="text-neutral-900">Premium Economy</strong>
                          <Button
                                        className={handleClass===2 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>setHandleClass(2)}
                            />
                          </ItemWrapper>
                  <ItemWrapper>
                          <strong className="text-neutral-900">Business</strong>
                          <Button
                                        className={handleClass===3 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>setHandleClass(3)}
                            />
                          </ItemWrapper>
                  <ItemWrapper>
                          <strong className="text-neutral-900">First class</strong>
                          <Button
                                        className={handleClass===4 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                        onClick={()=>setHandleClass(4)}
                            />
                          </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>

            {/* Start price range filter element */}
            <AccordionItem dangerouslySetExpanded={exBudget}>
              <AccordionItemHeading onClick={(e)=>handleSetExBudget(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaMoneyBillWave className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">Budget</span></span>
                  <IoIosArrowDown />

                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel >
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong><span className="text-neutral-900">Budget</span></strong>
                                <InputIncDec
                                  id="budget"
                                  className="text-neutral-500"
                                  increment={() => handleIncrementBudget()}
                                  decrement={() => handleDecrementBudget()}
                                  value={flightBudget}
                                />
                            </ItemWrapper>


                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of price range filter element */}


            {/* Start price range filter element */}
            <AccordionItem dangerouslySetExpanded={exTime}>
              <AccordionItemHeading onClick={(e)=>handleSetExTime(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaStopwatch20 className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">{t('filter.time')}</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                  <ItemWrapper>
                            <strong><span className="text-neutral-900">{t('timeFlightDep')}</span></strong>
                                 <Slider
                                    getAriaLabel={() => 'Departure Time'}
                                    value={valueDep}
                                    onChange={handleChangeDepTime}
                                    getAriaValueText={valuetext}
                                    min={0}
                                    max={23}
                                    valueLabelDisplay="on"

                                />
                            </ItemWrapper>

                            {boolDate &&(
                                <ItemWrapper>
                                <strong><span className="text-neutral-900">{t('timeFlightRet')}</span></strong>
                                     <Slider
                                        getAriaLabel={() => 'Arrival Time'}
                                        value={valueArr}
                                        onChange={handleChangeArrTime}
                                        getAriaValueText={valuetext}
                                        min={0}
                                        max={23}
                                        valueLabelDisplay="on"

                                    />
                                </ItemWrapper>
                            )}

                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of price range filter element */}

            {/* Start airline filter element */}
            <AccordionItem dangerouslySetExpanded={exAirline}>
              <AccordionItemHeading onClick={(e)=>handleSetExAirline(e)}>
                <AccordionItemButton>
                  <span className="flex flex-wrap text-neutral-900 ml-1"><FaPlaneArrival className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">Airline</span></span>
                  <IoIosArrowDown />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RoomGuestWrapper>
                    <List dense sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper',maxHeight: 300, overflow: 'auto' }}>
                            {airNameCheck.map((value) => {
                            const labelId = `checkbox-list-secondary-label-${airNameCheck.indexOf(value)}`;
                            return (
                               <ListItem
                                key={airNameCheck.indexOf(value)}
                                secondaryAction={
                                  <Input
                                   id={`mapCheckbox${ncNanoId()}`}
                                   name={`${value}`}
                                   type="checkbox"
                                   className="focus:ring-action-primary h-1 w-1 text-primary border-primary"
                                   onChange={handleToggleAirP(value)}
                                   checked={value.checked}

                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <div
                                          className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-xl ring-1 ring-white dark:ring-neutral-900`}
                                          style={{ backgroundColor: "#fff"}}
                                        >
                                        <img
                                            className="wil-avatar__name"
                                            src={`https://images.kiwi.com/airlines/64x64/${value.icon}.png`}
                                            alt="logo"
                                         />


                                      </div>
                                  </ListItemAvatar>
                                  <ListItemText id={labelId} className="text-neutral-900" primary={value.name} />
                                </ListItemButton>
                              </ListItem>
                            );
                          })}
                        </List>



                </RoomGuestWrapper>
              </AccordionItemPanel>
            </AccordionItem>
            {/* End of airline filter element */}

          </Accordion>
         <ButtonPrimary className="mt-10 mb-5 w-full" onClick={handleApplyFilter}>
                {t('filter.search')}
            </ButtonPrimary>

        </FilterElementsWrapper>
        </div>
        </DivHidden>
        </div>

        </Drawer>
        </ThemeProvider>


    </FilterArea>
  );
};

export default FilterDrawer;
