import { Routes, Route, HashRouter } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageSingle from "containers/PageSingle/PageSingle";
import PageAbout from "containers/PageAbout/PageAbout";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageForgotPass from "containers/PageForgotPass/PageForgotPass";
import PageResetPassword from "containers/PageLogin/PageResetPassword";
import DashboardRoot from "containers/PageDashboard/DashboardRoot";
import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageTrending from "containers/PageHome/PageTrending";
import PageCountry from "containers/PageCountry/PageCountry";
import PageArticles from "containers/PageHome/PageArticle";
import PageCategory from "containers/PageCategory/PageCategory";
import DashboardEditPost from "containers/PageDashboard/DashboardEditPost";
import PageSearch from "containers/PageSearch/PageSearch";
import PageAuthor from "containers/PageAuthor/PageAuthor";
import PageBlog from "containers/PageHome/PageHome";
import PageSearchFH from "containers/PageSearchFlightHotel/PageSearch";
import PageSearchDetailsFH from "containers/PageSearchFlightHotel/PageSearchFlight/PageContainerSearchDetailsFlight";
import PageSearchDetailsH from "containers/PageSearchFlightHotel/PageSearchHotel/PageContainerSearchDetailsHotel";
import PagePrivacy from "containers/PagePrivacy/PagePrivacy";
import PageTerms from "containers/PageTerms/PageTerms";
import PageCookie from "containers/PageCookie/PageCookie";
import PageUnsubscribe from "containers/PageUnsubscribe/PageUnsubscribe";
import PageSustainability from "containers/PageSustainability/PageSustainability";
import PageGonnawayAdvice from "containers/PageGonnawayAdvice/PageGonnawayAdvice";
import DashboardPosts from "containers/PageDashboard/DashboardPosts";
import DashboardEditProfile from "containers/PageDashboard/DashboardEditProfile";
import DashboardSubmitPost from "containers/PageDashboard/DashboardSubmitPost";
import DashboardChangePassword from "containers/PageDashboard/DashboardChangePassword";
import DashboardPrivacy from "containers/PageDashboard/DashboardPrivacy";
import PageWhySignUp from "containers/PageWhySignUp/PageWhySignUp";
import PageSearchDetailsNomad from "containers/PageSearchFlightHotel/GonnawayItineraries/PageContainerSearchDetailsNomad";
import PageSearchDetailsMultiCity from "containers/PageSearchFlightHotel/PageMultiCities/PageContainerMultiCityDetails";




export const pages: Page[] = [
  { path: "/", component: PageSearchFH },
  { path: "/#", component: PageSearchFH },
  { path: "/blog/author/:slug", component: PageAuthor },
  { path: "/blog/category/:slug", component: PageCategory },
  { path: "/blog/article/:slug", component: PageSingle },
  { path: "/blog/edit-post/:slug", component: DashboardEditPost },
  { path: "/blog/country/:slug", component: PageCountry },
  { path: "/blog/trending", component: PageTrending },
  { path: "/blog/all_articles", component: PageArticles },
  { path: "/blog/search", component: PageSearch },
  { path: "/blog/unsubscribe", component: PageUnsubscribe },
  { path: "/about", component: PageAbout },
  //{ path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  { path: "/forgot-pass", component: PageForgotPass },
  { path: "/reset-password/*", component: PageResetPassword },
  { path: "/dashboard", component: DashboardRoot },
  { path: "/blog", component: PageBlog },
  { path: "/privacy", component: PagePrivacy},
  { path: "/sustainability", component: PageSustainability},
  { path: "/terms", component: PageTerms},
  { path: "/cookie", component: PageCookie},
  { path: "/searchflightdetails", component: PageSearchDetailsFH},
  { path: "/searchhotelsdetails", component: PageSearchDetailsH},
  { path: "/gonnawayadvice", component: PageGonnawayAdvice},
  { path: "/dashboard/posts", component: DashboardPosts },
  { path: "/dashboard/edit-profile", component: DashboardEditProfile },
  { path: "/dashboard/submit-post", component: DashboardSubmitPost },
  { path: "/dashboard/root", component: DashboardRoot },
  { path: "/dashboard/change-password", component: DashboardChangePassword },
  { path: "/dashboard/privacy", component: DashboardPrivacy },
  { path: "/gonnawayners", component: PageWhySignUp },
  { path: "/searchflightailored", component: PageSearchDetailsNomad},
  { path: "/searchmulticitydetails", component: PageSearchDetailsMultiCity},
];

const RoutesApp = () => {
  return (
    <HashRouter>
      <HeaderContainer />
      <ScrollToTop />
      <Routes>
        {pages.map(({ component:Component, path },index) => {
          return (
            <Route
              key={index}
              element={<Component />}
              path={path}
            />
          );
        })}
        <Route element={<Page404 />} />
      </Routes>
      <Footer />
    </HashRouter>
  );
};

export default RoutesApp;
