import React from 'react';
import ReactDOMServer from 'react-dom/server';

import getDomMarkerIcon from 'utils/mapUtils/get-dom-marker-icon';
import getMarkerIcon from 'utils/mapUtils/get-marker-icon';
import MapContext from 'utils/mapUtils/map-context';
import { setMarkerDragEvent } from 'utils/mapUtils/set-drag-event';
import { HEvents, events, Events } from 'utils/mapUtils/map-events';
import { useTranslation } from 'react-i18next';
import getSymbolFromCurrency from 'currency-symbol-map';



export interface CountriesProps{
  name?: string;
  lat?: string;
  lng?: string;
  country_id?: number;
  flag?: string;

}

export interface MarkerProps
  extends H.map.Marker.Options,
    H.geo.IPoint,
    HEvents {
      bitmap?: string;
      draggable?: boolean;
      ui?:H.ui.UI | undefined;
      personalDashboardMap?:boolean;
      homeMap?:boolean;
      hotelDashboardMap?:boolean;
      stateCountry?:CountriesProps;
      countries?:any;
      hotelData?:any;
      nomadCountry?:boolean;
      countriesNomad?:any;
    }


export const Marker: React.FC<React.PropsWithChildren<React.PropsWithChildren<MarkerProps>>> = ({
  children,
  bitmap,
  lat,
  lng,
  draggable,
  ui,
  stateCountry,
  countries,
  hotelData,
  personalDashboardMap,
  homeMap,
  hotelDashboardMap,
  nomadCountry,
  countriesNomad,
  ...rest
}) => {
  const mapContext = React.useContext(MapContext);
  const [marker, setMarker] = React.useState<
    H.map.Marker | H.map.DomMarker | undefined
  >(undefined);
  const {t} = useTranslation();


  let bubble:any;
  function markerPersonalDashboard(){
    bubble = new H.ui.InfoBubble(
            {
                lat: lat,
                lng: lng,
            },
            {
                content: `<div style="width:75px" onclick="window.location.replace('/#/blog/country/${stateCountry?.country_id !==undefined ? stateCountry?.country_id : ""}')">
                            <section><img src='${stateCountry?.flag !==undefined ? stateCountry?.flag : ""}' /><p>${stateCountry?.name !==undefined ? stateCountry?.name : ""}</p></section>

                        </div>`
            }
          );
          if(ui!==undefined){ui.addBubble(bubble);}

  }



  function markerHomeDashboard(){

    bubble = new H.ui.InfoBubble(
       {
                lat: lat,
                lng: lng,
       },
       {
                content: `<div style="width:50px" onclick="window.location.replace('/#/blog/country/${countries.country_id}')">
                            <section><img src='${countries.flag}' /><p>${countries.name}</p></section>
                            <span>${countries.postcount} + ${t('sectionHereMapArt')}</span>
                        </div>`
       }
    );
    if(ui!==undefined){ui.addBubble(bubble);}
  }

  function markerNomad(){
    bubble = new H.ui.InfoBubble(
       {
                lat: lat,
                lng: lng,
       },
       {
                content: `<div style="width:50px" onclick="window.location.replace('/#/blog/country/${countriesNomad.country_id}')">
                            <section><img src='${countriesNomad.flag}' /><p>${countriesNomad.name}</p></section>

                        </div>`
       }
    );
    if(ui!==undefined){ui.addBubble(bubble);}
  }


  function markerHotelDashboard(){
    let currency=getSymbolFromCurrency(hotelData.currency+'');
    let urlImage=hotelData.images[0];
    let stars:any;
    switch (hotelData.stars){
            default: stars=`<span className="flex flex-wrap text-sm text-yellow-600"></span>`; break;
            case 1: stars=`<span className="flex flex-wrap text-sm text-yellow-600">⭐</span>`; break;
            case 2:stars=`<span className="flex flex-wrap text-sm text-yellow-600">⭐⭐</span>`; break;
            case 3: stars=`<span className="flex flex-wrap text-sm text-yellow-600">⭐⭐⭐</span>`; break;
            case 4: stars=`<span className="flex flex-wrap text-sm text-yellow-600">⭐⭐⭐⭐</span>`; break;
            case 5: stars=`<span className="flex flex-wrap text-sm text-yellow-600">⭐⭐⭐⭐⭐</span>`; break;
    }
    bubble = new H.ui.InfoBubble(
       {
                lat: lat,
                lng: lng,
       },
       {
                content: `<div style={{ width: "150px" }}>
                            <table>
                                <tr>
                                    <td width="50%"><img src=${urlImage} /></td>
                                    <td width="50%" style="padding:10px">
                                        <p><b>${hotelData.name}</b></p>
                                        <p>${stars}</p>
                                        <p>Rating <b>${hotelData.customerRating}</b></p>
                                        <p><b>${t('priceHotel')} ${hotelData.priceNumber} ${currency}</b></p>
                                        <a href='${hotelData.rooms[0].buy_link}' target="_blank" rel="noopener noreferrer"><button style="background-color:#008487; border:1; color:#fff; width:100% "><b>${t('map.book')}</b></button></a>
                                    </td>
                                </tr>
                            </table>
                           </div>
                          `
       }
    );
    if(ui!==undefined){ui.addBubble(bubble);}
  }


  React.useEffect(() => {
    const { map, behavior } = mapContext;
    const svgMarkup = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.75em" height="1.75em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1200 1200"><path d="M600 0C350.178 0 147.656 202.521 147.656 452.344c0 83.547 16.353 169.837 63.281 232.031L600 1200l389.062-515.625c42.625-56.49 63.281-156.356 63.281-232.031C1052.344 202.521 849.822 0 600 0zm0 261.987c105.116 0 190.356 85.241 190.356 190.356C790.356 557.46 705.116 642.7 600 642.7s-190.356-85.24-190.356-190.356S494.884 261.987 600 261.987z" fill="#008489"/></svg>`;

    if (map && !marker) {
      let newMarker: H.map.DomMarker | H.map.Marker;
      if (React.Children.count(children) > 0) {
        const html = ReactDOMServer.renderToStaticMarkup(
          <div className="dom-marker">{children}</div>,
        );


        const icon = getDomMarkerIcon(html);
        newMarker = new H.map.DomMarker({ lat, lng }, { icon });
      } else if (bitmap) {
        const icon = getMarkerIcon(bitmap);

        newMarker = new H.map.Marker({ lat, lng }, { icon });
      } else {

        newMarker = new H.map.Marker({ lat, lng });
      }

      if (draggable && behavior) {
        newMarker.draggable = draggable;
        setMarkerDragEvent(map, behavior);
      }

      map.addObject(newMarker);
      setMarker(newMarker);

      //events managements
      newMarker.addEventListener("tap",function (evt: any) {
        if(stateCountry!==undefined && personalDashboardMap){markerPersonalDashboard();}
        if(countries!==undefined && homeMap){markerHomeDashboard();}
        if(hotelData!==undefined && hotelDashboardMap){markerHotelDashboard();}
        if(countriesNomad!==undefined && nomadCountry){markerNomad();}
        },
          false
      );

      newMarker.addEventListener("pointerenter",function (evt: any) {
        if(stateCountry!==undefined && personalDashboardMap){markerPersonalDashboard();}
        if(countries!==undefined && homeMap){markerHomeDashboard();}
        if(hotelData!==undefined && hotelDashboardMap){markerHotelDashboard();}
        if(countriesNomad!==undefined && nomadCountry){markerNomad();}
        },
          false
      );

      newMarker.addEventListener("pointerleave",function (evt: any) {
        bubble.close();
        },
          false
      );




    }
    return () => {
      if (map && marker) {
        map.removeObject(marker);
      }
    };
  }, [bitmap, children, draggable, lat, lng, mapContext, marker]);

  React.useEffect(() => {
    if (marker) {
      Object.entries(events).forEach(([event, hereEvent]) => {
        const e = rest[event as keyof Events];
        if (typeof e === 'function') {
          marker.addEventListener(hereEvent, e);
        }
      });
    }
    return () => {
      if (marker) {
        Object.entries(events).forEach(([event, hereEvent]) => {
          const e = rest[event as keyof Events];
          if (typeof e === 'function') {
            marker.removeEventListener(hereEvent, e);
          }
        });
      }
    };
  }, [marker, rest]);

  React.useEffect(() => {
    if (marker && lat && lng) {
      marker.setGeometry({
        lat,
        lng,
      });
    }
  }, [lat, lng, marker]);

  return null;
};

export default Marker;
