import {useAppDispatch, useAppSelector} from "app/hooks";
import {removeUser, selectUser} from "app/user/user";
import axios from "../../axios";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";
import { useTranslation } from 'react-i18next';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import { useNavigate } from "react-router";
import {useEffect} from "react";
import Layout from "./layout";
import { Switch } from "app/headlessui";
import { useThemeMode } from "hooks/useThemeMode";





const DashboardPrivacy = () => {
  const { user }: any = useAppSelector(selectUser);
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState<any>({});
  const [showAlert, setShowAlert] = useState(false);
  const [followFlight, setFollowFlight] = useState(false);
  const [mailingList, setMailingList] = useState(false);
  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {isDarkMode}=useThemeMode();

  //da aggiornare folloFlight and mailing list su base api

  const [value, setValue] = useState<any>({
    id: user.id,
    currentPassword: "",
    newPassword: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValue((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setShowAlert(false);
  };

  useEffect(() => {
    getAccessToken().then((access_token: any) => {
    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                dispatch(removeUser());
                history("/login");
        }
    });


  }, []);

  const filterData = (consent:any, value:any) =>
  {
       switch (consent){
            case 1: setFollowFlight(value);
                    break;
            case 2: setMailingList(value);
                    break;
       }
  };


  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
     getAccessToken().then((access_token: any) => {
      axios
        .post("/authors/change-privacy", [followFlight,mailingList],{
        headers: {
          'Authorization': `Bearer ${access_token}`,
          }
        })
        .then((res: any) => {
          setSubmitting(false);
          setMessage(res.data);
          setShowAlert(true);

        })
        .catch((err) => {
          setSubmitting(false);
        });
     }).catch((err) => {
      if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        dispatch(removeUser());
        history("/login");
    });
  };

  return (
    <Layout>
    <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
      <Collapse in={showAlert}>
        <Alert
          severity={message.status}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <SvgIcon>
                <path
                  fill="inherit"
                  d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                />
              </SvgIcon>
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          <p style={{ marginTop: ".2rem" }}>{message.message}</p>
        </Alert>
      </Collapse>

      <div className="rounded-xl min-h-full text-sm border border-neutral-100 dark:border-neutral-800 p-6 md:text-base mb-5 container">
      <span className="block text-lg mb-3 mt-5">
        <strong>Privacy Management </strong>
      </span>
      <p className="block text-lg mb-3 mt-5" style={{textAlign:"justify"}}>
        {t('privacy.updateHeader')}
      </p>

      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-6 mt-10">
        <p className="block text-lg" style={{textAlign:"justify"}}> 🔭 {t('dashboard.privacyFollowFlight')}</p>
        <div className="justify-self-end">
            <Switch
                checked={followFlight}
                onChange={setFollowFlight}
                className={`${
                    followFlight ? 'bg-teal-900' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Flight</span>
                <span
                  aria-hidden="true"
                  className={`${
                      !followFlight ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
        <p className="block text-lg" style={{textAlign:"justify"}}> 📩 {t('dashboard.privacyMailingList')}</p>
        <div className="justify-self-end">
            <Switch
                checked={mailingList}
                onChange={setMailingList}
                className={`${
                    mailingList ? 'bg-teal-900' : 'bg-gray-200'
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Mailing</span>
                <span
                  aria-hidden="true"
                  className={`${
                      !mailingList ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
        </div>
       </div>

        <ButtonPrimary className="mt-10 mb-5 w-full">
          {submitting ? (
            <CircularProgress
              size={24}
              sx={{
                color: "#eee",
              }}
            />
          ) : (
            <span>{t('privacy.update')}</span>
          )}
        </ButtonPrimary>

      </form>




      </div>
    </div>
    </Layout>
  );
};

export default DashboardPrivacy;
