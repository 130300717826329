import React, { FC, useState } from "react";
import ButtonCircle from "components/Button/ButtonCircle";
import NcImage from "components/NcImage/NcImage";
import Input from "components/Input/Input";
import { CircularProgress } from "@mui/material";
import axios from "../../axios";
import { useTranslation } from 'react-i18next';
import Label from 'components/Label/Label';


export interface SectionSubscribe2Props {
  className?: string;
}

const SectionUnSubscribe: FC<React.PropsWithChildren<React.PropsWithChildren<SectionSubscribe2Props>>> = ({ className = "" }) => {
  const [submitting, setSubmitting] = useState(false);
  const [unsubscribed, setUnSubscribed] = useState(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [email, setEmail] = useState("");
  const {t} = useTranslation();

  const handleChange = (e: any) => {
    setEmail(e.target.value);
    setUnSubscribed(false);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmitting(true);
    axios
      .post("/subscribe/remove", {
        email: email,

      })
      .then((res: any) => {
        setEmail("");
        setSubmitting(false);
        setUnSubscribed(true);
        setTimeout(() => {
          setActiveLabel(false);
        }, 5000);
        setActiveLabel(true);
      })
      .catch((err) => {
        setEmail("");
        //true for avoid to give information regarding emails in our db to attackers. In case the backend does not remove any account
        setSubmitting(true);
        setUnSubscribed(true);
      });

  };
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row items-center ${className}`}
      data-nc-id="SectionUnSubscribe"
    >
      <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl"> Remove your mail from our newsletter 🥺</h2>
        <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
          By removing your email you will no longer be able to receive information about the best trips!

        </span>

        <form className="mt-10 relative max-w-sm" onSubmit={handleSubmit}>
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            value={email}
            onChange={handleChange}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
          >
            {submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#eee",
                }}
              />
            ) : unsubscribed ? (
              <i className="las la-check text-xl"></i>
            ) : (
              <i className="las la-arrow-right text-xl"></i>
            )}
          </ButtonCircle>
        </form>
        {activeLabel ? (
            <Label className="mt-10">You are no longer subscribed to our newsletter, we are sorry 🥺🥺🥺</Label>
         ) : ( <div />)}
      </div>
      <div className="flex-grow">
        <NcImage src="/static/newsletter.png" />
      </div>
    </div>
  );
};

export default SectionUnSubscribe;
