import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";

import React from "react";
import { useAppSelector } from "app/hooks";
import { useThemeMode } from "hooks/useThemeMode";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const Footer: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const {isDarkMode}=useThemeMode();

  return (
    <>
      <div className="relative py-4 border-t border-neutral-200 dark:border-neutral-700">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            flexDirection: "column",
          }}
        >
          <div className="my-5">
             <img src={isDarkMode ? "/static/gway_light.png" : "/static/gway_logo.png"} alt="Gonnaway.com" style={{ width: "180px", height: "160px" }} />
          </div>
          <div className="col-span-2 my-2 flex items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-5  " />
          </div>
        </div>
      </div>
      <p
        className="text-center w-full p-2"
        style={{ fontSize: ".8em", color: "grey" }}
      >
        Copyright © 2023 GONNAWAY. All Rights Reserved
      </p>
    </>
  );
};

export default Footer;
