import SectionHero from "components/SectionHero/SectionHero";
import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import gway_logo from "images/login-page-bg.png";
import { useTranslation } from 'react-i18next';
import { useThemeMode } from "hooks/useThemeMode";
import SectionWhySignUp from "./SectionWhySignUp"


export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<React.PropsWithChildren<React.PropsWithChildren<PageAboutProps>>> = ({ className = "" }) => {
  const {t} = useTranslation();
  const {isDarkMode}=useThemeMode();
  return (
    <div
      className={`nc-PageAbout px-6 overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Why Signup || Community Travel</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28" >
        <SectionHero
          rightImg={gway_logo}
          heading="👋 Gonnawayners 👋"
          btnText={t('signup.heading')}
          subHeading={t('nomad.title')}
        />

        <div className="relative container mt-10 ">
            <SectionWhySignUp />
        </div>
        <SectionSubscribe2 className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default PageAbout;
