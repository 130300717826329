import { FC, useEffect, useState } from "react";
import Avatar from "components/Avatar/Avatar";
import SocialsList from "components/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import Card11 from "components/Card11/Card11";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import { convertDate } from "utils/convertDate";
import { useAppSelector } from "app/hooks";
import { selectTopAuthors, selectTopCategories } from "app/content/content";
import twFocusClass from "utils/twFocusClass";
import useFetch from "hooks/useFetch";
import ButtonPrimary from "components/Button/ButtonPrimary";
import PageLoading from "containers/PageLoading/PageLoading";
import Page404 from 'containers/Page404/Page404';
import isLiked from "utils/isLiked";
import { selectUser } from "app/user/user";
import { SocialType } from "components/SocialsShare/SocialsShare";
import { useTranslation } from 'react-i18next';
import NcImage from "components/NcImage/NcImage";
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import { Fragment } from "react";
import {backendAddress} from "../../index";
import { useLocation } from 'react-router-dom'

export interface PageAuthorProps {
  className?: string;
}

const PageAuthor: FC<React.PropsWithChildren<React.PropsWithChildren<PageAuthorProps>>> = ({ className = "" }) => {
  const topCategories: any = useAppSelector(selectTopCategories);
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const { user } = useAppSelector(selectUser);
  const [articles, setArticles] = useState([]);
  const [articlesBk, setArticlesBk] = useState([]);
  const [author, setAuthor] = useState<any>("");
  const [pages, setPages] = useState<any>([]);
  const [page, setPage] = useState<any>({ start: 0, end: 8 });
  const [currentPage, setCurrentPage] = useState(1);
  const {t} = useTranslation();
  const [showPosts, setShowPosts] = useState(24);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const [checkAvatar, setCheckAvatar] = useState(false);
  const [checkImgUrl, setCheckImgUrl] = useState(false);



const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filter.selection") },
    {id: 1, name: t("filter.early") },
    {id: 2, name: t("filter.likes") },
    {id: 3, name: t("filter.comments") },
    //{id: 3, name: "Most Viewed" },
  ];
    const [selected, setSelected] = useState(FILTERS[0]);

  const pageChange = (e: any) => {
    var number = parseInt(e.currentTarget.id);
    setCurrentPage(number);
    window.scrollTo({
      top: 300,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  let path = location.pathname.split("/");
  const authorId = path[3];
  const { loading, error, data } = useFetch(`/authors/${authorId}/articles`);

  useEffect(() => {
    if (data) {
      let splitAuthor;
      let splitBGAuthor;
      if(data.author.imgurl){splitAuthor=data.author.imgurl.split("/");}
      if(data.author.imgbgurl){splitBGAuthor=data.author.imgbgurl.split("/");}
      setCheckAvatar(splitAuthor[0]==="authors" ? true : false);
      if(splitBGAuthor){setCheckImgUrl(splitBGAuthor[0]==="authors" ? true : false);}
      setArticles([]);
      setArticlesBk([]);

      if (data.articles[0]) {
        let articlesData: any = [];
        data.articles.map((article: any) => {
          articlesData.push({
            id: article.article_id,
            featuredImage: backendAddress+"/"+article.imgurl,
            title: article.title,
            desc: article.excerpt,
            date: convertDate(article.created_date),
            href: `/blog/article/${article.article_id}`,
            commentCount: article.commentcount,
            readingTime: article.readingtime,
            country_name: article.country_name,
            bookmark: {isBookmarked: false},
            like: isLiked(article.likes, user),

            author: {
              id: article.author_id,
              displayName: article.author_name,
              avatar: checkAvatar ? backendAddress+"/"+article.author_imgurl : article.author_imgurl,
              href: `/blog/author/${article.author_id}`,
              imgBgUrl: checkImgUrl ? backendAddress+"/"+article.author_imgbgurl : article.author_imgbgurl,
            },
            categories: [
              {
                id: article.category_id,
                name: article.category_name,
                href: `/blog/category/${article.category_id}`,
                thumbnail: article.category_thumbnail,
                color: article.category_color,
              },
            ],
          });
        });
        setArticles(articlesData);
        setArticlesBk(articlesData);
      }
        setAuthor(data.author);
    }

  }, [data,checkImgUrl,checkAvatar]);




  useEffect(() => {
    switch (currentPage) {
      case 1:
        return setPage({ start: 0, end: 8 });
      case 2:
        return setPage({ start: 8, end: 16 });
      case 3:
        return setPage({ start: 16, end: 24 });
      default:
        return setPage({ start: 0, end: 8 });
    }
  }, [currentPage]);

   useEffect(() => {
     if (articles.length <= 8) {
      setPages([]);
    } else if ( articles.length <= 16) {
      setPages([1, 2]);
    } else if ( articles.length <= 24) {
      setPages([1, 2, 3]);
    } else {
      setShowMoreButton(true);
    }
  }, [articles]);


  const socialLinks: SocialType[] = [
    {
      id: "Facebook",
      name: "Facebook",
      icon: "lab la-facebook-square",
      href: author.facebook_url,
    },
    {
      id: "Twitter",
      name: "Twitter",
      icon: "lab la-twitter",
      href: author.twitter_url,
    },
    {
      id: "Instagram",
      name: "Instagram",
      icon: "lab la-instagram",
      href: author.instagram_url,
    },
  ];




  if (loading) return <PageLoading />;
  if (error) return <Page404 />;

  //manage showmore
  const showMoreFunc = () =>
  {
    setShowPosts(showPosts*2);
  }

  let postsSort=articles;
  const changeSelectedFilter = (e:any) =>
  {
    setSelected(e);
     switch (e.id){
        default: setArticles(articlesBk);
              break;
        case 1: postsSort.sort((a:any, b:any) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()));
                setArticles(postsSort);
                break;
        case 2: postsSort.sort((a:any, b:any) => ((a.like.count)  < (b.like.count)) ? 1 : -1);
                setArticles(postsSort);
                break;
        case 3: postsSort.sort((a:any, b:any) => ((a.commentCount)  < (b.commentCount)) ? 1 : -1);
                setArticles(postsSort);
                break;
     }
  }



   return (
    <div className={`nc-PageAuthor  ${className}`} data-nc-id="PageAuthor">
       <Helmet>
            <title>Author || Community Travel</title>
       </Helmet>


        {author && (
          <div className="w-screen px-2 xl:max-w-screen-2xl mx-auto">
            <div className="rounded-3xl md:rounded-[40px] relative aspect-w-16 aspect-h-12 sm:aspect-h-7 xl:sm:aspect-h-6 overflow-hidden">
              {author.imgbgurl &&(
              <NcImage
                containerClassName="absolute inset-0"
                src= {(checkImgUrl ? `${backendAddress}/${author.imgbgurl}?${Date.now()}` : `${author.imgbgurl}?${Date.now()}` )}
                className="object-cover w-full h-full"
              />
              )}
           </div>

            <div className="relative container -mt-20 lg:-mt-48">
              <div className="bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-16 rounded-[40px] shadow-2xl flex flex-col sm:flex-row sm:items-center">
                <Avatar
                  containerClassName="ring-4 ring-white dark:ring-0 shadow-2xl"
                  userName={author.name}
                  sizeClass="w-20 h-20 text-xl lg:text-2xl lg:w-36 lg:h-36"
                  radius="rounded-full"
                  imgUrl={checkAvatar ? `${backendAddress}/${author.imgurl}?${Date.now()}` : `${author.imgurl}?${Date.now()}`}
                />

                <div className="mt-5 sm:mt-0 sm:ml-8 space-y-4 !max-w-lg">
                  <h2 className="inline-block text-xl sm:text-3xl md:text-4xl font-semibold">
                    {author.name}
                  </h2>
                  <span className="block text-sm text-neutral-6000 dark:text-neutral-300 md:text-base">
                    {author.desc}
                  </span>
                  <SocialsList className="mt-3" socials={socialLinks} />
                </div>
              </div>
            </div>
          </div>
      )}




      <div className="container py-20 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">

       <div className="space-y-16 lg:space-y-28">
            <div className="flex justify-end">
              <div
                  className={`nc-ArchiveFilterListBox ${className}`}
                  data-nc-id="ArchiveFilterListBox"
                >
              <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                <div className="relative md:min-w-[200px]">
                  <Listbox.Button as={"div"}>
                    <ButtonDropdown>{selected.name}</ButtonDropdown>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900 dark:text-neutral-200 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700">
                      {FILTERS.map((item: ListBoxItemType, index: number) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `${
                              active
                                ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                : ""
                            } cursor-default select-none relative py-2 pl-10 pr-4`
                          }
                          value={item}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`${
                                  selected ? "font-medium" : "font-normal"
                                } block truncate`}
                              >
                                {item.name}
                              </span>
                              {selected ? (
                                <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
             </div>
        </div>
      </div>

        <main>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {!showMoreButton ? (
                articles.filter((_: any, i: any) => i >= page.start && i < page.end).map((post: any) => <Card11 key={post.id} post={post} />)

            ):(
                articles.filter((_:any, i:any) => i < showPosts).map((post: any) => <Card11 key={post.id} post={post} />)
            )}





          </div>

          {/* PAGINATION */}
          <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <nav
                className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
              >
            {!articles[0] ? (
                  <span>{t('articleAuthor.issue')}</span>
                ) : (
              pages &&
              pages.map((page: any) => (
                <div key={page}>
                  {page === currentPage ? (
                    <span
                      className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`}
                    >
                      {page}
                    </span>
                  ) : (

                    <button
                      className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
                      id={`${page}`}
                      onClick={pageChange}
                    >
                      {page}
                    </button>
                  )}
                </div>
              ))
            )}
            </nav>
            {showMoreButton && <ButtonPrimary onClick={showMoreFunc}>{t('slider.showMore')}</ButtonPrimary>}
          </div>
        </main>
        {/* === SECTION 5 === */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox categories={topCategories} />

        </div>

        {/* === SECTION 5 === */}
        <SectionSliderNewAuthors
          heading={t('author.eliteHeading')}
          subHeading={t('author.eliteSubHeading')}
          authors={topAuthors}
        />

        {/* SUBCRIBES */}
        <SectionSubscribe2 className="py-16 lg:py-28" />
      </div>
    </div>
  );
};

export default PageAuthor;
