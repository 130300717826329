import LayoutPage from "components/LayoutPage/LayoutPage";
import React, { FC, useState,useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import { Helmet } from "react-helmet";
import { useNavigate,Route } from "react-router";
import axios from "../../axios";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";
import { useAppDispatch } from "app/hooks";
import { setUser } from "app/user/user";
import { useTranslation } from 'react-i18next';
import {setAccessToken, setRefreshToken} from "../../TokenAuth";
import Label from 'components/Label/Label';
import { CircularProgress } from "@mui/material";

export interface PageLoginProps {
  className?: string;
}



const PageLogin: FC<React.PropsWithChildren<React.PropsWithChildren<PageLoginProps>>> = ({ className = "relative" }) => {
  const history = useNavigate();
  const dispatch = useAppDispatch()
  const [messageBool, setMessageBool] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(false);
  const [activeLabel, setActiveLabel] = useState(false);
  const [labelText, setLabelText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const {t} = useTranslation();

  const loginSocials = [
  {
    name: t('login.fb'),
    href: "#",
    icon: facebookSvg,
  },
  {
    name: t('login.twitter'),
    href: "#",
    icon: twitterSvg,
  },
  {
    name: t('login.google'),
    href: "#",
    icon: googleSvg,
  },
];


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUserInput((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setMessageBool(false);
  };

  const handleSubmit = (e: any)=>{
    e.preventDefault();
    setSubmitting(true);
        axios
          .post("/authors/login", userInput)
          .then((res: any) => {
            setMessage(res.data);
            if (res.data.status !== "error") {
              setUserInput({
                email: "",
                password: "",
              });
            } else {
              setUserInput((prevState: any) => ({
                ...prevState,
                password: "",
              }));
            }
            if (res.data.status==="success") {
              setRefreshToken(res.data.refresh);
              setAccessToken(res.data.access);
              dispatch(setUser(res.data.user))
              history('/dashboard');
            }
            setSubmitting(false);
          })
          .catch((err) => {
                        setTimeout(() => {
                      setActiveLabel(false);
                    }, 5000);
                    let textL=t('error.login');
                    setLabelText(textL);
                    setActiveLabel(true);
                    setSubmitting(false);
              });

  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Community Travel</title>
      </Helmet>
      <LayoutPage
        subHeading={t('signup.subheading')}
        headingEmoji="🔑"
        heading={t('login.1')}
        
      >
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
          {/*  {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}*/}
          </div>
          {/* OR */}
          <div className="relative text-center">
         {/*   <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              {t("login.or")}
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
            */}
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <Collapse in={messageBool}>
              <Alert
                severity={message.status}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setMessageBool(false);
                    }}
                  >
                    <SvgIcon>
                      <path
                        fill="inherit"
                        d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                      />
                    </SvgIcon>
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                <p style={{ marginTop: ".2rem" }}>{message.message}</p>
              </Alert>
            </Collapse>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t('contact.email')}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                pattern="[a-zA-Z0-9._%+-]+@(gmail|hotmail|outlook|yahoo|icloud|live|me|tiscali|libero)+\.[a-z]{2,4}$"
                className="mt-1"
                name="email"
                onInvalid={e=>(e.target as HTMLInputElement).setCustomValidity(t('error.email'))}
                onInput={e=>(e.target as HTMLInputElement).setCustomValidity("")}
                value={userInput.email}
                onChange={handleChange}
              />
            </label>
            <label className="block">
            <span
                 className="flex justify-between items-center
                 text-neutral-800 dark:text-neutral-200">
                 {t('password.normal')}
                 <NcLink to="/forgot-pass" className="text-sm">
                 {t('login.forgot')} </NcLink>
            </span>

            <Input
                 type="password"
                 className="mt-1"
                 name="password"
                 pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                 value={userInput.password}
                 onChange={handleChange}
              />
            </label>
            <ButtonPrimary type="submit">
            {submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "#eee",
                }}
              />
            ) : (
              <span>{t('signup.continue')}</span>
            )}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t('new.user')} {` `}
            <NcLink to="/signup"> {t('signup.create')} </NcLink>
          </span>
          {activeLabel ? (
            <Label className="mt-10">{labelText}</Label>
         ) : ( <div />)}
        </div>
      </LayoutPage>
    </div>
  );
};

export default PageLogin;
