// import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import i18n from 'i18next';
import * as env from "env-var";

let static_assets_uri_prefix = env.get('REACT_APP_STATIC_ASSETS_URI_PREFIX').default("static/").asString()

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(backend)
  .init({
    lng: navigator.language,
    fallbackLng: "en",
    debug: false,
    backend: {
    loadPath: static_assets_uri_prefix + 'locales/{{lng}}/translation.json'
    },
    saveMissing: false,
    keySeparator:false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;