const isLiked = (likes: any, user: any) => {
  if (likes && user) {
    if (likes.includes(user.id)) {
      return {
        count: likes.length,
        isLiked: true,
      };
    } else {
      return {
        count: likes.length,
        isLiked: false,
      };
    }
  } else {
    return {
      count: 0,
      isLiked: false,
    };
  }
};
export default isLiked;
