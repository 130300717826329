// @ts-nocheck
import React, { FC,useState,useEffect,Fragment}  from "react";
import { FlightType} from "data/types";
import getSymbolFromCurrency from 'currency-symbol-map';
import {FaClock,FaHourglassHalf,FaBed,FaCity} from "react-icons/fa";
import { BsAirplaneFill} from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import axios from "../../axios";
import { FcCurrencyExchange,FcShop } from "react-icons/fc";
import ncNanoId from "utils/ncNanoId";
import { useNavigate } from "react-router";
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import airportsJson from "data/jsons/airports.json";
import Badge from "components/Badge/Badge";
import { Popover, Transition } from "app/headlessui";
import ButtonClose from "components/ButtonClose/ButtonClose";
import { useThemeMode } from "hooks/useThemeMode";

export interface Card3Props {
  className?: string;
  dataFlight: FlightType;
  size?: "normal" | "large";
  destinations?:any[];
}

export interface FlightSegmentArray {
  departureAirport?: string;
  arrivalAirport?: string;
  departure_time?:string;
  arrival_time?:string;
  carrier?:string;
  flight_number?:string;
  carrier_operating?:string;
  class?:string;
}

const CardFlight: FC<React.PropsWithChildren<React.PropsWithChildren<Card3Props>>> = ({
  className = "",
  size = "large",
  dataFlight,
  destinations
}) => {
  const history = useNavigate();
    const {t} = useTranslation();
    const {isDarkMode}=useThemeMode();
  //sort flight flight_prices
  let flight_prices=dataFlight.flight_prices || undefined;
  if(flight_prices) flight_prices.sort((a:any, b:any) => (a.amount_to_pay > b.amount_to_pay) ? 1 : -1);
  let departure_currency = getSymbolFromCurrency(dataFlight.itineraries[0].currency_code)? getSymbolFromCurrency(dataFlight.itineraries[0].currency_code): itineraries[0].currency_code;

  /*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/

  /*set variable to set flight type*/
  let withReturn=dataFlight.itineraries.length>1 ? true : false;
  let availableSeat=(dataFlight.itineraries[0].available_seats) ? ("("+dataFlight.itineraries[0].available_seats+" "+(parseInt(dataFlight.itineraries[0].available_seats)>1 ? t('flight.seats'):t('flight.seat'))+")") : "";

  const [openFrag, setOpenFrag] = useState(false);

  let buy_link;
  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 0, name: "Any", type:"Y" },
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];

  /*variables card*/
    let flight_routes=dataFlight.itineraries;

  /*END*/

  function logoAirline(name:any){
    let airlogo="https://images.kiwi.com/airlines/64x64/"+name+".png";
    return airlogo;
  }

  //manage the buy request
 const BuyRedirect = (e:any,b_link:string) =>
  {
    e.preventDefault();
    //let urlBuy="/"+b_link;
    const buyWindow = window.open('about:blank');
       axios
      .get(b_link)
              .then((result) => {
                let data: any = result.data;

                buyWindow.document.location.href = data.buy_link;
              })
              .catch((err) => {
                console.log("There was an error");
              });
 }

 function routeTime(tp,airport_code)
  {
       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let formattedTime=momentTimezone.tz(tp,tzCod[0].timezone).format('ddd D MMM - HH:mm')
       return formattedTime;
  }

  function durationStopOver(route:any,routenext:any){
    let duration = (new Date(routenext.departure_timestamp*1000).getTime() - new Date(route.arrival_timestamp*1000).getTime());
        var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

           hours = (hours < 10) ? "0" + hours : hours;
           minutes = (minutes < 10) ? "0" + minutes : minutes;
           seconds = (seconds < 10) ? "0" + seconds : seconds;

           return (
            <p className="flex flex-wrap gap-2 items-center">{route.arrival_airport_code}<FaHourglassHalf />{hours + ":" + minutes}</p>
          );
  }



  function durationPath(duration:any){
    return (
            <p className="flex flex-wrap items-center" style={{gap:"0.2rem"}}><FaClock />{duration}</p>
          );
  }

    function durationFlight(entireDuration:any){
          const totalMinutes = Math.floor(entireDuration / 60);
          let hoursTemp = Math.floor(totalMinutes / 60);
          let minutesTemp = totalMinutes % 60;

          let hours = (hoursTemp < 10) ? "0" + (hoursTemp) : (hoursTemp);
          let minutes = (minutesTemp < 10) ? "0" + minutesTemp : minutesTemp;

          return (
            <p className="flex flex-wrap items-center" style={{gap:"0.2rem"}}><FaClock />{hours + ":" + minutes}</p>
          );
  }

  function stayNight(route:any,routeNext:any){
        let arrival= new Date(retDay(route.utc_arrival,route.flyTo));
        let departure= new Date(retDay(routeNext.utc_departure,routeNext.flyFrom));
        let diffTime = Math.abs(departure - arrival);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return parseInt(diffDays);
  }

    function retDay(tp,airport_code)
    {
       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let formattedTime=momentTimezone.tz(new Date(tp*1000),tzCod[0].timezone).format('MM/DD/YYYY')
       return formattedTime;
    }

  function stayNightPrev(route:any,routePrev:any){

        let arrival= new Date(retDay(routePrev.flight_segments[routePrev.flight_segments.length-1].arrival_timestamp,routePrev.flight_segments[routePrev.flight_segments.length-1].arrival_airport_code));
        let departure= new Date(retDay(route.flight_segments[0].departure_timestamp,route.flight_segments[0].departure_airport_code));
        // To calculate the time difference of two dates
        var Difference_In_Time = departure.getTime() - arrival.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        return Difference_In_Days;
  }

  function tpToTime(tp,airport_code)
  {

       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let timestampFormat=moment(tp*1000).format();
       let formattedTime=momentTimezone.tz(timestampFormat,tzCod[0].timezone).format('ddd D MMM - HH:mm')
       return formattedTime;
  }

  const searchAirport = (airport:string)=>{
    let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
    if(navigator.language==="en"){tempLocale="en-US";}
    let city="";
     axios
            .get(`/airports?name=${airport}&locale=${tempLocale}`)
            .then((results) => {
              let res=results.data.airports.filter((item:any) => {return item.id === airport;});
              city=res[0].city_name;
            })
            .catch((err) => {
              console.log("There was an error");
            });
            //console.log(city);
            return (<span>{city}</span>);
  }


  //class
  let transport_classDep= "Economy";



      for (let key in FILTERS_CLASS){
        if(FILTERS_CLASS[key].name===dataFlight.itineraries[0].flight_segments[0].transport_class)
        {transport_classDep=FILTERS_CLASS[key].type}
      }



  return (
    <div
      className={`nc-Card3 relative sm:items-center rounded-[40px] group border-gray-800 mx-1  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="Card3"
      key={ncNanoId()}
    >
        <div className="relative">
            {dataFlight.itineraries.map((itinerary:any,index:any)=>(
              <div key={index+ncNanoId()}>
                <div className="grid grid-cols-10 gap-1 bg-neutral-100 dark:bg-neutral-900 rounded-[20px] relative z-1" key={ncNanoId()}>
                    <div key={ncNanoId()} className={windowSize.innerWidth>600 ? "relative col-span-5 flex justify-start items-center gap-3 py-5 ml-3" : "relative col-span-5 flex justify-start items-center gap-3 py-5 ml-1"}>
                        <div
                                  className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-xl ring-1 ring-neutral-300 dark:ring-neutral-900`}
                                  style={{ backgroundColor: "#fff"}}
                                  key={ncNanoId()}
                                >
                                <div className="flex flex-wrap">
                                        <img
                                            key={index+ncNanoId()}
                                            className="wil-avatar__name"
                                            alt="logo"
                                            src={logoAirline(itinerary.flight_segments[0].display_airline_code.toString())}
                                        />
                                </div>
                        </div>
                        <div className="flex flex-wrap items-center gap-2" key={ncNanoId()}>
                            <p className="font-semibold gap-2 flex flex-wrap items-center" key={ncNanoId()}>{destinations[index].city_departure} ({itinerary.flight_segments[0].departure_airport_code})</p>
                        </div>
                    </div>
                    <div className="relative col-span-5 flex justify-start items-center gap-2 py-5" key={ncNanoId()}>
                        <div className="grid grid-cols-10 w-full" key={ncNanoId()}>
                            <div className="col-span-5 flex items-center" key={ncNanoId()}>
                                <Badge
                                  className="px-2"
                                  key={ncNanoId()}
                                  name={durationFlight(itinerary.duration)}
                                  href=""
                                  color="gonnaway"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={windowSize.innerWidth > 600 ? "grid grid-cols-8 gap-2 ml-3" : "grid grid-cols-8 gap-1"}>
                    <div className="relative items-center col-span-1">
                       <div className=" border-l border-gray-900 dark:border-neutral-100 w-1 mx-4 mb-1" style={{height:"43%"}}></div>
                            <BsAirplaneFill style={{marginLeft:"0.5rem", marginRight:"0.5rem", rotate:"180deg"}}/>
                       <div className="border-l border-gray-900 dark:border-neutral-100 w-1 mx-4 mt-1" style={{height:"43%"}}></div>
                    </div>
                    <div className="relative col-span-5 flex items-center">
                       <div className={"grid grid-rows-3 gap-2 mt-2" }>
                            <div>
                                <p  className="font-semibold italic">{itinerary.flight_segments[0].display_airline_name}</p>
                                <p>{tpToTime(itinerary.flight_segments[0].departure_timestamp,itinerary.flight_segments[0].departure_airport_code)}</p>

                            </div>
                            <div className="flex flex-wrap gap-2">
                                {itinerary.flight_segments.length>1 && itinerary.flight_segments.map((rt:any,i:number) => (
                                    (i+1)<itinerary.flight_segments.length && (
                                        <Badge
                                          className=""
                                          key={i+ncNanoId()}
                                          name={durationStopOver(rt,itinerary.flight_segments[i+1])}
                                          href=""
                                          color="gonnaway"
                                        />
                                    )
                                ))}
                                {itinerary.flight_segments.length<2 && (
                                        <Badge
                                          className="flex items-center"
                                          key={ncNanoId()}
                                          name={t('nomad.direct')}
                                          href=""
                                          color="gonnaway"
                                        />
                                )}
                            </div>


                            <div className={"row-span-2 items-center gap-2"}>
                                <p className="font-semibold italic">
                                    {itinerary.flight_segments[itinerary.flight_segments.length-1].display_airline_name}
                                </p>
                                <p>
                                    {tpToTime(itinerary.flight_segments[itinerary.flight_segments.length-1].arrival_timestamp,itinerary.flight_segments[itinerary.flight_segments.length-1].arrival_airport_code)}
                                </p>

                            </div>


                       </div>
                    </div>
                     <div className="relative col-span-2 flex items-center">
                        <Badge
                            key={ncNanoId()}
                            className=""
                            name={transport_classDep || "Economy"}
                            href=""
                            color="indigo"
                        />

                     </div>
                </div>
                <div className="grid grid-cols-10 gap-1 bg-neutral-100 dark:bg-neutral-900 rounded-[20px] relative z-1">
                    <div className={windowSize.innerWidth>600 ? "relative col-span-10 flex justify-start items-center gap-3 py-5 ml-3" : "relative col-span-10 flex justify-start items-center gap-3 py-5 ml-1"}>

                        <div className="flex flex-wrap items-center gap-2">
                           <p className="font-semibold gap-2 flex flex-wrap items-center"><FaCity />{destinations[index].city_return} ({itinerary.flight_segments[itinerary.flight_segments.length-1].arrival_airport_code})</p>

                        </div>
                    </div>

                </div>
                {(index>=0 && (index+1)<dataFlight.itineraries.length) &&(
                    <div className="relative flex py-5 items-center">
                        <div className="flex-grow border-t border-gray-900 dark:border-neutral-100"></div>
                        <span className=" flex flex-wrap items-center flex-shrink mx-4 text-neutral-900 dark:text-neutral-100 gap-2"><FaBed style={{color:"#008487"}}/>{stayNightPrev(dataFlight.itineraries[dataFlight.itineraries.length-1],itinerary)} {stayNightPrev(dataFlight.itineraries[dataFlight.itineraries.length-1],itinerary)<2 ? t('nomad.night') : t('nomad.nights')}</span>
                        <div className="flex-grow border-t border-gray-900 dark:border-neutral-100"></div>
                    </div>
                )}

              </div>
            ))}
            </div>
            <div className="relative mt-5">

        {/*DROPDOWN*/}
            <div className="">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`w-full
                ${open ? "" : "text-opacity-90"}
                 group  p-3 inline-flex items-center text-base font-medium hover:text-opacity-100
                  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
                      <div className="w-full rounded-2xl bg-primary-gonnaway py-2" style={{backgroundColor:"#008487", color:"#fff"}} onClick={()=>setOpenFrag(!openFrag)}>
                        <span className="font-semibold">{t("buyFlight")}{dataFlight.minFee || dataFlight.amount_to_pay} {departure_currency}</span>
                      </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              show={openFrag}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                    className={windowSize.innerWidth>800 ? "absolute z-10  px-4 -right-28 sm:right-0 sm:px-0" : "absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 "}
                    style={windowSize.innerWidth>800 ? {width:"95%",right:"1rem"} : {marginBottom:"-1rem",right:"-0.3rem"}}
                    static={openFrag}
              >
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5 z-500 bg-white" style={isDarkMode ? {backgroundColor:"#1D2333"} : {backgroundColor:"#fff"}}>
                    <div className="relative ">
                        <div  className="flex justify-end" >
                                <ButtonClose
                                    onClick={()=>setOpenFrag(!openFrag)}
                                    className="mr-3 mt-1"
                                />
                            </div>
            <div className="relative bg-transparent dark:bg-transparent grid grid-cols-2 sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base mt-2 ">
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcShop /><b>{t('room.agency')}</b></span>
                </div>
                <div className="relative col-span-1 bg-transparent dark:bg-transparent">
                     <span className="flex flex-wrap gap-6 justify-center text-neutral-900 dark:text-neutral-50	"><FcCurrencyExchange /><b>{t('room.buy')}</b></span>
                </div>
            </div>

            {flight_prices && flight_prices.map((flightPrice:FlightType,index:number) => (
                <div key={index+ncNanoId()} className="bg-transparent dark:bg-transparent grid grid-cols-2 gap-2 border-t sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">
                  <div key={index+ncNanoId()} className="relative flex flex-wrap items-center justify-center col-span-1 bg-transparent dark:bg-transparent break-words">
                     {flightPrice.is_airline===true ? (
                      <img
                        src={(flightPrice.airline_id!==null && flightPrice.airline_id!==undefined) ? "http://pics.avs.io/as_gates/50/50/"+flightPrice.airline_id+"@2x.png" : "http://pics.avs.io/50/50/"+dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString()+"@2x.png" }
                        alt="logo"
                        key={index+ncNanoId()}
                      />
                    ):(
                        <img
                        src={"https://pics.avs.io/as_gates/50/50/"+flightPrice.agency_id+"@2x.png"}
                        alt="logo"
                        key={index+ncNanoId()}
                     />
                    )}
                  </div>

                  <div className="relative col-span-1 bg-transparent dark:bg-transparent break-words flex items-center justify-center">
                     <div className=" w-11/12 py-1 rounded-md justify-center" style={{backgroundColor:"#008487", color:"#fff"}} onClick={(e:any) => BuyRedirect(e,flightPrice.buy_link)}>
                        <p className="font-semibold flex justify-center">{Math.round(flightPrice.amount_to_pay)} {departure_currency}</p>
                        <p className="flex justify-center text-sm">{availableSeat}</p>
                    </div>
                  </div>

                </div>
            ))}

            {!flight_prices && (
                <div className="bg-transparent dark:bg-transparent grid grid-cols-2 border-t sm:text-sm md:text-base lg:text-base xl:text-base 2xl:text-base">
                    <div className="relative flex flex-wrap items-center justify-center col-span-1 bg-transparent dark:bg-transparent break-words">
                     {dataFlight.is_airline===true ? (
                      <img
                        src={(dataFlight.airline_id!==null && dataFlight.airline_id!==undefined) ? "http://pics.avs.io/as_gates/50/50/"+dataFlight.airline_id+"@2x.png" : "http://pics.avs.io/50/50/"+dataFlight.itineraries[0].flight_segments[0].display_airline_code.toString()+"@2x.png" }
                        alt="logo"
                      />
                    ):(
                        <img
                        src={"https://pics.avs.io/as_gates/50/50/"+dataFlight.agency_id+"@2x.png"}
                        alt="logo"
                     />
                    )}
                    </div>

                  <div className="relative col-span-1 bg-transparent dark:bg-transparent inline-block break-words">
                     <div className=" w-11/12 py-1 rounded-md justify-center" style={{backgroundColor:"#008487", color:"#fff"}} onClick={(e:any) => BuyRedirect(e,dataFlight.buy_link)}>
                            <p className="font-semibold flex justify-center">{Math.round(dataFlight.fee)} {departure_currency}</p>
                            <p className="flex justify-center text-sm">{availableSeat}</p>
                    </div>
                  </div>
                </div>
            )}

            </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}

      </Popover>
    </div>

        {/*END*/}

        </div>

    </div>

  );};

export default CardFlight;
