import React, { FC, useEffect, useRef } from "react";
import NcModal from "components/NcModal/NcModal";
import { CommentType } from "./CommentCard";
import { useTranslation } from 'react-i18next';


export interface ModalMessageProps {
  commentId?: CommentType["id"];
  show: boolean;
  onClickSubmit?: any;
  onCloseModalMessage: () => void;
}

const ModalMessage: FC<React.PropsWithChildren<React.PropsWithChildren<ModalMessageProps>>> = ({
  commentId,
  show,
  onClickSubmit,
  onCloseModalMessage,
}) => {
  const textareaRef = useRef(null);
  const {t} = useTranslation();



  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
        }
      }, 400);
    }
  }, [show]);




  const renderContent = () => {
    return  (
      <form>
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          {t('modal.message')}
        </h3>
        <span className="text-sm">
         {t('modal.submessage')}
        </span>
      </form>

    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalMessage}
      contentExtraClass="max-w-screen-sm"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default ModalMessage;
