import React, {useEffect,useState} from "react";
import NcLink from "components/NcLink/NcLink";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { removeUser, selectUser } from "app/user/user";
import { useTranslation } from 'react-i18next';
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";
import { useNavigate } from "react-router";
import VisitedCountries from "./VisitedCountries/VisitedCountries";
import Heading from "components/Heading/Heading";
import axios from "../../axios";
import {backendAddress} from "../../index";
import PageLoading from "containers/PageLoading/PageLoading";
import HereMap from 'components/HereMapComponent/HEREMap';
import MarkerMap from 'components/HereMapComponent/Marker';
import ncNanoId from "utils/ncNanoId";
import allCountriesList from "data/jsons/__countries.json";
import AntiSpamBadge from "components/Badge/Badge";
import Layout from "./layout";
import usePathname from "hooks/usePathname";
import { Route } from "routers/types";




export interface CountriesProps{
  name?: string;
  lat?: string;
  lng?: string;
  country_id?: number;
  flag?: string;

}

interface DashboardLocationState {
  "/root"?: {};
  "/posts"?: {};
  "/edit-profile"?: {};
  "/change-password"?: {};
  "/privacy"?: {};
  "/submit-post"?: {};
  "/account"?: {};
}




const DashboardRoot = () => {

  const {t} = useTranslation();
  const user: any = useAppSelector(selectUser);
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [vCountriesState, setVCountriesState] = useState<any>([]);
  const [stateCountry,setStateCountry]=useState<CountriesProps[]>();



  useEffect(() => {
    getAccessToken().then((access_token: any) => {
    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                handleLogout();
                history("/login");
        }
    });


  }, []);

  /*manage logout*/

  const dispatch = useAppDispatch();
  const handleLogout = () => {
    dispatch(removeUser());
  };


  /*GET visited countries*/
  useEffect(() => {
    setLoading(true);

    getAccessToken().then((access_token: any) => {
    axios
      .get(`/authors/${user.user.id}/articles?include_drafts=True`, {headers: {'Authorization': `Bearer ${access_token}`}})
      .then((result) => {
        setLoading(false);
        let data: any = result.data;
        if (data.articles[0]) {
          let vCountries: any = [];
          data.articles.map((article: any) => {
            vCountries.push({
              article:{
                  id: article.article_id,
                  country_name: article.country,
              },
            });
          });
          setVCountriesState(vCountries);



        } else {setVCountriesState([]);}


      }).catch((err) => {
        setLoading(false);
        setError(err);
      });
    }).catch((err) => {
        if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
        {
                handleLogout();
                history("/login");
        }
    });
  }, []);

  useEffect(() => {
     /*manage countries lat and lng*/
          let userCountries=[];
          for(let key in allCountriesList){
              for (let temp in vCountriesState){
                if(allCountriesList[key].name==vCountriesState[temp].article.country_name){
                    let findItem = userCountries.find((x:any) => x.name===vCountriesState[temp].article.country_name);
                        if(!findItem){
                            userCountries.push({
                                name:allCountriesList[key].name,
                                lat: allCountriesList[key].lat,
                                lng: allCountriesList[key].lng,
                                country_id:allCountriesList[key].country_id,
                                flag:allCountriesList[key].flag,
                            });
                        }
                }
              }
            }
            setStateCountry(userCountries);
  }, [vCountriesState]);

  /*end*/





const mapId="map"+ncNanoId();
  return (

    <Layout>

    {loading ? (
        <PageLoading />

    ):(



    <div className="rounded-xl min-h-full text-sm border border-neutral-100 dark:border-neutral-800 p-6 md:text-base">
      <span className="block text-lg mb-3">

        <AntiSpamBadge
            color="green"
            name={`Your Anti-phishing code: ${user.user.antiphishing_id}`}
        />


      </span>
      <span className="block text-lg mb-3 mt-5">
        👋 Hello <strong>{user.user.name}, </strong>


      </span>

      <p className="block mt-5"><strong>{t('antiphishing.code')}</strong></p>

      <Heading className="mt-10 text-neutral" desc={t("dashboard.submap")}>
            🌍️ {t('dashboard.map')}
      </Heading>
      {/*}<VisitedCountries countryList={vCountriesState} />*/}
      <div
          className="relative mt-5"
           style={{ width: "100%", height: "45vh", minHeight: "350px" }}
      >
          <HereMap
              mapContainerId={mapId}
              center={{ lat: 52.5321472, lng: 13.3935785 }}
              zoom={1}
              animateZoom={true}
              interactive={true}
              secure={true}
              stateCountry={stateCountry}
              personalDashboardMap={true}
            />


      </div>


    </div>)}
    </Layout>

  );
};

export default DashboardRoot;
