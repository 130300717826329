import React from 'react';
import { FixedMapGWay } from './Listing.style';
import useScript from 'hooks/useScript';




const ListingMap = (url:any) => {

  const script=useScript(url.url);
  return (
    <FixedMapGWay id="fixedMap" >
        {script}
    </FixedMapGWay>

  );
};

export default ListingMap;
