import React, { useEffect,useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionLatestPosts from "../PageHome/SectionLatestPosts";
import { useAppSelector } from "app/hooks";
import SectionLargeSlider from "components/SliderImage/SliderImage";
import SectionCountry from "components/SectionSliderBestCountries/SectionSliderBestCountries";
import {
  selectTopCategories,
  selectLatestArticles,
  selectCountries
} from "app/content/content";
import { useTranslation } from 'react-i18next';
import SectionWidgetPlaceDesktop from "components/SectionMapFlight/SectionWidgetPlaceDesktop";
import { selectUser } from "app/user/user";
import getAccessToken, {UNAUTHORIZED_ERROR_CODE} from "../../TokenAuth";

import { Button } from 'antd';
import AdSenseComponent from 'components/AdSenseComponent/AdSenseComponent';
import { Tab } from "@headlessui/react";
import PageContainerSearchFlightDesktop from './PageSearchFlight/PageContainerSearchFlightDesktop';
import PageContainerSearchFlight from './PageSearchFlight/PageContainerSearchFlight';
import SectionWhyItineraries from './GonnawayItineraries/SectionWhyItineraries';
import SectionSuggestedPlaces from './GonnawayItineraries/SectionSuggestedPlaces';
import PageSearchFlightNomadDesktop from './GonnawayItineraries/PageSearchFlightNomadDesktop';
import PageSearchFlightNomad from './GonnawayItineraries/PageSearchFlightNomad';
import {ImAirplane,ImEarth} from 'react-icons/im';
import { MdFlightTakeoff } from "react-icons/md";
import PageContainerMultiCityFlightDesktop from './PageMultiCities/PageContainerMultiCityFlightDesktop';
import PageContainerMultiCityFlight from './PageMultiCities/PageContainerMultiCityFlight';


const PageSearch: React.FC<React.PropsWithChildren<React.PropsWithChildren<unknown>>> = () => {
  const topCategories: any = useAppSelector(selectTopCategories);
  const latestArticles: any = useAppSelector(selectLatestArticles);
  const latestCountries: any = useAppSelector(selectCountries);
  const user: any = useAppSelector(selectUser);
  const [logged,setLogged]=useState(false);
  const {t} = useTranslation();
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [openDialog, setOpenDialog] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  let locale=navigator.language;
    let localeSplit=locale.split('-');
    if(localeSplit.length<2){
        if(localeSplit[0]==='en'){
            locale="en-us"
        }else{
            locale=localeSplit[0]+"-"+localeSplit[0].toUpperCase();
        }
    }


    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }


    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-orange-grey";
    }
    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, []);

  useEffect(() => {
    getAccessToken().then((access_token: any) => {
        setLogged(true);
    }).catch((err) => {
            setLogged(false)
            if(err.code && err.code === UNAUTHORIZED_ERROR_CODE)
            {setLogged(false)}
    });
  }, [user]);

  //close dialog
  const handleClose = (idItem:number) => {

    setOpenDialog(false);
  };

  function classNames(...classes:any) {
          return classes.filter(Boolean).join(' ')
  }







  //

  return (
    <div className="nc-PageHome overflow-hidden relative">
      <Helmet>
        <title>Gonnaway.com || Homepage</title>
      </Helmet>
      {(!logged && window.innerWidth>750) && (
        <div className="relative mt-5 ">

              <div className="w-full text-lg text-white flex flex-wrap justify-center items-center align-middle" style={{ backgroundColor:"#008487", height:width>500 ? "10vh" : "40vh"}} >
                    <div className="">
                        <span className="ml-2 font-semibold ">
                                {t('gway.whysignup')}
                        </span>
                        <Button className="ml-10 text-white border border-slate-300 hover:border-slate-400 font-semibold" style={{backgroundColor:"#008487"}} href="/#/signup">{t('signup.heading')}</Button>
                        <Button className="ml-2 text-white border border-slate-300 hover:border-slate-400" style={{backgroundColor:"#008487"}} href="/#/gonnawayners">{t('gway.advantage')}</Button>
                    </div>
              </div>


        </div>
       )}

       <div className={`relative flex justify-center items-center min-h-screen	`}>
        <SectionLargeSlider />
        </div>

        <div className={`container ${window.innerWidth>750 ? ' ': 'flex justify-center items-center'}  z-100`}>
              <div className={` nc-ContainerSearch  mx-auto ${width<479 ? 'container':''} sm:p-5`}>
                    <div className="container">
                        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
                            <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20">
                                <Tab
                                  key={t('flightText')}
                                  className={({ selected }) =>
                                    classNames(
                                      'w-full rounded-lg py-2.5 text-sm font-semibold leading-5',
                                      'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                      selected
                                        ? 'bg-primary-6000 shadow text-white'
                                        : 'text-white hover:bg-white/[0.12] hover:text-white'
                                    )
                                  }
                                >
                                   <p className="flex flex-wrap gap-2 justify-center items-center"><ImAirplane /> {t('flightText')}</p>
                                </Tab>

                                        <Tab
                                          key="multicity"
                                          className={({ selected }) =>
                                            classNames(
                                              'w-full rounded-lg py-2.5 text-sm font-semibold leading-5',
                                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                              selected
                                                ? 'bg-primary-6000 shadow  text-white'
                                                : 'text-white hover:bg-white/[0.22] hover:text-white'
                                            )
                                          }
                                        >
                                         <p className="flex flex-wrap gap-2 justify-center items-center"><ImEarth /> Multi-city</p>
                                        </Tab>

                                        <Tab
                                          key="Itinerary"
                                          className={({ selected }) =>
                                            classNames(
                                              'w-full rounded-lg py-2.5 text-sm font-semibold leading-5',
                                              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                              selected
                                                ? 'bg-primary-6000 shadow  text-white'
                                                : 'text-white hover:bg-white/[0.22] hover:text-white'
                                            )
                                          }
                                        >
                                         <p className="flex flex-wrap gap-2 justify-center items-center"><ImEarth /> Itinerary</p>
                                        </Tab>


                            </Tab.List>
                            <Tab.Panels className="mt-2">

                                <Tab.Panel
                                  key={0}
                                  className={classNames(
                                    'rounded-xl bg-white p-3',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                  )}
                                >
                                  {width>991 ? (
                                        <PageContainerSearchFlightDesktop
                                         className=""
                                        />
                                    ):(
                                        <PageContainerSearchFlight
                                         className=""
                                        />
                                    )}
                                </Tab.Panel>

                                <Tab.Panel
                                  key={1}
                                  className={classNames(
                                    'rounded-xl bg-white p-2',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                  )}
                                >
                                    {width>991 ? (
                                        <PageContainerMultiCityFlightDesktop
                                         className=""
                                        />
                                    ):(
                                        <PageContainerMultiCityFlight
                                         className=""
                                        />
                                    )}

                                </Tab.Panel>

                                <Tab.Panel
                                  key={2}
                                  className={classNames(
                                    'rounded-xl bg-white p-3',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                                  )}
                                >
                                    { !logged ? (
                                        <div className="">
                                            <center><MdFlightTakeoff style={{height:"60px",width:"60px",color:"#008487"}}/></center>
                                            <h3 className="flex mt-3 items-center justify-center text-xl font-semibold leading-none text-neutral-800 md:text-4xl">
                                              Itinerary
                                            </h3>
                                            <span style={{textAlign:"justify"}} className="flex items-center justify-center block text-base text-neutral-800 mt-3 sm:text-base ">
                                              {t('nomad.everywhere')}
                                            </span>
                                            <p className="flex mt-5 items-center justify-center font-semibold leading-none text-neutral-800" style={{textAlign:"justify"}}>
                                              {t('gway.whysignup')}
                                            </p>
                                            <Button className="w-full mt-5 text-white border border-slate-300 hover:border-slate-400 font-semibold" style={{backgroundColor:"#008487"}} href="/#/signup">{t('signup.heading')}</Button>
                                        </div>
                                    ):(
                                       width>991 ? (
                                        <PageSearchFlightNomadDesktop
                                            className=""
                                        />
                                        ):(
                                            <PageSearchFlightNomad
                                                className=""
                                            />
                                        )

                                    )}

                                </Tab.Panel>
                            </Tab.Panels>
                          </Tab.Group>
                    </div>




            </div>
        </div>

        {(!logged && window.innerWidth<750) && (
        <div className="relative mt-5 ">
              <div className="grid grid-rows-2 w-full text-lg text-white flex items-center justify-center" style={{ backgroundColor:"#008487", height:width>500 ? "10vh" : "25vh"}} >

                        <center><p className="flex justify-center font-semibold mt-4">
                                {t('gway.whysignup')}
                        </p></center>
                        <div className="flex flex-wrap justify-center gap-4">
                            <Button className="text-white border border-slate-300 hover:border-slate-400 font-semibold" style={{backgroundColor:"#008487"}} href="/#/signup">{t('signup.heading')}</Button>
                            <Button className="text-white border border-slate-300 hover:border-slate-400 font-semibold" style={{backgroundColor:"#008487"}} href="/#/gonnawayners">{t('gway.advantage')}</Button>
                        </div>

              </div>


        </div>
       )}


      {(selectedIndex!==2) && (
        <div className={width<1440 ? "container": ""}>
            <div className="relative mt-10">
                <SectionCountry
                    heading={t('section6.heading')}
                    subHeading={t('section6.subheading')}
                    countries={latestCountries}
                  />
            </div>

            <div>
                <AdSenseComponent dataAdSlot="8475847979" />
            </div>


            <div className="relative mt-10 ">
                <SectionWidgetPlaceDesktop
                    heading={t('flight.searchMapTitle')}
                    subHeading={t('flight.searchMapSubTitle')}
                />
            </div>

            <div className="container relative py-16 lg:py-28">
              <SectionLatestPosts
                className=""
                posts={latestArticles}
                postCardName="card14"
                gridClass="sm:grid-cols-2"
              />
            </div>

           <div>
                <AdSenseComponent dataAdSlot="4862006219" />
            </div>
        </div>

      )}

      {(selectedIndex===2 && logged ) && (
        <div  className="container">
        <div className=" relative mx-2 mt-10 ">
            <SectionWhyItineraries />
        </div>

         <div className=" relative -mx-5 mt-10 ">
                <SectionSuggestedPlaces />
         </div>
         <div className=" relative">
              <SectionLatestPosts
                heading="🗺  Gonnaway Articles"
                subheading={t('nomad.articles')}
                className="mx-5"
                posts={latestArticles}
                postCardName="card14"
                gridClass="sm:grid-cols-2"
              />
         </div>
         </div>
      )}

      {(selectedIndex===2 && !logged ) && (
        <div className={width<1440 ? "container": ""}>
        <div className="relative mt-10 ">
            <SectionCountry
                heading={t('section6.heading')}
                subHeading={t('section6.subheading')}
                countries={latestCountries}
              />
        </div>

        <div>
            <AdSenseComponent dataAdSlot="8475847979" />
        </div>


        <div className="relative mt-10 ">
            <SectionWidgetPlaceDesktop
                heading={t('flight.searchMapTitle')}
                subHeading={t('flight.searchMapSubTitle')}
            />
        </div>

        <div className="container relative py-16 lg:py-28">
          <SectionLatestPosts
            className=""
            posts={latestArticles}
            postCardName="card14"
            gridClass="sm:grid-cols-2"
          />
       </div>

       <div>
            <AdSenseComponent dataAdSlot="4862006219" />
        </div>
        </div>
      )}




     <div className="container relative">
       <SectionSubscribe2 className="py-16 lg:py-28" />
     </div>



    </div>
  );
};

export default PageSearch;






