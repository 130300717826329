import React, {FC, useState,useEffect } from 'react';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {FaUserFriends,FaMoneyBillWave, FaStopwatch20,FaGem,FaBriefcase,FaRegCalendar} from 'react-icons/fa';
import { setStateToUrl } from 'helpers/url_handler';
import Slider from '@mui/material/Slider';
import {getCurrency} from "locale-currency";
import Drawer from '@mui/material/Drawer';
import {IoFilterSharp} from 'react-icons/io5';
import ButtonPrimary from "components/Button/ButtonPrimary";
import ncNanoId from "utils/ncNanoId";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import { useThemeMode } from "hooks/useThemeMode";
import MultiCityDropdown from 'components/NomadDropdown/MultiCityDropdownFilter';
import { IoIosArrowDown } from 'react-icons/io';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { MULTICITY_DETAILS_PAGE } from 'data/constant';
import ButtonClose from "components/ButtonClose/ButtonClose";
import Button from "components/Button/Button";
import { Alert, Collapse, IconButton, SvgIcon } from "@mui/material";


import {
  FilterArea,
  FilterElementsWrapper,
  RoomGuestWrapper,
  ItemWrapper,

} from './MultiCityFilterSearchViewDark.style';

interface QuerySearch {
  adults_count: number;
  child_counts: number;
  infants_count:number;
  multi_city:string;
  flight_class:string;
  currency:string;
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  airlines?:string;
  id?:string;
  depart_time?:string;
  budget?:number;
}

export interface ModalCategoriesProps {
  query: QuerySearch;
  destinations:any;
}

export interface ListBoxClassItemType {
  id: number;
  name: string;
  type:string;
}


export interface AirportType {
  value: string;
  name: string;
}

const FilterDrawer: FC<React.PropsWithChildren<React.PropsWithChildren<ModalCategoriesProps>>> = ({
    query,
    destinations
    }) => {

  const {t} = useTranslation();
  const {isDarkMode} = useThemeMode();

  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];

  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (query.adults_count);
  const [flightChild,setFlightChild] = useState (query.child_counts);
  const [flightInfant,setFlightInfant] = useState (query.infants_count);
  const [flightBudget,setFlightBudget] = useState (query.budget || 3000);
  const [bagAdult,setBagAdult] = useState (query.adult_hold_bag || 0);
  const [hBagAdult,setHBagAdult] = useState (query.adult_hand_bag ||0);
  const [handleClass,setHandleClass] = useState (0);
  const [showAlert, setShowAlert] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
      for (let key in FILTERS_CLASS){
        if(FILTERS_CLASS[key].type===query.flight_class){
            setHandleClass(FILTERS_CLASS[key].id);
            break;
        }
      }
   },[]);

  const [destinationData,setDestinationData]=useState<any>(destinations);


    //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };

  //manage departure time
  const [valueDep, setValueDep] = React.useState<number[]>([0, 23]);

  const handleChangeDepTime = (event: Event, newValue: number | number[]) => {
    setValueDep(newValue as number[]);
  };

  function valuetext(value: number) {
    return `${value}:00`;
  }

const [valueArr, setValueArr] = React.useState<number[]>([0, 23]);

  const handleChangeArrTime = (event: Event, newValue: number | number[]) => {
    setValueArr(newValue as number[]);
  };

///////

  const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
  };

  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);
  };

   const handleIncrementBudget = () => {
    setFlightBudget(flightBudget+100);
  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);

  };


  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
    }
  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
    }
  };

  //set guests number
  const handleDecrementBudget = () => {
    if(flightBudget<=0)
    {
        setFlightBudget(0);
    } else {
      setFlightBudget(flightBudget-100);
    }
  };

   const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
    }
  };

  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);}

  };


///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  const [toggle, setToggle] = useState(false);
  const handleCloseDrawer = () => {
    window.scrollTo(0, 0);
    setToggle(false);
  };


  const handleToggleDrawer = () => {
    setToggle(!toggle);
    window.scrollTo(0, 0);
  };


   const themeDark = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                color:"white"
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
        },
        input:{
                background:"transparent",
                color:"white"
        },
        inputRoot: {
            background:"transparent",
            color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
            "& .MuiChip-label": {
              color: "#fff"
            },
            "& .MuiChip-deleteIcon": {
              color: "#1b8489"
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "#fff"
            },
          fontSize:"14px"
        },

      },
    },

    MuiInput: {
        styleOverrides: {
            root:{

                "&:before": {
                  borderBottom: "1px solid rgb(255,255,255)",
                },
                "&:hover": {
                  borderBottom: "1px solid #008489",
                },
           },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root:{
                color:"white",

           },


        },
    },

    MuiDrawer: {
        styleOverrides: {
            root:{
               // height:"100%",
                width:(windowSize.innerWidth > 800) ? "40%" : "100%",
           },
           paper:{

                width:(windowSize.innerWidth > 800) ? "40%" : "100%",
                backgroundColor:"#111827"
           },
        },
    },
    },
});



  const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
            color:"grey",
        },
        input:{
                background:"transparent",
        },
        inputRoot: {
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
          fontSize:"14px"
        },

      },
    },


    MuiDrawer: {
        styleOverrides: {
            root:{
                height:(windowSize.innerWidth > 600) ? "100%" : "100%",
                width:(windowSize.innerWidth > 800) ? "40%" : "100%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "40%" : "100%",
                backgroundColor:isDarkMode ? "#111827" : "#fff"

           },
        },
    },
    },
});

  /*management of Accordion expansion*/
  const [exCity,setExCity]=useState(true);
  const [exDate,setExDate]=useState(true);
  const [exGuest,setExGuest]=useState(true);
  const [exBudget,setExBudget]=useState(true);
  const [exDestination,setExDestination]=useState(true);
  const [exClass,setExClass]=useState(true);
  const [exBags,setExBags]=useState(true);
  const [exFlex,setExFlex]=useState(true);
  const [exTime,setExTime]=useState(true);

  const handleSetExCity=(e:any)=>{
    e.preventDefault();
    setExCity(!exCity);
  }

  const handleSetExDate=(e:any)=>{
    e.preventDefault();
    setExDate(!exDate);
  }

  const handleSetExGuest=(e:any)=>{
    e.preventDefault();
    setExGuest(!exGuest);
  }

  const handleSetExBudget=(e:any)=>{
    e.preventDefault();
    setExBudget(!exBudget);
  }

  const handleSetExDestination=(e:any)=>{
    e.preventDefault();
    setExDestination(!exDestination);
  }



  const handleSetExBags=(e:any)=>{
    e.preventDefault();
    setExBags(!exBags);
  }

  const handleSetExClass=(e:any)=>{
    e.preventDefault();
    setExClass(!exClass);
  }

  const handleSetExFlex=(e:any)=>{
    e.preventDefault();
    setExFlex(!exFlex);
  }

  const handleSetExTime=(e:any)=>{
    e.preventDefault();
    setExTime(!exTime);
  }

  /*end management of expanded Accordion*/

  /*END*/

  /*GO To Search*/
  const history=useNavigate();
  const goToSearchPage = () => {

let check=false;
    for(let key in destinations){
        if(destinations[key].departure_airport!=="" &&
        destinations[key].arrival_airport!=="" &&
        destinations[key].city_departure!=="" &&
        destinations[key].city_return!=="" &&
        destinations[key].outbound_date!==null && destinations[key].outbound_date!==undefined && destinations[key].outbound_date!==""){
            check=true;

        }
    }
    if(!check){
       setShowAlert(true);
       setShowMessage(false);
    }
    let multi_city_string="";
    let depart_time_string="";
    for(let i=0;i<destinationData.length;i++){
        if(i>0){
            multi_city_string+="__";
            depart_time_string+="__";
        }
        multi_city_string+=destinationData[i].departure_airport+"_"+destinationData[i].arrival_airport+"_"+destinationData[i].outbound_date;
        depart_time_string+=destinationData[i].id+"_"+destinationData[i].deparTime+"_"+destinationData[i].deparTimeTo;
    }

    var query : QuerySearch= {
      multi_city:multi_city_string,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      child_counts: flightChild,
      infants_count: flightInfant,
     // flight_class: FILTERS_CLASS[handleClass].type,
     flight_class: FILTERS_CLASS[handleClass].type,
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      id:ncNanoId(),
      depart_time:depart_time_string,
      budget:flightBudget

    };

    if(check){
    handleCloseDrawer();
        const search = setStateToUrl(query);
    history({
      pathname: MULTICITY_DETAILS_PAGE,
      search: search,
    },
      { state: {
        query: query ,
        destinations:destinationData
      } },


    );
    }
  };


  /*END*/

  /*manage callback for destination Data*/
  const destData = (userDestination:any) =>
  {
        let tempArray=[];
        let tempArrayDropdown=[];
        for (let key in userDestination){
            tempArray.push({
                id:key,
                departure_airport:userDestination[key].name,
                arrival_airport:userDestination[key].nameTo,
                city_departure:userDestination[key].city,
                city_return:userDestination[key].cityTo,
                outbound_date:userDestination[key].date,
                checkCompleteness:userDestination[key].modified,
                deparTime:userDestination[key].deparTime,
                deparTimeTo:userDestination[key].deparTimeTo,
            });

        }
        setDestinationData(tempArray);
  }

  //initialization
  useEffect(() => {
    //destData(destinations)
  }, []);


  /*END*/

  /* TIME DEPARTURE MANAGEMENT*/
  const valueTimeDeparture = (departFrom:number,departTo:number) =>
  {
    let temp=[departFrom!== undefined ? departFrom : 0,departTo!== undefined ? departTo : 23];
    return temp;
  }

  const changeStateArray = (item:any,event: any) => {

            const newState = destinationData.map((obj:any) => {

                if (parseInt(obj.id) === parseInt(item.id)) {
                console.log(obj.id)
                  return {...obj, deparTimeTo:event.target.value[1],deparTime:event.target.value[0]};
                }
                return obj;
              });

            setDestinationData(newState);


  };

  /*END*/




  return (
    <FilterArea>
        <div className=" container flex flex-wrap items-center" onClick={handleToggleDrawer} >
           <IoFilterSharp  style={{color:"#008489",fontSize:"28"}} /><b><span className="text-lg ml-2">{t('filter.title')} </span></b>
        </div>

        <ThemeProvider theme={isDarkMode ? themeDark : theme}>
            <Drawer
                anchor="left"
                open={toggle}
                onClose={handleCloseDrawer}
              >
                <div className="overflow-y-auto">
                    <ButtonClose
                                    onClick={()=>handleCloseDrawer()}
                                    className="absolute top-2 right-5 mb-5 bg-neutral-100 hover:bg-neutral-300 dark:hover:bg-neutral-700"
                    />
                    <div style={windowSize.innerWidth<600 ? {height:"280vh"} : {height:"150vh"}}>

                    <Collapse in={showAlert}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  <SvgIcon>
                    <path
                      fill="inherit"
                      d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42z"
                    />
                  </SvgIcon>
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              <p style={{ marginTop: ".2rem" }}>{showMessage ? t('profile.success') : t('nomad.compileFields')}</p>
            </Alert>
          </Collapse>

                        <div className="bg-primary-6000">
                            <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-12"></div>
                                <div className="w-full">
                                    <b><h2 className="text-white mt-5 text-center ">{t("filter.search")}</h2></b>
                                </div>
                            <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-5"></div>
                        </div>

                        <div className="p-8">
                            <FilterElementsWrapper>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    {/* DESTINATIONS */}
                                    <AccordionItem dangerouslySetExpanded={exDestination}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExDestination(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 ml-1"><FaRegCalendar className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('nomad.destination')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                            <div className="mb-10">



                                                    <MultiCityDropdown  destData={destData} destTemp={destinationData} />



                                            </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>



                                    {/*end cities*/}



                                    {/* Room & Guest type filter element */}
                                    <AccordionItem dangerouslySetExpanded={exGuest}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExGuest(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaUserFriends className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('pax')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <RoomGuestWrapper>
                                          <ItemWrapper>
                                                    <strong><span className="text-neutral-900 dark:text-neutral-100">{t('adult')}</span></strong>

                                                    <InputIncDec
                                                      id="adult"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementAdult()}
                                                      decrement={() => handleDecrementAdult()}
                                                      value={flightAdult}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong>
                                                        <div>
                                                            <p className="text-neutral-900 dark:text-neutral-100">{t('child')}</p>
                                                            <p className="text-xs text-neutral-900 dark:text-neutral-100">(2-11)</p>
                                                        </div>
                                                    </strong>

                                                    <InputIncDec
                                                      id="child"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementChild()}
                                                      decrement={() => handleDecrementChild()}
                                                      value={flightChild}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong>
                                                        <div>
                                                            <p className="text-neutral-900 dark:text-neutral-100">{t('setInfant')}</p>
                                                            <p className="text-xs text-neutral-900 dark:text-neutral-100">(under 2)</p>
                                                        </div>
                                                    </strong>

                                                    <InputIncDec
                                                      id="infant"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementInfant()}
                                                      decrement={() => handleDecrementInfant()}
                                                      value={flightInfant}
                                                    />
                                                  </ItemWrapper>

                                        </RoomGuestWrapper>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    {/* BAGS */}
                                    <AccordionItem dangerouslySetExpanded={exBags}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExBags(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaBriefcase className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('bags.total')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <RoomGuestWrapper>
                                          <ItemWrapper>
                                                    <strong className="text-neutral-900 dark:text-neutral-100">{t("bag.adult")}</strong>
                                                    <InputIncDec
                                                      id="bags"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementBagAdult()}
                                                      decrement={() => handleDecrementBagAdult()}
                                                      value={bagAdult}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong className="text-neutral-900 dark:text-neutral-100">{t("bag.hand")}</strong>
                                                    <InputIncDec
                                                      id="child"
                                                      className="text-neutral-500  dark:text-neutral-100"
                                                      increment={() => handleIncrementHBagAdult()}
                                                      decrement={() => handleDecrementHBagAdult()}
                                                      value={hBagAdult}
                                                    />
                                                  </ItemWrapper>


                                        </RoomGuestWrapper>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* END PASSENGERS AND BAGS */}

                                    {/*set class for flights*/}
                                            <AccordionItem dangerouslySetExpanded={exClass}>
                                              <AccordionItemHeading onClick={(e)=>handleSetExClass(e)}>
                                                <AccordionItemButton>
                                                  <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaGem className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('flightDetailClass')}</span></span>
                                                  <IoIosArrowDown />
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <RoomGuestWrapper>

                                                      <ItemWrapper>
                                                              <strong className="text-neutral-900 dark:text-neutral-100">Economy</strong>
                                                              <Button
                                                                            className={handleClass===1 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                                                            onClick={()=>setHandleClass(1)}
                                                                />
                                                              </ItemWrapper>
                                                      <ItemWrapper>
                                                              <strong className="text-neutral-900 dark:text-neutral-100">Premium Economy</strong>
                                                              <Button
                                                                            className={handleClass===2 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                                                            onClick={()=>setHandleClass(2)}
                                                                />
                                                              </ItemWrapper>
                                                      <ItemWrapper>
                                                              <strong className="text-neutral-900 dark:text-neutral-100">Business</strong>
                                                              <Button
                                                                            className={handleClass===3 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                                                            onClick={()=>setHandleClass(3)}
                                                                />
                                                              </ItemWrapper>
                                                      <ItemWrapper>
                                                              <strong className="text-neutral-900 dark:text-neutral-100">First class</strong>
                                                              <Button
                                                                            className={handleClass===4 ? "bg-primary-6000 h-3 w-3" :"h-3 w-3 bg-slate-100 dark:bg-slate-700"}
                                                                            onClick={()=>setHandleClass(4)}
                                                                />
                                                              </ItemWrapper>


                                                </RoomGuestWrapper>
                                              </AccordionItemPanel>
                                            </AccordionItem>

                                            {/* TIME */}
                                        <AccordionItem dangerouslySetExpanded={exTime}>
                                              <AccordionItemHeading onClick={(e)=>handleSetExTime(e)}>
                                                <AccordionItemButton>
                                                  <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaStopwatch20 className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('filter.time')}</span></span>
                                                  <IoIosArrowDown />
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <RoomGuestWrapper>
                                                    {destinationData && destinationData.map((item:any,index:number) =>(
                                                        <ItemWrapper className="gap-2 flex flex-wrap" key={index+ncNanoId()}>
                                                                <strong className="text-neutral-900 dark:text-neutral-100 " style={{width:"15%"}}>Flight {index+1}:</strong>
                                                                <Slider
                                                                    key={index+ncNanoId()}
                                                                    getAriaLabel={() => 'Departure Time'}
                                                                    value={valueTimeDeparture(item.deparTime,item.deparTimeTo)}
                                                                    onChange={(e:any)=>changeStateArray(item,e)}
                                                                    getAriaValueText={valuetext}
                                                                    min={0}
                                                                    max={23}
                                                                    valueLabelDisplay="on"
                                                                    style={{width:"80%"}}

                                                                />
                                                        </ItemWrapper>
                                                    ))}
                                                </RoomGuestWrapper>
                                              </AccordionItemPanel>
                                            </AccordionItem>

                                            {/* Start price range filter element */}
                                            <AccordionItem dangerouslySetExpanded={exBudget}>
                                              <AccordionItemHeading onClick={(e)=>handleSetExBudget(e)}>
                                                <AccordionItemButton>
                                                  <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaMoneyBillWave className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2">Budget</span></span>
                                                  <IoIosArrowDown />
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <RoomGuestWrapper>
                                                  <ItemWrapper>
                                                            <strong className="text-neutral-900 dark:text-neutral-100">Budget</strong>
                                                                <InputIncDec
                                                                  id="budget"
                                                                  className="text-neutral-500"
                                                                  increment={() => handleIncrementBudget()}
                                                                  decrement={() => handleDecrementBudget()}
                                                                  value={flightBudget}
                                                                />
                                                            </ItemWrapper>


                                                </RoomGuestWrapper>
                                              </AccordionItemPanel>
                                            </AccordionItem>
                                            {/* End of price range filter element */}




                                </Accordion>
                            </FilterElementsWrapper>
                        </div>

                    </div>

                </div>

                <ButtonPrimary className="mt-10 mb-2 w-11/12 ml-5" onClick={goToSearchPage}>
                    {t('filter.search')}
                </ButtonPrimary>




            </Drawer>
        </ThemeProvider>


    </FilterArea>
  );
};

export default FilterDrawer;
