import Heading from "components/Heading/HeadingSearch";
import React, { FC, useState, useEffect } from "react";
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import Autocomplete from "@mui/material/Autocomplete";
import ncNanoId from "utils/ncNanoId";
import Input from "components/Input/Input";

import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import TextField from "@mui/material/TextField";
import { useTranslation } from 'react-i18next';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import {FaMapMarkerAlt, FaRegCalendar, FaCalendarCheck, FaUserFriends,FaBriefcase} from 'react-icons/fa';
import {ComponentWrapper,FormWrapper,ItemWrapper, RoomGuestWrapper} from '../SearchDesktop.style';
import { Button } from 'antd';
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {getCurrency} from "locale-currency";
import { setStateToUrl } from 'helpers/url_handler';
import { NOMAD_DETAILS_PAGE } from 'data/constant';
import { useNavigate } from "react-router";
import axios from "../../../axios";
import type {} from '@mui/lab/themeAugmentation';
import NomadDropdown from 'components/NomadDropdown/NomadDropdown';
import { isMobile} from 'react-device-detect';



export interface SectionFlightProps {
  className?: string;
  heading?: string;
}

export interface AirportType {
  value: string;
  name: string;
}

export interface ListBoxItemType {
  id:number;
  name: number;
}

interface QuerySearch {
  adults_count: number;
  childs_count: number;
  infants_count:number;
  departure_airport:string;
  return_airport:string;
  flight_class:string;
  currency:string;
  date_from?:any;
  date_from_up_to?:any;
  date_to?:any;
  places_to_visit?:any[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
  id?:string;
  flexibility?:number;
}

const PageContainerSearchFlight: FC<React.PropsWithChildren<React.PropsWithChildren<SectionFlightProps>>> = ({
  heading = "",
  className = "",
}) => {
    const {t} = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [checkField, setCheckField] = useState(false);
    const [nomad, setNomad] = useState(false);
    const [userInput, setUserInput] = useState<any>("City");
    const tagArray = [userInput];
    const [returnDiff, setReturnDiff] = useState(false);

    /* to manage search variables */
   const [searchDate, setSearchDate] = useState({
    setStartDate: null,
    setEndDate: null,
  });

  const [oneWayDate, setOneWayDate] = useState({
    setDate: null,
  });

    const addDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()+days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return formattedToday;
        }
        return oneWayDate.setDate;
    }

    const decreaseDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            var today = new Date();
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()-days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return (date.getTime()-today.getTime()>=0) ? formattedToday : oneWayDate.setDate;
        }
        return oneWayDate.setDate;
    }
    /*END*/




  const navigate=useNavigate();

  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (1);
  const [flightChild,setFlightChild] = useState (0);
  const [flightPeopleTotal,setFlightPeopleTotal] = useState (1);
  const [flightInfant,setFlightInfant] = useState (0);
  const [departureAirportValue, setDepartureAirportValue] = useState<string>("");
  const [arrivalAirportValue, setArrivalAirportValue] = useState("");
  const [switchState, setSwitchState] = useState(true);
  const [value, setValue] = useState<AirportType | null>(null);
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:""}]);
  const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:""}]);
  const [destinations,setDestinations]=useState<any[] | undefined>(undefined);
  const [flexDays,setFlexDays] = useState (3);


  const [bagAdult,setBagAdult] = useState (0);
  const [hBagAdult,setHBagAdult] = useState (0);
  const [bagTotal,setBagTotal] = useState (0);

  let switchValue=true;

  let oneWayReturn=true;

      //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };


  ///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());


  useEffect(() => {
    function handleWindowResize() {


      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

    const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=it`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
              }
                setAirValue(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }


  const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
                }
                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });

    }


  const updateDepartureAirportFunc = (value:any) => {
    if(value!=null){
     setDepartureAirportValue(value.value);
     }else{return;}
   };

  const updateArrivalAirportFunc = (value:any) => {
    if(value!=null){
        setArrivalAirportValue(value.value);
    }
    else{return;}
   };

   const handleInput = (e: any) => {
    setUserInput(e.target.value);
  };

  // Flight Guest data handling

   const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
        setFlightPeopleTotal(flightPeopleTotal+1);
  };


  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);
    setFlightPeopleTotal(flightPeopleTotal+1);

  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);
    setFlightPeopleTotal(flightPeopleTotal+1);

  };

  const handleIncrementDays = () => {
        setFlexDays(flexDays+1);

  };

  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
      if(flightPeopleTotal<=1)
    {
        setFlightPeopleTotal(1);
    } else{
      setFlightPeopleTotal(flightPeopleTotal-1);
    }
    }



  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
      if(flightPeopleTotal<=1)
        {
            setFlightPeopleTotal(1);
        } else{
          setFlightPeopleTotal(flightPeopleTotal-1);
        }
    }



  };

  //set guests number
  const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
      if(flightPeopleTotal<=1)
        {
            setFlightPeopleTotal(1);
        } else{
          setFlightPeopleTotal(flightPeopleTotal-1);
        }
    }

  };

  const handleDecrementDays = () => {
    if(flexDays<=1){setFlexDays(1);}else{setFlexDays(flexDays-1);}
  };


  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };



  /**/





  const handleTagChange = (value:any) => {

  };



  const setMode = (e:any) => {
    e.preventDefault();
    if(switchState){
    setSwitchState(false);
   } else { setSwitchState(true);}
   switchValue=!switchValue;
  };

  const handleFocus=(e:any)=>{
  }


 const defaultDepProps = {
    options: airValue,
    getOptionLabel: (option: AirportType) => option.name,
  };

   const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => option.name,
  };





  const res = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});


  const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{

                background:"transparent",

           },

        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot

        root: {
          // Some CSS
           background:"transparent",
            color:"grey",
            fontSize:windowSize.innerWidth>1024?"18px":"11px"

        },

        input:{
                background:"transparent",
                fontSize:windowSize.innerWidth>1024?"18px":"11px"
        },
        inputRoot: {
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
              fontSize:windowSize.innerWidth>1024?"18px":"11px"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
            fontSize:windowSize.innerWidth>1024?"18px":"11px"
        },

      },
    },

  },
});



const history=useNavigate();
  const goToSearchPage = (queryArray:any, userDestArray:any) => {

    var query : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      childs_count: flightChild,
      infants_count: flightInfant,
      flight_class: "Y",
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      places_to_visit:queryArray,
      flexibility:flexDays


    };

    var querySearchParam : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      childs_count: flightChild,
      infants_count: flightInfant,
      flight_class: "Y",
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      flexibility:flexDays,
      id:ncNanoId()
    };

    const search = setStateToUrl(querySearchParam);
    history(
      {
        pathname: NOMAD_DETAILS_PAGE,
        search: search,
      },
      { state: {
        query: query ,
        destinations:userDestArray
      } },
    );


  };

//CALLBACK NOMAD_DROPDOWN

const filterData = (userDestination:any) =>
{
        let tempArray=[];
        for (let key in userDestination){
            tempArray.push({
                location:userDestination[key].name,
                min_days:userDestination[key].min_days,
                max_days:userDestination[key].max_days
            });
        }
        setDestinations(tempArray);
        goToSearchPage(tempArray,userDestination);

}






  return (

      <div className="relative">
       {/* SWITCH */}
      <Heading
       className="nc-HeadingSearch text-sm w-full text-left	"
      desc={t('nomad.flightsubheader')}>
        {t('nomad.flightheader')}
      </Heading>


      <div className="relative rounded-xl bg-neutral-50 dark:bg-gray-50 md:border md:border-neutral-400 dark:border-neutral-400 sm:p-6 w-full ">
        {/*}<form onSubmit={goToSearchPage} >*/}
        <FormWrapper className="grid grid-cols-11 gap-2">

            <ComponentWrapper style={{gridColumn: "span 3 / span 3"}}>
                    <ThemeProvider theme={theme}>
                        <FaMapMarkerAlt className="map-marker" />
                        <div className="ml-10 w-full -mt-3">
                            <Autocomplete
                                {...defaultDepProps}
                                id="departure"
                                autoComplete={true}
                                autoSelect={true}
                                onInputChange={(e:any, newValue: any) => onChangeDepartureAirport(e,newValue)}
                                onChange={(event:any, newValue:any)=>updateDepartureAirportFunc(newValue)}
                                isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                style={{ color: "black", marginLeft:"0.7rem"}}
                                className="text-gray-800"
                                autoHighlight={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label={t('city')}
                                    variant="standard"

                                      />
                                )}
                                loading={true}
                                noOptionsText={t('noOptionTextAutoComplete')}
                          />

                        {returnDiff && (
                            <Autocomplete
                                {...defaultArrProps}
                                id="arrival"
                                autoComplete={true}
                                autoSelect={true}
                                onInputChange={(e:any, newValue: any) => onChangeArrivalAirport(e,newValue)}
                                onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                                isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                style={{ color: "black", marginLeft:"0.7rem"}}
                                className="text-gray-800"
                                autoHighlight={true}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={t('tocity')}
                                    variant="standard"
                                      />
                                )}
                                loading={true}
                                noOptionsText={t('noOptionTextAutoComplete')}
                          />
                        )}

                      </div>
                  </ThemeProvider>
      </ComponentWrapper>



      <ComponentWrapper style={{gridColumn: "span 4 / span 4"}}>
        <FaRegCalendar className="calendar" style={{color:"#008487"}}/>
        <ViewWithPopup
          key={300}
          noView={true}
          className="room_guest"
          style={{}}
          view={
            <Button type="default" >
              <div className="flex flex-wrap gap-3 w-full border-b border-gray-400	">
                <p className=" flex flex-wrap text-neutral-450 text-lg mt-1 gap-4 items-center ml-2 " >{oneWayDate.setDate ? oneWayDate.setDate : t('startDate') }</p>
              </div>
            </Button>
          }
          popup={
            <RoomGuestWrapper>
                <p className="text-neutral-900 text-lg font-semibold mb-4 flex flex-wrap items-center gap-4"><FaCalendarCheck className="calendar" style={{color:"#008487"}}/>{t('advice.period')}</p>
                <ItemWrapper>
                  <strong>
                    <p>{t('startDate')}</p>

                  </strong>
                  <SingleDatePicker
                        numberOfMonths={1}
                        small={true}
                        required={true}
                        placeholder= {t('startDate')}
                        onDateChange={(dateValue:any) => setOneWayDate(dateValue)}
                        displayFormat="DD-MM-YYYY"
                        readOnly={isMobile ? true : false}


                      />
                </ItemWrapper>

                <ItemWrapper>
                  <strong>
                    <p>{t('itinerary.flex')}</p>
                    <p className="text-neutral-900 text-2xl mt-1 text-xs" style={{marginLeft:"1rem"}}>({t('itinerary.daysFlex')})</p>
                  </strong>
                  <InputIncDec
                     id="flexDays"
                     className="text-neutral-500 mr-10"
                     increment={() => handleIncrementDays()}
                     decrement={() => handleDecrementDays()}
                     value={flexDays}
                  />
                </ItemWrapper>
            </RoomGuestWrapper>
          }
        />
      </ComponentWrapper>

      <ComponentWrapper style={{gridColumn: "span 1 / span 1"}}>
        <FaUserFriends className="user-friends" />
        <ViewWithPopup
          key={201}
          noView={true}
          className="room_guest"
          style={{}}
          view={
            <Button type="default">
                          <span className="text-neutral-900 text-2xl mt-1" style={{marginLeft:"1rem"}}>{flightPeopleTotal >= 0 && `${flightPeopleTotal}`}</span>

                        </Button>
          }
          popup={
            <RoomGuestWrapper>
              <ItemWrapper>
                            <strong>{t('adult')}</strong>
                            <InputIncDec
                              id="adult"
                              className="text-neutral-500"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={flightAdult}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <div>
                                  <p className="text-neutral-900">{t('child')}</p>
                                  <p className="text-xs text-neutral-900">(2-11)</p>
                            </div>
                            <InputIncDec
                              id="child"
                              className="text-neutral-500"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={flightChild}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <div>
                                    <p className="text-neutral-900">{t('setInfant')}</p>
                                    <p className="text-xs text-neutral-900">(under 2)</p>
                            </div>
                            <InputIncDec
                              id="infant"
                              className="text-neutral-500"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={flightInfant}
                            />
                          </ItemWrapper>
            </RoomGuestWrapper>
          }
        />
      </ComponentWrapper>


      <ComponentWrapper style={{gridColumn: "span 1 / span 1"}}>
        <FaBriefcase className="user-friends" style={{width:"50px"}} />
        <ViewWithPopup
          key={202}
          noView={true}
          className="room_guest"
          style={{}}
          view={
            <Button type="default">
                     <span className="text-neutral-900 text-2xl mt-1" style={{marginLeft:"1rem"}}>{bagTotal >= 0 && `${bagTotal}`}</span>
            </Button>
          }
          popup={
            <RoomGuestWrapper>
              <ItemWrapper>
                            <strong>{t("bag.adult")}</strong>
                            <InputIncDec
                              id="bagAdult"
                              className="text-neutral-500"
                              increment={() => handleIncrementBagAdult()}
                              decrement={() => handleDecrementBagAdult()}
                              value={bagAdult}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <strong>{t("bag.hand")}</strong>
                            <InputIncDec
                              id="hBag"
                              className="text-neutral-500"
                              increment={() => handleIncrementHBagAdult()}
                              decrement={() => handleDecrementHBagAdult()}
                              value={hBagAdult}
                            />
                          </ItemWrapper>

            </RoomGuestWrapper>
          }
        />
      </ComponentWrapper>

      <ComponentWrapper style={{gridColumn: "span 2 / span 2"}}>
            <NomadDropdown
                  filterData={filterData}
            />
      </ComponentWrapper>


    </FormWrapper>
     {/*}</form>*/}
     <p className="text-neutral-500 ml-3 mt-2 flex flex-wrap gap-2 items-center font-semibold">
        <Input
                type="checkbox"
                className="mt-1 w-1 h-1 self-center dark:bg-neutral-200"
                name="comeBack"
                value="comeBack"
                style={{height:"10px", width:"10px"}}
                onChange={()=>setReturnDiff(!returnDiff)}

              />
        <span>{t('itinerary.diffReturn')}</span>
     </p>

        </div>

        </div>


  );
};

export default PageContainerSearchFlight;


