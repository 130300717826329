import { SocialType } from "components/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  {
    id: "Facebook",
    name: "Facebook",
    icon: "lab la-facebook-square",
    href: "https://www.facebook.com/gonnaway.com2021",
  },
  {
    id: "Twitter",
    name: "Twitter",
    icon: "lab la-twitter",
    href: "https://twitter.com/GonnawayCom",
  },
  // { id: "Youtube", name: "Youtube", icon: "lab la-youtube", href: "#" },
  {
    id: "Instagram",
    name: "Instagram",
    icon: "lab la-instagram",
    href: "https://www.instagram.com/gonnaway.travel",
  },
];

export const SOCIALS_2 = socialsDemo;

const SocialsList: FC<React.PropsWithChildren<React.PropsWithChildren<SocialsListProps>>> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map(
        (item, i) =>
          item.href && (
            <a
              key={i}
              className={`${itemClass}`}
              href={item.href}
              target="_blank"
              rel="noopener noreferrer"
              title={item.name}
            >
              <i className={item.icon}></i>
            </a>
          )
      )}
    </nav>
  );
};

export default SocialsList;
