import CardCategory1 from "components/CardCategory1/CardCategory1";
import CardCategory2 from "components/CardCategory2/CardCategory2";

import CardCategory5 from "components/CardCategory5/CardCategory5";
import Heading from "components/Heading/Heading";
import React from "react";
import { useTranslation } from 'react-i18next';


export interface SectionGridCategoryBoxProps {
  categories?: any;
  headingCenter?: boolean;
  categoryCardType?: "card1" | "card2" | "card3" | "card4" | "card5";
  className?: string;
  isNotHeading?: boolean;
}

const SectionGridCategoryBox: React.FC<React.PropsWithChildren<React.PropsWithChildren<SectionGridCategoryBoxProps>>> = ({
  categories = "",
  categoryCardType = "card2",
  headingCenter = true,
  className = "",
  isNotHeading,
}) => {
    const {t} = useTranslation();

  let CardComponentName = CardCategory2;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategory1;
      break;
    case "card2":
      CardComponentName = CardCategory2;
      break;

    case "card5":
      CardComponentName = CardCategory5;
      break;

    default:
      CardComponentName = CardCategory2;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      {!isNotHeading && (
        <Heading
          desc={t('section4.subheading',{topCat: categories.length})}
          isCenter={headingCenter}
        >
          {t('section4.heading')}
        </Heading>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 sm:gap-6 md:gap-8">
        {categories.map((item: any, i: any) => (
          <CardComponentName
            index={i < 3 ? `#${i + 1}` : undefined}
            key={i}
            taxonomy={item}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
