// @ts-nocheck
import React, { FC,useState,useEffect}  from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import {FaClock,FaBed,FaHourglassHalf} from "react-icons/fa";
import {AiFillWarning} from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import axios from "../../axios";
import { BsAirplaneFill} from "react-icons/bs";
import { FcTimeline} from "react-icons/fc";
import ncNanoId from "utils/ncNanoId";
import { useNavigate } from "react-router";
import momentTimezone from 'moment-timezone';
import moment from 'moment';
import airportsJson from "data/jsons/airports.json";
import Badge from "components/Badge/Badge";


export interface Card3Props {
  className?: string;
  dataFlight: any;
  size?: "normal" | "large";
}

export interface FlightSegmentArray {
  departureAirport?: string;
  arrivalAirport?: string;
  departure_time?:string;
  arrival_time?:string;
  carrier?:string;
  flight_number?:string;
  carrier_operating?:string;
  class?:string;
}
interface QuerySearch {
  adults_count: number;
  child_counts: number;
  infants_count:number;
  departure_airport:string;
  arrival_airport:string;
  with_return:boolean;
  flight_class:string;
  currency:string;
  outbound_date?:any;
  return_date?:any;
  rangeDep?:number[];
  rangeArr?:number[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag:number;
  child_hand_bag:number;
}

const CardNomad: FC<React.PropsWithChildren<React.PropsWithChildren<Card3Props>>> = ({
  className = "",
  size = "large",
  dataFlight,
}) => {
  const history = useNavigate();


  /*start check size*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /*end windows size*/

  /*set variable to set flight type*/
  let withReturn=false;
  let buy_link;
  const {t} = useTranslation();
  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 0, name: "Any", type:"Y" },
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];


  /*variables card*/
    let flight_routes=dataFlight.route;


  /*END*/

  function logoAirline(name:any){
    let airlogo="https://images.kiwi.com/airlines/64x64/"+name+".png";
    return airlogo;
  }

  //manage the buy request
 const BuyRedirect = (e:any,b_link:string) =>
  {
    e.preventDefault();
    //let urlBuy="/"+b_link;
    const buyWindow = window.open('about:blank');
       axios
      .get(b_link)
              .then((result) => {
                let data: any = result.data;

                buyWindow.document.location.href = data.buy_link;
              })
              .catch((err) => {
                console.log("There was an error");
              });
 }



 function routeTime(tp,airport_code)
  {
       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let formattedTime=momentTimezone.tz(tp,tzCod[0].timezone).format('ddd D MMM - HH:mm')
       return formattedTime;
  }




  function durationStopOver(route:any,routenext:any){
    let duration = (new Date(routenext.utc_departure).getTime() - new Date(route.utc_arrival).getTime());

        var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

           hours = (hours < 10) ? "0" + hours : hours;
           minutes = (minutes < 10) ? "0" + minutes : minutes;
           seconds = (seconds < 10) ? "0" + seconds : seconds;

           return (
            <p className="flex flex-wrap gap-2 items-center">{route.cityTo}<FaHourglassHalf />{hours + ":" + minutes}</p>
          );

  }



    function durationFlight(route:any){

        let duration = (new Date(route.utc_arrival).getTime() - new Date(route.utc_departure).getTime());
        var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        let days = parseInt((Math.floor((duration / (1000 * 60 * 60*24)))+'')[0])*24;
        let hoursTemp=days+hours;

           hours = (hoursTemp < 10) ? "0" + (hoursTemp) : (hoursTemp);
           minutes = (minutes < 10) ? "0" + minutes : minutes;
           seconds = (seconds < 10) ? "0" + seconds : seconds;

          return (
            <p className="flex flex-wrap items-center" style={{gap:"0.2rem"}}><FaClock />{hours + ":" + minutes}</p>
          );
  }

  function stayNight(route:any,routeNext:any){
        let arrival= new Date(retDay(route.utc_arrival,route.flyTo));
        let departure= new Date(retDay(routeNext.utc_departure,routeNext.flyFrom));

        let diffTime = Math.abs(departure - arrival);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return parseInt(diffDays);
  }

    function retDay(tp,airport_code)
    {
       let tzCod=airportsJson.filter((item:any) => {return item.iata === airport_code;});
       let formattedTime=momentTimezone.tz(tp,tzCod[0].timezone).format('MM/DD/YYYY')
       return formattedTime;
    }

  function stayNightPrev(route:any,routePrev:any){

        let arrival= new Date(retDay(routePrev.utc_arrival,routePrev.flyTo));
        let departure= new Date(retDay(route.utc_departure,route.flyFrom));
        // To calculate the time difference of two dates
        var Difference_In_Time = departure.getTime() - arrival.getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

        return Difference_In_Days;
  }



  return (

    <div
      className={`nc-Card3 relative sm:items-center rounded-[40px] group border-gray-800 mx-1  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="Card3"
    >
        {flight_routes && flight_routes.map((route:any,index:number) => (
            <div key={index}>
                <div className="grid grid-cols-10 gap-1 bg-neutral-100 dark:bg-neutral-900 rounded-[20px] relative z-1">
                    <div className={windowSize.innerWidth>600 ? "relative col-span-5 flex justify-start items-center gap-3 py-5 ml-3" : "relative col-span-5 flex justify-start items-center gap-3 py-5 ml-1"}>
                        <div
                                  className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 text-xl ring-1 ring-neutral-300 dark:ring-neutral-900`}
                                  style={{ backgroundColor: "#fff"}}
                                  key={index+ncNanoId()}
                                >
                                <div className="flex flex-wrap">
                                    {route && route.airlines.map((airL:any,ind:number) => (
                                        <img
                                            key={ind+ncNanoId()}
                                            className="wil-avatar__name"
                                            src={logoAirline(airL)}
                                            alt=""
                                        />
                                    ))}

                                </div>
                        </div>
                        <p className="flex flex-wrap items-center gap-2">
                            {index===0 ? t('nomad.start') : ""}<strong>{route.cityFrom}</strong> ({route.flyFrom})

                        </p>
                    </div>
                <div className="relative col-span-5 flex justify-start items-center gap-2 py-5">
                    <div className="grid grid-cols-10 w-full">
                        <div className="col-span-5 flex items-center">
                                <Badge
                                  className="px-2"
                                  key={index+ncNanoId()}
                                  name={durationFlight(route)}
                                  href=""
                                  color="gonnaway"
                                />
                        </div>
                        <div className={windowSize.innerWidth>600 ? "col-span-5 flex flex-wrap items-center justify-end mr-5" : "col-span-5 flex flex-wrap items-center justify-end mr-1"}>
                            { index>0 && (index+1)<=flight_routes.length && (
                                <p className={windowSize.innerWidth>600 ? "gap-2 flex flex-wrap items-center" : "gap-1 flex flex-wrap items-center"}> <FaBed /><strong>{stayNightPrev(route,flight_routes[index-1])}</strong> {stayNightPrev(route,flight_routes[index-1])<2 ? t('nomad.night') : t('nomad.nights')}</p>
                            )}
                        </div>
                    </div>
                </div>


                </div>

                <div className={windowSize.innerWidth > 600 ? "grid grid-cols-8 gap-2 ml-3" : "grid grid-cols-8 gap-1"}>
                    <div className="relative items-center col-span-1">
                       <div className=" border-l border-gray-900 dark:border-neutral-100 w-1 mx-4 mb-1" style={{height:"43%"}}></div>
                            <BsAirplaneFill style={{marginLeft:"0.5rem", marginRight:"0.5rem", rotate:"180deg"}}/>
                       <div className="border-l border-gray-900 dark:border-neutral-100 w-1 mx-4 mt-1" style={{height:"43%"}}></div>
                    </div>
                    <div className="relative col-span-7 flex items-center">
                       <div className={((index+1)<flight_routes.length && route.flyTo!==flight_routes[index+1].flyFrom) ? "grid grid-rows-4 gap-2" : "grid grid-rows-3 gap-2 mt-4" }>
                            <p>{routeTime(route.utc_departure,route.flyFrom)}</p>
                            <div className="flex flex-wrap gap-2">
                                {route.route.map((rt:any,i:number) => (
                                    (i+1)<route.route.length && (
                                        <Badge
                                          className=""
                                          key={i+ncNanoId()}
                                          name={durationStopOver(rt,route.route[i+1])}
                                          href=""
                                          color="gonnaway"
                                        />
                                    )
                                ))}
                                {route.route.map((rt:any,i:number) => (
                                    route.route.length<2 && (
                                        <Badge
                                          className=""
                                          key={i+ncNanoId()}
                                          name={t('nomad.direct')}
                                          href=""
                                          color="gonnaway"
                                        />
                                    )
                                ))}
                            </div>


                            <div className={((index+1)<flight_routes.length && route.flyTo!==flight_routes[index+1].flyFrom) ? "row-span-1 flex flex-wrap items-center gap-2" : "row-span-2 flex flex-wrap items-center gap-3"}>
                                <p>
                                    {routeTime(route.utc_arrival,route.flyTo)} {((index+1)<flight_routes.length && route.flyTo!==flight_routes[index+1].flyFrom) ? <span>({route.flyTo})</span> : <span></span> }</p>
                            </div>
                            <div className="flex flex-wrap">
                                <p>
                                   {((index+1)<flight_routes.length && route.flyTo!==flight_routes[index+1].flyFrom) && (
                                        <span className={windowSize.innerWidth>600 ? "flex flex-wrap items-center gap-2" : "flex flex-wrap items-center gap-2"}> <AiFillWarning style={{color:"orange"}}/>{t('nomad.transfer')}</span>
                                   )}
                                </p>
                            </div>

                       </div>
                    </div>

                </div>
            </div>


        ))}

        <div className="grid grid-cols-10 gap-2 bg-neutral-100 dark:bg-neutral-900 rounded-[20px]">
            <div className="relative col-span-10 flex justify-start items-center gap-3 py-5">
                <p className="flex flex-wrap items-center ml-5 gap-2"> <FcTimeline /> {t('nomad.end')} <strong>{flight_routes[flight_routes.length-1].cityTo}</strong> ( {flight_routes[flight_routes.length-1].flyTo})</p>
            </div>
        </div>

        <div className="relative col-span-1 py-10 bg-transparent dark:bg-transparent ">
              <ButtonPrimary className="nc-BtnSubmitSearch mt-3" onClick={(e:any) => BuyRedirect(e,dataFlight.buy_link)}>
                        <b><span>{t('nomad.book')} {Math.round(dataFlight.price)} {dataFlight.currency}</span></b>
              </ButtonPrimary>
        </div>

    </div>

  );};

export default CardNomad;
