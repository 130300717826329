import { useEffect } from "react";
import withRouter from "hooks/withRouter";
import { createMemoryHistory } from "history";

export interface ScrollToTopProps {
  //history: RouterProps["history"];
}

const history = createMemoryHistory();

const ScrollToTop: React.FC<React.PropsWithChildren<React.PropsWithChildren<ScrollToTopProps>>> = () => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
};

export default withRouter(ScrollToTop);
