import React, { FC  } from 'react';


export interface CatProps {
  dataAdSlot: string;
}


const AdSenseComponent: FC<CatProps> = ({
    dataAdSlot,

    }) => {

    return (
        <div>
            <ins className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-4781953001044216"
                data-ad-slot={dataAdSlot}
                data-ad-format="auto"
                data-full-width-responsive="true">
            </ins>
            <script>
                 (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
    );
};

export default AdSenseComponent;