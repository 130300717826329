import { FC, useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionSliderNewAuthors from "components/SectionSliderNewAthors/SectionSliderNewAuthors";
import { Helmet } from "react-helmet";
import { useAppSelector } from "app/hooks";
import { selectTopCategories, selectTopAuthors } from "app/content/content";
import { convertDate } from "utils/convertDate";
import useFetch from "hooks/useFetch";
import Page404 from "containers/Page404/Page404";
import PageLoading from "containers/PageLoading/PageLoading";
import isLiked from "utils/isLiked";
import { selectUser } from "app/user/user";
import { useTranslation } from 'react-i18next';
import { CheckIcon } from "@heroicons/react/24/solid";
import { Listbox, Transition } from "@headlessui/react";
import ButtonDropdown from "components/ButtonDropdown/ButtonDropdown";
import ModalCategories from "../PageCategory/ModalCategories";
import { ListBoxItemType } from "components/NcListBox/NcListBox";
import { Fragment } from "react";
import SectionGridPosts from "../PageHome/SectionGridPosts";
import ButtonSecondary from "components/Button/ButtonSecondary";
import {backendAddress} from "../../index";
import axios from "../../axios";
import { useLocation } from 'react-router-dom';



export interface PageCountryProps {
  className?: string;
}

const PageCountry: FC<React.PropsWithChildren<React.PropsWithChildren<PageCountryProps>>> = ({ className = "" }) => {
  const topCategories: any = useAppSelector(selectTopCategories);
  const topAuthors: any = useAppSelector(selectTopAuthors);
  const { user } = useAppSelector(selectUser);
  const [articles, setArticles] = useState([]);
  const [articlesBkc, setArticlesBkc] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const location = useLocation();
  let path = location.pathname.split("/");
  const countryId = path[3];
  const {t} = useTranslation();
  const [showPosts, setShowPosts] = useState(24);

  const { loading, error, data } = useFetch(`/countries/${countryId}/articles`);

  const FILTERS : ListBoxItemType[]= [
    {id: 0, name: t("filter.selection") },
    {id: 1, name: t("filter.early") },
    {id: 2, name: t("filter.likes") },
    {id: 3, name: t("filter.comments") },
    //{id: 3, name: "Most Viewed" },
  ];

  const [selected, setSelected] = useState(FILTERS[0]);

  useEffect(() => {
     if (data) {
        if (data[0]) {
       let articlesArray: any = [];
          data.map((article: any) => {
              articlesArray.push({
                  id: article.article_id,
                  featuredImage: `${backendAddress}/${article.imgurl}`,
                  title: article.title,
                  desc: article.excerpt,
                  date: convertDate(article.created_date),
                  href: `/blog/article/${article.article_id}`,
                  commentCount: article.commentcount,
                  readingTime: article.readingtime,
                  country_name: article.country_name,
                  bookmark: {
                          isBookmarked: false,
                  },
                  like: isLiked(article.likes, user),

                  author: {
                          id: article.author_id,
                          displayName: article.author_name,
                          avatar: `${backendAddress}/${article.author_imgurl}`,
                          href: `/blog/author/${article.author_id}`,
                  },
                  categories: [
                  {
                            id: article.category_id,
                            name: article.category_name,
                            href: `/blog/category/${article.category_id}`,
                            thumbnail: article.category_thumbnail,
                            color: article.category_color,
                  },
                  ],
               });
             });
             setArticles(articlesArray);
             setArticlesBkc(articlesArray);
             setCountry(data[0].country_name);
       }
     }

  }, [data]);


  if (loading) return <PageLoading />;
  if (error) return <Page404 />;

    let articleTemp=articles;
   const changeSelectedFilter = (e:any) =>
  {
    setSelected(e);
     switch (e.id){
        default: setArticles(articlesBkc);
            break;
        case 1: articleTemp.sort((a:any, b:any) => ((new Date(b.date)).getTime() - (new Date(a.date)).getTime()));
                setArticles(articleTemp);
                break;
        case 2: articleTemp.sort((a:any, b:any) => ((a.like.count)  < (b.like.count)) ? 1 : -1);
                setArticles(articleTemp);
                break;
        case 3: articleTemp.sort((a:any, b:any) => ((a.commentCount)  < (b.commentCount)) ? 1 : -1);
                setArticles(articleTemp);
                break;
      }
  }

  const showMore = () =>
  {
    setShowPosts(showPosts*2);
  }


  return (
    <div>
      {articles ? (
        <div className={`nc-PageArchive ${className}`} data-nc-id="PageArchive">
            <Helmet><title>{country} || Community Travel</title></Helmet>
            <div className="container space-y-16 lg:space-y-28">
                <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row gap-4">
                    <div className={`nc-ArchiveFilterListBox ${className}`}>
                      <ModalCategories categories={topCategories} />
                    </div>
                    <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
                    <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row sm:gap-y-2 justify-end">
                        <div className={`nc-ArchiveFilterListBox ${className}`} data-nc-id="ArchiveFilterListBox">
                            <Listbox value={selected} onChange={(e:any)=>changeSelectedFilter(e)}>
                                <div className="relative md:min-w-[200px]">
                                  <Listbox.Button as={"div"}>
                                    <ButtonDropdown>{selected.name}</ButtonDropdown>
                                  </Listbox.Button>
                                  <Transition
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute right-0 z-20 w-52 py-1 mt-1 overflow-auto text-sm text-neutral-900 dark:text-neutral-200 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-neutral-900 dark:ring-neutral-700">
                                      {FILTERS.map((item: ListBoxItemType, index: number) => (
                                        <Listbox.Option
                                          key={index}
                                          className={({ active }) =>
                                            `${
                                              active
                                                ? "text-primary-700 dark:text-neutral-200 bg-primary-50 dark:bg-neutral-700"
                                                : ""
                                            } cursor-default select-none relative py-2 pl-10 pr-4`
                                          }
                                          value={item}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <span
                                                className={`${
                                                  selected ? "font-medium" : "font-normal"
                                                } block truncate`}
                                              >
                                                {item.name}
                                              </span>
                                              {selected ? (
                                                <span className="text-primary-700 absolute inset-y-0 left-0 flex items-center pl-3 dark:text-neutral-200">
                                                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                            </Listbox>
                        </div>
                    </div>
                </div>

                <div className="relative">
                    <SectionGridPosts
                      className=""
                      postCardName="card11"
                      heading={country}
                      subHeading={t('article.all')}
                      posts={articles.filter((_:any, i:any) => i < showPosts)}
                      gridClass="sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 lg:gap-8"
                      headingIsCenter
                    />
                    {articles.length> 24 && articles.length>showPosts &&  (
                     <div className="text-center mx-auto mt-8 md:mt-10">
                        <ButtonSecondary onClick={showMore}>{t('slider.showMore')}</ButtonSecondary>
                     </div>
                     )}
                     <div className="mt-10" />
                </div>

                {/* === SECTION 5 === */}
                <SectionSliderNewAuthors
                    heading={t('author.eliteHeading')}
                    subHeading={t('author.eliteSubHeading')}
                    authors={topAuthors}
                />

                {/* SUBSCRIBES */}
                <SectionSubscribe2 className="py-16 lg:py-28" />



            </div>
        </div>

      )
      :
      (
          <Page404 />
      )}
    </div>
  );

};

export default PageCountry;
