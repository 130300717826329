import React, {FC, useState,useEffect } from 'react';
import InputIncDec from 'components/InputIncDec/InputIncDec';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import {FaMapMarkerAlt,FaRegCalendar, FaUserFriends, FaGem,FaBriefcase} from 'react-icons/fa';
import {TbPlusMinus} from 'react-icons/tb';
import { setStateToUrl } from 'helpers/url_handler';
import SingleDatePicker from 'components/DatePicker/SingleDatePicker';
import {getCurrency} from "locale-currency";

import Drawer from '@mui/material/Drawer';
import {IoFilterSharp} from 'react-icons/io5';
import ncNanoId from "utils/ncNanoId";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "../../../../axios";
import {
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import ButtonCircle from "components/Button/ButtonCircle";
import NomadDropdown from 'components/NomadDropdown/NomadFilterDropdown';
import { useThemeMode } from "hooks/useThemeMode";

import { IoIosArrowDown } from 'react-icons/io';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { NOMAD_DETAILS_PAGE } from 'data/constant';
import ButtonClose from "components/ButtonClose/ButtonClose";





import {
  FilterArea,
  FilterElementsWrapper,
  RoomGuestWrapper,
  ItemWrapper,
  DivHidden
} from './NomadFilterSearchView.style';

interface QuerySearch {
  adults_count: number;
  childs_count: number;
  infants_count:number;
  departure_airport:string;
  return_airport:string;
  flight_class:string;
  currency:string;
  date_from?:any;
  date_from_up_to?:any,
  date_to?:any;
  places_to_visit?:any[];
  locale:string;
  adult_hold_bag:number;
  adult_hand_bag:number;
  child_hold_bag?:number;
  child_hand_bag?:number;
  id?:string;
  flexibility?:number | undefined;
}



export interface ModalCategoriesProps {
  query: QuerySearch;
  destinations:any;

}

export interface ListBoxClassItemType {
  id: number;
  name: string;
  type:string;


}


export interface AirportType {
  value: string;
  name: string;
}

const FilterDrawer: FC<React.PropsWithChildren<React.PropsWithChildren<ModalCategoriesProps>>> = ({
    query,
    destinations

    }) => {

  const {t} = useTranslation();
  const {isDarkMode} = useThemeMode();
    const [searchDate, setSearchDate] = useState({
        setStartDate: query.date_from,
        setEndDate: query.date_from_up_to,
      });

  const FILTERS_CLASS : ListBoxClassItemType[]= [
    {id: 0, name: "Any", type:"Y" },
    {id: 1, name: "Economy", type:"Y"  },
    {id: 2, name: "Premium Economy", type:"W"  },
    {id: 3, name: "Business", type:"C"  },
    {id: 4, name: "First Class", type:"F"  },
  ];


  // Flight guest state
  const [flightAdult,setFlightAdult] = useState (query.adults_count);
  const [flightChild,setFlightChild] = useState (query.childs_count);
  const [flightInfant,setFlightInfant] = useState (query.infants_count);
  const [flightBudget,setFlightBudget] = useState (1000);
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:""}]);
  const [airValueArr, setAirValueArr] = useState<AirportType[]>([{value:"",name:""}]);
  const [departureAirportValue, setDepartureAirportValue] = useState(query.departure_airport);
  const [arrivalAirportValue, setArrivalAirportValue] = useState(query.return_airport);
  const [bagAdult,setBagAdult] = useState (query.adult_hold_bag || 0);
  const [hBagAdult,setHBagAdult] = useState (query.adult_hand_bag ||0);
  const [handleClass,setHandleClass] = useState (0);
  const [flexDays,setFlexDays] = useState (query.flexibility || 3);

    //calendar style
    const calendarItem = {
      separator: '-',
      format: 'DD-MM-YYYY',
      locale: 'en',
    };


  //manage departure time
  const [valueDep, setValueDep] = React.useState<number[]>([0, 23]);


  const handleChangeDepTime = (event: Event, newValue: number | number[]) => {
    setValueDep(newValue as number[]);
  };

  function valuetext(value: number) {
    return `${value}:00`;
  }

const [valueArr, setValueArr] = React.useState<number[]>([0, 23]);

  const handleChangeArrTime = (event: Event, newValue: number | number[]) => {
    setValueArr(newValue as number[]);
  };

///////

  const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
  };

  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);
  };

   const handleIncrementBudget = () => {
    setFlightBudget(flightBudget+100);
  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);

  };


  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
    }
  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
    }
  };

  //set guests number
  const handleDecrementBudget = () => {
    if(flightBudget<=0)
    {
        setFlightBudget(0);
    } else {
      setFlightBudget(flightBudget-100);
    }
  };

   const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
    }
  };

  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);}

  };

  //Manage days flexibility



    const labelDeparture = (dateTemp:any, days:any)=>{
         if(dateTemp!==null && days!==undefined) {
            var today = new Date();
            let temp= (dateTemp +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            let dateCheck=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            dateCheck.setDate(date.getDate()-days);
            if(dateCheck.getTime()-today.getTime()<=0){
                return dateTemp;
            }
            date.setDate(date.getDate()+days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return formattedToday;
        }
        return null;
    }

    const [oneWayDate, setOneWayDate] = useState({
    setDate: query.date_from ? labelDeparture(query.date_from,query.flexibility) : null,
  });


    const addDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()+days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return formattedToday;
        }
        return oneWayDate.setDate;
    }

    const decreaseDays = (days:number)=>{
         if(oneWayDate.setDate!==null) {
            var today = new Date();
            let temp= (oneWayDate.setDate +'').split("-");
            let date=  new Date(temp[2]+"-"+temp[1]+"-"+temp[0])
            date.setDate(date.getDate()-days);

            let yyyy = date.getFullYear();
            let mm = date.getMonth() + 1; // Months start at 0!
            let dd = date.getDate();
            let daysDate=dd+'';
            let monthsDate=mm+'';
            if (dd < 10) {daysDate = '0' + dd};
            if (mm < 10) {monthsDate = '0' + mm};
            let formattedToday = daysDate + '-' + monthsDate + '-' + yyyy;
            return (date.getTime()-today.getTime()>=0) ? formattedToday : oneWayDate.setDate;
        }
        return oneWayDate.setDate;
    }

  const handleIncrementDays = () => {
        setFlexDays(flexDays+1);
  };
  const handleDecrementDays = () => {
    if(flexDays<=1){setFlexDays(1);}else{setFlexDays(flexDays-1);}
  };



///check width screen

  function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  const [toggle, setToggle] = useState(false);
  const handleCloseDrawer = () => {
    window.scrollTo(0, 0);
    setToggle(false);
  };


  const handleToggleDrawer = () => {
    setToggle(!toggle);
    window.scrollTo(0, 0);
  };


  const themeDark = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
                color:"white"
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
        },
        input:{
                background:"transparent",
                color:"white"
        },
        inputRoot: {
            background:"transparent",
            color:"white",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
            "& .MuiChip-label": {
              color: "#fff"
            },
            "& .MuiChip-deleteIcon": {
              color: "#1b8489"
            },
            "& .MuiAutocomplete-popupIndicator": {
              color: "#fff"
            },
          fontSize:"14px"
        },

      },
    },

    MuiInput: {
        styleOverrides: {
            root:{

                "&:before": {
                  borderBottom: "1px solid rgb(255,255,255)",
                },
                "&:hover": {
                  borderBottom: "1px solid #008489",
                },
           },
        },
    },

    MuiInputLabel: {
        styleOverrides: {
            root:{
                color:"white",

           },


        },
    },

    MuiDrawer: {
        styleOverrides: {
            root:{
                height:"140%",
                width:(windowSize.innerWidth > 800) ? "30%" : "100%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "30%" : "100%",
                backgroundColor:"#111827"
           },
        },
    },
    },
});



  const theme = createTheme({
  components: {
    // Name of the component
    MuiTextField: {
        styleOverrides: {
            root:{
                background:"transparent",
           },
        },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
           background:"transparent",
            color:"grey",
        },
        input:{
                background:"transparent",
        },
        inputRoot: {
            background:"transparent",
          "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#e5e7eb",
              background:"transparent",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#008489"
            },
          fontSize:"14px"
        },

      },
    },


    MuiDrawer: {
        styleOverrides: {
            root:{
                height:"130%",
                width:(windowSize.innerWidth > 800) ? "30%" : "100%",
           },
           paper:{
                width:(windowSize.innerWidth > 800) ? "30%" : "100%",
                backgroundColor:isDarkMode ? "#111827" : "#fff"

           },
        },
    },
    },
});

  /*management of Accordion expansion*/
  const [exCity,setExCity]=useState(true);
  const [exDate,setExDate]=useState(true);
  const [exGuest,setExGuest]=useState(true);
  const [exBudget,setExBudget]=useState(true);
  const [exDestination,setExDestination]=useState(true);
  const [exClass,setExClass]=useState(true);
  const [exBags,setExBags]=useState(true);
  const [exFlex,setExFlex]=useState(true);

  const handleSetExCity=(e:any)=>{
    e.preventDefault();
    setExCity(!exCity);
  }

  const handleSetExDate=(e:any)=>{
    e.preventDefault();
    setExDate(!exDate);
  }

  const handleSetExGuest=(e:any)=>{
    e.preventDefault();
    setExGuest(!exGuest);
  }

  const handleSetExBudget=(e:any)=>{
    e.preventDefault();
    setExBudget(!exBudget);
  }

  const handleSetExDestination=(e:any)=>{
    e.preventDefault();
    setExDestination(!exDestination);
  }



  const handleSetExBags=(e:any)=>{
    e.preventDefault();
    setExBags(!exBags);
  }

  const handleSetExClass=(e:any)=>{
    e.preventDefault();
    setExClass(!exClass);
  }

  const handleSetExFlex=(e:any)=>{
    e.preventDefault();
    setExFlex(!exFlex);
  }

  /*end management of expanded Accordion*/

  /*change airport*/
    const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
          if (value.length<3) return;
          axios
            .get(`/airports?name=${value}&locale=it`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
              }
                setAirValue(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });
    }

    const onChangeArrivalAirport = async (e:any, value:any) => {
        e.preventDefault();
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        if (value.length<3) return;
        axios
            .get(`/airports?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
                {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name + "-" + item.city_name + " ("+item.id+")"});
                    });
                }
                setAirValueArr(airTemp);
            })
            .catch((err) => {
              console.log("There was an error");
            });

    }

     const updateArrivalAirportFunc = (value:any) => {
    if(value!=null){
        setArrivalAirportValue(value.value);
    }
    else{return;}
   };

    const updateDepartureAirportFunc = (value:any) => {
        if(value!=null){
         setDepartureAirportValue(value.value);
         }else{return;}
    };

    const defaultDepProps = {
        options: airValue,
        getOptionLabel: (option: AirportType) => option.name,
    };

    const defaultArrProps = {
    options: airValueArr,
    getOptionLabel: (option: AirportType) => option.name,
  };

  /*END*/

  /*GO To Search*/
  const history=useNavigate();
  const goToSearchPage = (queryArray:any, userDestArray:any) => {
    handleCloseDrawer();
    var query : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      childs_count: flightChild,
      infants_count: flightInfant,
      flight_class: FILTERS_CLASS[handleClass].type,
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      flexibility:flexDays,
      places_to_visit:queryArray
    };

    var querySearchParam : QuerySearch= {
      departure_airport: departureAirportValue,
      return_airport: arrivalAirportValue==="" ? departureAirportValue : arrivalAirportValue,
      adults_count: (flightAdult === 0 && flightChild===0) ? 1 : flightAdult,
      childs_count: flightChild,
      infants_count: flightInfant,
      flight_class: FILTERS_CLASS[handleClass].type,
      currency: getCurrency(navigator.languages ? navigator.languages[0]:navigator.language),
      locale: navigator.language,
      adult_hold_bag:bagAdult,
      adult_hand_bag:hBagAdult,
      child_hold_bag:0,
      child_hand_bag:0,
      date_from:decreaseDays(flexDays),
      date_from_up_to:addDays(flexDays),
      flexibility:flexDays,
      id:ncNanoId()
    };

    const search = setStateToUrl(querySearchParam);

    history(
      {
        pathname: NOMAD_DETAILS_PAGE,
        search: search,
      },
      { state: {
        query: query ,
        destinations:userDestArray
      } },
    );
  };


  /*END*/




  /*NOMAD DROPDOWN MANAGEMENT*/
    const filterData = (userDestination:any) =>
    {
        let tempArray=[];
        for (let key in userDestination){
            tempArray.push({
                location:userDestination[key].name,
                min_days:userDestination[key].min_days,
                max_days:userDestination[key].max_days
            });
        }
        goToSearchPage(tempArray,userDestination);
    }
  /*END*/


  return (
    <FilterArea>
        <div className=" container flex flex-wrap items-center" onClick={handleToggleDrawer} >
           <IoFilterSharp  style={{color:"#008489",fontSize:"28"}} /><b><span className="text-lg ml-2">{t('filter.title')} </span></b>
        </div>

        <ThemeProvider theme={isDarkMode ? themeDark : theme}>
            <Drawer
                anchor="left"
                open={toggle}
                onClose={handleCloseDrawer}
              >
                <div>
                    <ButtonClose
                                    onClick={()=>handleCloseDrawer()}
                                    className="absolute top-2 right-5 mb-5 bg-neutral-100 hover:bg-neutral-300 dark:hover:bg-neutral-700"
                    />
                    <DivHidden>
                        <div className="bg-primary-6000">
                            <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-12"></div>
                                <div className="w-full">
                                    <b><h2 className="text-white mt-5 text-center ">{t("filter.search")}</h2></b>
                                </div>
                            <div className="w-12/13 border-t border-gray-100 dark:border-neutral-100 mt-5"></div>
                        </div>

                        <div className="p-8">
                            <FilterElementsWrapper>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true}>
                                    {/* Start city filter element */}
                                    <AccordionItem dangerouslySetExpanded={exCity}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExCity(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 ml-1"><FaMapMarkerAlt className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('filter.airport')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <ThemeProvider theme={isDarkMode ? themeDark : theme}>
                                        <span>{t('city')}:</span>
                                        <Autocomplete
                                            {...defaultDepProps}
                                            id="departure"
                                            autoComplete={true}
                                            autoSelect={true}
                                            onInputChange={(e:any, newValue: any) => onChangeDepartureAirport(e,newValue)}
                                            onChange={(event:any, newValue:any)=>updateDepartureAirportFunc(newValue)}
                                            isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                            style={isDarkMode ? { color: "black"} : {color:"white"}}
                                            className="text-gray-800 mb-3  dark:text-neutral-100"
                                            autoHighlight={true}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                required
                                                label={departureAirportValue}
                                                variant="standard"

                                                  />
                                            )}
                                            loading={true}
                                            noOptionsText={t('noOptionTextAutoComplete')}
                                      />
                                      <span className="mt-3">{t('tocity')}:</span>
                                      <Autocomplete
                                            {...defaultArrProps}
                                            id="arrival"
                                            autoComplete={true}
                                            autoSelect={true}
                                            onInputChange={(e:any, newValue: any) => onChangeArrivalAirport(e,newValue)}
                                            onChange={(event:any, newValue:any)=>updateArrivalAirportFunc(newValue)}
                                            isOptionEqualToValue={(option:any, value:any) => option.id === value.id}
                                            style={isDarkMode ? { color: "black"} : {color:"white"}}
                                            className="text-gray-800 dark:text-neutral-100"
                                            autoHighlight={true}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                required
                                                label={arrivalAirportValue}
                                                variant="standard"

                                                  />
                                            )}
                                            loading={true}
                                            noOptionsText={t('noOptionTextAutoComplete')}
                                      />
                                        </ThemeProvider>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* End of city filter element */}

                                    {/* Start date filter element */}
                                    <AccordionItem dangerouslySetExpanded={exDate}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExDate(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 ml-1"><FaRegCalendar className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('advice.period')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <SingleDatePicker
                                          numberOfMonths={1}
                                          small={true}
                                          required={true}
                                          placeholder= {oneWayDate.setDate ? oneWayDate.setDate : t('startDate')}
                                          onDateChange={(dateValue:any) => setOneWayDate(dateValue)}
                                          displayFormat="DD-MM-YYYY"
                                          withPortal={false }
                                        />
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* End of date filter element */}

                                    {/* Flexibility */}
                                    <AccordionItem dangerouslySetExpanded={exFlex}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExFlex(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><TbPlusMinus className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('itinerary.flex')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <RoomGuestWrapper>
                                          <ItemWrapper>
                                                    <strong>

                                                        <p className="text-neutral-900 dark:text-neutral-100">{t('itinerary.daysFlex')}</p>
                                                      </strong>
                                                      <InputIncDec
                                                         id="flexDays"
                                                         className="text-neutral-500 dark:text-neutral-100"
                                                         increment={() => handleIncrementDays()}
                                                         decrement={() => handleDecrementDays()}
                                                         value={flexDays}
                                                      />
                                                  </ItemWrapper>


                                        </RoomGuestWrapper>
                                      </AccordionItemPanel>
                                    </AccordionItem>



                                    {/* Room & Guest type filter element */}
                                    <AccordionItem dangerouslySetExpanded={exGuest}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExGuest(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaUserFriends className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('pax')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <RoomGuestWrapper>
                                          <ItemWrapper>
                                                    <strong><span className="text-neutral-900 dark:text-neutral-100">{t('adult')}</span></strong>

                                                    <InputIncDec
                                                      id="adult"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementAdult()}
                                                      decrement={() => handleDecrementAdult()}
                                                      value={flightAdult}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong>
                                                        <div>
                                                            <p className="text-neutral-900 dark:text-neutral-100">{t('child')}</p>
                                                            <p className="text-xs text-neutral-900 dark:text-neutral-100">(2-11)</p>
                                                        </div>
                                                    </strong>

                                                    <InputIncDec
                                                      id="child"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementChild()}
                                                      decrement={() => handleDecrementChild()}
                                                      value={flightChild}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong>
                                                        <div>
                                                            <p className="text-neutral-900 dark:text-neutral-100">{t('setInfant')}</p>
                                                            <p className="text-xs text-neutral-900 dark:text-neutral-100">(under 2)</p>
                                                        </div>
                                                    </strong>




                                                    <InputIncDec
                                                      id="infant"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementInfant()}
                                                      decrement={() => handleDecrementInfant()}
                                                      value={flightInfant}
                                                    />
                                                  </ItemWrapper>

                                        </RoomGuestWrapper>
                                      </AccordionItemPanel>
                                    </AccordionItem>

                                    {/* BAGS */}
                                    <AccordionItem dangerouslySetExpanded={exBags}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExBags(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaBriefcase className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('bags.total')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                        <RoomGuestWrapper>
                                          <ItemWrapper>
                                                    <strong className="text-neutral-900 dark:text-neutral-100">{t("bag.adult")}</strong>
                                                    <InputIncDec
                                                      id="bags"
                                                      className="text-neutral-500 dark:text-neutral-100"
                                                      increment={() => handleIncrementBagAdult()}
                                                      decrement={() => handleDecrementBagAdult()}
                                                      value={bagAdult}
                                                    />
                                                  </ItemWrapper>
                                                  <ItemWrapper>
                                                    <strong className="text-neutral-900 dark:text-neutral-100">{t("bag.hand")}</strong>
                                                    <InputIncDec
                                                      id="child"
                                                      className="text-neutral-500  dark:text-neutral-100"
                                                      increment={() => handleIncrementHBagAdult()}
                                                      decrement={() => handleDecrementHBagAdult()}
                                                      value={hBagAdult}
                                                    />
                                                  </ItemWrapper>


                                        </RoomGuestWrapper>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* END PASSENGERS AND BAGS */}

                                    {/*set class for flights*/}
                                            <AccordionItem dangerouslySetExpanded={exClass}>
                                              <AccordionItemHeading onClick={(e)=>handleSetExClass(e)}>
                                                <AccordionItemButton>
                                                  <span className="flex flex-wrap text-neutral-900 dark:text-neutral-100 ml-1"><FaGem className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('flightDetailClass')}</span></span>
                                                  <IoIosArrowDown />
                                                </AccordionItemButton>
                                              </AccordionItemHeading>
                                              <AccordionItemPanel>
                                                <RoomGuestWrapper>
                                                  <ItemWrapper>
                                                            <strong className="text-neutral-900 dark:text-neutral-100">Any</strong>
                                                            <ButtonCircle
                                                                size=" w-6 h-6"
                                                                onClick={()=>setHandleClass(0)}
                                                            />
                                                          </ItemWrapper>
                                                  <ItemWrapper>
                                                          <strong className="text-neutral-900 dark:text-neutral-100">Economy</strong>
                                                          <ButtonCircle
                                                                size=" w-6 h-6"
                                                                onClick={()=>setHandleClass(1)}
                                                            />
                                                          </ItemWrapper>
                                                  <ItemWrapper>
                                                          <strong className="text-neutral-900 dark:text-neutral-100">Premium Economy</strong>
                                                          <ButtonCircle
                                                                size=" w-6 h-6"
                                                                onClick={()=>setHandleClass(2)}
                                                            />
                                                          </ItemWrapper>
                                                  <ItemWrapper>
                                                          <strong className="text-neutral-900 dark:text-neutral-100">Business</strong>
                                                          <ButtonCircle
                                                                size=" w-6 h-6"
                                                                onClick={()=>setHandleClass(3)}
                                                            />
                                                          </ItemWrapper>
                                                  <ItemWrapper>
                                                          <strong className="text-neutral-900 dark:text-neutral-100">First class</strong>
                                                          <ButtonCircle
                                                                size=" w-6 h-6"
                                                                onClick={()=>setHandleClass(4)}
                                                            />
                                                          </ItemWrapper>


                                                </RoomGuestWrapper>
                                              </AccordionItemPanel>
                                            </AccordionItem>



                                    {/* DESTINATIONS */}
                                    <AccordionItem dangerouslySetExpanded={exDestination}>
                                      <AccordionItemHeading onClick={(e)=>handleSetExDestination(e)}>
                                        <AccordionItemButton>
                                          <span className="flex flex-wrap text-neutral-900 ml-1"><FaRegCalendar className="calendar" style={{color:"#008489",marginTop:"2px"}} /><span className="ml-2 text-neutral-900 dark:text-neutral-100">{t('nomad.destination')}</span></span>
                                          <IoIosArrowDown />
                                        </AccordionItemButton>
                                      </AccordionItemHeading>
                                      <AccordionItemPanel>
                                            <div className="mb-10">
                                                <NomadDropdown
                                                    itineraryDestination={destinations}
                                                    filterData={filterData}
                                                />
                                            </div>
                                      </AccordionItemPanel>
                                    </AccordionItem>
                                    {/* End of date filter element */}

                                </Accordion>
                            </FilterElementsWrapper>
                        </div>

                    </DivHidden>

                </div>




            </Drawer>
        </ThemeProvider>


    </FilterArea>
  );
};

export default FilterDrawer;
