import { FC, useEffect,useState,Fragment } from "react";
import Heading from "components/Heading/Heading";
import ncNanoId from "utils/ncNanoId";
import { useTranslation } from 'react-i18next';

import Stack from "@mui/material/Stack";
import {FaMapMarkerAlt,FaPlaneDeparture} from 'react-icons/fa';
import axios from "../../axios";
import {getCurrency} from "locale-currency";
import ViewWithPopup from 'components/ViewWithPopup/ViewWithPopup';
import {ComponentWrapper,FlightGuestWrapper,ItemWrapper} from './SearchDesktop.style';
import { Button } from 'antd';
import {FaPlane,FaBriefcase,FaUserFriends} from 'react-icons/fa';
import DateRangePickerBox from 'components/DatePicker/ReactDates';
import ListingMapKiwi from 'components/ListingPlacesKiwi/ListingMapKiwi';
import { useThemeMode } from "hooks/useThemeMode";
import { Combobox, Transition } from "app/headlessui";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import InputIncDec from 'components/InputIncDec/InputIncDec';
import { isMobile} from 'react-device-detect';





export interface SectionWidgetPlaceDesktopProps {
  className?: string;
  heading: string;
  subHeading: string;

}


export interface AirportType {
  value: string;
  name: string;
}

const SectionWidgetPlaceDesktop: FC<React.PropsWithChildren<React.PropsWithChildren<SectionWidgetPlaceDesktopProps>>> = ({
  heading,
  subHeading,
  className = "",
}) => {

  const UNIQUE_CLASS = "glide_" + ncNanoId();
  const {t} = useTranslation();
  const [airValue, setAirValue] = useState<AirportType[]>([{value:"",name:""}]);
  const [selectedAirValue, setSelectedAirValue] = useState<AirportType>({value:"",name:''});
  const [query, setQuery] = useState('');

  const filteredAirport =
    query === ''
      ? airValue
      : airValue.filter((air) => {
          return air.name.toLowerCase().includes(query.toLowerCase())
        })



  const [departureAirportValue, setDepartureAirportValue] = useState("");
  const [showMap,setShowMap] = useState (true);
  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  let limit= window.innerWidth > 900 ? 12 : 6;
  let locale=navigator.language;
  const currencyLocale=getCurrency(locale).toString().toLowerCase();
  const [flightAdult,setFlightAdult] = useState (1);
  const [flightChild,setFlightChild] = useState (0);
  const [flightInfant,setFlightInfant] = useState (0);
  const [bagAdult,setBagAdult] = useState (0);
  const [hBagAdult,setHBagAdult] = useState (0);
  const [bagTotal,setBagTotal] = useState (0);

  let lang=locale.split("-");


  const {isDarkMode} = useThemeMode()
  //calendar style
    const calendarItem = {
      separator: '-',
      format: 'YYYY-MM-DD',
      locale: 'en',

    };

    const [searchDate, setSearchDate] = useState({
    setStartDate: null,
    setEndDate: null,
  });

 const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }


    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

   // Flight Guest data handling

   const handleIncrementAdult = () => {
        setFlightAdult(flightAdult+1);
  };


  const handleIncrementChild = () => {
    setFlightChild(flightChild+1);

  };

  const handleIncrementInfant = () => {
    setFlightInfant(flightInfant+1);

  };

  //set guests number
  const handleDecrementAdult = () => {
    if(flightAdult<=1)
    {
        setFlightAdult(1);
    } else{
      setFlightAdult(flightAdult-1);
    }
  };

  //set guests number
  const handleDecrementChild = () => {
    if(flightChild<=0)
    {
        setFlightChild(0);
    } else {
      setFlightChild(flightChild-1);
    }
  };

  //set guests number
  const handleDecrementInfant = () => {
    if(flightInfant<=0)
    {
        setFlightInfant(0);
    } else {
      setFlightInfant(flightInfant-1);
    }
  };

  /*set bags*/
  const handleIncrementBagAdult = () => {
        setBagAdult(bagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleIncrementHBagAdult = () => {
        setHBagAdult(hBagAdult+1);
        setBagTotal(bagTotal+1);
  };

  const handleDecrementBagAdult = () => {
    if(bagAdult<=0){setBagAdult(0);} else {setBagAdult(bagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };


  const handleDecrementHBagAdult = () => {
    if(hBagAdult<=0){setHBagAdult(0);} else {setHBagAdult(hBagAdult-1);if(bagTotal<=0){setBagTotal(0);} else {setBagTotal(bagTotal-1);}}

  };



  /**/




const defaultDepProps = {
    options: airValue,
    getOptionLabel: (option: AirportType) => option.name,
  };


  const onChangeDepartureAirport = async (e:any, value:any) => {
        e.preventDefault();
        setAirValue([]);
        setQuery(value);
        let tempLocale=navigator.language.split("-").length>1 ? navigator.language : (navigator.language + "-"+navigator.language.toUpperCase());
        if(navigator.language==="en"){tempLocale="en-US";}
        setAirValue([]);
          if (value.length<3) return;
          axios
            .get(`/airports_by_city?name=${value}&locale=${tempLocale}`)
            .then((results) => {
              let airTemp:AirportType[]=[];
              let airports:any=results.data;
              for(let key in airports)
              {
                    airports[key].map((item:any) => {
                       airTemp.push({ value: item.id, name: item.name});
                    });
              }
                setAirValue(airTemp);



            })
            .catch((err) => {
              console.log("There was an error");
            });
    }


  const updateDepartureAirportFunc = (value:any) => {
    if(value!=null){
     setDepartureAirportValue(value.value);
     return value.name;

     }else{return;}
   };

   const classNameDiv= window.innerWidth >900 ? "flex items-center justify-end" : "relative";


   /*windows size management*/
   function getWindowSize() {
      const {innerWidth, innerHeight} = window;
      return {innerWidth, innerHeight};
    }

   const [windowSize, setWindowSize] = useState(getWindowSize());

   useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());

    }

    window.addEventListener('resize', handleWindowResize);


    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  /**/

  function onChangeSelectedAirport(str:any) {
        var depAir = str.substring(
            str.indexOf("(") + 1,
            str.lastIndexOf(")")
        );

        setDepartureAirportValue(depAir);
        setSelectedAirValue({
            value:depAir,
            name:str
        });

    }


  return (
    <div className={`nc-SectionSliderNewAuthors ${className} container`}>
      <div className={`${UNIQUE_CLASS}`}>
      <Heading desc={t('flight.searchMapSubTitle')} className="md:mb-5">
          {t('flight.searchMapTitle')} 🗾
        </Heading>

        <div className="relative rounded-xl bg-transparent sm:p-6 container ">
            <div className={classNameDiv}>

             <ComponentWrapper  style={{fontSize:"16px", marginLeft:"0px", width:"90%"}}>
                <FaMapMarkerAlt className="map-marker" />
                <Stack spacing={4}
                    sx={{
                    width:"90%",
                    ml: 6,
                     }}
                >


                  <Combobox value={selectedAirValue.name} onChange={onChangeSelectedAirport}>
                    <div className="relative mt-1">
                      <div
                        className="relative w-full cursor-default overflow-hidden rounded-lg text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"

                      >
                        <Combobox.Input
                          className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                          style={isDarkMode ? {backgroundColor:"#242426", color:"#fff"} : {backgroundColor:"#fff", color:"#000"}}
                          onChange={(event) => onChangeDepartureAirport(event,event.target.value)}
                           placeholder={t('city')}
                        />
                        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Combobox.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                      >
                        <Combobox.Options
                            className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            style={isDarkMode ? {backgroundColor:"#242426", color:"#fff",zIndex:100} : {backgroundColor:"#fff", color:"#000",zIndex:100}}
                        >
                          {airValue.length === 0 && query !== '' ? (
                            <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                              Loading...
                            </div>
                          ) : (
                            airValue.map((air,index) => (
                              <Combobox.Option
                                key={index}
                                className={({ active }) =>
                                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                    active ? 'bg-teal-600 text-white' : isDarkMode ? 'text-gray-200' :'text-gray-900'
                                  }`
                                }
                                value={`${air.name} (${air.value})`}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={`flex flex-wrap items-center gap-2 block truncate ${
                                        selected ? 'font-medium font-semibold' : 'font-normal'
                                      }`}
                                    >
                                       {air.name} ({air.value})
                                    </span>
                                    {selected ? (
                                      <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active ? 'text-white' : 'text-teal-600'
                                        }`}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : (
                                        <span
                                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                          active ? 'text-white' : 'text-teal-600'
                                        }`}
                                      >
                                        <FaPlaneDeparture className="h-5 w-5" style={{color:"#008487"}} aria-hidden="true"/>
                                      </span>

                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))
                          )}
                        </Combobox.Options>
                      </Transition>
                    </div>
                  </Combobox>

                 {/*} </ThemeProvider>*/}
                 </Stack>

            </ComponentWrapper>

             <ComponentWrapper  style={{fontSize:"16px", width:"90%", marginTop:window.innerWidth>900 ? "0px" : "20px"}}>
                <FaPlane className="map-marker" />
                  <Stack spacing={4}
                    sx={{
                    width:"100%"

                     }}
                >
                     <FlightGuestWrapper style={{marginLeft:"3rem"}}>

                              <DateRangePickerBox
                               id="date_picker"
                               item={calendarItem}
                               startDateId="startDateId-id-home"
                               endDateId="endDateId-id-home"
                               updateSearchData={(setDateValue:any) => (setSearchDate(setDateValue))}
                               showClearDates={true}
                               small={true}
                               numberOfMonths={(window.innerWidth > 800) ? 2 : 1 }
                               required={true}
                               startDatePlaceholderText={searchDate.setStartDate || t('startDate')}
                               endDatePlaceholderText={searchDate.setEndDate || t('endDate')}
                               firstDayOfWeek={1}
                               withPortal={(windowSize.innerWidth > 800) ? false : true }
                               readOnly={isMobile ? true : false}

                             />
                            </FlightGuestWrapper>



                         </Stack>

            </ComponentWrapper>
            <ComponentWrapper>
                    <ViewWithPopup
                      key={200}
                      noView={true}
                      className="room_guest"
                      style={isDarkMode ? {backgroundColor:"#242426", color:"#fff"} : {backgroundColor:"#fff"}}
                      view={
                       <Button type="default">
                                <FaUserFriends className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}}/>
                                <span className="text-neutral-900 dark:text-neutral-200" style={{fontSize:"18px"}}>
                                    {flightAdult+flightChild+flightInfant >= 1 && `${flightAdult+flightChild+flightInfant}`}
                                </span>
                                <FaBriefcase className="user-friends" style={{color: "#008783", width:"25px", height:"25px"}} />
                                <span className="text-neutral-900 dark:text-neutral-200" style={{fontSize:"18px"}}>
                                    {bagTotal >= 0 && `${bagTotal}`}
                                </span>
                        </Button>
                      }

                      /*popup to choose room and guest*/
                      popup={
                        <FlightGuestWrapper>

                          <ItemWrapper >
                            <div>
                                  <p className="text-neutral-900 dark:text-neutral-100">{t('adult')}</p>
                            </div>
                            <InputIncDec
                              id="adult"
                              className="text-neutral-500 dark:text-neutral-200"
                              increment={() => handleIncrementAdult()}
                              decrement={() => handleDecrementAdult()}
                              value={flightAdult}
                              style={isDarkMode ? {color:"#fff"} : {color:"#000"}}
                            />
                          </ItemWrapper>
                          <ItemWrapper>
                            <div>
                                  <p className="text-neutral-900 dark:text-neutral-100">{t('child')}</p>
                                  <p className="text-xs text-neutral-900 dark:text-neutral-100">(2-11)</p>
                            </div>
                            <InputIncDec
                              id="child"
                              className="text-neutral-500 dark:text-neutral-200"
                              increment={() => handleIncrementChild()}
                              decrement={() => handleDecrementChild()}
                              value={flightChild}
                              style={isDarkMode ? {color:"#fff"} : {color:"#000"}}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <div>
                                    <p className="text-neutral-900 dark:text-neutral-100">{t('setInfant')}</p>
                                    <p className="text-xs text-neutral-900 dark:text-neutral-100">(under 2)</p>
                            </div>
                            <InputIncDec
                              id="infant"
                              className="text-neutral-500 dark:text-neutral-200"
                              increment={() => handleIncrementInfant()}
                              decrement={() => handleDecrementInfant()}
                              value={flightInfant}
                              style={isDarkMode ? {color:"#fff"} : {color:"#000"}}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                                <div className="flex-grow border-t border-neutral-900 dark:border-neutral-200"></div>
                                <span className="flex flex-wrap gap-2 items-center flex-shrink mx-4 text-neutral-900 dark:text-neutral-100"><FaBriefcase className="user-friends" style={{color: "#008783", width:"12px", height:"12px"}} />{t("bags.total")}</span>
                                <div className="flex-grow border-t border-neutral-900 dark:border-neutral-200"></div>
                          </ItemWrapper>

                        <ItemWrapper>
                            <p className="text-neutral-900 dark:text-neutral-100">{t("bag.adult")}</p>
                            <InputIncDec
                              id="bagAdult"
                              className="text-neutral-500 dark:text-neutral-200"
                              increment={() => handleIncrementBagAdult()}
                              decrement={() => handleDecrementBagAdult()}
                              value={bagAdult}
                              style={isDarkMode ? {color:"#fff"} : {color:"#000"}}
                            />
                          </ItemWrapper>

                          <ItemWrapper>
                            <p className="text-neutral-900 dark:text-neutral-100">{t("bag.hand")}</p>
                            <InputIncDec
                              id="hBag"
                              className="text-neutral-500 dark:text-neutral-200"
                              increment={() => handleIncrementHBagAdult()}
                              decrement={() => handleDecrementHBagAdult()}
                              value={hBagAdult}
                              style={isDarkMode ? {color:"#fff"} : {color:"#000"}}
                            />
                          </ItemWrapper>

                        </FlightGuestWrapper>
                      }
                    />

            </ComponentWrapper>

            </div>

            <div className="relative mt-10" >

                <ListingMapKiwi
                    currency={currencyLocale}
                    lang={lang[0]}
                    dataFrom={departureAirportValue}
                    datadep={searchDate.setStartDate ? searchDate.setStartDate : ""}
                    dataret={searchDate.setEndDate ? searchDate.setEndDate : ""}
                    dataLimit={limit+""}
                    dataPassengers={flightAdult+"-"+flightChild+"-"+flightInfant}
                    dataBags={hBagAdult+"."+bagAdult}
                />
                </div>

      </div>
      </div>
    </div>
  );
};

export default SectionWidgetPlaceDesktop;

