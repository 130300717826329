import styled from 'styled-components';

const BannerWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  color:white;

  .glide,
  .glide__track,
  .glide__slides {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: all;
  }

  .glide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .glide__bullets {
    position: absolute;
    bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    @media (max-width: 480px) {
    bottom:10px;


  }
  }

  .glide__bullet {
    width: 7px;
    height: 7px;
    transition: width 0.3s ease;
  }

  .glide__bullet--active {
    width: 25px;
    border-radius: 8px;
  }

  > div {
    min-height: 30vh;
  }

  &:after {
    display: block;
    content: '';
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
    pointer-events: none;
    z-index: 0;
  }
`;

export const SearchWrapper = styled.div`
 position:relative;
  min-height:30vh;
  width: 40%;
  padding: 0px 30px 10px;
  border-radius: 6px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.08);
  bottom: 50px;
  z-index: 1;
  color:white;

/*larghezza per mobile*/
 @media (min-width: 1400px) {
    top: 120px;
    padding-top: 15px;
    width: 87%;
  margin:auto;
 }

  @media (min-width: 900px) and (max-width: 1400px) {
    float:right;
    top: 90px;
    width: 40%;
    right: 50px;
    min-height:30vh;
  }

  @media (max-width: 899px) {
    width: 95%;
    top:100px;
    min-height:30vh;
    padding: 15px;
    left: 15px;

    > p {
      display: none;
    }
  }

  @media (max-width: 480px) {
    width: 95%;
    top:100px;
    padding: 15px;
    left: 15px;
    min-height:30vh;


    > p {
      display: none;
    }
  }

  > div {

    @media (min-width: 569px) and (max-width: 1400px) {
      margin-top: 10px;
    }

    @media (min-width: 481px) {
      margin-top: 10px;
    }
  }
  h2 {
    margin-bottom: 10px;
  }
`;


export const SearchWrapperContainer = styled.div`
  width: calc(100%);
  border-radius: 6px;
  background-color: transparent;
  z-index: 1;
  left:30px;
  display:flex;

/*larghezza per mobile*/
  @media (min-width: 569px) and (max-width: 1400px) {
    width: calc(100% - 30px);
    left: 30px;
  }

  @media (max-width: 480px) {
    width: calc(100% - 30px);
    padding: 0px;
    left: 15px;

    > p {
      display: none;
    }
  }

  > div {
    @media (min-width: 481px) {
      margin-top: 10px;
    }
  }

  h2 {
    margin-bottom: 10px;
  }
`;




export const FormWrapper = styled.div`
  display: flex;
  align-items: center;
 // justify-content: flex-end;
  border-radius: 3px 0 0 3px;
  min-height: 10vh;
  color:white;

  @media (min-width: 1400px) {

    margin-top:20px;
  }

  @media (max-width: 1400px) {
    flex-direction: column;
    margin:auto;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
  }

  /* submit button style */
  button[type='submit'].ant-btn {
    min-width: 10v;
    height: 60px;
    border-radius: 0 3px 3px 0;
    border: 0;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media (min-width: 1400px) {
      //min-width: 100%;
      border-radius: 0 0 3px 3px;
      height:90px;
    }

    @media (max-width: 1400px) {
      min-width: 100%;
      border-radius: 0 0 3px 3px;
    }

    @media (max-width: 480px) {
      height: 47px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
;

   img[type="buttonsearch"]{
    width: 40px;
    height: 40px;
    padding: 10px 10px 10px 10px;
   };



  button[type='searchData'].ant-btn {
    min-width: 157px;
    height: 60px;
    color: none;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 15px;
    font-weight: 700;
    text-shadow: none;

    @media (max-width: 1400px) {
      min-width: 100%;
    //  border-radius: 0 0 3px 3px;
    }

    @media (max-width: 480px) {
      height: 47px;
    }

    &::after {
      display: none;
    }

    &:hover {
      opacity: 0.85;
    }
  }
`;


export const ComponentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  min-height: 5vh;
  color:white;
  margin-top:10px;

  .componentBlock {
    @media (min-width: 1400px) {
      flex-direction:column;
    }
  }

  /* map auto complete style */
  .map_autocomplete {
    width: 100%;
    position: relative;
    zIndex:200;
    color:white;

    &::after {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: auto;
      color:white;
    }

    @media (max-width: 1400px) {
      &::after {
        top: auto;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }
    }
    @media (min-width: 1400px) {
      &::after {
        top: auto;
        bottom: 0;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
        flex-direction:column;
      }
    }

    @media (max-width: 480px) {
      &::after {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    input {
      border: 0;
      font-size: 15px;
      font-weight: 400;
      border-radius: 0;
      height: 60px;
      padding: 0 30px 0 40px;
      background: transparent;
      color:white;

      @media (max-width: 480px) {
        height: 47px;
        padding: 0 30px 0 36px;
      }

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
        color:white;
      }

      &::placeholder {
      color:white;
      }
    }
  }

   .map_autocomplete_fixed {
        width: 100%;
        height:50px;
        position: relative;
        zIndex:200;


        &::after {
          content: '';
          width: 1px;
          height: 20px;
          display: inline-block;
          position: absolute;
          top: calc(50% - 10px);
          right: auto;
          left: auto;

        }
          @media (min-width: 1400px) {
          &::after {
            top: auto;
            bottom: 0;
            height: 1px;
            width: calc(100% - 40px);
            left: 20px;
            flex-direction:column;
          }
        }

        @media (max-width: 1400px) {
          &::after {
            top: auto;
            bottom: 0;
            height: 1px;
            width: calc(100% - 40px);
            left: 20px;
          }
        }

        @media (max-width: 480px) {
          &::after {
            width: calc(100% - 30px);
            left: 15px;
          }
        }

        input {
          //border: 0;
          font-size: 15px;
          font-weight: 400;
          border-radius: 0;
          height: 60px;
          padding: 0 30px 0 40px;
          background: transparent;
          color:white;


          @media (max-width: 480px) {
            height: 47px;
            padding: 0 30px 0 36px;
          }

          &:hover,
          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
          color:white;
          }
        }


  }

      /**
     * Main wrapper
     */
    .select-search {
        min-width: calc(250px - 14px);
        width: calc(100% - 40px);
        margin: 0 0 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom:1px solid white;
        border-width: 1px !important;
        border-color: #e6e6e6 !important;
        color:white;


        @media (min-width: 1400px) {
          justify-content: flex-start;
          height: 60px;
        }

        @media (max-width: 1400px) {
          justify-content: flex-start;
          height: 60px;
        }

        @media (max-width: 480px) {
          height: 47px;
        }

    }



    .select-search *,
    .select-search *::after,
    .select-search *::before {

        background-color: transparent;
        color:white;
    }

    /**
     * Value wrapper
     */
    .select-search__value {
        position: relative;
        z-index: 1;
        color:white;
    }

    .select-search__value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
        color:white;
    }

    /**
     * Input
     */
    .select-search__input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 40px 0 16px;

        background: transparent;
        border: 0px solid transparent;
        //box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
        border-radius: 0px;
        outline: none;
        font-family: 'Lato', sans-serif;
        color:white;
        font-size: 15px;
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        -webkit-appearance: none;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
     * Options wrapper, box options
     */
    .select-search__select {

        height:200px;
        color:white;
        //box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    }

    /**
     * Options
     */
    .select-search__options {
        list-style: none;
    }

    /**
     * Option row
     */
    .select-search__row:not(:first-child) {
        border-top: 1px solid RGB(0,136,137,0.3);
    }

    /**
     * Option
     */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: #fff;
        border: none;
        outline: none;
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        text-align: left;
        cursor: pointer;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: #008489;
        color: white;
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: rgba(0, 136, 137, 0.1);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: #2eb378;
        color: #fff;
    }

    /**
     * Group
     */
    .select-search__group-header {
        font-size: 10px;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
     * States
     */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
       // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }

    /**
     * Modifiers
     */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
        transform: rotate(45deg);
        border-right: 0px solid #fff;
        border-bottom: 0px solid #fff;
        pointer-events: none;
    }
    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: #2FCC8B;
    }
    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 44px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 360px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #fff;
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: #888;
    }

    /* date picker style */
   .SingleDatePickerInput {
          margin: 0 0 0 0rem;

   }

  /* date picker style */
  .date_picker {
    min-width: calc(250px - 14px);
    width: calc(100% - 40px);
    margin: 0 0 0 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 1400px) {
      justify-content: flex-start;
      height: 60px;
    }

    @media (max-width: 480px) {
      height: 47px;
    }

    .DayPicker__withBorder {
      box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
    }

    .DateRangePicker {
      display: block;
      width: 100%;


      .DateRangePickerInput {
        border: 0;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;


        .DateRangePickerInput_clearDates,
        .DateRangePickerInput_clearDates {
          &.DateRangePickerInput_clearDates__small {
            display: none;
          }
        }

        .DateInput {
          width: 100%;
          padding: 0 15px 0 30px;
          background: transparent;


          @media (min-width: 569px) and (max-width: 1400px) {
            padding: 0 25px 0 30px;
          }

          @media (max-width: 568px) {
            padding: 0 15px 0 25px;
          }

          .DateInput_fang {
            display: none;
            color:white;

          }

          .DateInput_input {
            padding: 0;
            font-weight: 400;
            background: transparent;
            color:white;
            border-color:#fff;

            &.DateInput_input__focused {
              border-color: transparent;
            }

            &::placeholder {
                color:white;
            }
          }
        }

        .DateRangePickerInput_arrow {
          & + .DateInput {
            input {
              text-align: right;
            }
          }
        }
      }

      .DateRangePicker_picker {
        margin-top: -12px;
        z-index: 2;


        @media (max-width: 1400px) {
          top: 47px !important;
        }

        @media (max-width: 320px) {
          left: -29px !important;
          .DayPicker,
          .DayPicker > div > div,
          .DayPicker > div > div .DayPicker_transitionContainer {
            width: 294px !important;
          }

          .DayPicker {
            .DayPicker_weekHeader {
              padding: 0 !important;
            }

            .CalendarMonth {
              padding: 0 !important;
            }
          }
        }
      }
    }
  }



  /* view with popup component style */
  .view_with__popup {
    max-width: 100%;
    position: relative;
    margin: 0 0 0 1rem;
    color:white;

    &::before {
      content: '';
      width: 1px;
      height: 20px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 10px);
      right: auto;
      left: 0;
      z-index: 1;
      color:white;

      @media (max-width: 1400px) {
        top: 0;
        bottom: auto;
        height: 1px;
        width: calc(100% - 40px);
        left: 20px;
      }

      @media (max-width: 480px) {
        width: calc(100% - 30px);
        left: 15px;
      }
    }

    /* popup handler style */
    .popup_handler {

      margin-left: 0.2rem;
      color:white;

      button.ant-btn {
        min-width: auto;
        width: 100%;
        height: 60px;
        border: 0;
        padding: 0 30px;
        border-radius: 0;
        box-shadow: none;
        font-weight: 400;
        color:white;
        font-size: 15px;



        @media (max-width: 1400px) {
          text-align: left;
          padding: 0 25px 0 30px;
        }

        @media (max-width: 480px) {
          height: 47px;
          padding: 0 20px 0 1.8rem;
        }

        &::after {
          display: none;
        }
      }
    }

    &.room_guest {
      .popup_handler {
        button.ant-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color:white;

        }
      }
    }

    &.active {
      .popup_container {
        margin-top: 0;
        padding: 22px;
        box-shadow: 0 15px 46px -10px rgba(26, 26, 29, 0.3);
        color:white;


        @media (max-width: 1400px) {
          margin-top: 0;
        }

        @media (max-width: 480px) {
          min-width: auto;
        }
      }
    }
  }

  /* icon style */
  > svg {
    position: absolute;
    z-index: 1;
    top: auto;
    &.map-marker,
    &.calendar,
    &.user-friends {
      left: 5px;
      right: auto;
      color: #008783;
      top:20px;
    }
    

    
    &.calendar {
      @media (max-width: 480px) {
        width: 14px;
        height: 14px;
        color: #008783;
      }
    }

    &.user-friends {
      width: 17px;
      height: 17px;
      color: #008783;
      @media (max-width: 480px) {
        width: 16px;
        height: 16px;
      }
    }

    &.caret-down {
      right: 12px;
      left: auto;

      @media (max-width: 1400px) {
        right: 20px;
      }

      @media (max-width: 1400px) {
        right: 15px;
      }
    }
  }
`;

export const RoomGuestWrapper = styled.div`
  width: 100%;

  strong {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const FlightGuestWrapper = styled.div`
  width: 100%;

  strong {
    font-size: 15px;
    font-weight: 400;
  }
`;

export const FlightTypeWrapper = styled.div`
     width: 20%;
     display: flex;
    margin-left: 75%;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;

    p{
        margin-left:60%;
    }
`;



export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color:white;


  &:last-child {
    margin-bottom: 5px;
  }

  .quantity {
    height: 30px;

    input {
      font-size: 15px;

    }

    button.btn svg {
      width: 18px;
      height: 18px;
      color:white;
    }
  }
`;

export const ChooseWrapper = styled.div`
  width: calc(100%);
  padding: 28px 0px 0px;
  border-radius: 1px;
  background-color: transparent;
  //box-shadow: 0 1px 20px rgba(0, 0, 0, 0.08);
 // position: absolute;
  bottom: 290px;
  z-index: 1;

`;

export const SingleDateWrapper = styled.div`
  min-width: calc(250px - 14px);
  //width: calc(100% - 40px);
  //margin: 0 0 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-Index:1210;


   @media (max-width: 1400px) {
          justify-content: flex-start;
          height: 60px;
        }



    @media (max-width: 480px) {
      height: 47px;
    }
      .SingleDatePicker {
              display: block;
              width: 100%;
               .SingleDatePickerInput {
                    border: 0;
                    padding-right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: transparent;
                    color:white;

                    .SingleDatePickerInput_clearDates,
                    .SingleDatePickerInput_clearDates {
                      &.SingleDatePickerInput_clearDates__small {
                        display: none;
                      }
                    }
               }

               .DateInput {
                  width: 100%;
                  //padding: 0 15px 0 1rem;
                  background: transparent;
                  color:white;

                  .DateInput_fang {
                    display: none;
                  }

                  .DateInput_input {
                    padding: 0;
                    font-weight: 400;
                    background: transparent;
                    color:white;
                    border-color:#fff;

                    &.DateInput_input__focused {
                      border-color: transparent;
                    }

                    &::placeholder {
                        color:white;
                    }
                 }
        }

      }

`;








export default BannerWrapper;
